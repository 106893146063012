angular.module('vantageApp').controller('controller.AddStore', [
  '$scope',
  'orgs',
  'va.config',
  function($scope, orgs, config) {
    var ctl = this;

    $scope.platforms = [
      'Shopify',
      'Magento',
      'Bigcommerce',
      'Zoey',
      'External',
    ];

    $scope.showOrgLink = function() {
      return orgs.length;
    };

    ctl.goTo = function(platform, shopDomain) {
      if (platform === 'Shopify') {
        ctl.goToShopify(shopDomain);

      } else if (platform === 'Bigcommerce') {
        ctl.goToBigcommerce(shopDomain);

      } else if (platform === 'Magento') {
        ctl.goToMagento();

      } else if (platform === 'Zoey') {
        ctl.goToZoey();

      } else if (platform === 'External') {
        ctl.goToExternal();
      }
    };

    ctl.goToShopify = function(shopDomain) {
      if (typeof shopDomain === 'undefined') {
        shopDomain = "";
      }
      window.location = '/#/connectStore/shopify/' + shopDomain;
    };

    ctl.goToBigcommerce = function(shopDomain) {
      if (typeof shopDomain === 'undefined') {
        shopDomain = "";
      }
      window.location = '/#/connectStore/bigcommerce/' + shopDomain;
    };

    ctl.goToMagento = function() {
      window.location = '/#/connectStore/magento/';
    };

    ctl.goToZoey = function() {
      window.location = 'https://www.zoey.com/apps/vantage-analytics';
    };

    ctl.goToExternal = function() {
      window.location = '/#/connectStore/external/';
    };

    if (!config.features.admin && config.shopPlatform && config.shopDomain && config.features.auto_install_store) {
      if (config.shopPlatform === 'Shopify') {
        ctl.goTo(config.shopPlatform, config.shopDomain);
      }
    }

  }
]);
