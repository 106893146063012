angular.module('vantageApp').directive('selectedCampaignAudience', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/selected-campaign-audience.html',
      scope: {
        audience: '=',
        store: '='
      },
    };
  }
]);
