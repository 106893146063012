angular.module('vantageApp').service('service.DPASampleProducts', [
  'model.Product',
  function(Product) {
    var DPASampleProducts = {};

    DPASampleProducts.getSampleProducts = function(filter) {
      var params = {
        is_available: true,
        ordering: '-source_created_at',
      };

      return new Promise(function(resolve, reject) {
        params = _.assign(params, filter);
        product_promise = Product.all(params);
        product_promise.then(resolve).catch(function (err) {
          // Return an empty list if the products call errors out for any reason
          resolve([]);
        });
      });
    };

    DPASampleProducts.setSampleProducts = function(products, ad, quantity) {
      // Grab two random products to save as samples for the carousel
      if (products.length > 0) {
        ad.sampleProducts = _.sample(products, quantity); // When we update lodash we expect this to break.
      } else {
        ad.sampleProducts = [ // Use demo products if products can't be found in inbound.
          {"name": "Product A", "price": "34.9900"},
          {"name": "Product B", "price": "39.9900"},
        ];
      }
    };

    DPASampleProducts.populateSampleProducts = function(ads, filter) {
      var dpaProductFilter = filter;
      DPASampleProducts.getSampleProducts(dpaProductFilter).then(function(products) {
        _.forEach(ads, function(ad) {
          DPASampleProducts.setSampleProducts(products, ad, 2);
        });
      });
    };

    return DPASampleProducts;
  }
]);
