angular.module('vantageApp').factory('model.Store', [
  'lib.Model',
  '$http',
  'va.config',
  function(Model, http, config) {
    var Store = Model({
      isShopify: function() {
        return (this.store_type === 'SHOPIFY' || this.store_type === 'SHOPIFY_SAT');
      },

      isBigcommerce: function() {
        return this.store_type === 'BIGCOMMERCE';
      },

      isMagento: function() {
        return this.store_type === 'MAGENTO';
      },

      isExternal: function() {
        return this.store_type === 'EXTERNAL';
      },

      isCoopBrand: function() {
        return config.store.is_brand;
      },

      isCoopRetailer: function() {
        return config.store.is_retailer;
      },

      coopBrandName: function() {
        if(this.isCoopBrand()){
          return this.name.split(" - ")[0].trim();
        }
        else{
          return ''
        }
      },

      coopRetailerName: function() {
        if(this.isCoopBrand()){
          return this.name.split(" - ")[1].trim();
        }
        else if (this.name.includes('homedepot')){
            return 'The Home Depot';
        }
        else if (this.isCoopRetailer()){
            return 'the retailer';
        }
        else{
          return ''
        }
      },

      coopRetailerDisplayName: function() {
        var name = this.coopRetailerName();
        if (name.includes('Home Depot')){
          name = 'The Home Depot';
        }
        return name
      },

      hasRealtime: function() {
        return config.store.realtime_enabled;
      },

      hasFeature: function(name) {
        return _.contains(this.organization.features, name);
      },

      hasInstagramAccountId: function() {
        return this.primary_facebook_page && this.primary_facebook_page.instagram_account_id;
      },

      hasAdvertisingPackage: function() {
        return _.startsWith(this.organization.tier.key, 'adver') && !_.endsWith(this.organization.tier.key, 'free');
      },

      showTrafficData: function(){
        var isExternal = this.store_type === 'EXTERNAL';
        var hasAuthorizedGoogle = this.has_authorized_google;
        var uses_corona_traffic_data = config.store.uses_corona_traffic_data;
        var hasChosenProfile = !!this.ga_profile_id;

        return isExternal || uses_corona_traffic_data || (hasAuthorizedGoogle && hasChosenProfile);
      },

      retailerIsHomeDepotCanada: function(){
        return this.coopRetailerName() === 'Home Depot CA';
      },

      retailerIsHomeDepotUS: function(){
        return this.coopRetailerName() === 'Home Depot US' || this.name === 'www.homedepot.com';
      },

      hideAllRecommendations: function(){
        return this.owner.email.includes('@loreal.com');
      }
    });

    return Store.configure({
      url: '/ecom/api/v1/store/',
      instance: null,
      lastFetched: null,

      current: function() {
        var store = this;
        if ( store.instance ) {
          var timeDiff = store.lastFetched !== null ? Math.abs(new Date() - new Date(store.lastFetched)) : 100 * 60 * 1000;
          if ( timeDiff < 5 * 60 * 1000 ) {
            return Promise.resolve(store.instance);
          }
        }
        if (config.store) {
          var fetch = this.get(config.store.id);
          fetch.then(function(result) {
            store.instance = result;
            store.lastFetched = new Date().toString()
          });
          return fetch;
        }
      },

      requestFacebookPage: function(store, pageId) {
        return http.post(store.resource_uri + 'fb_page_request/', {
          "facebook_page_id": pageId
        }).then(function(response) {
          this.response = response;
        });
      },

      requestFacebookAdAccount: function(store, accountId) {
        return http.post(store.resource_uri + 'fb_agency_access_request/', {
          "facebook_ad_account_id": accountId
        });
      },

      checkAgencyAccess: function(store) {
        if (store.isCoopBrand()) {
          return Promise.resolve({
            data: {
              agency_access: true,
            }
          });
        }

        return http.post(store.resource_uri + 'check_agency_access/', {});
      },

      checkInstagramAccess: function(store, forceFetch) {
        if (store.isCoopBrand()) {
          return Promise.resolve({
            data: {
              instagram_access: true,
            }
          });
        }

        return http.post(store.resource_uri + 'check_instagram_access/', {
          'force_fetch': forceFetch
        });
      },

      checkPageBackedInstagramAccess: function(store, forceFetch) {
        if (store.isCoopBrand()) {
          return;
        }

        return http.post(store.resource_uri + 'check_page_backed_instagram_access/', {
          'force_fetch': forceFetch
        });
      }

    });
  }
]);
