angular.module('vantageApp').controller('controller.cancelWithMessage', [
  '$scope',
  '$http',
  'campaign',
  '$element',
  'close',
  'store',
  function($scope, $http, campaign, element, close, store) {
    var ctl = this;

    $scope.campaign = campaign;
    $scope.budget = $scope.campaign.budget;

    $scope.loading = false;
    $scope.error = false;
    $scope.store = store;

    $scope.canProceed = function() {
      return true;
    };

    ctl.close = function() {
      element.modal('hide');
      close();
    };

    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }
      ctl.close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      var reason = $scope.reason;

      campaign.cancelByRetailer(reason);
    };

  }
]);
