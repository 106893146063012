angular.module('vantageApp').controller('controller.confirmDeleteComment', [
  '$scope',
  '$http',
  '$element',
  'close',
  'action',
  function($scope, $http, element, close, action) {
    var ctl = this;

    $scope.loading = false;
    $scope.error = false;

    $scope.canProceed = function() {
      return true;
    };

    ctl.close = function() {
      element.modal('hide');
      close();
    };

    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }
      ctl.close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;

      action();

      this.close();
    };
  }
]);
