angular.module('vantageApp').directive('keyHandler', [
  function() {
    var keys = {
      left: 37,
      up: 38,
      right: 39,
      down: 40
    };

    return {
      scope: {
        keyDown: '=',
        keyWhitelist: '=?',
      },

      link: function(scope, element) {
        var keyDown = scope.keyDown;

        if (keyDown) {
          element.on('keydown', function(event) {
            var whitelist = scope.keyWhitelist,
                keyCode = event.keyCode,
                keyChar = _.invert(keys)[keyCode];

            // Only accept keys that were passed in the whitelist
            if (whitelist && !_.contains(whitelist, keyChar)) {
              return;
            }

            keyDown(keyChar, element, event);
          });
        }
      }
    };
}]);
