angular.module('vantageApp').factory('model.Tile', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    var Tile = Model();

    return Tile.configure({
      url: config.inboundUrl + 'api/dashboard/' + config.ownerId() + '/',
    });
  }
]);
