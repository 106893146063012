angular.module('vantageApp').controller('controller.imagesRenamed', [
  '$scope',
  '$element',
  'close',
  'renamedImages',
  function($scope, $element, close, renamedImages) {

    $scope.renamedImages = renamedImages;

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };
  }
]);
