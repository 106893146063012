angular.module('vantageApp').filter('formatDate', [
  function() {
    return function(value, format) {
      if (!format) {
        format = 'DD MMM YYYY';
      }

      return moment(value).format(format);
    };
  }
]);
