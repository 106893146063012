angular.module('vantageApp').factory('model.GoogleAd', [
  '$sce',
  'lib.Model',
  'service.CopyValidator',
  'service.UrlValidator',
  'va.config',
  function($sce, Model, copyValidator, urlValidator, config) {

    var GoogleAd = Model({
      id: null,
      type: null,
      report: null,

      status: null,
      marketingImage: null,
      finalUrl: null,
      shortHeadline: null,
      longHeadline: null,
      description: null,
      businessName: null,
      callToAction: null,
      return_on_ad_spend: null,

      // Frontend-only states that the ad can find itself in.
      isSelected: false,
      isEditable: false,

      // Persisted frontend states and values.
      isPristine: false,
      isCopied: false,

      sortOrder: 0,

      isDpa: false,

      isValid: function() {
        // If edits are needed, nothing else matters, the ad is invalid
        if (this.review_status === 'EDITS_NEEDED') {
          return false;
        }
        if (this.type == 'SHOPPING'){
          return true;
        }
        if (!this.shortHeadline || !this.longHeadline || !this.description || !this.businessName || !this.callToAction) {
          return false;
        }
        if (!copyValidator.isValidGoogleCommonRules(this.shortHeadline) || !copyValidator.isValidGoogleCommonRules(this.longHeadline) || !copyValidator.isValidGoogleCommonRules(this.description) || !copyValidator.isValidGoogleCommonRules(this.businessName)) {
          return false;
        }
        if (!copyValidator.isValidGoogleHeadline(this.shortHeadline) || !copyValidator.isValidGoogleHeadline(this.longHeadline)){
          return false;
        }
        if (this.finalUrl) {
          if (!urlValidator.isUrlParseable(this.finalUrl)) {
            return false;
          }
          if (!urlValidator.isUrlCoopDomain(this.finalUrl)) {
            return false;
          }
        }
        
        return true;
      },

      init: function(opts) {
        this.$parent.init.apply(this, [opts]);

        if (!_.contains(GoogleAd.ALLOWED_TYPES, this.type)) {
          throw new Error("Invalid ad type '" + this.type + "'");
        }
      },


      isActive: function() {
        return this.status === 'ENABLED';
      },
    });

    GoogleAd.configure({
      DISPLAY: 'DISPLAY',
      SHOPPING: 'SHOPPING'
    });

    GoogleAd.DEFAULT_CTA_MAPPING = {
        'Apply Now': 'Apply Now',
        'Book Now': 'Book Now',
        'Contact Us': 'Contact Us',
        'Download': 'Download',
        'Learn More': 'Learn More',
        'Install': 'Install',
        'Visit Site': 'Visit Site',
        'Shop Now': 'Shop Now',
        'Sign Up': 'Sign Up',
        'Get Quote': 'Get Quote',
        'Subscribe': 'Subscribe',
        'See More': 'See More',
      };

    GoogleAd.HDUS_CTA_MAPPING = {
      'Install': 'Install Now',
      'Book Now': 'Book Now',
      'Download': 'Download Now',
      'Learn More': 'Learn More',
      'Shop Now': 'Shop Now',
      'Sign Up': 'Sign Up',

    };

    GoogleAd.ALLOWED_TYPES = [
      GoogleAd.DISPLAY,
      GoogleAd.SHOPPING,
    ];

    return GoogleAd;
  }
]);
