angular.module('vantageApp').factory('model.Audience', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    /**
     * Model for campaign audiences.
     */
    var Audience = Model({
      id: null,
      name: null,
      type: null,
      status: null,
      size: null,
      errorCode: null,
      externalIdentifier: null,
      facebookChannelStatus: null,
      googleChannelStatus: null,
      pinterestChannelStatus: null,

      init: function(opts) {
        if (_.isString(opts)) {
          opts = {type: opts};
        }
        if (opts.name) {
          this.name = opts.name;
        }

        this.$parent.init.apply(this, [opts]);
      },

      isType: function(type) {
        return this.type === type;
      },

      isDPA: function() {
        return this.isType(Audience.DYNAMIC_PRODUCT) || this.isType(Audience.DYNAMIC_PROSPECTING)
          || this.isType(Audience.DYNAMIC_PRODUCT_SET);
      },

      isShopping: function() {
        return this.isType(Audience.GOOGLE_SHOPPING)
          || this.isType(Audience.PINTEREST_SHOPPING_PROSPECTING)
          || this.isType(Audience.PINTEREST_SHOPPING_RETARGETING);
      },

      isGoogleShopping: function () {
        return this.isType(Audience.GOOGLE_SHOPPING);
      },

      isPinterestShopping: function () {
        return this.isType(Audience.PINTEREST_SHOPPING_RETARGETING)
          || this.isType(Audience.PINTEREST_SHOPPING_PROSPECTING);
      },

      isDIY: function() {
        return !this.isDPA() && !this.isShopping();
      },

      isNormalLayout: function() {
          return !(this.isFlipLayout() || this.isGeoLayout() || this.isType(Audience.EXPLORE) || this.isType(Audience.CSV));
      },

      isFlipLayout: function() {
          return !this.isGeoLayout() && this.doubleSided;
      },

      isGeoLayout: function() {
          return this.isType(Audience.GEO_TARGETING);
      },

      isAvailable: function() {
        if (config.isDemoing() || this.isType(Audience.CSV) || this.isType(Audience.GEO_TARGETING)) {
          return true;
        }
        return this.status === "AVAILABLE";
      },

      isSmall: function() {
        if (!config.store.name.endsWith('- Home Depot CA')) {
          return false;
        }
        return (!this.isShopping() && this.facebookChannelStatus === 'AVAILABLE' && this.size < 1001) || (this.isGoogleShopping() && this.googleChannelStatus === 'AVAILABLE' && this.googleAudienceSize < 1001);
      },

      isAvailableFacebook: function() {
        switch (this.type) {
            case Audience.CSV:
            case Audience.GEO_TARGETING:
            case Audience.PAGE_LIKES:
            case Audience.RETAILER_PAGE_LIKES:
            case Audience.DEPARTMENT:
            case Audience.BRAND_AFFINITY:
            case Audience.BRAND_AFFINITY_RMP:
            case Audience.BRAND_ACQUISITION:
            case Audience.CAPABILITIES:
            case Audience.CATEGORY_AFFINITY:
            case Audience.CATEGORY_INTENT:
            case Audience.CATEGORY_OPPORTUNITY:
            case Audience.AGE_GROUP:
            case Audience.ETHNICITY:
            case Audience.GENDER:
            case Audience.HOME_OWNER:
            case Audience.IN_MARKET:
            case Audience.PRO_TRADES:
            case Audience.PRO_CON:
            case Audience.PRO_GENERAL:
            case Audience.E_PRO:
            case Audience.MOBILE:
            case Audience.DIY:
                return true;
            case Audience.GOOGLE_SHOPPING:
                return false;
            default:
                break;
        }

        if (config.isDemoing()) {
          return true;
        }

        return this.isAvailable() && this.facebookChannelStatus === "AVAILABLE";
      },

      isAvailableGoogle: function() {
        switch (this.type) {
            case Audience.GEO_TARGETING:
            case Audience.GOOGLE_SHOPPING:
            case Audience.DEPARTMENT:
              return true;
            default:
              break;
        }
        if (config.isDemoing() && this.couldBeAvailableGoogle()) {
          return true;
        }
        return this.isAvailable() && this.googleChannelStatus === "AVAILABLE";
      },

      isAvailablePinterest: function () {
          switch (this.type) {
              case Audience.GEO_TARGETING:
              case Audience.PINTEREST_SHOPPING_PROSPECTING:
              case Audience.PINTEREST_SHOPPING_RETARGETING:
              case Audience.BRAND_AFFINITY:
              case Audience.BRAND_AFFINITY_RMP:
              case Audience.BRAND_ACQUISITION:
              case Audience.CAPABILITIES:
              case Audience.CATEGORY_AFFINITY:
              case Audience.CATEGORY_INTENT:
              case Audience.CATEGORY_OPPORTUNITY:
              case Audience.AGE_GROUP:
              case Audience.ETHNICITY:
              case Audience.GENDER:
              case Audience.HOME_OWNER:
              case Audience.IN_MARKET:
              case Audience.PRO_TRADES:
              case Audience.PRO_CON:
              case Audience.PRO_GENERAL:
              case Audience.E_PRO:
              case Audience.MOBILE:
                  return true;
              default:
                  return false;
          }
      },

      couldBeAvailableGoogle: function() {
        switch ( this.type ) {
          case Audience.PAGE_LIKES:
          case Audience.RETAILER_PAGE_LIKES:
          case Audience.TOP_CUSTOMERS:
          case Audience.REPEATERS:
          case Audience.DYNAMIC_PRODUCT:
          case Audience.CSV:
          case Audience.LOYALTY:
            return false;
          default:
            return true;
        }
      },

      hasErrors: function() {
        return this.errorCode !== undefined && this.errorCode !== null;
      },

      errorMessage: function() {
        var map = {};
        map.audience_too_small = "The audience list is too small. We require a minimum of 500 emails. Try uploading a larger list.";
        map.error_parsing_csv = "I'm sorry, I'm having trouble reading the file you gave me. It should be a simple CSV file with one email per line.";
        return map[this.errorCode];
      },

      getName: function(includeId) {
        var name = this.name || this.getNameFromType()
        if ( includeId && this.externalIdentifier ) {
          name += ' (' + this.externalIdentifier + ')';
        }

        return name;
      },

      getNameFromType: function() {
        var map = {};

        map[Audience.ABANDONERS] = 'Abandoned Carts';
        map[Audience.REPEATERS] = 'Ready Customers';
        map[Audience.TOP_CUSTOMERS] = 'Top Customers';
        map[Audience.DYNAMIC_PRODUCT] = 'Dynamic Product';
        map[Audience.DYNAMIC_PROSPECTING] = 'DABA';
        map[Audience.DYNAMIC_PRODUCT_SET] = 'Custom Product Set';
        map[Audience.PINTEREST_SHOPPING_RETARGETING] = 'Pinterest Shopping Retargeting';
        map[Audience.PINTEREST_SHOPPING_PROSPECTING] = 'Pinterest Shopping Prospecting';
        map[Audience.GOOGLE_SHOPPING] = 'Google Shopping';
        if (config.store.is_brand) {
          map[Audience.WEBSITE_VISITORS] = 'Brand Visitors';
        } else {
          map[Audience.WEBSITE_VISITORS] = 'Website Visitors';
        }

        if (config.store.is_brand) {
          map[Audience.PAGE_LIKES] = 'Brand Facebook Page Likes';
          map[Audience.RETAILER_PAGE_LIKES] = 'Retailer Facebook Page Likes'
        } else {
          map[Audience.PAGE_LIKES] = 'Facebook Page Likes';
        }

        map[Audience.FRIENDS_OF_PAGE_LIKES] = 'Friends of Page Likes';
        //Use the audience name to be more descriptive than SavedAudience
        map[Audience.SAVED] = this.name || 'Targeted Audiences';
        map[Audience.EXPLORE] = 'Explore List';
        map[Audience.LOYALTY] = 'Loyalty Program';
        map[Audience.CSV] = 'Upload List';
        map[Audience.GEO_TARGETING] = 'Geo-Target';
        map[Audience.LOOKALIKE] = 'Lookalike Audiences';
        map[Audience.DEPARTMENT] = 'Department Shoppers';
        map[Audience.GENERIC_PROSPECTING] = 'Attract Visitors';
        map[Audience.GENERIC_RETARGETING] = 'Convert Shoppers';

        map[Audience.BRAND_AFFINITY] = 'Brand Affinity';
        map[Audience.BRAND_AFFINITY_RMP] = 'Brand Affinity RMP';
        map[Audience.BRAND_ACQUISITION] = 'Brand Acquisition';
        map[Audience.CATEGORY_AFFINITY] = 'Category Affinity';
        map[Audience.CATEGORY_INTENT] = 'Category Intent';
        map[Audience.CATEGORY_OPPORTUNITY] = 'Category Opportunity';
        map[Audience.GENDER] = 'Gender';
        map[Audience.ETHNICITY] = 'Ethnicity';
        map[Audience.AGE_GROUP] = 'Age Cohort';
        map[Audience.HOME_OWNER] = 'Home Ownership Status';
        map[Audience.E_PRO] = 'E Pros';
        map[Audience.PRO_CON] = 'Pros and Contractors';
        map[Audience.PRO_TRADES] = 'Pros and Contractors Trades';
        map[Audience.PRO_GENERAL] = 'Pros and Contractors General';
        map[Audience.CAPABILITIES] = 'Capabilities';
        map[Audience.MOBILE] = 'Mobile App Users';
        map[Audience.DIY] = 'Do It Yourself'

        return map[this.type];
      },

      getFunnelStage: function() {
        return Audience.getFunnelStage(this.type)
      }
    });

    if (config.store) {  // TODO Bandaid for drilldown_modal
      Audience.configure({
        url: '/api/' + config.store.id + '/audiences',

        ABANDONERS: 'AbandonerAudience',
        REPEATERS: 'RepeaterAudience',
        TOP_CUSTOMERS: 'TopCustomerAudience',
        DYNAMIC_PRODUCT: 'DynamicProductAudience',
        DYNAMIC_PROSPECTING: 'DynamicProductProspectingAudience',
        DYNAMIC_PRODUCT_SET: 'DynamicProductSetAudience',
        WEBSITE_VISITORS: 'WebsiteVisitorsAudience',
        CSV: 'CSVAudience',
        PAGE_LIKES: 'PageLikeAudience',
        RETAILER_PAGE_LIKES: 'RetailerPageLikeAudience',
        FRIENDS_OF_PAGE_LIKES: 'FriendsOfPageLikeAudience',
        LOYALTY: 'LoyaltyAudience',
        EXPLORE: 'ExploreAudience',
        GEO_TARGETING: 'GeoTargetingAudience',
        SAVED: 'SavedAudience',
        PINTEREST_SHOPPING_RETARGETING: 'PinterestShoppingRetargetingAudience',
        PINTEREST_SHOPPING_PROSPECTING: 'PinterestShoppingProspectingAudience',
        GOOGLE_SHOPPING: 'ShoppingAudience',
        LOOKALIKE: 'LookalikeAudience',
        DEPARTMENT: 'DepartmentAudience',
        GENERIC_PROSPECTING: 'GenericProspecting',
        GENERIC_RETARGETING: 'GenericRetargeting',
        BRAND_AFFINITY: 'BrandAffinityAudience',
        BRAND_AFFINITY_RMP: 'BrandAffinityRmpAudience',
        BRAND_ACQUISITION: 'BrandAcquisitionAudience',
        CATEGORY_AFFINITY: 'CategoryAffinityAudience',
        CATEGORY_INTENT: 'CategoryIntentAudience',
        CATEGORY_OPPORTUNITY: 'CategoryOpportunityAudience',
        AGE_GROUP: 'AgeGroupAudience',
        CAPABILITIES: 'CapabilitiesAudience',
        PRO_GENERAL: 'ProGeneralAudience',
        PRO_TRADES: 'ProTradeAudience',
        PRO_CON: 'ProConAudience',
        E_PRO: 'EProAudience',
        MOBILE: 'MobileAudience',
        ETHNICITY: 'EthnicityAudience',
        GENDER: 'GenderAudience',
        HOME_OWNER: 'HomeownerRenterAudience',
        IN_MARKET: 'InMarketAudience',
        DIY: 'DiyDifmAudience'
      });
    }

    Audience.create = function (type, doubleSided) {
      return new Audience({
        type: type,
        desc: Audience.getDescription(type),
        doubleSided: doubleSided === true,
        purpose: Audience.getFunnelStage(type)
      });
    }

    Audience.getDescription = function (type) {
      var map = {};
      map[Audience.ABANDONERS] = "Target your customers who left the site without making a purchase.";
      map[Audience.AGE_GROUP] = "Target customers based on broad age demographics.";
      map[Audience.ETHNICITY] = "Target customers based on ethnicity";
      map[Audience.GENDER] = "Target customers based on gender";
      map[Audience.HOME_OWNER] = "Target customers based on their home ownership status";
      map[Audience.BRAND_ACQUISITION] = "Conquest Targeting; Target customers who do not have a brand preference within a class.";
      map[Audience.CAPABILITIES] = "Target contractors and pros who demonstrate specific work capabilities";
      map[Audience.E_PRO] = "Target professionals and contractors based on geographic area.";
      map[Audience.PRO_CON] = "Target customers who exhibit signals of being a pro or a contractor.";
      map[Audience.PRO_GENERAL] = "Target professionals or contractors based on general purchase volume.";
      map[Audience.PRO_TRADES] = "Target pros in Cross-Category (Remodeler, Residential Property Investor, etc) or Specialty Trades.";
      map[Audience.REPEATERS] = "Bring back past customers who we think are likely to make another purchase.";
      map[Audience.TOP_CUSTOMERS] = "Bring your best customers back.";
      map[Audience.DYNAMIC_PRODUCT] = "Create a carousel ad that shows potential customers products they have already viewed.";
      map[Audience.DYNAMIC_PROSPECTING] = "Dynamic Ads for Broad Audiences";
      map[Audience.DYNAMIC_PRODUCT_SET] = "Create a carousel ad that dynamically shows shoppers a product within a specific set they have already viewed.";
      map[Audience.WEBSITE_VISITORS] = "Target anybody who's visited your website; regardless of where they bounced.";
      map[Audience.PAGE_LIKES] = "Target everybody who's liked your Facebook Page.";
      map[Audience.MOBILE] = "Target customers using the Home Depot Mobile App";
      map[Audience.GOOGLE_SHOPPING] = "Show your products in relevant Google searches.";
      map[Audience.PINTEREST_SHOPPING_PROSPECTING] = "Show your products in relevant Pinterest searches.";
      map[Audience.PINTEREST_SHOPPING_RETARGETING] = "Show your products in Pinterest shopping searches to past site visitors.";
      map[Audience.SAVED] = "Audiences made specifically for you.";
      map[Audience.LOOKALIKE] = "Target audiences similar to your existing customers.";
      map[Audience.DEPARTMENT] = "Target shoppers who are browsing products from a specific department at Home Depot.";
      map[Audience.CSV] = "Upload a .csv or .txt file of at least 500 customers with email addresses and we'll target them.";
      map[Audience.RETAILER_PAGE_LIKES] = "Target everybody who's liked the retailer's Facebook Page.";
      map[Audience.GEO_TARGETING] = "Target a specific neighborhood";
      map[Audience.GENERIC_PROSPECTING] = "Launch a prospecting campaign as soon as your store has enough data to build an audience (Minimum budget $500).";
      map[Audience.BRAND_AFFINITY] = "Target pros who have a preference for a specific brand within a class.";
      map[Audience.BRAND_AFFINITY_RMP] = "Target pros who have a preference for a specific brand within a class.";
      map[Audience.CATEGORY_AFFINITY] = "Target customers with moderate signals that are 4X more likely to convert in the next 30 days.";
      map[Audience.CATEGORY_INTENT] = "Target customers with moderate signals that are 2.5X more likely to convert in the next 30 days.";
      map[Audience.CATEGORY_OPPORTUNITY] = "Target customers with some signals that are 1.5X more likely to convert in the next 30 days.";
      map[Audience.IN_MARKET] = "Target customers with the strongest and most recent signals that are 12X more likely to convert in the next 30 days.";
      map[Audience.GENERIC_RETARGETING] = "Set a retargeting campaign to launch as soon as your store has enough data to build the audience.";
      map[Audience.DIY] = "Target customers who exhibit signs of being a DIY or DIFM";
      map[Audience.EXPLORE] = "Create a custom list using Vantage's Explore functionality.";
      map[Audience.LOYALTY] = "Drive members of your loyalty program to the website to make a purchase.";

      return map[type];
    }

    Audience.getFunnelStage = function (type) {
        var map = {};

        map[Audience.ABANDONERS] = 'Retargeting > Convert';
        map[Audience.REPEATERS] = 'Retargeting > Re-Engage';
        map[Audience.TOP_CUSTOMERS] = 'Retargeting > Re-Engage';
        map[Audience.DYNAMIC_PRODUCT] = 'Retargeting > Convert';
        map[Audience.DYNAMIC_PROSPECTING] = 'Prospecting > Attract';
        map[Audience.DYNAMIC_PRODUCT_SET] = 'Retargeting > Convert';
        map[Audience.WEBSITE_VISITORS] = 'Retargeting > Convert';
        map[Audience.PAGE_LIKES] = 'Prospecting';
        map[Audience.FRIENDS_OF_PAGE_LIKES] = 'Retargeting';
        map[Audience.RETAILER_PAGE_LIKES] = 'Prospecting';
        map[Audience.CSV] = 'Retargeting';
        map[Audience.GEO_TARGETING] = 'Prospecting';
        map[Audience.LOOKALIKE] = 'Prospecting > Attract';
        map[Audience.DEPARTMENT] = 'Prospecting > Attract';
        map[Audience.GENERIC_PROSPECTING] = 'Prospecting > Attract';
        map[Audience.BRAND_AFFINITY] = 'Prospecting > Attract';
        map[Audience.BRAND_AFFINITY_RMP] = 'Prospecting > Attract';
        map[Audience.BRAND_ACQUISITION] = 'Prospecting > Attract';
        map[Audience.IN_MARKET] = 'Retargeting > Convert';
        map[Audience.CATEGORY_OPPORTUNITY] = 'Prospecting > Attract';
        map[Audience.CATEGORY_INTENT] = 'Prospecting > Attract';
        map[Audience.CATEGORY_AFFINITY] = 'Prospecting > Attract';
        map[Audience.GENERIC_RETARGETING] = 'Retargeting > Convert';
        map[Audience.GOOGLE_SHOPPING] = 'Prospecting > Attract';
        map[Audience.PINTEREST_SHOPPING_PROSPECTING] = 'Prospecting > Attract';
        map[Audience.PINTEREST_SHOPPING_RETARGETING] = 'Retargeting > Convert';
        map[Audience.GENDER] = 'Prospecting > Attract';
        map[Audience.ETHNICITY] = 'Prospecting > Attract';
        map[Audience.AGE_GROUP] = 'Prospecting > Attract';
        map[Audience.HOME_OWNER] = 'Prospecting > Attract';
        map[Audience.E_PRO] = 'Retargeting > Convert';
        map[Audience.PRO_CON] = 'Prospecting > Attract';
        map[Audience.PRO_TRADES] = 'Retargeting > Convert';
        map[Audience.PRO_GENERAL] = 'Prospecting > Attract';
        map[Audience.CAPABILITIES] = 'Prospecting > Attract';
        map[Audience.MOBILE] = 'Retargeting > Convert';
        map[Audience.DIY] = 'Prospecting > Attract';


        return map[type]
    }

    Audience.DEMO_AUDIENCES = [
        Audience.EXPLORE,
        Audience.LOYALTY
    ]

    Audience.BACKEND_AUDIENCES = [
        Audience.SAVED,
        Audience.LOOKALIKE,
        Audience.DEPARTMENT,
        Audience.DYNAMIC_PRODUCT_SET,
        Audience.DYNAMIC_PROSPECTING,
        Audience.GEO_TARGETING,
        Audience.GENDER,
        Audience.AGE_GROUP,
        Audience.ETHNICITY,
        Audience.MOBILE,
        Audience.HOME_OWNER,
        Audience.CAPABILITIES,
        Audience.PRO_CON,
        Audience.PRO_GENERAL,
        Audience.PRO_TRADES,
        Audience.E_PRO,
        Audience.CATEGORY_INTENT,
        Audience.CATEGORY_OPPORTUNITY,
        Audience.CATEGORY_AFFINITY,
        Audience.DIY
    ]

    Audience.DEFAULT_AUDIENCES = [
      Audience.DYNAMIC_PRODUCT,
      Audience.WEBSITE_VISITORS,
      Audience.REPEATERS,
      Audience.TOP_CUSTOMERS,
      Audience.ABANDONERS,
      Audience.GOOGLE_SHOPPING,
      Audience.PINTEREST_SHOPPING_RETARGETING,
      Audience.PINTEREST_SHOPPING_PROSPECTING,
      Audience.CSV,
      Audience.PAGE_LIKES,
      Audience.RETAILER_PAGE_LIKES,
      Audience.GENERIC_PROSPECTING,
      Audience.GENERIC_RETARGETING,
      Audience.BRAND_AFFINITY,
      Audience.BRAND_AFFINITY_RMP,
      Audience.BRAND_ACQUISITION
    ];

    Audience.VALID_TYPES = [
      Audience.ABANDONERS,
      Audience.REPEATERS,
      Audience.TOP_CUSTOMERS,
      Audience.DYNAMIC_PRODUCT,
      Audience.DYNAMIC_PROSPECTING,
      Audience.WEBSITE_VISITORS,
      Audience.PAGE_LIKES,
      Audience.RETAILER_PAGE_LIKES,
      Audience.FRIENDS_OF_PAGE_LIKES,
      Audience.EXPLORE,
      Audience.LOYALTY,
      Audience.CSV,
      Audience.SAVED,
      Audience.GEO_TARGETING,
      Audience.LOOKALIKE,
      Audience.DEPARTMENT,
      Audience.GENERIC_PROSPECTING,
      Audience.GENERIC_RETARGETING,
      Audience.BRAND_AFFINITY,
      Audience.BRAND_AFFINITY_RMP,
      Audience.BRAND_ACQUISITION,
      Audience.GENDER,
      Audience.AGE_GROUP,
      Audience.ETHNICITY,
      Audience.MOBILE,
      Audience.HOME_OWNER,
      Audience.CAPABILITIES,
      Audience.PRO_CON,
      Audience.PRO_GENERAL,
      Audience.PRO_TRADES,
      Audience.E_PRO,
      Audience.CATEGORY_INTENT,
      Audience.CATEGORY_OPPORTUNITY,
      Audience.CATEGORY_AFFINITY,
      Audience.DIY
    ];

    return Audience;
  }
]);
