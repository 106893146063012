angular.module('vantageApp').factory('tile.TotalRevenue', [
  'lib.Tile',
  function(Tile) {
    return Tile({
      name: 'total-revenue',
      loadData: function(response) {
        // This conditional is here to support both the old and new responses
        // of the revenue count API. The old response returned a list of
        // revenues grouped by currency, while the new one removed that.
        // We can get rid of this when PR #255 is merged and deployed.
        var data = response.data[0] || response.data;

        this.amount = data.amount;
        this.percentChange = Math.abs(data.percent_change);
        this.setTrend(data.trend);

        if (response.data.benchmark) {
          this.benchmark = Math.abs(response.data.benchmark.value);
          this.setBenchmarkTrend(response.data.benchmark.trend);
        }

        if (response.data.share_of_benchmark) {
          this.shareOfBenchmark = response.data.share_of_benchmark.value;
          this.setShareOfBenchmarkTrend(response.data.share_of_benchmark.trend);
          this.shareOfBenchmarkPercentChange = Math.abs(response.data.share_of_benchmark.percentage_change);
        }
      }
    });
  }
]);
