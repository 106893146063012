angular.module('vantageApp').directive('adsetEditorCopy', [
  'model.FacebookAd',
  'model.GoogleAd',
  'service.CopyValidator',
  'service.UrlValidator',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  'service.AprimoSelector',
  'service.S3ImageUploader',
  'va.config',
  function(FacebookAd, GoogleAd, copyValidator, urlValidator, AdvancedEcom, AprimoSelector, S3ImageUploader, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/adset-editor-copy.html',
      require: ['ngModel', '^adsetEditor'],
      scope: {
        type: '=',
        store: '=',
        campaign: '=',
        options: '=',
      },
      link: function(scope, element, attrs, requires) {
        var model = requires[0];
        var adset = requires[1];

        if (scope.store.retailerIsHomeDepotUS()){
          scope.defaultCTAs = FacebookAd.HDUS_CTA_MAPPING;
          scope.defaultCTAsGoogle = GoogleAd.HDUS_CTA_MAPPING;
          scope.dpaCTAs = FacebookAd.HDUS_CTA_MAPPING;
        }
        else {
          scope.defaultCTAs = FacebookAd.DEFAULT_CTA_MAPPING;
          scope.defaultCTAsGoogle = GoogleAd.DEFAULT_CTA_MAPPING;
          scope.dpaCTAs = FacebookAd.DPA_CTA_MAPPING;
        }

        scope.showPagePicker = function() {
          return !_.isUndefined(this.campaign.facebookPages) && this.campaign.facebookPages.length > 1;
        };

        scope.facebookPages = function() {
          return _.reduce(this.campaign.facebookPages, function(result, page) {
            result[page.facebook_id] = page.facebook.name;
            return result;
          }, {});
        };

        scope.instagramAccounts = function() {
          return _.reduce(this.campaign.facebookPages, function(result, page) {
            // Because the back end takes a facebook ID and pulls the associated Instagram Account ID,
            // We use facebook page id here.
            result[page.facebook_id] = page.instagram.username;
            return result;
          }, {});
        };

        // Instagram defaults to "Learn More" for the `NO_BUTTON` case.
        // We'd prefer to keep things more explicit, so we copy the above two
        // objects, and remove the `NO_BUTTON` option from them.
        if (scope.store.retailerIsHomeDepotUS()){
          scope.instagramCTAs = $.extend({}, FacebookAd.HDUS_CTA_MAPPING);
          scope.instagramDPA_CTAs = $.extend({}, FacebookAd.HDUS_CTA_MAPPING);
        }
        else {
          scope.instagramCTAs = $.extend({}, FacebookAd.DEFAULT_CTA_MAPPING);
          scope.instagramDPA_CTAs = $.extend({}, FacebookAd.DPA_CTA_MAPPING);
        }
        delete scope.instagramCTAs.NO_BUTTON;
        delete scope.instagramDPA_CTAs.NO_BUTTON;

        // Called when the ng-model is passed to the view.
        model.$render = function() {
          scope.ads = model.$viewValue;

          // Ensure the example is the same as the first ad in the list.
          // We'll reduce the example's attrs down to the common elements.
          var example = scope.ads[0].clone();
          // Reduce the selected ads down to a template that we
          // can use for editing. This allows us to fill in defaults
          // when all selected ads share the same copy.
          _.forEach(scope.ads, function(ad) {
            var fields = [
              'creative.linkDescription',
              'creative.title',
              'creative.body',
              'creative.imageUrl',
              'creative.resizedImageUrl',
              'creative.videoId',
              'creative.videoFileUrl',
              'creative.videoFacebookSourceUrl',
              'creative.objectUrl',
              'creative.callToAction',
              'creative.carouselOptimized',
              'creative.carouselEndCard',
              'creative.pageId',
              'shortHeadline',
              'longHeadline',
              'description',
              'businessName',
              'finalUrl',
              'callToAction',
            ];

            _.forEach(fields, function(field) {
              var value = _.get(ad, field);

              if (_.get(example, field) != _.get(ad, field)) {
                _.set(example, field, null);
              }
            });
          });

          // Intentionally blank out fields that don't matter.
          example.isEditable = true;
          example.isSelected = false;
          scope.example = example;
          if (scope.example.creative && scope.example.creative.carouselCards){
            scope.initialCardsNumber = scope.example.creative.carouselCards.length;
            // Set True for new ads to show users that these options will be True by default on Facebook
            if (scope.example.creative.carouselOptimized == null){
              scope.example.creative.carouselOptimized = false;
            }
            if (scope.example.creative.carouselEndCard == null){
              scope.example.creative.carouselEndCard = true;
            }
            scope.isVideoCarousel = _.some(scope.example.creative.carouselCards[0].video);
          }
        };

        var getCreativeText = function(creative){
          if (scope.store.retailerIsHomeDepotUS() && creative.includes(": ")){
            creative = creative.split(":")[1].trim();
          }
          return creative;
        };

        scope.creativeChange = function(creative, index){
          var value = '';
          if (index != undefined)
            value = scope['example']['creative']['carouselCards'][index][creative];
          else
            value = scope['example']['creative'][creative];

          var text = getCreativeText(value);

          if (index != undefined)
            scope['example']['creative']['carouselCards'][index][creative] = text;
          else
            scope['example']['creative'][creative] = text;
        };

        scope.sendGAEvents = function() {
        };

        // Actions to confirm the changes
        scope.save = function() {
          var form = this.form;
          var updates = {};
          var fields = [
            'creative.linkDescription',
            'creative.title',
            'creative.body',
            'creative.imageUrl',
            'creative.resizedImageUrl',
            'creative.videoId',
            'creative.videoFileUrl',
            'creative.videoFacebookSourceUrl',
            'creative.objectUrl',
            'creative.callToAction',
            'creative.carouselOptimized',
            'creative.carouselEndCard',
            'creative.pageId',
            'shortHeadline',
            'longHeadline',
            'description',
            'businessName',
            'finalUrl',
            'callToAction',
          ];

          var cards = 5;
          var i = 0
          for (; i < cards; i++) {
            const title = 'creative.carouselCards[' + i + '].title';
            fields.push(title);
            const description = 'creative.carouselCards[' + i + '].description';
            fields.push(description);
            const link = 'creative.carouselCards[' + i + '].link';
            fields.push(link);
          }
          _.forEach(fields, function(field) {
            if (form[field] && (form[field].$dirty || scope.deleteFromMiddle)) {
              _.set(updates, field, form[field].$viewValue);
            }
          });

          if (scope.example.creative && scope.example.creative.carouselCards) {
            i = 0;
            for (; i < scope.example.creative.carouselCards.length; i++) {
              const image = 'creative.carouselCards[' + i + '].imageUrl';
              _.set(updates, image, scope.example.creative.carouselCards[i].imageUrl);
              _.forEach(Object.keys(scope.example.creative.carouselCards[0].video), function(item){
                const item_name = 'creative.carouselCards[' + i + '].video.' + item;
                _.set(updates, item_name, scope.example.creative.carouselCards[i].video[item]);
              });
            }
              for (; i < scope.initialCardsNumber; i++) {
                const title = 'creative.carouselCards[' + i + '].title';
                _.set(updates, title, '');
                const description = 'creative.carouselCards[' + i + '].description';
                _.set(updates, description, '');
                const link = 'creative.carouselCards[' + i + '].link';
                _.set(updates, link, '');
                const image = 'creative.carouselCards[' + i + '].imageUrl';
                _.set(updates, image, '');
                _.forEach(Object.keys(scope.example.creative.carouselCards[0].video), function(item){
                  const item_name = 'creative.carouselCards[' + i + '].video.' + item;
                  _.set(updates, item_name, '');
                });
              }
          }
          AdvancedEcom.markAdsetEdited(scope.type);
          adset.confirmEdit(updates);
        };

        scope.cancel = function() {
          adset.cancelEdit();
        };

        scope.template = function() {
          var map = {};

          if (scope.example.isDpa) {
            map[FacebookAd.RIGHT_SIDE] = 'views/directives/adset-editor-copy/right-side-dpa.html';
            map[FacebookAd.NEWSFEED] = 'views/directives/adset-editor-copy/newsfeed-dpa.html';
            map[FacebookAd.MOBILE] = 'views/directives/adset-editor-copy/mobile-dpa.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/adset-editor-copy/instagram-dpa.html';
            map[FacebookAd.STORIES] = 'views/directives/adset-editor-copy/stories.html';
            return map[scope.type];
          }
          else if (scope.example.creative && scope.example.creative.creativeType == 'CAROUSEL') {
            map[FacebookAd.NEWSFEED] = 'views/directives/adset-editor-copy/newsfeed-carousel.html';
            map[FacebookAd.MOBILE] = 'views/directives/adset-editor-copy/mobile-carousel.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/adset-editor-copy/instagram-carousel.html';
            map[FacebookAd.STORIES] = 'views/directives/adset-editor-copy/stories-carousel.html';
            return map[scope.type];
          }
          else {
            map[FacebookAd.NEWSFEED] = 'views/directives/adset-editor-copy/newsfeed.html';
            map[FacebookAd.RIGHT_SIDE] = 'views/directives/adset-editor-copy/right-side.html';
            map[FacebookAd.MOBILE] = 'views/directives/adset-editor-copy/mobile.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/adset-editor-copy/instagram.html';
            map[FacebookAd.STORIES] = 'views/directives/adset-editor-copy/stories.html';
            map['DISPLAY'] = 'views/directives/adset-editor-copy/google.html';


            return map[scope.type];
          }
        };

        scope.copyIsValid = function(copy) {
          return copyValidator.isValid(copy);
        };

        scope.carouselCopyIsValid = function(copy) {
          return copyValidator.isDPAValid(copy);
        };

        scope.isUrlParseable = function(url) {
          // Check that url contains a value
          if (!url) { return true; }
          return urlValidator.isUrlParseable(url)
        }

        scope.isUrlCoopDomain = function (url) {
          if (!url || !scope.store.isCoopBrand()) { return true; }
          return urlValidator.isUrlCoopDomain(url, scope.store.retailer_domain)
        }

        scope.googleCopyIsValid = function(copy, isHeadline) {
          var commonRuleResult = copyValidator.isValidGoogleCommonRules(copy)

          if(isHeadline){
            return commonRuleResult && copyValidator.isValidGoogleHeadline(copy)
          } else {
            return commonRuleResult;
          }
        };

        scope.googleCopyHasDoubleUppercase = function(copy) {
          return copyValidator.hasDoubleUpperCase(copy);
        };

        scope.$on("campaignSave", function(event, data) {
          scope.save();
        });

        scope.addNewCard = function(event){
          event.preventDefault();
          if (scope.example.creative.carouselCards.length < 5) {
            var card = {
              "title": "",
              "description": "",
              "link": scope.example.creative.objectUrl,
              "imageUrl": "",
              "video": {
                "videoId": "",
                "videoFileUrl": "",
                "videoFacebookSourceUrl": "",}
            }
            scope.example.creative.carouselCards.push(card);
            scope.cardToActivate = card;
            scope.cardIndex = scope.example.creative.carouselCards.length-1;
          }
        };

        scope.deleteFromMiddle = false;
        scope.deleteCard = function(){
          if (scope.example.creative.carouselCards.length > 2){
            if (scope.cardIndex != scope.example.creative.carouselCards.length-1){
              scope.deleteFromMiddle = true;
            }
            scope.example.creative.carouselCards.splice(scope.cardIndex, 1);
            scope.cardIndex = scope.example.creative.carouselCards.length - 1;
            scope.cardToActivate = scope.example.creative.carouselCards[scope.cardIndex];
          }
        }

        scope.cardIndex = -1;
        scope.uploading = false;
        scope.uploadImage = function(file) {
          scope.uploading = true;
          S3ImageUploader.uploadImages(file, scope.getImageUrl, scope.uploadError);
        }

        scope.uploadError = function() {
          console.log("There was an error uploading the selected image");
        }

        scope.getImageUrl = function(name, url){
          if (url && scope.cardIndex !== -1){
            scope.example.creative.carouselCards[scope.cardIndex].imageUrl = url;
          }
          scope.uploading = false;
        }

        scope.damUploading = false;
        scope.loadDAM = function() {
          scope.damUploading = true;
          AprimoSelector.openAprimo(scope.getDamImageUrl, scope.damCancelled, scope.damError, true);
        }

        scope.findImageByType = function (list, type, imageDefault) {
          var imageFound = _.filter(list, function (image) {
            var ratio = scope.getRatioByType(type);
            return image['name'].indexOf(ratio) > -1;
          });
          return (imageFound && imageFound.length) ? imageFound[0]['uri'] : imageDefault;
        }

        scope.getRatioByType = function (type) {
          var collection = [
            {
              type: 'DISPLAY',
              ratio: '16:9'
            },
            {
              type: 'INSTAGRAM',
              ratio: '1:1'
            },
            {
              type: 'MOBILE',
              ratio: '1:1'
            },
            {
              type: 'NEWS',
              ratio: '1:1'
            },
            {
              type: 'RIGHT',
              ratio: '1.91:1'
            },
            {
              type: 'RIGHT',
              ratio: '1.9:1'
            },
            {
              type: 'STORIES',
              ratio: '9:16'
            }
          ];
          var collectionItem = _.filter(collection, function (item) {
            return item.type === type;
          });
          return (collectionItem && collectionItem.length) ? collectionItem[0].ratio : undefined;
        }

        scope.getDamImageUrl = function(image_data) {
          var damUrlDefault = image_data['image_data'][0]["url"];
          if (damUrlDefault && scope.cardIndex !== -1){
            var image_source = scope.findImageByType(image_data['image_data'][0]['all_files'], scope.type, damUrlDefault);
            scope.example.creative.carouselCards[scope.cardIndex].imageUrl = image_source;
          }

          scope.damUploading = false;
        }

        scope.damError = function() {
          console.log("There was an error retrieving the selected image");
        }

        scope.damCancelled = function() {
          scope.damUploading = false;
        }

        scope.addCardVideo = function(video) {
          scope.instagram_message = '';

          if (!video.is_instagram_eligible) {
            scope.instagram_message = 'This video can not be used for Instagram ads.';
          }

          scope.example.creative.carouselCards[scope.cardIndex].video.videoId = video.id;
          scope.example.creative.carouselCards[scope.cardIndex].video.videoFacebookSourceUrl = video.facebook_source_url;
          scope.example.creative.carouselCards[scope.cardIndex].video.videoFileUrl = video.file_url;

        }

        scope.cardToActivate = undefined
        scope.tabClick = function(card, index) {
          if (index !== undefined){
            scope.cardIndex = index;
          }
          else {
            scope.cardIndex = -1;
          }
          scope.cardToActivate = card;
        }

        scope.googleInvalidCopyMessage = "Google copy cannot contain emojis, exclamation marks in the headlines, more than one exclamation mark in the ad, or multiple consecutive spaces or symbols like '@ # $ %'.";
        scope.googleUppercaseWarningMessage = "Words in all caps may trigger a rejection by Google in some cases.";
        scope.storeTerm = config.storeTerm();

      }
    };
  }
]);
