angular.module('vantageApp').directive('campaignStatus', [
  '$rootScope',
  function(rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-status.html',
      scope: {
        campaign: '=',
      },
      link: function(scope) {
        var campaign = scope.campaign;

        scope.openFeedback = rootScope.openFeedback;

        scope.state = {
          approved: 'pending',
          submitted: 'pending',
          running: 'pending',
          completed: 'pending',
        };

        scope.dates = {
          submitted: null,
          approved: null,
          running: null,
          completed: null,
          continuous: campaign.schedule.isOngoing(),
        };

        if (campaign.approvedAtDate) {
          scope.dates['approved'] = moment(campaign.approvedAtDate).toDate();
        }

        if (campaign.submittedAtDate) {
          scope.dates['submitted'] = moment(campaign.submittedAtDate).toDate();
        }

        if (campaign.schedule.startDate) {
          if (campaign.approvedAtDate) {
            scope.dates['running'] = moment.max(moment(campaign.schedule.startDate), moment(campaign.approvedAtDate)).toDate();
          } else {
            scope.dates['running'] = moment(campaign.schedule.startDate).toDate();
          }
        }

        if (campaign.schedule.endDate) {
          scope.dates['completed'] = moment(campaign.schedule.endDate).toDate();
        }

        // Set specific scope states depending on the state of the campaign.
        if (campaign.isApproved()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'active';
        }

        if (campaign.isRejected()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'warning';
        }

        if (campaign.isRunning()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'completed';
          scope.state.running = 'active';
        }

        if (campaign.isPaused()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'completed';
          scope.state.running = 'paused';
        }

        if (campaign.isCompleted()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'completed';
          scope.state.running = 'completed';
          scope.state.completed = 'completed';
        }

        if (campaign.isCancelled()) {
          scope.state.approved = 'completed';
          scope.state.submitted = 'completed';
          scope.state.running = 'completed';
          scope.state.completed = 'warning';
        }

        scope.hasRan = function() {
          var finishedStates = ['completed', 'warning'];
          return finishedStates.indexOf(scope.state.completed) !== -1 && !scope.isStartDateInFuture();
        };

        scope.isStartDateInFuture = function() {
           return moment(scope.campaign.schedule.startDate).isAfter(moment().add(5, 'minutes'));
        }

        scope.willStartInFuture = function() {
          return scope.isStartDateInFuture() && !scope.isStopped();
        };

        scope.isRunning = function() {
          if (scope.willStartInFuture()) {
            return false;
          }

          return !scope.isStopped();
        }

        scope.isStopped = function() {
          var stoppedStates = ['completed', 'warning'];
          return stoppedStates.indexOf(scope.state.completed) >= 0 || scope.state.running === 'paused';
        };

      }
    };
  }
]);
