(function (app) {
  // Manages the extremely complex functionality of the navigation.
  app.controller('NavigationController', [
    '$scope',
    '$location',
    'va.config',
    '$state',
    'model.Campaign2',
    'model.Audience',
    '$rootScope',
    function (scope, location, config, $state, Campaign, Audience, rootScope) {
      // Allow navigation to build the urls we need to go to.
      scope.urls = _.extend({}, config.urls);
      scope.section = config.section;
      scope.isOpen = false;
      scope.org = config.org;
      scope.user = config.user;
      scope.store = config.store;
      scope.coBrand = config.co_brand;
      scope.storeTerm = config.storeTerm();
      scope.openFeedback = rootScope.openFeedback;

      if (config.store) {
        if (config.store.facebook_ad_account_id) {
          var adAccountId = config.store.facebook_ad_account_id.substring(4);
          scope.facebookAdAccountURL =
            'https://business.facebook.com/adsmanager/manage/campaigns?act=' +
            adAccountId +
            '&business_id=' +
            config.store.facebook_business_manager_id;

          scope.facebookAudiencesURL =
            'https://www.facebook.com/ads/manager/audiences/?act=' +
            adAccountId +
            '&business_id=' +
            config.store.facebook_business_manager_id;

          scope.facebookPaymentsURL =
            'https://business.facebook.com/ads/manager/account_settings/account_billing/?act=' +
            adAccountId +
            '&business_id=' +
            config.store.facebook_business_manager_id;

          scope.facebookPixelURL =
            'https://business.facebook.com/events_manager2/list/pixel/' +
            config.store.facebook_pixel_id +
            '/overview?business_id=' +
            config.store.facebook_business_manager_id +
            '&act=' +
            adAccountId;

          scope.facebookBusinessManagerSettingsURL =
            'https://business.facebook.com/settings/ad-accounts/' +
            adAccountId +
            '?business_id=' +
            config.store.facebook_business_manager_id;

          scope.facebookBusinessManagerPeopleURL =
            'https://business.facebook.com/settings/people/' +
            '?business_id=' +
            config.store.facebook_business_manager_id;
        }

        if (config.store.google_ad_account_id) {
          var adAccountId = config.store.google_ad_account_id;

          scope.googleAdAccountURL =
            'https://ads.google.com/aw/campaigns?__e=' + adAccountId;
          scope.googlePaymentsURL =
            'https://ads.google.com/aw/billing/summary?__e=' + adAccountId;
          scope.googleAudiencesURL =
            'https://ads.google.com/aw/audiences/management?__e=' + adAccountId;
        }

        if (config.store.stripe_customer_id) {
          scope.stripeCustomerURL =
            'https://dashboard.stripe.com/customers/' +
            config.store.stripe_customer_id;
        }

        if (config.store.facebook_product_catalog_id) {
          scope.facebookProductCatalogURL =
            'https://business.facebook.com/products/catalogs/' +
            config.store.facebook_product_catalog_id +
            '/overview';
        }

        scope.adminStoreListURL =
          '/admin/ecommerce/store?q=' + config.store.name;
        scope.adminStoreDetailURL = '/admin/ecommerce/store/' + config.store.id;
        scope.adminStoreCampaignsURL =
          '/admin/campaign2/vantagecampaign/?store_id=' + config.store.id;
        scope.adminStoreAudiencesURL =
          '/admin/campaign2/vantageaudience/?store_id=' + config.store.id;
      }

      if (config.isDemoing()) {
        scope.campaign_count = 1;
        scope.audience_count = 7;
      } else {
        Campaign.all({ state: 'running' }).then(function (campaigns) {
          scope.campaign_count = campaigns.length;
          if (!scope.storeIsDepotUS) {
            Audience.all({ status: 'AVAILABLE' }).then(function (audiences) {
              scope.audience_count = audiences.length - scope.campaign_count;
            });
          }
        });
      }

      // Listen to the dashboard's category changes, which affects
      // the highlighted section in the navigation. We can fix this
      // once we move to real routing in a little while.
      scope.$on('$locationChangeSuccess', function () {
        var url = location.url();

        if (!url) {
          return;
        }

        scope.section = url.replace('/', '');
        scope.isExtrasOpen = false;
      });

      scope.isVIP = function () {
        if (!config.user.email) return false;
        var vipList = [
          'untika_grusz@homedepot.com',
          'amanda_berglove@homedepot.com',
          'amanda_h_adams@homedepot.com',
          'mayo_loving@homedepot.com',
          'catherine_carson@homedepot.com',
          'stephenie_cattonar@homedepot.com',
          'ivan_pena@homedepot.com',
          'tyler@gotvantage.com',
          'brandon@gotvantage.com',
        ];

        for (var i = 0; i < vipList.length; i++) {
          if (vipList[i].toLowerCase() === config.user.email) {
            return true;
          }
        }

        return false;
      };

      scope.storeIsDepot = function () {
        return (
          config.store.name.endsWith('- Home Depot CA') ||
          config.store.name === 'www.homedepot.ca'
        );
      };

      scope.storeIsDepotUS = function () {
        return (
          config.store.name.endsWith('- Home Depot US') ||
          config.store.name === 'www.homedepot.com'
        );
      };

      // Afford toggling the visibility of the navigation.
      scope.toggleVisibility = function () {
        scope.isOpen = !scope.isOpen;
      };

      // Afford toggling the account section of the navigation.
      scope.toggleExtras = function () {
        scope.isExtrasOpen = !scope.isExtrasOpen;
      };

      // Afford toggling demo mode, which shows features that are dark-released.
      scope.toggleDemo = function (event) {
        config.toggleDemo();

        if (event) {
          event.preventDefault();
        }

        scope.isExtrasOpen = false;
        $state.go($state.current, null, { reload: true });
      };

      scope.isDemoing = function () {
        return config.isDemoing();
      };
    },
  ]);
})(window.vantageApp);
