angular.module('vantageApp').directive('campaignSchedule', [
  'model.CampaignSchedule',
  '$timeout',
  'va.config',
  function(CampaignSchedule, $timeout, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-schedule.html',
      require: 'ngModel',
      scope: {
        campaign: '=',
      },
      link: function(scope, element, attrs, model) {
        model.$render = function() {
          scope.storeTerm = config.storeTerm();
          scope.schedule = model.$viewValue;
          var now = new Date();
          scope.allowEditStartDate = (!scope.campaign.id) || (scope.campaign.state === 'draft') || (scope.schedule.startDate > now);
          scope.campaignWillRestart = (scope.campaign.state === 'terminated') || (scope.campaign.state === 'completed');

          if (scope.schedule.startDate) {
            scope.schedule.startDate = moment(scope.schedule.startDate);
          }

          if (scope.schedule.endDate) {
            scope.schedule.endDate = moment(scope.schedule.endDate);
          } else {
            scope.schedule.endDate = moment(new Date()).add(90, 'days');
            console.log(scope.schedule.endDate);
          }
          // I do not know why, but the datepicker library requires
          // that these values are nested in an object. I am v confused.
          scope.newSchedule = {
            startDate: scope.schedule.startDate,
            endDate: scope.schedule.endDate,
          };

          scope.timezone = config.org.timezone || 'UTC';

          scope.isLoaded = true;

          scope.startTime = '0';
          if (scope.schedule.startDate) {
              scope.startTime = String(scope.schedule.startDate.get('hour'));
          }
          else if (config.store.name.includes('Home Depot US') || config.store.name.includes('homedepot.com')){
            scope.startTime = '9';
          }

          scope.endTime = '24';
          if (scope.schedule.endDate) {
            if (scope.schedule.endDate.get('hour') == 23 && scope.schedule.endDate.get('minute') == 59){
              scope.endTime = '24';
            } else {
              scope.endTime = String(scope.schedule.endDate.get('hour'));
            }
          }
          else if (config.store.name.includes('Home Depot US') || config.store.name.includes('homedepot.com')){
            scope.endTime = '9';
          }

          // We wrap this in a timeout to ensure the datepicker
          // is fully built before setting the initial boundaries
          $timeout(scope.refreshBoundaries);
        };

        scope.hideDatePickers = function() {
          scope.isEditingStartDate = false;
          scope.isEditingEndDate = false;
        };

        scope.formatTime = function(date){
          return date.format('h:mm A');
        };

        var setDate = function() {
          if (scope.newSchedule.startDate && scope.startTime) {
              scope.newSchedule.startDate.set('hour', parseInt(scope.startTime));
              scope.newSchedule.startDate.set('minute', 0);
          }

          if (scope.newSchedule.endDate && scope.endTime) {
            if(scope.endTime == '24'){
              scope.newSchedule.endDate.set('hour', 23);
              scope.newSchedule.endDate.set('minute', 59);
            } else {
              scope.newSchedule.endDate.set('hour', parseInt(scope.endTime));
              scope.newSchedule.endDate.set('minute', 0);
            }
          }

          // Copy over the new, updated dates.
          scope.schedule.startDate = scope.newSchedule.startDate;
          scope.schedule.endDate = scope.newSchedule.endDate;

          // Ensure the end date cannot got past the start date
          scope.refreshBoundaries();
        };

        scope.onClick = function(event, datePickerOpen) {
          if (!datePickerOpen || !$(event.target).is('td span.active')) {
            return;
          }

          // This is wrapped in a timeout to give the user a small
          // amount of time to register which date was picked.
          $timeout(setDate, 250);
        };

        $('#startTime,#endTime').change(function () {
          setDate();
        });

        scope.campaignHasGoogle = function(){
          return scope.campaign.adsets.hasOwnProperty('DISPLAY') && scope.campaign.adsets['DISPLAY'].ads.length > 0;
        };

        scope.customTimeSet = function() {
          return scope.startTime != '0' || scope.endTime != '24';
        };

        // Handles updating the minimum and maximum date boundaries
        // for the pickers, to ensure that users can't mess up.
        scope.refreshBoundaries = function() {
          scope.$broadcast('pickerUpdate', 'startDate', {
            minDate: moment(),
            maxDate: scope.schedule.endDate ? scope.schedule.endDate.clone().subtract(1, 'days') : scope.schedule.endDate
          });

          var minEndDate = moment();
          if (scope.schedule.startDate > minEndDate) {
            minEndDate = scope.schedule.startDate;
          }
          scope.$broadcast('pickerUpdate', 'endDate', {
            minDate: minEndDate.clone().add(1, 'days')
          });
        };

        // Called when toggling the datepicker. Closing the datepicker
        // without choosing an option is equivalent to reverting it.
        scope.editStartDate = function(event) {
          if (!scope.allowEditStartDate) {
            return;
          }
          scope.isEditingEndDate = false;
          scope.isEditingStartDate = !scope.isEditingStartDate;

          if (!scope.isEditingStartDate) {
            model.$render();
          }
        };

        // Called when toggling the datepicker. Closing the datepicker
        // without choosing an option is equivalent to reverting it.
        scope.editEndDate = function(event) {
          scope.isEditingStartDate = false;
          scope.isEditingEndDate = !scope.isEditingEndDate;

          if (!scope.isEditingEndDate) {
            model.$render();
          }
        };

        // Afford clearing the start date.
        scope.clearStartDate = function() {
          scope.schedule.startDate = scope.newSchedule.startDate = null;
          scope.isEditingStartDate = false;
          scope.refreshBoundaries();
        };

        // Helper to format dates.
        scope.formatDate = function(dateStr) {
          var date = moment(dateStr);
          var now = moment();

          if (date.year() === now.year()) {
            return date.format('MMM Do');
          }

          return date.format('MMM Do, YYYY');
        };
      }
    };
  }
]);
