angular.module('vantageApp').controller('controller.invalidVideoUrl', [
  '$element',
  'close',
  function($element, close) {

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };
  }
]);