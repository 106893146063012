angular.module('vantageApp').controller('controller.Setup', [
  '$scope',
  'model.Store',
  'store',
  '$state',
  '$timeout',
  '$window',
  'va.config',
  'service.FacebookPage',
  function(scope, Store, store, state, timeout, $window, config, facebookPage) {
    var ctl = this;

    // No need to go here when we're finished importing
    if (store.import_finalized && !store.isExternal()) {
      if (store.hasRealtime()) { // TODO remove when the vantage dash is retired
        return state.go('dashboard-v2.realtime', { period: 'daily' });
      }

      return state.go('dashboard.home');
    }

    if (config.user.isVantageFirst) {
      scope.steps = [
        "facebook-page",
        "category-role",
        "final"
      ];
    } else {
      scope.steps = [
        "email-phone",
        "name",
        "facebook-page",
        "category-role",
        "final"
      ];
    }

    scope.importFinalized = false;
    this.step = 0;
    this.store = store;

    this.isAnalytics = this.store.isShopify() && this.store.organization.tier.key === 'analytics_free';

    this.progress = function(data) {
      return Store.update(store, data).then(function() {
        ctl.step++;
        // Once we get to the last step start checking to see if the store is done importing.
        if (scope.isOnLastStep() && !store.isExternal()) {
          scope.checkStoreProgress();
        }
      });
    };

    scope.checkStoreProgress = function() {
      Store.get(store.id).then(function(store) {
        if (store.import_finalized) {
          scope.importFinalized = true;
        } else {
          // Check again in 60 seconds
          timeout(scope.checkStoreProgress, 60*1000);
        }
      });
    };

    scope.isOnLastStep = function() {
      return ctl.step == (scope.steps.length - 1);
    };

    scope.completeSecondLastStep = function() {
      var data = {category: store.category, owner: store.owner};
      if (store.isExternal()) {
        scope.importFinalized = true;
      }
      ctl.progress(data);
    };

    scope.isExternal = function() {
      return store.isExternal(); // Expose this without exposing the store
    };

    // FACEBOOK PAGE
    scope.facebook_page_url = "";
    scope.loading = false;

    scope.facebookApproveURL = facebookPage.getApproveURL;
    scope.facebookSettingsURL = facebookPage.getSettingsURL;

    scope.requestFacebookPage = function(facebook_page_url) {
      scope.loading = true;
      scope.facebook_page_url = facebookPage.sanitizeURL(facebook_page_url);

      Store.requestFacebookPage(
        store,
        scope.facebook_page_url
      ).then(
        function(response) {
          scope.facebookPageRequestResult = 'success';
          facebookPage.openAdmin(scope.facebook_page_url);
          scope.loading = false;
        },
        function(response) {
          scope.facebookPageRequestResult = 'error';
          scope.page_request_response = response;
          scope.loading = false;
        }
      );
    };

    scope.showForm = function() {
      var checks = [
        !scope.showSuccess(),
      ];

      return checks.every(function(element, index, array) {
        return element;
      });
    };

    scope.showSuccess = function() {
      var checks = [
        scope.facebookPageRequestResult === 'success',
      ];

      return checks.some(function(element, index, array) {
        return element;
      });
    };

    scope.isFacebookPageValid = function(facebook_page_url) {
        var sanitizedUrl = facebookPage.sanitizeURL(facebook_page_url).toLowerCase();
        return _.contains(sanitizedUrl, 'facebook.com');
    };

    facebookPage.getURL(store).then(function(pageURL) {
      scope.facebook_page_url = pageURL;
    });

  }
]);
