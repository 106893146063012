angular.module('vantageApp').controller('controller.dpaContentChangeConfirmation', [
  '$element',
  'close',
  'campaign',
  'model',
  'clearMedia',
  function($element, close, campaign, model, clearMedia) {

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };

    // Proceed with switching the content type
    this.proceed = function() {
      campaign.hasDynamicAds = true;
      campaign.deleteAllAds();
      clearMedia();

      model.$setViewValue(campaign);
      model.$render();

      $element.modal('hide');
      close();
    };
  }
]);
