angular.module('vantageApp').directive('campaignCopyset', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-copyset.html',
      require: 'ngModel',

      link: function(scope, element, attrs, model) {
        var copyset = null;

        scope.callsToAction = {
            'BOOK_TRAVEL': 'Book Now',
            'SHOP_NOW': 'Shop Now',
            'LISTEN_MUSIC' : 'Listen Now',
            'WATCH_MORE': 'Watch More'
        };

        // Called when the view has passed us the list of facebook ads
        model.$render = function() {
          copyset = scope.copyset = model.$viewValue.slice();
        };

        function countCharactersLeft(prop, totalAllowed) {
          return function(newValue, oldValue) {
            if (newValue !== oldValue) {
              scope.charsLeft[prop] = totalAllowed - newValue.length;
            }
          };
        }

        scope.create = function() {
          copyset.push({
            ad_header: '',
            ad_text: '',
            ad_link_description: '',
            call_to_action: '',
            ad_url: '',
          });

          setViewValue();
        };

        // Afford deleting ads
        scope.delete = function(copy) {
          // Keep around actually persisted ads, or ads with copy.
          // This allows us to undo, but gets rid of stupid ones.
          if (copy.id || copy.ad_header) {
            copy.deleted = true;
          } else {
            _.remove(scope.copyset, function(c) {
              return c === copy;
            });
          }

          setViewValue();
        };

        scope.$watch('ad.ad_header', countCharactersLeft('ad_header', 25));
        scope.$watch('ad.ad_text', countCharactersLeft('ad_text', 90));
        scope.$watch('ad.ad_link_description', countCharactersLeft('ad_text', 200));

        // As well as undeletes
        scope.undelete = function(copy) {
          copy.deleted = false;
          setViewValue();
        };

        // And perma-deletes
        scope.permadelete = function(copy) {
          copy.deleted = true;
          copy.hidden = true;
          setViewValue();
        };

        // Automatically select the input, but don't steal focus
        if (!$('input').is(':focus')) {
          element.find('input:first').focus();
        }

        function setViewValue() {
          model.$setViewValue(copyset.slice());
        }
      }
    };
  }
]);
