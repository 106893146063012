angular.module('vantageApp').factory('lib.BrandNameExtractor', [
  'va.config',
  function(config) {
    return {
      getBrandName: function(campaign) {
        var brandName = null;
        if (config.store.is_brand || (campaign.brand && campaign.brand.name)) {
          var campaignStoreName = null;
          if(config.store.is_brand) {
            campaignStoreName = config.store.name
          } else {
            campaignStoreName = campaign.brand.name
          }
          brandName = campaignStoreName.split(" - ")[0].trim();
        }
        return brandName;
      }
    };
  }
]);
