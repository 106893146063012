angular.module('vantageApp').controller('controller.changeAudienceConfirmation', [
  '$element',
  'close',
  'campaign',
  'model',
  'audience',
  'model.Audience',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  function($element, close, campaign, model, selectedAudience, Audience, AdvancedEcom) {

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };

    // Proceed with switching the audience
    this.proceed = function() {
      campaign.deleteAllAds();

      $element.modal('hide');
      var audience = new Audience(_.clone(selectedAudience));

      model.$setViewValue(audience);
      model.$render();
      AdvancedEcom.pickAudience();

      close();
    };
  }
]);
