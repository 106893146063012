angular.module('vantageApp').directive('dismissableAlert', [
  '$rootScope', function(rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        key: '@'
      },
      templateUrl: 'views/directives/dismissable-alert.html',

      link: function(scope, element, attrs, model) {
        scope.dismissAlert = rootScope.dismissAlert;
        scope.isAlertDismissed = rootScope.isAlertDismissed;
      }
    }
  }
]);
