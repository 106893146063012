angular.module('vantageApp').service('service.BrandMeasurement', [
  '$http',
  'va.config',
  function ($http, config) {
      var init = function() {
        var defaultSort = function (a, b) {
          if ( a.value == "NA" || a.value == "Multi" ) {
            return -1;
          }
          if ( b.value == "NA" || b.value == "Multi" ) {
            return 1;
          }

          if ( a.label > b.label ) return 1;
          if ( b.label > a.label ) return -1;
          return 0
        }
        var assignBrandData = function(brands) {
          this.departmentCodes = brands[0].data.sort(defaultSort);
          this.secondaryBrands = brands[1].data.sort(defaultSort);
          this.parentBrands = brands[2].data;
          this.brandIds = brands[3].data;
          this.productClass = brands[4].data.sort(defaultSort);
          this.eventType = brands[5].data.sort(defaultSort);
          this.podCodes = brands[6].data.sort(defaultSort);
          this.objectiveType = brands[7].data.sort(defaultSort);
        }

        return Promise.all([
          $http.get(config.asset('frontend/json/department-codes.json')),
          $http.get(config.asset('frontend/json/secondary-brands.json')),
          $http.get(config.asset('frontend/json/parent-brands.json')),
          $http.get(config.asset('frontend/json/brand-ids.json')),
          $http.get(config.asset('frontend/json/product-class.json')),
          $http.get(config.asset('frontend/json/event-list.json')),
          $http.get(config.asset('frontend/json/pod-list.json')),
          $http.get(config.asset('frontend/json/objective-list.json'))
        ]).then(assignBrandData.bind(this))
      }

      var getParents = function(brand) {
        if ( !brand ) return;
        var parentBrands = this.parentBrands[brand.toUpperCase()];
        return parentBrands ? parentBrands.sort() : undefined
      }

      var getBrandIds = function(brand) {
        if ( !brand ) return;
        var brandIds = this.brandIds[brand.toUpperCase()];
        return brandIds ? brandIds.sort() : undefined
      }

      this.initialize = init.bind(this)
      this.getParentBrands = getParents.bind(this)
      this.getBrandIds = getBrandIds.bind(this)
      this.departmentCodes = []
      this.secondaryBrands = []
      this.productClass = []
      this.parentBrands = {}
      this.brandIds = {}
      this.eventType = []
      this.objectiveType = []
  }
]);
