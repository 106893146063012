angular.module('vantageApp').directive('liveValue', [
  '$timeout',
  function(timeout) {
    return {
      restrict: 'E',
      template: '<span ng-repeat="value in values">{{value}}</span>',
      scope: {
        value: '='
      },

      link: function(scope, element) {
        var id = 0;

        scope.$watch('value', function(newValue, oldValue) {
          scope.values = {};
          // Angular likes to cache dom nodes based on their
          // data's id, but we don't want this at all.
          scope.values[id++] = oldValue;
          scope.values[id++] = newValue;

          timeout(function() {
            var current = element.find('span:first');

            element.css('height', current.outerHeight());

            if (oldValue !== newValue) {
              current.animate({ height: 0, opacity: 0 });
            } else {
              current.css('height', 0);
            }
          }, 0);
        });

        // Ensure the metric-value element gets the
        // class we expect. It could break otherwise.
        element.addClass('live-value');
      }
    };
  }
]);
