angular.module('vantageApp').directive('audienceIcon', function() {
  return {
    restrict: 'E',
    templateUrl: 'views/directives/audience-icon.html',
    scope: {
      audience: '=?',
      store: '=?',
      isDemoing: '=?',
    },

    link: function(scope) {
      if (scope.audience) {
        scope.audienceName = scope.audience.type;
      }

      scope.className = function() {
        if (scope.audienceName) {
          return 'is-' + _.kebabCase(scope.audienceName);
        }

        return 'is-default';
      };

      scope.showIcon = function() {
        var audience = scope.audience;
        var store = scope.store;
        switch (audience.type) {
          case 'RetailerPageLikeAudience':
            return !(store && store.retailer_logo_image_url && !scope.isDemoing)
          case 'PageLikeAudience':
          case 'BrandAffinityAudience':
          case 'BrandAffinityRmpAudience':
          case 'BrandAcquisitionAudience':
            return !(store && store.logo_image_url && !scope.isDemoing)
          default:
            return true;
        }
      }
    }
  };
});
