angular.module('vantageApp').directive('googleAd', [
  '$rootScope',
  'lib.Modal',
  'va.config',
  function(rootScope, Modal, config) {
    return {
      restrict: 'E',
      template: '<ng-include src="template()"></ng-include>',
      scope: {
        ad: '=',
        campaign: '=',
        isPreview: '=',
        rank: '=',
        numads: '=',
      },

      link: function(scope) {
        scope.isBrand = config.store.is_brand;
        scope.isRetailer = config.store.is_retailer;
        scope.retailerIsHomeDepotCanada = config.store.name.endsWith("- Home Depot CA") || config.store.name === "www.homedepot.ca";
        scope.retailerIsHomeDepotUS = config.store.name.endsWith("- Home Depot US") || config.store.name === "www.homedepot.com";
        scope.adminDisplay = !scope.retailerIsHomeDepotUS || config.features.admin;
        scope.logoImageUrl = function() {
          return config.store.retailer_logo_image_url || config.store.logo_image_url;
        };

        scope.template = function() {
          if (scope.campaign.audience.type == "DynamicProductAudience") {
            return 'views/directives/google-ad/dynamic-remarketing-ad.html';
          } else {
            return 'views/directives/google-ad/responsive-display-ad.html';
          }
        };

        scope.copyDrag = function(event, copy) {
          var field = event.currentTarget.getAttribute("data-creative-field");
          var maxSize = event.currentTarget.getAttribute("data-creative-field-max-length");
          if (copy.source === "VANTAGE" && copy.body.includes(": ")){
            copy.body = copy.body.split(":")[1].trim();
          }
          if (maxSize) {
            copy.body = copy.body.substring(0, maxSize);
          }

          scope.ad[field] = copy.body;
          //Since the long headline isn't shown in the preview, use the short headline as a default on drop.
          if(field === 'shortHeadline'){
            scope.ad['longHeadline'] = copy.body;
          }
          scope.ad.isPristine = false;
          scope.ad.isCopied = false;
        };

        scope.addHttp = function (url) {
          if ( !url || !url.length )
            return '...';

          if (url.toLowerCase().startsWith('http')) {
            return url
          } else {
            return 'http://' + url;
          }
        };

        scope.getUrl = function () {
          return scope.addHttp(scope.ad['finalUrl']);
        };

        scope.openLink = function () {
          window.open(scope.getUrl(), '_blank');
        };

        scope.editComment = function(comment) {
          Modal.show({
            templateUrl: 'views/modal/edit-comment.html',
            controller: 'controller.editComment',
            controllerAs: 'modal',
            inputs: {
              comment: comment,
              action: function() {
                scope.campaign.updateComment(comment);
              }
            }
          });
        };

        scope.deleteComment = function(comment) {
          Modal.show({
            templateUrl: 'views/modal/confirm-delete-comment.html',
            controller: 'controller.confirmDeleteComment',
            controllerAs: 'modal',
            inputs: {
              action: function() {
                scope.campaign.deleteComment(comment);
              }
            }
          });
        };

        scope.canEditComment = function(comment) {
          return comment.user_id == config.user.id;
        };

        scope.canDeleteComment = function(comment) {
          return comment.user_id == config.user.id;
        };
      }
    };
  }
]);
