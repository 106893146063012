angular.module('vantageApp').factory('model.UrlVideo', [
  'lib.Model',
  'va.config',
  function(Model, config) {
     var UrlVideo = Model({
       id: null,
       title: null,
       facebook_source_url: null,
       is_instagram_eligible: null,

       init: function(opts) {
         this.$parent.init.apply(this, arguments);

         if(!this.thumbnail) {
           this.thumbnail = {
             id: null,
             uri: null
           }
         }
       }
     });

     if (config.store) {
       UrlVideo.configure({
         url: '/api/' + config.store.id + '/url_videos',

         deserializeAll: function(response) {
           var cls = this;

           var videos = _.map(response.data.objects, function(data) {
             return cls.deserialize(data);
           });

           return {
             videos: videos
           };
         }

       });
     }
     return UrlVideo
  }
]);
