angular.module('vantageApp').factory('lib.Class', function() {
  /**
   * A factory for making prototypical classes.
   *
   * Book = Class({
   *   name: null,
   *   author: null,
   * });
   *
   * var book = new Book({
   *   name: 'In Watermelon Sugar',
   *   author: 'Richard Brautigan',
   * });
   *
   * You can also pass class methods in the second argument.
   *
   * Book = Class({
   *   name: null,
   *   author: null,
   * }, {
   *   create: function(name, author) {
   *     return new this({
   *       name: name,
   *       author: author
   *     });
   *    }
   * });
   *
   * var book = Book.create('In Watermelon Sugar', 'Richard Brautigan');
   */
  function Class(parent, proto) {
    if (!(this instanceof Class)) {
      // If we're extending the base Class, there will be no
      // parent passed along, so shift the arguments around
      // to provide the base prototype.
      if (!parent || !parent.prototype) {
        proto = parent;
        parent = Class;
      }

      var child = function SubClass(proto) {
        if (!(this instanceof Class)) {
          return Class(child, proto);
        } else {
          return Class.apply(this, arguments);
        }
      };

      // Ensure the child inherits class methods
      child = angular.extend(child, parent, {
        $parent: parent,
      });

      // As well as the prototype chain, so instanceof
      // and method inheritance works properly.
      child.prototype = angular.extend(Object.create(parent.prototype), proto, {
        $parent: parent.prototype,
        $class: child,
      });

      return child;
    }

    this.init.apply(this, arguments);
  }

  Class.prototype = {
    init: function(data) {
      angular.extend(this, data);
    },

    clone: function() {
      return new this.$class(_.cloneDeep(this));
    }
  };

  return Class;
});
