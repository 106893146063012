angular.module('vantageApp').directive('adsetBuilder', [
  '$rootScope',
  '$http',
  'model.FacebookAd',
  'model.GoogleAd',
  'model.PinterestAd',
  'model.Product',
  'service.CampaignCopy',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  'service.AprimoSelector',
  'service.S3ImageUploader',
  'lib.Modal',
  'va.config',
  '$sce',
  function (
    $rootScope,
    $http,
    FacebookAd,
    GoogleAd,
    PinterestAd,
    Product,
    CampaignCopy,
    AdvancedEcom,
    AprimoSelector,
    S3ImageUploader,
    Modal,
    config,
    $sce
  ) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/adset-builder.html',
      require: 'ngModel',
      scope: {
        store: '=',
      },

      link: function (scope, element, attrs, model) {
        var ctl = this;

        var buildBasicAd = function (type) {
          var copyIndex = _.random((lower = 0), (upper = 9));
          if (
            scope.campaign.lineItem &&
            scope.campaign.lineItem.creativeHeadline
          ) {
            var titles = CampaignCopy.getTitles();
            for (var i = 0; i < titles.length; i++) {
              if (
                titles[i]
                  .toLowerCase()
                  .indexOf(
                    scope.campaign.lineItem.creativeHeadline.replace(/[(]\w+[)]/, "").trim().toLowerCase()
                  ) >= 0
              ) {
                copyIndex = i;
                break;
              }
            }
          }

          var baseAdAttributes = {
            type: type,
            isPristine: scope.store && !(
              scope.store.retailerIsHomeDepotUS() ||
              scope.store.retailerIsHomeDepotCanada()
            ),
            isCopied: false,
            sortOrder: scope.campaign.generateAdOrderNumber(type),
          };

          if (type === 'DISPLAY') {
            ad = new GoogleAd(baseAdAttributes);

            ad.finalUrl = scope.store ?
              scope.store.retailer_domain ||
              scope.store.preferred_domain ||
              scope.store.domain : '';
            ad.shortHeadline = getCreativeText(
              CampaignCopy.getTitle(copyIndex).replace('!', '')
            );
            ad.longHeadline = getCreativeText(
              CampaignCopy.getTitle(copyIndex + 1).replace('!', '')
            );
            ad.description = getCreativeText(CampaignCopy.getBody(copyIndex));
            if (scope.store.isCoopBrand()) {
              ad.businessName = scope.store
                .coopRetailerDisplayName()
                .slice(0, 25);
            } else {
              ad.businessName = scope.store.name.slice(0, 25);
            }
            ad.callToAction = 'Shop Now';
          } else {
            ad = new FacebookAd(baseAdAttributes);
            if (_.contains(['RIGHT'], type)) {
              ad.creative.title = CampaignCopy.getRHSTitle(copyIndex);
            }

            if (_.contains(['MOBILE', 'NEWS'], type)) {
              ad.creative.title = getCreativeText(
                CampaignCopy.getTitle(copyIndex)
              );
            }

            if (_.contains(['MOBILE', 'NEWS'], type)) {
              ad.creative.linkDescription = getCreativeText(
                CampaignCopy.getTitle(copyIndex + 1)
              ); // Different from the title
            }

            ad.creative.body = getCreativeText(CampaignCopy.getBody(copyIndex));
            ad.creative.callToAction = 'SHOP_NOW';
            ad.creative.objectUrl =
              scope.store.retailer_domain ||
              scope.store.preferred_domain ||
              scope.store.domain;

            var facebook_page =
              scope.store.primary_facebook_page ||
              scope.store.retailer_facebook_page;
            if (facebook_page && facebook_page.facebook_id) {
              ad.creative.pageId = facebook_page.facebook_id;
            }
          }

          return ad;
        };

        var buildVideoAd = function (type, media_item) {
          if (type === 'INSTAGRAM' && media_item.state.instagram !== 'ready') {
            return;
          }

          var ad = buildBasicAd(type);
          ad.isVideoAd = true;
          ad.creative.videoId = media_item.video.id;
          ad.creative.videoFileUrl = media_item.video.file_url;
          ad.creative.videoFacebookSourceUrl =
            media_item.video.facebook_source_url;

          return ad;
        };

        var buildAds = function (placements) {
          var campaign = scope.campaign;

          _.forEach(placements, function (placement, type) {
            if (!placement.enabled) {
              campaign.setAdset(type, scope.store.retailerIsHomeDepotUS());
              return;
            }

            _.forEach(scope.media, function (media_item) {
              if (
                media_item.state.facebook !== 'ready' &&
                media_item.state.instagram !== 'ready'
              ) {
                // skip error state images
                return;
              }
              var ad = null;

              if (media_item.type === 'video') {
                // No right hand side videos
                if (
                  placement.name == 'Instagram' ||
                  placement.name == 'Newsfeed' ||
                  placement.name == 'Mobile' ||
                  placement.name == 'Stories'
                ) {
                  ad = buildVideoAd(type, media_item);
                }
              } else {
                ad = buildBasicAd(type);
              }

              if (typeof ad !== 'undefined' && ad) {
                if (ad.type === 'DISPLAY') {
                  ad.marketingImage = findImageByType(media_item['all_images'], ad.type, media_item.image_url);
                } else {
                  ad.creative.imageUrl = findImageByType(media_item['all_images'], ad.type, media_item.image_url);
                }
                campaign.pushAd(ad);
              }
            });
          });

          scope.isChoosingProducts = false;
          model.$setViewValue(campaign);
          model.$render();

          AdvancedEcom.buildAds();
        };

        var findImageByType = function (list, type, imageDefault) {
          var imageFound = _.filter(list, function (image) {
            var ratio = getRatioByType(type);
            return image['name'].indexOf(ratio) > -1;
          });
          return (imageFound && imageFound.length) ? imageFound[0]['uri'] : imageDefault;
        }

        var getRatioByType = function (type) {
          var collection = [
            {
              type: 'DISPLAY',
              ratio: '16:9'
            },
            {
              type: 'INSTAGRAM',
              ratio: '1:1'
            },
            {
              type: 'MOBILE',
              ratio: '1:1'
            },
            {
              type: 'NEWS',
              ratio: '1:1'
            },
            {
              type: 'RIGHT',
              ratio: '1.91:1'
            },
            {
              type: 'RIGHT',
              ratio: '1.9:1'
            },
            {
              type: 'STORIES',
              ratio: '9:16'
            }
          ];
          var collectionItem = _.filter(collection, function (item) {
            return item.type === type;
          });
          return (collectionItem && collectionItem.length) ? collectionItem[0].ratio : undefined;
        }

        var buildPlacements = function (
          mobileEnabled,
          newsEnabled,
          rightEnabled,
          instagramEnabled,
          storiesEnabled,
          googleEnabled,
          pinterestEnabled
        ) {
          var placements = {};

          placements[FacebookAd.MOBILE] = {
            name: 'Mobile',
            enabled: mobileEnabled,
          };
          placements[FacebookAd.NEWSFEED] = {
            name: 'Newsfeed',
            enabled: newsEnabled,
          };
          placements[FacebookAd.RIGHT_SIDE] = {
            name: 'Right-column',
            enabled: rightEnabled,
          };
          placements[FacebookAd.INSTAGRAM] = {
            name: 'Instagram',
            enabled: instagramEnabled,
          };
          placements[FacebookAd.STORIES] = {
            name: 'Stories',
            enabled: storiesEnabled,
          };
          placements['DISPLAY'] = {
            name: 'Display Ad',
            enabled: googleEnabled,
          };
          placements['PINTEREST'] = {
            name: 'Pinterest Ad',
            enabled: pinterestEnabled,
          };

          return placements;
        };

        var afterQueryProducts = function (products) {
          scope.isLoadingProducts = false;
          // Work-around for legacy magento data
          scope.products = [];
          if (
            products &&
            products.length > 0 &&
            !_.isArray(products[0].images)
          ) {
            _.forEach(products, function (product) {
              var images = [];
              _.forEach(product.images, function (image) {
                if (!_.endsWith(image, 'no_selection')) {
                  images.push({ standard: image });
                }
              });
              product.images = images;
            });
          }
          scope.products = products;
          scope.pageInfo = products.pageInfo;
          $(".input-lg[name='query']").focus();
        };

        var afterAddMedia = function () {
          // Scroll to the newly added image so it is visible
          window.setTimeout(function () {
            var adsetMedia = $('.adset-builder-media');
            if (adsetMedia[0]) {
              // TODO: Scroll to the position of the image if it is already in the list.
              var scrollPosition = adsetMedia[0].scrollWidth;
              adsetMedia.scrollLeft(scrollPosition);
            }
          }, 200);
          AdvancedEcom.pickImages();
        };

        model.$render = function () {
          scope.campaign = model.$viewValue;
        };

        // This is the list of images & videos that the builder is working with.
        // All operations will act on, and modify this media.
        scope.media = {};

        // Initialize pagination data
        scope.pageNumber = 1;
        scope.pageInfo = {};
        scope.pageSize = 10;
        scope.numberOfDaysForTopProducts = 7;

        // Initialize dam image loading value
        scope.damUploading = false;
        scope.damPopUp = false;
        scope.noImages = false;

        scope.hasMedia = function () {
          return !_.isEmpty(scope.media);
        };

        scope.checkImageDimensions = function (name, url) {
          if (!url.match(/^https?/)) {
            // This is called twice, before and after uploading to the CDN.
            return; // Skip the non-CDN call that causes a 404
          }
          var img = new Image();
          img.onload = function () {
            if (this.width < 900 && this.height < 900) {
              scope.media[name].state = {
                facebook: 'warning',
                instagram: 'warning',
                google: 'warning',
                pinterest: 'warning',
              };
              scope.media[name].message = 'Image must be at least 900px wide';
            }
            scope.$apply();
          };
          img.src = url;
        };

        scope.addImage = function (name, url, state, original, image_sources) {
          if (!url) {
            url = name;
          }
          scope.checkImageDimensions(name, url);
          scope.media[name] = {
            image_url: url,
            state: state,
            type: 'image',
            message: null,
          };

          if (image_sources && image_sources.length) {
            scope.media[name]['all_images'] = image_sources;
          }

          if (original) {
            scope.media[name].originalFilename = original.name;
            scope.media[name].newFilename = name;
            scope.media[name].wasRenamed = original.name !== name;
            showRenamedImageDialog();
          }
          afterAddMedia();
        };

        scope.addVideo = function (video) {
          var instagram_status;
          var message;

          if (video.is_instagram_eligible) {
            instagram_status = 'ready';
            message = null;
          } else {
            instagram_status = 'warning';
            message = 'This video can not be used for Instagram ads.';
          }

          var video_key = video.id || video.file_url;

          scope.media[video_key] = {
            state: {
              facebook: 'ready',
              instagram: instagram_status,
            },
            type: 'video',
            image_url: video.thumbnail.uri,
            video_file_url: video.facebook_source_url || video.file_url,
            video: video,
            message: message,
          };
          afterAddMedia();
        };

        scope.removeMedia = function (key) {
          delete scope.media[key];
        };

        scope.clearMedia = function () {
          scope.media = {};
        };

        scope.isGoogleAudienceAvailable = function () {
          //Don't allow Google Ad creation before audience is selected.
          if (!scope.campaign || !scope.campaign.audience) {
            return false;
          }
          return scope.campaign.audience.isAvailableGoogle();
        };

        scope.isPinterestAudienceAvailable = function () {
          if (!scope.campaign || !scope.campaign.audience) {
            return false;
          }
          return scope.campaign.audience.isAvailablePinterest();
        };

        scope.buildAllAds = function () {
          var isAvailableGoogle = scope.isGoogleAudienceAvailable();
          var isAvailablePinterest = scope.isPinterestAudienceAvailable();

          var facebookSelected = scope.selectedPlatforms['facebookSelected'];
          var instagramSelected = scope.selectedPlatforms['instagramSelected'];
          var googleSelected = scope.selectedPlatforms['googleSelected'];
          var storiesSelected = scope.selectedPlatforms['storiesSelected'];
          var pinterestSelected = scope.selectedPlatforms['pinterestSelected'];

          // Dont build google ads if the checkbox was selected but they chose an audience that doesn't have google
          var placements = buildPlacements(
            facebookSelected,
            facebookSelected,
            facebookSelected,
            instagramSelected,
            storiesSelected,
            isAvailableGoogle && googleSelected,
            isAvailablePinterest && pinterestSelected
          );
          buildAds(placements);
        };

        scope.showBuildFromTemplateDialog = function () {
          Modal.show({
            templateUrl: 'views/modal/build-campaign-from-template.html',
            controller: 'controller.buildCampaignFromTemplate',
            controllerAs: 'modal',
            inputs: {
              store: scope.store,
              campaign: scope.campaign,
              audience: scope.campaign.audience,
            },
          });
        };

        scope.moreOptionsExpanded = false;

        scope.toggleMoreOptions = function () {
          scope.moreOptionsExpanded = !scope.moreOptionsExpanded;
        };

        scope.selectedPlatforms = {
          facebookSelected: true,
          instagramSelected: true,
          storiesSelected: true,
          googleSelected: true,
          pinterestSelected: true,
        };

        scope.queryProducts = function () {
          scope.productQueryMethod = 'queryProducts';
          scope.isChoosingProducts = true;
          scope.isLoadingProducts = true;

          Product.all({
            is_available: true,
            search: scope.productQuery,
            page: scope.pageNumber,
            page_size: scope.pageSize,
          }).then(afterQueryProducts);
        };

        scope.queryNewProducts = function () {
          scope.productQueryMethod = 'queryNewProducts';
          scope.isChoosingProducts = true;
          scope.isLoadingProducts = true;
          scope.productQuery = '';

          Product.all({
            is_available: true,
            ordering: '-source_created_at',
            page: scope.pageNumber,
            page_size: scope.pageSize,
          }).then(afterQueryProducts);
        };

        scope.queryTopProducts = function () {
          scope.productQueryMethod = 'queryTopProducts';
          scope.isChoosingProducts = true;
          scope.isLoadingProducts = true;
          scope.productQuery = '';

          Product.top({
            days_ago: scope.numberOfDaysForTopProducts,
            page: scope.pageNumber,
            page_size: scope.pageSize,
          }).then(afterQueryProducts);
        };

        scope.numberOfPages = function () {
          return Math.ceil(scope.pageInfo.count / scope.pageSize);
        };

        scope.nextPage = function () {
          if (scope.pageInfo && scope.pageInfo.next !== null) {
            scope.pageNumber += 1;
            scope[scope.productQueryMethod]();
          }
        };

        scope.previousPage = function () {
          if (scope.pageInfo && scope.pageInfo.previous !== null) {
            scope.pageNumber -= 1;
            scope[scope.productQueryMethod]();
          }
        };

        scope.isFirstPage = function () {
          return scope.pageInfo.previous === null || scope.pageNumber == 1;
        };

        scope.isLastPage = function () {
          return scope.pageInfo.next === null;
        };

        scope.resetPage = function () {
          scope.pageNumber = 1;
        };

        scope.closeProducts = function () {
          scope.isChoosingProducts = false;
        };

        scope.isUploadingMedia = function () {
          return _.some(scope.media, function (image) {
            return (
              image.state.facebook === 'uploading' ||
              image.state.instagram === 'uploading' ||
              image.state.google === 'uploading'
            );
          });
        };

        scope.isUploadingDamMedia = function () {
          return scope.damUploading;
        };

        scope.isAudienceSelected = function () {
          var campaign = scope.campaign;
          return campaign['audience'];
        };

        scope.getVideoFileUrl = function (url) {
          return $sce.trustAsResourceUrl(url);
        };

        scope.isUrlGif = function (url) {
          return Boolean(url) && url.toLowerCase().indexOf('.gif') >= 0;
        };

        function showRenamedImageDialog() {
          if (scope.isUploadingMedia()) {
            return;
          }
          var renamedImages = {};
          _.forEach(scope.media, function (media_item) {
            if (media_item.wasRenamed) {
              renamedImages[media_item.originalFilename] =
                media_item.newFilename;
            }
          });
          var imagesWereRenamed = Object.keys(renamedImages).length > 0;
          if (imagesWereRenamed) {
            Modal.show({
              templateUrl: 'views/modal/images-renamed.html',
              controller: 'controller.imagesRenamed',
              controllerAs: 'modal',
              inputs: {
                renamedImages: renamedImages,
              },
            });
          }
        }

        scope.isPinterestShoppingSelected = false;
        scope.isDPAContentSelected = false;
        scope.isRetailerHomeDepotUS = scope.store
          ? scope.store.name.endsWith(' - Home Depot US')
          : false;

        function getCreativeText(creative) {
          if (scope.isRetailerHomeDepotUS && creative.includes(': ')) {
            creative = creative.split(':')[1].trim();
          }
          return creative;
        }

        scope.isPinterestShoppingAvailable = function () {
          return (
            scope.isRetailerHomeDepotUS &&
            scope.campaign.audience.isAvailablePinterest()
          );
        };

        scope.selectPinterestShopping = function () {
          if (scope.campaign.totalAds() > 0) {
            Modal.show({
              templateUrl:
                'views/modal/dynamic-content-change-confirmation.html',
              controller:
                'controller.pinterestShoppingContentChangeConfirmation',
              controllerAs: 'modal',
              inputs: {
                campaign: scope.campaign,
                model: model,
              },
            });
          } else {
            newAd = new PinterestAd({
              type: 'PINTEREST_SHOPPING',
            });
            scope.campaign.pushAd(newAd);
          }
        };

        scope.isDPAContentAvailable = function () {
          return (
            scope.isRetailerHomeDepotUS &&
            scope.campaign.audience.isAvailableFacebook()
          );
        };

        scope.selectDPAContent = function () {
          if (scope.campaign.totalAds() > 0) {
            Modal.show({
              templateUrl:
                'views/modal/dynamic-content-change-confirmation.html',
              controller: 'controller.dpaContentChangeConfirmation',
              controllerAs: 'modal',
              inputs: {
                campaign: scope.campaign,
                model: model,
                clearMedia: scope.clearMedia,
              },
            });
          } else {
            scope.campaign.hasDynamicAds = true;
            scope.clearMedia();
          }
        };

        scope.showSwitchToStaticContentButton = function () {
          return (
            (scope.isPinterestShoppingAvailable() &&
              scope.isPinterestShoppingSelected) ||
            (scope.isDPAContentAvailable() && scope.campaign.hasDynamicAds) ||
            (scope.campaign.id && scope.campaign.isDPAWithCustomAudience()) ||
            (scope.campaign.id && scope.campaign.hasPinterestShoppingAds())
          );
        };

        scope.switchToStaticContent = function () {
          scope.isPinterestShoppingSelected = false;
          scope.campaign.hasDynamicAds = false;

          scope.campaign.deleteAllAds();
          scope.campaign.isDirty = false;
        };

        scope.loadDam = function () {
          scope.damUploading = true;
          scope.damPopUp = false;
          AprimoSelector.openAprimo(
            scope.processDamImages,
            scope.damCancelled,
            scope.damError
          );
        };

        scope.processDamImages = function (image_data) {
          var errors = false;
          _.forEach(image_data['image_data'], function (image) {
            if (image['error_message'] != null) {
              errors = true;
            } else {
              scope.addImage(image['name'], image['url'], {
                facebook: 'ready',
                instagram: 'ready',
                google: 'ready',
              }, undefined, image['all_files']);
            }
          });
          scope.damUploading = false;
          if (errors) {
            scope.damError(null, false);
          }
        };

        scope.damError = function (message, noImage) {
          scope.damPopUp = true;
          scope.noImages = noImage;
          scope.damUploading = false;
        };

        scope.damCancelled = function () {
          scope.damUploading = false;
        };

        scope.closePopUp = function () {
          scope.damPopUp = false;
        };

        scope.uploadImages = function (images) {
          scope.isChoosingProducts = false;
          // Flag an upload so that we don't actually allow creating
          // ads until the upload has finished.

          S3ImageUploader.uploadImages(
            images,
            scope.addImage,
            scope.errorHandler
          );
        };

        scope.errorHandler = function (filename, error) {
          console.log('There was an error uploading images to S3', error);
          scope.media[filename].state = 'error';
        };

        scope.showRemoveMedia = function (state) {
          return (
            state === 'error' ||
            state.facebook === 'warning' ||
            state.instagram === 'warning' ||
            state.facebook === 'ready' ||
            state.instagram === 'ready'
          );
        };

        scope.showImage = function (state) {
          return (
            state.facebook === 'warning' ||
            state.instagram === 'warning' ||
            state.facebook === 'ready' ||
            state.instagram === 'ready'
          );
        };

        scope.isDemoing = $rootScope.isDemoing;
      },
    };
  },
]);
