angular.module('vantageApp').factory('model.Recommendation', [
  'model.Campaign2',
  'model.Store',
  '$q',
  '$rootScope',
  '$location',
  function(Campaign, Store, Promise, rootScope, location) {
    var makeRecommendation = function(name, applicable) {
      applicable = applicable || function() { return 0; };

      return {
        name: name,
        applicable: applicable
      };
    };

    var recommendations = [

      // Shown to coop brands as their first tile
      makeRecommendation('coop-brand', function(store) {
        if (!store || store.hideAllRecommendations() || (!store.isCoopBrand() && !store.name.includes('homedepot.'))) {
          return;
        }

        return 0;
      }),

      // Shown to brand new users who have never been to Vantage before.
      makeRecommendation('welcome', function(store) {
        if (!store || store.hideAllRecommendations() || store.isCoopBrand() || moment(store.installed_at).isBefore(moment().subtract(7, 'days')) || store.name.includes('homedepot.')) {
          return;
        }

        return 1;
      }),

      makeRecommendation('schedule-training', function(store) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || !isHomeDepot) {
          return;
        }

        return 2;
      }),


      // For now, shown to anyone who isn't a coop retailer to let them know how great we are
      // Later we may change this to only show to people that have Google audiences ready
      makeRecommendation('google', function(store) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || store.isCoopRetailer() || isHomeDepot || rootScope.showSpecialTHDDemoTiles()) {
          return;
        }

        return 2;
      }),

      // Shown to users who have not connected Google Analytics
      makeRecommendation('connect-google-account', function(store) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || isHomeDepot){
          return;
        }
        var hasAnalytics = !store.isExternal() && rootScope.hasFeature('ecommerce_dashboard');
        var lessThanTwoWeeksSinceInstall = moment(store.installed_at).isAfter(moment().subtract(14, 'days'));
        if (hasAnalytics && store && !store.has_authorized_google && lessThanTwoWeeksSinceInstall) {
          return 4;
        }
      }),

      // Shown if the store has the advertising feature but isn't running campaigns yet
      makeRecommendation('awaiting-campaigns', function(store, runningCampaigns) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || (store.isCoopRetailer() && !isHomeDepot)) {
          return;
        }
        var hasAdvertising = rootScope.hasFeature('advertising');
        if ((hasAdvertising && runningCampaigns.length === 0) || isHomeDepot) {
          return 6;
        }
      }),

      // Shown when there are running campaigns
      makeRecommendation('funnel', function(store) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || isHomeDepot){
          return;
        }
        var hasAdvertising = rootScope.hasFeature('advertising');
        if (hasAdvertising) {
          return 7;
        }
      }),

      // Shown when there are running campaigns
      makeRecommendation('team', function(store, runningCampaigns) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || isHomeDepot){
          return;
        }
        if (runningCampaigns.length > 0) {
          return 8;
        }
      }),

      // Shown when there are running campaigns
      makeRecommendation('campaign-progress', function(store, runningCampaigns) {
        const isHomeDepot = store.retailerIsHomeDepotUS() || store.retailerIsHomeDepotCanada() || store.name.includes('homedepot.');
        if (!store || store.hideAllRecommendations() || isHomeDepot){
          return;
        }
        if (runningCampaigns.length > 0) {
          return 10;
        }
      }),

      // Shown in demo mode for Jenny only
      makeRecommendation('demo-campaign-reco', function(store) {
        if (store && store.preferred_domain === 'jenny.mystore.com') {
          return 3;
        }
      }),

      makeRecommendation('demo-campaign-progress', function(store) {
        if (store && store.preferred_domain === 'jenny.mystore.com') {
          return 4;
        }
      }),

      // Shown in demo mode for THD only
      makeRecommendation('demo-thd-reco', function(store) {
        if (store && rootScope.showSpecialTHDDemoTiles() && location.path().indexOf('realtime') > -1) {
          return 1;
        }
      }),

      // Shown in demo mode for THD only
      makeRecommendation('demo-thd-reco-2', function(store) {
        if (store && rootScope.showSpecialTHDDemoTiles() && location.path().indexOf('campaign') > -1) {
          return 1;
        }
      }),

    ];

    return {
      all: function() {
        return Promise.all([
          Store.current(),
          Campaign.all({state: 'running'}),
        ]).then(function(results) {
          var store = results[0];
          var runningCampaigns = results[1];

          // And merge in the applicable recommendations (that might not
          // necessarily be campaign-based).
          return _(recommendations).map(function(el) {
              el.weight = el.applicable(store, runningCampaigns);
              return el;
            })
            .filter(function(el) { return typeof el.weight === 'number'; })
            .sortBy('weight')
            .value();
        });
      }
    };
  }
]);
