angular.module('vantageApp').factory('model.CampaignCharges',[
  '$http',
  'lib.Model',
  'va.config',
  function(http, Model, config) {
    /**
     * Model for campaign charges.
     */
    var CampaignCharges = Model({});

    CampaignCharges.configure({
      url: '/api/' + config.store.id + '/campaigns/',
      get: function(id, params) {
        var cls = this;

        return http.get(this.url + id + '/charges/', {
          params: params
        }).then(function(response) {
          return cls.deserializeGet(response);
        }, function(response) {
            if (response.status == 404) {
                return [];
            }
        }
        );
      }
    });

    return CampaignCharges;
  }
]);
