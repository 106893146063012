angular.module('vantageApp').directive('campaignStats', [
  'model.FacebookAd',
  'model.Audience',
  'va.config',
  function(FacebookAd, Audience, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-stats.html',
      scope: {
        campaign: '=',
        charges: '=',
      },
      link: function(scope, element, attrs, model) {

        scope.insightsSelectedTab = 'ALL';
        scope.insightsReports = {
          'ALL': scope.campaign.insights_report,
          '30DAYS': scope.campaign.insights_report_30d,
        };

        scope.googleReports = {}
        if(scope.campaign.googleCampaign){
          scope.googleReports = {
            'ALL': scope.campaign.googleCampaign.report,
            '30DAYS': scope.campaign.googleCampaign.report_30d,
          };
        }

        scope.aggregateReports = {
          'ALL': scope.campaign.aggregate_report,
          '30DAYS': scope.campaign.aggregate_report_30d,
        };

        scope.showCPA = scope.campaign.showCPA;

        var hasFacebookBreakdown = scope.campaign.insights_report && scope.campaign.insights_report.coop_breakdown && scope.campaign.insights_report.coop_breakdown.brand;
        var hasGoogleBreakdown = scope.campaign.googleCampaign && scope.campaign.googleCampaign.report && scope.campaign.googleCampaign.report.coop_breakdown && scope.campaign.googleCampaign.report.coop_breakdown.brand;

        var hasConversionData = hasFacebookBreakdown || hasGoogleBreakdown;


        scope.showConversionMetrics = scope.campaign.isCoop && hasConversionData;
        scope.showCoopBreakdown = scope.showConversionMetrics && !scope.campaign.pinterestCampaign;

        scope.showAllTimeInsights = function() {
          scope.insightsSelectedTab = 'ALL';
        };

        scope.show30dInsights = function() {
          scope.insightsSelectedTab = '30DAYS';
        };

        scope.insightsAllTimeSelected = function() {
          return scope.insightsSelectedTab === 'ALL';
        };

        scope.insights30dSelected = function() {
          return scope.insightsSelectedTab === '30DAYS';
        };

        scope.getROAS = function() {
          if (scope.insightsAllTimeSelected()) {
            return scope.campaign.return_on_ad_spend;
          } else if (scope.insights30dSelected()) {
            return scope.campaign.return_on_ad_spend_30d;
          }
        };

        scope.getCPA = function(insightsReport) {
          if (insightsReport.total_conversions > 0 && insightsReport.spend > 0) {
            return insightsReport.spend / insightsReport.total_conversions;
          }
        };

        scope.showPercentageAudienceReached = function() {
          return !scope.campaign.isDPA() &&
                 scope.insights30dSelected() &&
                 !_.isUndefined(scope.getPercentageAudienceReached());
        };

        scope.showViewRevenueMetrics = function() {
          return !scope.campaign.audience.isShopping();
        };

        scope.getPercentageAudienceReached = function() {
          // The audience for scope campaign may have been deleted, or there may be no IR yet.
          // Only valid when looking at the last 30 days.
          if (scope.campaign.audience && scope.campaign.insights_report_30d) {
            var audienceSize = scope.campaign.audience.size;
            var reach = scope.campaign.insights_report_30d.reach;

            if (!_.isUndefined(reach) && audienceSize > 0) {
              return reach / audienceSize * 100;
            }
          }
        };

        scope.showOfflineBreakdown = function() {
          var isHomeDepotCA = config.store.name.includes("Home Depot CA") || config.store.name === "www.homedepot.ca";
          var isRensPets = config.store.name.includes("Ren's Pets") || config.store.name.includes("www.renspets.com");

          return isHomeDepotCA || isRensPets;
        };

        scope.toggleBrandBreakdown = function() {
          scope.isBrandBreakdownExpanded = !scope.isBrandBreakdownExpanded;
        };
        scope.toggleVendorBreakdown = function() {
          scope.isVendorBreakdownExpanded = !scope.isVendorBreakdownExpanded;
        };
        scope.toggleRetailerBreakdown = function() {
          scope.isRetailerBreakdownExpanded = !scope.isRetailerBreakdownExpanded;
        };

        scope.isBrandBreakdownExpanded = false;
        scope.isVendorBreakdownExpanded = false;
        scope.isRetailerBreakdownExpanded = false;
        scope.revenue_currency = config.store.revenue_currency;
        scope.facebook_account_currency = config.store.facebook_account_currency;
      },
    };
  }
]);
