// This directive is lightly plagiarized from https://git.io/vaLS7
angular.module('vantageApp').directive('clickOut', [
  '$parse',
  function($parse) {
    return {
      restrict: 'A',

      link: function(scope, element, attrs, model) {
        // Get the callback function off the attrs
        var clickOut = $parse(attrs.clickOut);

        angular.element(".section").on('click', function(event) {
          if (element[0].contains(event.target)) {
            return; // This click was in this directive
          }
          // Call the click out callback function
          clickOut(scope, {$event: event});
          scope.$apply();
        });
      },
    };
  }
]);
