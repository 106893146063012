angular.module('vantageApp').directive('timeAgo', [
  '$interval',
  'va.config',
  function (interval, config) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'views/directives/time-ago.html',
      scope: {
        time: '=',
      },

      link: function(scope, element, attrs, intro) {
        scope.isDemoing = function() {
          return config.isDemoing();
        };

        function updateInterval() {
          if (scope.interval) {
            interval.cancel(scope.interval);
          }

          // Update the time every minute
          scope.interval = interval(function() {
            if (scope.time) {
              scope.sinceUpdate = moment(scope.time).fromNow();
            }
          }, 60 * 1000);
        };

        scope.$watch('time', function(newValue, oldValue) {
          if (!newValue) {
            return;
          }

          scope.sinceUpdate = moment(scope.time).fromNow();
          updateInterval();
        });
      }
    };
  }
]);
