angular.module('vantageApp').directive('campaignCharges', function() {
  return {
    restrict: 'E',
    templateUrl: 'views/directives/campaign-charges.html',
    scope: {
      charges: '=',
    },
    link: function(scope, element, attrs, model) {
      scope.items = [];

      // Collect all charges and refunds into one list to simplify the template
      _.forEach(scope.charges.charges, function(charge) {
        charge.type = 'Charge';
        scope.items.push(charge);
        _.forEach(charge.refunds, function(refund) {
          refund.type = 'Refund';
          refund.amount = 0 - refund.amount;
          scope.items.push(refund);
        });
      });

      _.forEach(scope.charges.credits, function(credit) {
        credit.type = 'Credit';
        if (credit.source) {
          credit.type = credit.type + ' (' + credit.source + ')'
        }
        scope.items.push(credit);
      });
    }
  };
});
