angular.module('vantageApp').controller('controller.DashboardV2Metrics', [
  '$scope',
  'store',
  'metrics',
  '$stateParams',
  'va.config',
  function(scope, store, metrics, params, config) {
    scope.store = store;
    scope.metrics = metrics;
    scope.period = params.period;

    var excludedMetrics = [
      // Legacy metrics
      "abandoned_checkouts",
      "average_cltv_all_time",
      "avg_transaction_revenue_30_day",
      "avg_transaction_revenue_90_day",

      // Covered by the store overview
      "revenue_90_day",
      "revenue_1_day",
      "orders_all_time",
      "orders_90_day",
      "orders_1_day",
      "users_this_week",
      "users_today",

      // Covered by the repeat customers tile
      "repeat_customers_7_day",
      "repeat_customers_30_day",
      "repeat_customers_90_day",

      // Covered by the abandoned carts tile
      "abandoned_checkouts_1_day",
      "abandoned_checkouts_90_day",
      "abandoned_checkout_value_1_day",
      "abandoned_checkouts_7_day",
      "abandoned_checkout_value_7_day",
      "abandoned_checkouts_30_day",
      "abandoned_checkout_value_30_day",
      "abandoned_checkout_value_90_day",
      "abandoned_checkouts_365_day",
      "abandoned_checkout_value_365_day",

      // Covered by the revenue timeline
      "fiscal_quarter_to_date_revenue",
      "subtotal_all_time",
      "subtotal_365_day",
      "subtotal_7_day",
      "subtotal_30_day",
      "subtotal_90_day",
      "subtotal_1_day",
      "shipping_all_time",
      "shipping_365_day",
      "shipping_7_day",
      "shipping_30_day",
      "shipping_90_day",
      "shipping_1_day",
      "taxes_all_time",
      "taxes_365_day",
      "taxes_7_day",
      "taxes_30_day",
      "taxes_90_day",
      "taxes_1_day",
      "fulfilled_all_time",
      "fulfilled_365_day",
      "fulfilled_7_day",
      "fulfilled_30_day",
      "fulfilled_90_day",
      "fulfilled_1_day",
    ];

    var metricPeriods = {
      'daily':   '1_day',
      'weekly':  '7_day',
      'monthly': '30_day',
      'quarterly': '90_day',
      'yearly': '365_day',
    };

    var metricKeyTransforms = {
      "fiscal_year_to_date_revenue": "fiscal_365_day",
      "fiscal_month_to_date_revenue": "fiscal_30_day",
      "fiscal_week_to_date_revenue": "fiscal_7_day",
      "time_since_last_order_all_time": "recency_365_day",
      "time_between_orders_all_time": "frequency_365_day",
      "revenue_all_time": "revenue_all_time_365_day"
    };

    var excludedCoronaMetrics = [
      "percentage_female_7_day",
      "percentage_female_30_day",
      "percentage_female_365_day",
      "percentage_male_7_day",
      "percentage_male_30_day",
      "percentage_male_365_day",
    ];

    var excludedCoronaBrandMetrics = [
      "revenue_all_time_365_day",
      "revenue_all_time",
      "revenue_365_day",
      "revenue_90_day",
      "revenue_30_day",
      "revenue_7_day",
    ];

    scope.metricFilter = function(metric) {
      // Ignore excluded metrics
      var metricKey = metric.metric.key;

      if (_.includes(excludedMetrics, metricKey)) {
        return false;
      }

      if (scope.store.isExternal() && _.includes(excludedCoronaMetrics, metricKey)) {
        return false;
      }

      if (config.store.is_brand && _.include(excludedCoronaBrandMetrics, metricKey)) {
        return false;
      }

      if (metricKey in metricKeyTransforms) {
        metricKey = metricKeyTransforms[metricKey];
      }

      var period = metricPeriods[scope.period];

      // Ensure we're showing metrics from the correct period
      if (!metricKey.endsWith(period)) {
        return false;
      }

      return true;
    };

    if (store) {
      scope.hasAuthorizedGoogle = store.has_authorized_google;
    }
  }
]);
