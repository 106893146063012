angular.module('vantageApp').filter('urlize', function() {
  return function(url) {
    if (!url) {
      return '#';
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'https://' + url;
    }

    return url;
  };
});
