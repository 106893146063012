angular.module('vantageApp').controller('controller.cancelPackageModal', [
  '$scope',
  '$element',
  'close',
  'action',
  'activeCampaignsCount',
  'packageExpiry',
  function($scope, element, close, action, activeCampaignsCount, packageExpiry) {

    $scope.loading = false;
    $scope.packageExpiry = packageExpiry;
    $scope.activeCampaignsCount = activeCampaignsCount;

    // TODO: DRY this out - repeated in confirm-last-card-delete.js
    $scope.reasonCode = null;
    $scope.reason = '';

    $scope.reasonCodes = {
      PERFORMANCE: 'Poor Performance',
      COMPETITOR: 'Moving to Competitor',
      INHOUSE: 'Taking Ads In-House',
      EXPENSIVE: 'Too Expensive',
      OTHER:  'Other'
    };

    // Close the modal.
    this.close = function() {
      element.modal('hide');
      close();
    };

    this.setReasonCode = function(key) {
      $scope.reasonCode = key;
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      action($scope.reasonCode, $scope.reason);
    };

  }
]);
