angular.module('vantageApp').controller('controller.EmailSettings', [
  '$scope',
  'model.Store',
  'model.DigestRecipient',
  'store',
  'model.User',
  'user',
  function(scope, Store, DigestRecipient, store, User, user) {
    scope.store = store;
    scope.showSaveCompletedMessage = false;
    scope.user = user;
    if (!user.email_settings[store.organization.id]) {
      user.email_settings[store.organization.id] = { campaign_state_change_emails: false };
    }
    scope.user.campaign_state_change_emails = user.email_settings[store.organization.id] && user.email_settings[store.organization.id].campaign_state_change_emails;
    if (!user.email_settings[store.organization.id].digest_configuration) {
      user.email_settings[store.organization.id].digest_configuration = {
        days_of_week_friday: false,
        days_of_week_monday: true,
        days_of_week_saturday: false,
        days_of_week_sunday: false,
        days_of_week_thursday: false,
        days_of_week_tuesday: false,
        days_of_week_wednesday: false,
        frequency: "WEEKLY",
        time_of_day: "6"
      }
    }

    scope.user.digest_configuration = user.email_settings[store.organization.id].digest_configuration;
    scope.userIsOwner = user.id === store.owner.id;
    scope.retailerIsHomeDepotCanada = store.retailerIsHomeDepotCanada();
    scope.retailerIsHomeDepotUS = store.retailerIsHomeDepotUS();

    scope.saveUser = function() {
      user.email_settings[store.organization.id].campaign_state_change_emails = scope.user.campaign_state_change_emails;
      User.update(user, {
        email_settings: user.email_settings
      }).then(scope.openSaveCompletedMessage);
    };

    scope.selectDayOfWeek = function(selectedDay) {
      if (user.digest_configuration.frequency == 'WEEKLY') {
        if (selectedDay != 'monday')
            user.digest_configuration.days_of_week_monday = false;
        if (selectedDay != 'tuesday')
            user.digest_configuration.days_of_week_tuesday = false;
        if (selectedDay != 'wednesday')
            user.digest_configuration.days_of_week_wednesday = false;
        if (selectedDay != 'thursday')
            user.digest_configuration.days_of_week_thursday = false;
        if (selectedDay != 'friday')
            user.digest_configuration.days_of_week_friday = false;
        if (selectedDay != 'saturday')
            user.digest_configuration.days_of_week_saturday = false;
        if (selectedDay != 'sunday')
            user.digest_configuration.days_of_week_sunday = false;
      }
    };

    scope.selectDailyFrequency = function() {
      user.digest_configuration.days_of_week_monday = true;
      user.digest_configuration.days_of_week_tuesday = true;
      user.digest_configuration.days_of_week_wednesday = true;
      user.digest_configuration.days_of_week_thursday = true;
      user.digest_configuration.days_of_week_friday = true;
      user.digest_configuration.days_of_week_saturday = true;
      user.digest_configuration.days_of_week_sunday = true;
    };

    scope.selectWeeklyFrequency = function() {
      user.digest_configuration.days_of_week_monday = true;
      user.digest_configuration.days_of_week_tuesday = false;
      user.digest_configuration.days_of_week_wednesday = false;
      user.digest_configuration.days_of_week_thursday = false;
      user.digest_configuration.days_of_week_friday = false;
      user.digest_configuration.days_of_week_saturday = false;
      user.digest_configuration.days_of_week_sunday = false;
    };

    scope.openSaveCompletedMessage = function() {
      return scope.showSaveCompletedMessage = true;
    };

    scope.closeSaveCompletedMessage = function() {
      return scope.showSaveCompletedMessage = false;
    };
  }
]);
