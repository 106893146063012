angular.module('vantageApp').filter('multiplier', [
  function() {
    return function(value, format) {
      if (_.isNumber(value)) {
        return numbro(value).format(format || '0,0.0') + "X";
      }
      return "-";
    };
  }
]);
