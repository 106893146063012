angular.module('vantageApp').controller('controller.DashboardRecommendations', [
  '$scope',
  '$rootScope',
  '$state',
  'va.config',
  'recommendations',
  'store',
  'campaigns',
  'audiences',
  function(scope, rootScope, state, config, recommendations, store, campaigns, audiences) {
    scope.recommendations = recommendations.slice(0, 3);
    scope.store = store;
    scope.config = config;
    scope.campaigns = campaigns;
    scope.audiences = audiences;
    scope.openFeedback = rootScope.openFeedback;
    scope.isDemoing = rootScope.isDemoing;
  }
]);
