(function(window) {
  var app = angular.module('vantageApp', [
    'ngSanitize',
    'highcharts-ng',
    'ngCookies',
    'ngTouch',
    'ui.bootstrap',
    'ui.router',
    'mgcrea.ngStrap',
    'angularModalService',
    'datePicker',
    'monospaced.elastic',
    'ngFileUpload',
    'slickCarousel',
    'stripe.checkout',
    'angulartics',
    'angulartics.google.analytics',
    'ang-drag-drop'
    ]);

  app.factory('va.config', [
    '$cookies',
    function(cookies) {
      // This can be replaced with a better system once we
      // have it in place. For now this is better than a global.
      return angular.extend({}, window.config, {
        /**
         * Helper for generating a URL by name.
         */
        url: function(name, params) {
          var url = this.urls[name];

          _.forEach(params, function(value, name) {
            url = url.replace(name, value);
          });

          return url;
        },

        /**
         * Helper for generating the path to a static asset.
         */
        asset: function(path) {
          return this.staticBase + path;
        },

        /**
         * Helper for returning the user's JWT.
         */
        jwt: function() {
          return this.user && this.user.jwt;
        },

        /**
         * Returns the owner id the user is currently accessing.
         */
        ownerId: function() {
          if (!this.store || !this.store.owner_id || this.store.owner_id === "None") {
            return null;
          }

          return this.store.owner_id;
        },

        retailerOwnerId: function() {
          if (!this.store || !this.store.retailer_owner_id || this.store.retailer_owner_id === "None") {
            return null;
          }

          return this.store.retailer_owner_id;
        },

        /**
         * Whether or not the user is demoing.
         */
        isDemoing: function() {
          var isDemoing = this.features && this.features.demo;

          // We try to use cookies to store what the actual demo state is.
          // This allows us to persist it across refreshes and store changes.
          if (typeof cookies.get('vantage-demo') !== 'undefined') {
            isDemoing = cookies.get('vantage-demo') === '1' ? true : false;
          }

          return isDemoing;
        },

        /**
         * Toggles demo mode.
         */
        toggleDemo: function() {
          if (this.isDemoing()) {
            cookies.put('vantage-demo', '0');
          } else {
            cookies.put('vantage-demo', '1');
          }
        },

        /**
         * Whether to use Store or Brand to describe the main app object to the user
         */
        storeTerm: function() {
          if(this.user.isBrandUser || this.user.isRetailerUser){
            return 'brand';
          }
          else{
            return 'store';
          }
        },
      });
    }
  ]);

  // This will go away once we switch to a real module system
  window.vantageApp = app;
})(window);
