angular.module('vantageApp').factory('model.PinterestAd', [
    'lib.Model',
    function(Model) {
        var PinterestAd = Model({
            id: null,
            type: null,
            status: null,
            pin: null,

            isValid: function() {
                if (this.type == 'PINTEREST_SHOPPING') {
                    return true;
                }

                return false;
            },

            init: function(opts) {
                this.$parent.init.apply(this, [opts]);

                if (!_.contains(PinterestAd.ALLOWED_TYPES, this.type)) {
                  throw new Error("Invalid ad type '" + this.type + "'");
                }
            }
        });

        PinterestAd.configure({
          PINTEREST_SHOPPING: 'PINTEREST_SHOPPING'
        });

        PinterestAd.ALLOWED_TYPES = [
          PinterestAd.PINTEREST_SHOPPING,
        ];

        return PinterestAd;
    },
]);