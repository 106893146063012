angular.module('vantageApp').factory('model.StoreDetails', [
  'lib.Model',
  '$q',
  '$http',
  function(Model, Promise, $http) {

    var StoreDetails = Model({
      store: null,
      audience: null,

      _idFromStore: function() {
        return this.store.id;
      },

      storeId: function() {
        var storeId = null;

        if (this.store) {
          storeId = this._idFromStore();
        }

        return storeId;
      },

      primaryFacebookPage: function() {
        var url = StoreDetails.url + this.storeId() + '/primary_facebook_page/';
        if (typeof this._facebookPageDetails === 'undefined') {
          this._facebookPageDetails = $http.get(url).then(function(response) {
            return response.data;
          });
        }
        return this._facebookPageDetails;
      },

      facebookPages: function() {
        var url = StoreDetails.url + this.storeId() + '/facebook_pages/';
        if (typeof this._secondaryPages === 'undefined') {
          this._secondaryPages = $http.get(url).then(function(response) {
            return response.data;
          });
        }
        return this._secondaryPages;
      },

      instagram: function() {
        // If the store doesn't have the appropriate IDs, we return an empty promise.
        if (!this.instagram_account_id) {
          return Promise.resolve({});
        }

        var url = StoreDetails.url + '/' + this.storeId() + '/instagram/';
        if (typeof this._instagramDetails === 'undefined') {
          this._instagramDetails = $http.get(url).then(function(response) {
            return response.data;
          });
        }
        return this._instagramDetails;
      },

      mediaAgreements: function() {
        var url = StoreDetails.url + this.storeId() + '/media_agreements/';
        if (typeof this._mediaAgreements === 'undefined') {
          this._mediaAgreements = $http.get(url).then(function(response) {
            return response.data;
          });
        }
        return this._mediaAgreements;
      },

      pioLineItems: function() {
        var url ='/api/' + this.storeId() + '/pio_line_items/';
        if (typeof this._pioLineItems === 'undefined') {
          this._pioLineItems = $http.get(url).then(function(response) {
            return response.data;
          });
        }
        return this._pioLineItems;
      },
    });

    return StoreDetails.configure({
      url: '/ecom/api/v1/store_details/',
    });
  }
]);
