angular.module('vantageApp').controller('controller.BillingSettings', [
  '$scope',
  '$http',
  'store',
  'stripeCustomer',
  'StripeCheckout',
  'lib.Modal',
  'service.GoogleAnalyticsEvents',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  function($scope, $http, store, stripeCustomer, StripeCheckout, Modal, vga, AdvancedEcom) {
    var ctl = this;

    ctl.store = store;
    ctl.stripeCustomer = stripeCustomer;
    ctl.tier = ctl.store.organization.tier;

    $scope.loading = false;
    $scope.tierInfoUrl = 'views/includes/advertising-tiers/' + ctl.tier.key + '.html';

    ctl.tierName = function() {
      // Get the index of the last space, so we can start after it.
      var startIndex = ctl.tier.name.lastIndexOf(' ') + 1;
      return ctl.tier.name.substring(startIndex);
    };

    ctl.isPackageCancelled = function() {
      if (ctl.stripeCustomer.subscription) {
        return ctl.stripeCustomer.subscription.cancel_at_period_end;
      }
      return false;
    };

    ctl.showTrialInfo = function() {
      // Show trial info only if there exists no terminated or completed campaigns
      return stripeCustomer.target_stripe_plan_name === 'advertising-monthly-30-day-trial';
    };

    ctl.showCampaignPercentageFee = function() {
      // API returns a string for default_campaign_fee_percentage, so we have to massage it.
      return parseInt(ctl.store.default_campaign_fee_percentage) > 0;
    };

    ctl.addPackageModal = function() {
      Modal.show({
        templateUrl: 'views/modal/add-package.html',
        controller: 'controller.addPackageModal',
        controllerAs: 'modal',
        inputs: {
          action: ctl.addPackage
        }
      });
    };

    ctl.addPackageCheck = function() {
      if (
        ctl.store.stripe_customer_id === null ||
        ctl.store.stripe_customer_id === '' ||
        ctl.stripeCustomer.cards.length === 0
      ) {
        var handler = StripeCheckout.configure();
        var options = {
          description: 'Advertising Package',
          panelLabel: 'Authorize $99 / mo',
          currency: 'USD',
        };

        handler.open(options).then(function(response) {
          ctl.stripeCustomer.addCard(response[0], ctl.addPackageModal);

        });
      } else {
        ctl.addPackageModal();
      }
    };

    ctl.addPackage = function() {
      $scope.loading = true;

      $http.post(
        "/api/" + config.store.id + "/packages/add", {}
      ).then(function(response){
        ctl.fireGoogleAnalyticsEvent();
        window.location.reload();
      });
    };

    ctl.fireGoogleAnalyticsEvent = function() {
      var package_value = 99.0;
      AdvancedEcom.addPackage(package_value);
      vga.purchase(package_value, 'VantageAdvertisingPackage', 'AdvertisingPackage');
    };

    // Opens a confirmation modal for cancelling advertising package.
    ctl.cancelPackageModal = function() {
      Modal.show({
        templateUrl: 'views/modal/cancel-package.html',
        controller: 'controller.cancelPackageModal',
        controllerAs: 'modal',
        inputs: {
          action: ctl.cancelPackage,
          activeCampaignsCount: ctl.store.active_campaigns_count,
          packageExpiry: ctl.stripeCustomer.subscription.next_billing_date,
        }
      });
    };

    ctl.cancelPackage = function(reasonCode, reason) {
      $scope.loading = true;

      $http.post(
        "/api/" + config.store.id + "/packages/cancel", {
          reasonCode: reasonCode,
          reason: reason
        }
      ).then(function(response){
        window.location.reload();
      });
    };

    ctl.deleteCard = function(cardId, confirmLastCard, reasonCode, reason) {

      if (ctl.store.hasAdvertisingPackage() && !ctl.isPackageCancelled() && confirmLastCard && stripeCustomer.cards.length === 1) {
        ctl.showConfirmLastCardModal(cardId);
      } else {
        var card = ctl.stripeCustomer.getCard(cardId);

        card.deleting = true;

        ctl.stripeCustomer.deleteCard(cardId, function() {
          if (ctl.store.hasAdvertisingPackage() && !ctl.isPackageCancelled() && stripeCustomer.cards.length === 0) {
            ctl.cancelPackage(reasonCode, reason);
          }
        });
      }
    };

    ctl.showConfirmLastCardModal = function(cardId) {
      Modal.show({
        templateUrl: 'views/modal/confirm-last-card-delete.html',
        controller: 'controller.confirmLastCardDelete',
        controllerAs: 'modal',
        inputs: {
          action: ctl.deleteCard,
          cardId: cardId,
          activeCampaignsCount: ctl.store.active_campaigns_count,
          packageExpiry: ctl.stripeCustomer.subscription.next_billing_date,
        }
      });
    };

    ctl.addCard = function() {
      var handler = StripeCheckout.configure();
      var options = {
        description: 'Add New Card',
        panelLabel: 'Add Card'
      };

      handler.open(options).then(function(response) {
        ctl.stripeCustomer.addCard(response[0]);
      });
    };

    ctl.setDefaultCard = function(cardId) {
      ctl.stripeCustomer.setDefaultCard(cardId);
    };
  }
]);
