angular.module('vantageApp').run([
  'StripeCheckout',
  'va.config',
  function(StripeCheckout, config) {
    StripeCheckout.defaults({
      key: config.stripeKey,
      name: 'Vantage',
      image: config.asset('frontend/images/vantage-stripe-logo.png'),
      zipCode: true,
    });
  }
]);
