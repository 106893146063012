angular.module('vantageApp').factory('tile.NewOrder', [
  'lib.Tile',
  function(Tile) {
    return Tile({
      name: 'new-order',
      loadData: function(response) {
        this.amount = response.data.amount;

        if (response.data.city && response.data.province) {
          this.location = [
            response.data.city,
            response.data.province,
          ].join(', ');
        } else if (response.data.city) {
          this.location = response.data.city;
        } else if (response.data.province) {
          this.location = response.data.province;
        } else {
          this.location = null;
        }

        if (response.data.first_name && response.data.last_name) {
          this.customer = [
            response.data.first_name,
            response.data.last_name
          ].join(' ')
        } else {
          this.customer = 'Someone';
        }
      },

      refreshData: function(response) {
        if (!response.data.amount) {
          return;
        }

        return this.loadData(response);
      }
    });
  }
]);
