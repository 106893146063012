angular.module('vantageApp').directive('shoppingEditor', [
  'model.GoogleAd',
  'model.PinterestAd',
  'model.Product',
  'lib.BrandNameExtractor',
  function(GoogleAd, PinterestAd, Product, BrandNameExtractor) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/shopping-editor.html',
      scope: {
        campaign: '=',
        store: '=',
        isPreviewOnly: '=',
      },

      link: function(scope) {
        scope.merchantName = scope.store.coopRetailerName() || scope.store.name;
        scope.storeName = "Shop " + (scope.store.coopBrandName() || scope.store.name).toLowerCase();
        scope.isPinterest = scope.campaign.audience.isPinterestShopping() || scope.campaign.hasPinterestShoppingAds();

        scope.defaultProductTitle = function (num) {
          return (scope.store.coopBrandName() || scope.store.name) + ' Product #' + num
        };

        scope.getPinterestShoppingAd = function () {
          if (!scope.isPinterest) {
            return null;
          }

          return {
            name: scope.defaultProductTitle(1),
            price: 36.00,
            merchantName: scope.store.coopBrandName() || scope.store.name,
          };
        };

        scope.pinterestShoppingAd = scope.getPinterestShoppingAd();

        scope.ads = [
          {
            name: scope.defaultProductTitle(1),
            price: 399.99,
          },
          {
            name: scope.defaultProductTitle(2),
            price: 249.99,
          },
        ];

        var brandName = BrandNameExtractor.getBrandName(scope.campaign);

        var params = {
          is_available: true,
          ordering: '-source_created_at',
        };

        if (brandName) {
          params['brand_name'] = brandName;
        }

        Product.all(params).then(function(products){
          if (products.length > 0) {
            scope.ads = _.sample(products, scope.isPinterest ? 1 : Math.min(products.length, 2));
          }
        });

        var newAd;
        if (scope.isPinterest) {
          newAd = new PinterestAd({
            'type': 'PINTEREST_SHOPPING',
          });
        } else {
          newAd = new GoogleAd({
            'type': 'SHOPPING'
          });
        }

        if(!scope.isPreviewOnly) {
          if (!scope.isPinterest || !('PINTEREST_SHOPPING' in scope.campaign.adsets)) {
            scope.campaign.pushAd(newAd);
          }
        }
        scope.campaign.isDirty = false;
      },
    };
  }
]);
