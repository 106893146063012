angular.module('vantageApp').service('service.CampaignCopy', [
  'model.FacebookAd',
  'va.config',
  function(FacebookAd, config) {

    var CampaignCopy = {};

    if (config.store.name.endsWith("- Home Depot CA") || config.store.domain.endsWith("homedepot.ca")) {
      var brandName = config.store.name.split(" - ")[0].trim();
      if (config.isDemoing()) {
        brandName = 'Brand';
      }
      var titles = [
        "Shop {BRAND NAME} Online",
        "Explore {BRAND NAME}",
        "Shop with confidence",
      ];
      titles.forEach(function (item, index) {
        titles[index] = titles[index].replace('{BRAND NAME}', brandName)
      });

      var bodies = [
        "Browse our selection online.",
        "Check out our assortment of {BRAND NAME} products.",
        "{BRAND NAME}. What you need, at a great price.",
        "{BRAND NAME}. Exactly what you need at great prices.",
        "Get your {BRAND NAME} essentials all in one place.",
        "Make your job easier with {BRAND NAME}.",
        "Explore innovative collections from {BRAND NAME}.",
        "Upgrade your home with {BRAND NAME}.",
        "Shop great quality products with {BRAND NAME}.",
        "{BRAND NAME}, products you’ll love.",
        "Shop new styles from {BRAND NAME}.",
        "Available at homedepot.ca",
        "Buy now at The Home Depot",
        "Shop online at homedepot.ca",
        "Browse our selection",
        "Shop Online at The Home Depot",
      ];
      bodies.forEach(function (item, index) {
        bodies[index] = bodies[index].replace('{BRAND NAME}', brandName)
      });

      var dpaMessageTemplates = titles;
      var rhsMessages = dpaMessageTemplates;

      var dpaNameTemplates = [
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
      ];

      var dpaDescriptionTemplates = [
        "Only {{product.price}}!",
        "Starting from {{product.price}}",
        "{{product.price}}",
        "{{product.price}}!",
      ];
    } else if (config.store.name.endsWith("- Home Depot US") || config.store.domain.endsWith("homedepot.com")) {
      var brandName = config.store.name.split(" - ")[0].trim();
      if (config.isDemoing()) {
        brandName = 'Brand';
      }
      var titles = [
        "Action Oriented 1: Tackle it Like a Pro",
        "Action Oriented 2: Explore Project Solutions",
        "Action Oriented 3: Explore Your Options",
        "Brand Awareness 1: We Found These for You",
        "Brand Awareness 2: Top Picks for Doers",
        "Brand Awareness 3: See What Sets Us Apart",
        "Inspirational 1: Get Inspired",
        "Inspirational 2: Find Your Inspiration",
        "Inspirational 3: A Quality Difference",
        "Inspirational 4: Results You Can Count On",
        "Innovation 1: Smart Solutions for You",
        "Innovation 2: Cutting-Edge Products",
        "Innovation 3: Innovative Must-Haves",
        "Product Launch 1: Take a Look at This",
        "Product Launch 2: Discover What’s New",
        "Single Product Feature: Explore Features & More",
        "Multiple Product Feature: Explore Your Options",
        "Seasonal: Do More This Season",
        "Style 1: On-Trend Styles",
        "Style 2: Explore Fresh Styles",
        "Style 3: Design Your Own Haven",
        "Style 4: Elevate Any Look",
        "Pro Default: Quality You Can Trust",
        "Pro Efficiency 1: Grow Your Business",
        "Pro Efficiency 2: Fill Your Toolbox",
        "Pro Efficacy/Versatility 1: Ready to Work",
        "Pro Efficacy/Versatility 2: Deliver Your Best",
        "Pro Reputation 1: The Quality You Trust",
        "Pro Reputation 2:  Get Started Right",
        "Pro Innovation 1: Find Smarter Solutions",
        "Pro Innovation 2: Improve Your Results",
        "Pro Cost 1: Stay on Budget",
        "Pro Cost 2: Invest in Quality",
        "Pro Cost 3: Value You Can Trust",
      ];
      titles.forEach(function (item, index) {
        titles[index] = titles[index].replace('{BRAND NAME}', brandName)
      });

      var bodies = [
        "Action Oriented 1: Get to-do lists done with {BRAND NAME}",
        "Action Oriented 2: {BRAND NAME} makes DIY easy",
        "Action Oriented 3: Get more done with {BRAND NAME}",
        "Brand Awareness 1: Browse our {BRAND NAME} selection",
        "Brand Awareness 2: This way to {BRAND NAME}",
        "Brand Awareness 3: {BRAND NAME} is here",
        "Inspirational 1: Start something new with {BRAND NAME}",
        "Inspirational 2: Upgrade your space with {BRAND NAME}",
        "Inspirational 3: Get the results you want with {BRAND NAME}",
        "Inspirational 4: Take on projects confidently with {BRAND NAME}",
        "Innovation 1: The latest innovations from {BRAND NAME}",
        "Innovation 2: Pick up the latest from {BRAND NAME}",
        "Innovation 3: Get the {BRAND NAME} Advantage",
        "Product Launch 1: Discover what's new from {BRAND NAME}",
        "Product Launch 2: Meet our latest {BRAND NAME} addition",
        "Single Product Feature: Check out this {BRAND NAME} Product",
        "Multiple Product Feature: Check out these {BRAND NAME} Products",
        "Seasonal: Step outside with {BRAND NAME}",
        "Style 1: Make your space feel like new with {BRAND NAME}",
        "Style 2: Bring new life to your space with {BRAND NAME}",
        "Style 3: Cozy up with {BRAND NAME}",
        "Style 4: Style your space with {BRAND NAME}",
        "Pro Default: How Pros get more done.",
        "Pro Efficiency 1: Take On More Jobs With {BRAND NAME}",
        "Pro Efficiency 2: {BRAND NAME}. The Right Tool for Your Jobs.",
        "Pro Efficacy/Versatility 1: Tackle Any Job With {BRAND NAME}",
        "Pro Efficacy/Versatility 2: Quality Results Start With {BRAND NAME}",
        "Pro Reputation 1: Get the Job Done Right With {BRAND NAME}",
        "Pro Reputation 2:  Quality Results Begin With {BRAND NAME}",
        "Pro Innovation 1: Power. Innovation. {BRAND NAME}.",
        "Pro Innovation 2: {BRAND NAME} Is Built to Upgrade Your Toolbox",
        "Pro Cost 1: {BRAND NAME} Has Your Bottom Line in Mind",
        "Pro Cost 2: {BRAND NAME} Works as Hard as You Do",
        "Pro Cost 3: Keep Jobs on Budget With {BRAND NAME}",
      ];
      bodies.forEach(function (item, index) {
        bodies[index] = bodies[index].replace('{BRAND NAME}', brandName)
      });

      var messages = [
          "Check out these {BRAND NAME} products.",
          "Check out these {BRAND NAME} products.",
          "Check out these {BRAND NAME} products.",
          "Check out these {BRAND NAME} products.",
      ];
      messages.forEach(function (item, index) {
        messages[index] = messages[index].replace('{BRAND NAME}', brandName)
      });

      var rhsMessages = [
          "Check out these products",
      ];

      var dpaMessageTemplates = messages;

      var dpaNameTemplates = [
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
      ];

      var dpaDescriptionTemplates = titles;
    }
    else {
      var titles = [
        "Get the Latest Trends!",
        "Sick of the Same Style?",
        "Need to Buy a Gift?",
        "Need Something Unique?",
        "A Variety of Products",
        "Shop And Enjoy!",
        "All the Name Brands!",
        "Shop Newest Trends",
        "Shop Brand New Styles",
        "Snap Up Hottest New Looks",
        "Get Your New Gear Here",
        "See Latest Trends Now",
        "New Apparel Available",
        "High Quality Products",
        "Shop Brand New Products",
        "Experience The New Trend",
        "Get The Best Products",
        "Natural Health Benefits",
        "Get Quality Products Now",
        "You want it, we've got it",
        "Products You'll Love",
        "Get Products You Deserve",
        "Improve Your Lifestyle!",
        "Shop Stunning Designs Now",
        "Great Deals Just For You",
        "Shop the Newest Arrivals",
      ];
      var bodies = [
        "Browse our latest styles & newest product line. You'll love what we have in store for you.",
        "For the person who has everything, we have all kinds of items that are sure to impress.",
        "Whether it's for yourself, sibling or partner, we have many items to choose from.",
        "Shop our newest arrivals now. This is the greatest collection yet!",
        "See what we have in store for you now. Guaranteed we have something you'll love",
        "Browse our fresh new line. We know you will find something you love!",
        "Find out what you're missing out on. Limited offers for you right now!",
        "Don't miss our incredible deals. See what's on sale now!",
        "Shop our newest arrivals and enjoy! You deserve it",
        "Quality infused products you need to get your hands on. Check out our offers now!",
        "Make sure you check out our store before you go anywhere else, we won't let you down!",
      ];

      var dpaMessageTemplates = [
        "One of our personal favorites!",
        "Selling Out!",
        "Limited Supply!",
        "Treat yourself.",
        "Our Hottest Selling Items",
        "We know. Hard to resist.",
      ];
      var rhsMessages = dpaMessageTemplates;
      var dpaNameTemplates = [
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
        "{{product.name}}",
      ];
      var dpaDescriptionTemplates = [
        "Only {{product.price}}!",
        "Starting from {{product.price}}",
        "{{product.price}}",
        "{{product.price}}!",
      ];
    }

    CampaignCopy[FacebookAd.RIGHT_SIDE] = {
      'creative.title': rhsMessages,
      'creative.body': bodies,
      'dpaTemplate.name': dpaNameTemplates,
      'dpaTemplate.message': rhsMessages,
      'dpaTemplate.description': dpaDescriptionTemplates,
    };

    CampaignCopy[FacebookAd.NEWSFEED] = {
      'creative.linkDescription': titles,
      'creative.title': titles,
      'creative.body': bodies,
      'dpaTemplate.name': dpaNameTemplates,
      'dpaTemplate.message': dpaMessageTemplates,
      'dpaTemplate.description': dpaDescriptionTemplates,
    };

    CampaignCopy[FacebookAd.MOBILE] = {
      'creative.linkDescription': titles,
      'creative.title': titles,
      'creative.body': bodies,
      'dpaTemplate.name': dpaNameTemplates,
      'dpaTemplate.message': dpaMessageTemplates,
      'dpaTemplate.description': dpaDescriptionTemplates,
    };

    CampaignCopy[FacebookAd.INSTAGRAM] = {
      'creative.body': bodies,
      'dpaTemplate.name': dpaNameTemplates,
      'dpaTemplate.description': dpaDescriptionTemplates,
    };

    CampaignCopy[FacebookAd.STORIES] = {
      'creative.body': bodies,
      'dpaTemplate.name': dpaNameTemplates,
      'dpaTemplate.description': dpaDescriptionTemplates,
    };

    CampaignCopy.suggest = function(type, ads) {
      var suggestions = _.cloneDeep(CampaignCopy[type]);
      var fields = [
            'creative.linkDescription',
            'creative.title',
            'creative.body',
            'creative.objectUrl',
          ];

      _.forEach(fields, function(field) {
        if (!suggestions[field]) {
          suggestions[field] = [];
        }

        // Pull in all of the copy from every ad
        _.forEach(ads, function(ad) {
          suggestions[field].unshift(_.get(ad, field));
        });

        suggestions[field] = _.uniq(suggestions[field]);
      });

      return suggestions;
    };

    CampaignCopy.getTitle = function(index) {
      return titles[index % titles.length];
    };

    CampaignCopy.getRHSTitle = function(index) {
      return rhsMessages[index % rhsMessages.length];
    };

    CampaignCopy.getBody = function(index) {
      return bodies[index % bodies.length];
    };

    CampaignCopy.getDpaTitle = function(index) {
      return dpaNameTemplates[index % dpaNameTemplates.length];
    };

    CampaignCopy.getDpaBody = function(index) {
      return dpaMessageTemplates[index % dpaMessageTemplates.length];
    };

    CampaignCopy.getDpaDescription = function(index) {
      if (config.store.name.endsWith("- Home Depot US") || config.store.domain.endsWith("homedepot.com"))
        return "";
      return dpaDescriptionTemplates[index % dpaDescriptionTemplates.length];
    };

    CampaignCopy.getTitles = function() {
      return titles.slice(); // Return copy of titles
    };

    CampaignCopy.getRHSTitles = function() {
      return rhsMessages.slice();
    };

    CampaignCopy.getBodies = function() {
      return bodies.slice(); // Return copy of bodies
    };

    CampaignCopy.getDPADescriptionTemplates = function() {
      return dpaDescriptionTemplates.slice();
    };

    CampaignCopy.getDPAMessageTemplates = function() {
      return dpaMessageTemplates.slice();
    };

    CampaignCopy.getDPANameTemplates = function() {
      return dpaNameTemplates.slice();
    };

    return CampaignCopy;
  }
]);
