angular.module('vantageApp').service('service.GoogleAnalyticsAdvancedEcomEvents', function() {
  var service = this;
  this.ga = window.ga;
  if (this.ga === undefined) {
    // ga may not be defined for superusers
    this.ga = function() {
      if (arguments.length === 3) {
        console.log("Sending GA Checkout: ", arguments[2]);
      } else if (arguments.length === 2) {
        console.log("Sending GA: ", arguments[0], arguments[1]);
      }
    };
  }

  this.ga('require', 'ec');

  service._addProduct = function(id, name, price, quantity) {
    if (_.isUndefined(quantity)) {
      quantity = 1;
    }
    if (_.isUndefined(price)) {
      price = '1.00';
    }

    service.ga('ec:addProduct', {
      'id': id,
      'name': name,
      'price': price,
      'quantity': quantity,
    });
  };

  service._markStep = function(stepNumber) {
    service.ga('ec:setAction', 'checkout', {
      'step': stepNumber,
    });
    service.ga('send', 'pageview');
  };

  service._markPurchase = function(id, affiliation, revenue) {
    service.ga('ec:setAction', 'purchase', {
      'id': id,
      'affiliation': affiliation,
      'revenue': revenue,
    });
  };

  service.visitCampaignCreate = function() {
    service._addProduct('campaign', 'campaign');
    service._markStep(1);
  };

  service.pickAudience = function() {
    service._markStep(2);
  };

  service.pickImages = function() {
    service._markStep(3);
  };

  service.buildAds = function() {
    service._markStep(4);
  };

  service.markAdsetEdited = function(adset_type) {
    service._markStep(5);
  };

  service.saveDraft = function() {
    service._markStep(6);
  };

  service.runCampaign = function(campaign_value, campaign_type) {
    var affiliation = "Vantage Campaign Builder";
    service._addProduct('campaign', 'campaign', campaign_value, 1);
    service._markPurchase(campaign_type, affiliation, campaign_value);
  };

  service.addPackage = function(package_value) {
    var id = "VantagePackage";
    var affiliation = "Vantage Advertising Package";
    service._addProduct('advertising_package', 'advertising_package', package_value, 1);
    service._markPurchase(id, affiliation, package_value);
  };
});
