angular.module('vantageApp').factory('model.Product', [
  'lib.Model',
  'va.config',
  '$http',
  function(Model, config, http) {
    var Product = Model();

    Product.top = function(params) {
        var cls = this;

        return http.get(this.url + 'top', {
            params: params
        }).then(function(response) {
            return cls.deserializeAll(response);
        });
    };

    var product_owner_id = (config.retailerOwnerId() || config.ownerId());

    return Product.configure({
      url: config.inboundUrl + 'api/dashboard/' + product_owner_id + '/products/',

      deserializeAll: function(response) {
        var cls = this;

        var objects = _.map(response.data.results, function(data) {
          return cls.deserialize(data);
        });
        if (response.data.count !== undefined) {
            objects.pageInfo = _.pick(response.data, 'count', 'previous', 'next');
        }
        return objects;
      }
    });
  }
]);
