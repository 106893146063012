angular.module('vantageApp').factory('lib.Model', [
  'lib.Class',
  '$http',
  function(Class, http) {
    /**
     * A base class for http-backed models.
     *
     * var Book = Model({
     *   name: '',
     *   author: '',
     * }).configure({
     *    url: 'api/books'
     * });
     *
     * Book.get(1).then(function(book) {
     *    // Do stuff with book
     * });
     */
    var Model = Class();

    // Helper for configuring the model
    Model.configure = function(opts) {
      return angular.extend(this, opts);
    };

    // Require a URL set for all model types
    Model.url = '';

    // Returns a list of models.
    Model.all = function(params) {
      var cls = this;

      return http.get(this.url, {
        params: params,
      }).then(function(response) {
        return cls.deserializeAll(response);
      });
    };

    // Returns a single model, by id.
    Model.get = function(id, params) {
      var cls = this;

      return http.get(this.url + id + '/', {
        params: params
      }).then(function(response) {
        return cls.deserializeGet(response);
      });
    };

    // Creates a model with the data passed.
    Model.create = function(attrs) {
      var cls = this;

      return http.post(this.url, attrs).then(function(response) {
        return cls.deserializeCreate(attrs, response);
      });
    };

    // Updates a model with the attributes passed.
    Model.update = function(model, attrs) {
      return http({
        method: 'PATCH',
        url: model.resource_uri,
        data: attrs,
      });
    };

    // Destroys the model passed.
    Model.destroy = function(model) {
      return http.delete(model.resource_uri);
    };

    // Called when deserializing a "get"
    Model.deserializeGet = function(response) {
      return this.deserialize(response.data);
    };

    // Called when deserializing an "all"
    Model.deserializeAll = function(response) {
      var cls = this;

      return _.map(response.data.objects, function(data) {
        return cls.deserialize(data);
      });
    };

    // Called when deserializing a "create"
    Model.deserializeCreate = function(data, response) {
      var resource_uri = response.headers('location');

      if (resource_uri) {
        angular.extend(data, {
          resource_uri: resource_uri,
          id: resource_uri.slice(-2, -1)[0],
        });
      }

      if (response.data) {
        angular.extend(data, response.data);
      }

      return this.deserialize(data);
    };

    // Called when turning JSON into a model.
    Model.deserialize = function (data) {
      return new this(data);
    };

    return Model;
  }
]);
