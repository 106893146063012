angular.module('vantageApp').controller('controller.Explore', [
  'model.Audience',
  '$scope',
  '$http',
  '$timeout',
  '$modal',
  '$location',
  '$sce',
  '$state',
  function(Audience, $scope, $http, $timeout, $modal, $location, $sce, $state) {
    $scope.runCampaign = function() {
      $state.go('campaign-create', { audience: Audience.EXPLORE });
    };

    $scope.isFieldLocked = function(field) {
      if(field.is_locked === false) {
        return field;
      }
    };

    function getField(key) {
      if (!key) {
        return null;
      }
      for (var i = 0; i < $scope.selectedDataset.fields.length; i++) {
        var currentField = $scope.selectedDataset.fields[i];
        if (currentField.key == key) {
          return currentField;
        }
      }
      return null;
    }

    function isNumber(value) {
      return !isNaN (value - 0) && value !== null && value !== "" && value !== false;
    }

    function checkNull(value) {
      if(value === null || value == "0001-255-255 00:00:00") {
        return "-";
      } else {
        return value;
      }
    }

    $scope.formatValue = function(key, value){
      if (!key) {
        return checkNull(value);
      }

      var field = getField(key);

      if (field === null) {
        return checkNull(value);
      }
      var datatype = field.np_datatype;
      var formattedValue = checkNull(value);

      if(datatype.startsWith("i") && (field.display_format === null || field.display_format == "0") && field.cardinality > 2) {
        value = value.toString();
        return $sce.trustAsHtml(value);
      } else {
        if (value != "All" && value != "Other" && value != "Missing" && formattedValue != '-' ) {
          if (datatype == "object" || datatype.startsWith("a")){
            formattedValue = value;
          } else if(datatype  ==  "datetime64" ){
            formattedValue = moment(value).format('DD MMM YYYY');
          // } else if(datatype.startsWith("i") && parseInt(field.cardinality) == 2 && parseInt(field.maximum) == 1){
          } else if(datatype.startsWith("i") && field.cardinality == 2 && field.maximum == 1){
            if(value == "0" || value == "0.0" || value == "'0.0'"){
              formattedValue = "No";
            } else if (value == "1" || value == "1.0" || value == "'1.0'") {
              formattedValue = "Yes";
            }
          } else if (datatype.startsWith("i") || datatype.startsWith("f")) {
            if (!isNumber(value)) {
              formattedValue = value;
            } else {
              var displayFormat = field.display_format;
              if (!displayFormat) {
                if (datatype.startsWith("i")) {
                  displayFormat = "0,0";
                } else {
                  displayFormat = "0,0.00";
                }
              }
              formattedValue = numbro(value).format(displayFormat);
            }

          }
        }

        return formattedValue;
      }
    };



    $scope.isCardinalityTwo = function(key) {
      var field = getField(key);
      return field.cardinality == 2;
    };

    $scope.formatCardinalityTwoResult = function(key, value){
      if ($scope.isCardinalityTwo(key) === true) {
        if(value == "0"){
          return "No";
        } else if(value == "1"){
          return "Yes";
        }
      } else {
        return value;
      }
    };

    $scope.transposeFields = function() {

      var current_row_field = null;
      var current_column_field = null;
      if ($scope.parameters['rows'].length > 0) {
        current_row_field = getField($scope.parameters['rows'][0].key);
      }

      if ($scope.parameters['columns'].length > 0) {
        current_column_field = getField($scope.parameters['columns'][0].key);
      }

      if (current_row_field) {
        addField(current_row_field, "columns");
      }

      if (current_column_field) {
        addField(current_column_field, "rows");
      }

    };

    function organizeFieldsByCardinality(field_one, field_two) {
      //Make the field with the lower cardinality as the columns, and the higher one the rows
      if (field_one.cardinality < field_two.cardinality) {
        addField(field_one, "columns");
        addField(field_two, "rows");
      } else {
        addField(field_two, "columns");
        addField(field_one, "rows");
      }
    }

    var lastSelected;

    $scope.addRow = function(field){
      var current_row_count = $scope.parameters['rows'].length,
        current_column_count = $scope.parameters['columns'].length,
        current_row, current_column;

      if ($scope.parameters['columns'][0]) {
        current_column = getField($scope.parameters['columns'][0].key);
      }

      if ($scope.parameters['rows'][0]) {
        current_row = getField($scope.parameters['rows'][0].key);
      }

      if (current_row_count === 0 && current_column_count === 0) {
        addField(field, "rows");
      } else if (current_row === field) {
        $scope.clearFieldsBySelectionType('rows');
        $scope.lastSelectedField = null;
      } else if (current_column === field) {
        $scope.clearFieldsBySelectionType('columns');
        $scope.lastSelectedField = null;
      } else if (current_row_count === 0 || current_row === $scope.lastSelectedField) {
        organizeFieldsByCardinality(field, current_column);
        $scope.lastSelectedField = field;
      } else if (current_column_count === 0 || current_column === $scope.lastSelectedField) {
        //If current column field has a higher cardinality, make it the row and make the new field the columns
        organizeFieldsByCardinality(field, current_row);
        $scope.lastSelectedField = field;
      }
    };

    $scope.addColumn = function(field){
      addField(field, "columns");
    };

    $scope.addSummary = function(field, value){
      $scope.parameters.aggregation.functions[0] = value;
      if ($scope.aggregation.function_name == 'mean' && field.np_datatype.startsWith('i')) {
        $scope.fieldFormat = "0,00.0";
      } else {
        $scope.fieldFormat = field.display_format;
      }
      addField(field, "summary");
    };


    function removeProperties(object, propertyToRemove) {
      for (var property in object) {
        if (object.hasOwnProperty(property)) {
          if (property == propertyToRemove) {
            delete object[property];
          } else if (typeof object[property] == "object") {
            removeProperties(object[property], propertyToRemove);
          }
        }
      }
    }

    function cleanFilters(parametersToSend) {
      filters = [];

      for (var i = 0; i < parametersToSend.filters.length; i++) {
        var filter = parametersToSend.filters[i];

        if (filter.field_key !== 0) {
          $scope.filterApplied = true;
        }

        if (filter.condition == 'AA') {
          field = getField(filter.field_key);
          filters.push({field_key: filter.field_key, condition: "GT", value: field.mean, negate: "false"});
        } else if (filter.condition == 'BA') {
          field = getField(filter.field_key);
          filters.push({field_key: filter.field_key, condition: "LT", value: field.mean, negate: "false"});
        } else {
          filters.push(filter);
        }
      }

      parametersToSend.filters = filters;

      return parametersToSend;
    }

    $scope.summarize = function() {
      var parametersToSend = JSON.parse(JSON.stringify($scope.parameters));
      removeProperties(parametersToSend, '$$hashKey');
      removeProperties(parametersToSend, 'field_values');

      $scope.filterApplied = false;

      parametersToSend = cleanFilters(parametersToSend);

      if ($scope.parameters.rows.length > 0 || $scope.parameters.columns.length > 0) {
        parameterString = JSON.stringify(parametersToSend);
        parameterString = parameterString.replace("&", "%26");
        $http.get(

          '/data/api/summarize/?source=EXPLORE_SUMMARY&parameters=' + parameterString ,
          {
            tracker: 'global'
          }).then(function(response) {
            $scope.summarizedData = response.data;
            delete $scope.detailData;
            $scope.selectedCell = {};
          }
        );
      } else {
        $scope.summarizedData = null;
      }
    };


    $scope.clearFieldsBySelectionType = function(selectionType) {
      for (var i = 0; i < $scope.selectedDataset.fields.length; i++) {
        var currentField = $scope.selectedDataset.fields[i];

        if (currentField.selectionType === selectionType) {
          currentField.selected = false;
          currentField.selectionType = null;
          $scope.parameters[selectionType] = [];
        }
      }
    };

    $scope.clearSum = function(selectionType) {
      for (var i = 0; i < $scope.selectedDataset.fields.length; i++) {
        var currentField = $scope.selectedDataset.fields[i];

        if (currentField.selectionType === selectionType) {
          currentField.selected = false;
          $scope.fieldFormat = null;
          $scope.parameters.aggregation = {type: 'standard', functions: ['count']};
        }
      }
    };

    function ensureFieldUsedOnlyOnce(field, selectionType) {
      if (selectionType == 'rows') {
        otherSelectionType = 'columns';
      } else {
        otherSelectionType = 'rows';
      }
      if ($scope.parameters[otherSelectionType] &&
        $scope.parameters[otherSelectionType].length > 0 &&
        $scope.parameters[otherSelectionType][0].key == field.key) {
        $scope.parameters[otherSelectionType] = [];
      }
    }

    function addField(field, selectionType) {
      $scope.clearFieldsBySelectionType(selectionType);
      field.selected = true;
      field.selectionType = selectionType;
      if (selectionType == 'summary') {
        $scope.parameters.aggregation.field = field.key;
      } else {
        parameter = {'key': field.key, 'name': field.name, 'binning': 'default'};
        $scope.parameters[selectionType] = [parameter];
        ensureFieldUsedOnlyOnce(field, selectionType);
      }

      $scope.parameters.hasValues = true;

      $scope.summarize();
    }

    $scope.removeColumnGrouping = function(){
      delete $scope.parameters.columns[0].binning;
      $scope.summarize();
    };

    $scope.applyColumnGrouping = function(){
      $scope.parameters.columns[0].binning = 'default';
      $scope.summarize();
    };

    $scope.removeRowGrouping = function(){
      delete $scope.parameters.rows[0].binning;
      $scope.summarize();
    };

    $scope.applyRowGrouping = function(){
      $scope.parameters.rows[0].binning = 'default';
      $scope.summarize();
    };

    $scope.isCardinalityOk = function(key){
      var isOk = false;
      var field = getField(key);
      return isOk = field && field.cardinality > 10 && field.cardinality < 1000;
    };

    $scope.isBinningDefault = function(field) {
      return field.binning == 'default';
    };

    $scope.showRemoveRowGrouping = function(field){
       for (var i = 0; i < $scope.selectedDataset.fields.length; i++) {
        var currentField = $scope.selectedDataset.fields[i];
        if (field.key === currentField.key) {
          return currentField.cardinality > 10;
        }
       }
    };

    $scope.selectDataset = function(selectedDatasetId) {
      var datasets = $scope.datasets;
      $timeout(function(){
        for (i = 0; i < datasets.length; i++) {
          if (datasets[i].id === selectedDatasetId) {
            $scope.selectedDataset = datasets[i];
            $scope.resetQuery();
            break;
          }
        }
      }, 0);
    };

    $scope.selectCell = function(row, column, count, rowIndex, columnIndex) {
      $scope.selectedCell = {};
      $scope.selectedCell.row = row;
      $scope.selectedCell.column = column;
      $scope.selectedCell.count = count;
      $scope.selectedCell.rowIndex = rowIndex;
      $scope.selectedCell.columnIndex = columnIndex;
    };

    $scope.isCellSelected = function(row, column) {
      if ($scope.selectedCell) {
        return row === $scope.selectedCell.row && column === $scope.selectedCell.column;
      } else {
        return false;
      }
    };

    $scope.updateFieldValues = function(filter) {
      if (!$scope.selectedDataset || filter.field_key === 0) {
        return;
      }
      var fieldValueParameters = {
        dataset: $scope.selectedDataset.id,
        rows: [
          {
            name: filter.field_key
          }
        ],
        totals: false
      };

      //Need to reset field_values back to empty list when field changes
      var newFields = [];

      // If they pick a field like "total spent" there are potentially hundreds of thousands of unique values
      // Trying to put them all in a dropdown is impractical
      if ($scope.canPickValues(filter.field_key)) {
        $http.get(
          '/data/api/summarize/?parameters=' + JSON.stringify(fieldValueParameters),
          {
            tracker: 'global'
          }).then(function (response) {
            summaryData = response.data;
            for (var index in summaryData['index']) {
              summaryKey = summaryData['index'][index];
              if (summaryKey != 'Missing' && summaryKey != 'All') {
                newFields.push(summaryKey);
              }
            }

            filter.field_values = newFields;
          }
        );
      } else {
        // Ensure the condition is not EQ/IN
        if (filter.condition == 'EQ' || filter.condition == 'IN') {
          filter.condition = 'LT';
        }
      }
    };

    $scope.canPickValues = function(field_key) {
      var field = getField(field_key);

      if (field) {
        var isNumeric = $scope.isFieldNumerical(field_key);
        if (isNumeric && field.cardinality <= 100 || !isNumeric && field.cardinality <= 2000) {
          return true;
        }
      }

      return false;
    };

    $scope.filterableField = function(field) {
       if (field && ($scope.canPickValues(field.key) || $scope.isFieldNumerical(field.key))){
         return field;
       }
    };

    $scope.addFilter = function(event) {
      var filter = {'field_key': 0, 'condition': 'EQ', 'value': 0, negate: 'false', field_values: []};
      $scope.parameters.filters.push(filter);
      $scope.updateFieldValues(filter);

      if (event) {
        event.preventDefault();
      }
    };

    $scope.removeFilter = function(filter, event) {
      for(i = 0; i < $scope.parameters.filters.length; i++){
        if ($scope.parameters.filters[i] == filter) {
          $scope.parameters.filters.splice(i, 1);
          break;
        }
      }

      if (event) {
        event.preventDefault();
      }
    };

    function addFilter(detailParameters, selectionType, value, index) {
      if (detailParameters[selectionType].length > 0 && value != "All") {
        var newFilter = null;
        //If it is a binned value, the label will start with "From" or end in "and greater" and we need to use the index
        if (value.startsWith("From") || value.endsWith("and greater")) {
          newFilter = {'field_key': detailParameters[selectionType][0].key, 'value': index, 'condition': 'BIN'};
        } else {
          //Otherwise it is just a standard value, filter on the value
          field = getField(detailParameters[selectionType][0].key);
          if (field.cardinality == 2 && field.np_datatype.startsWith('i')) {
            if (value == "Yes") {
              value = 1;
            } else if (value == "No") {
              value = 0;
            }
          }
          newFilter = {'field_key': detailParameters[selectionType][0].key, 'value': value, 'condition': 'EQ'};
        }
        detailParameters.filters.push(newFilter);
      }
    }

    $scope.addToFilterValues = function(filter, newValue) {
      if (typeof filter.value == "string" || filter.value === 0) {
        filter.value = [newValue];
      } else {
        filter.value.push(newValue);
      }
    };

    $scope.isFieldNumerical = function(field_key) {
      if($scope.selectedDataset){
        for (var i = 0; i < $scope.selectedDataset.fields.length; i++) {
          var currentField = $scope.selectedDataset.fields[i];
          if (currentField.key === field_key && (currentField.np_datatype[0] == "i" || currentField.np_datatype[0] == "f")) {
            return true;
          }
        }
        return false;
      }
    };

    $scope.populateDetailParameters = function() {

      var detailParameters = JSON.parse(JSON.stringify($scope.parameters));

      delete detailParameters.aggregation;

      detailParameters = cleanFilters(detailParameters);

      //Filter the details based on the selected row and column
      addFilter(detailParameters, 'columns', $scope.selectedCell.column, $scope.selectedCell.columnIndex);
      addFilter(detailParameters, 'rows', $scope.selectedCell.row, $scope.selectedCell.rowIndex);

      removeProperties(detailParameters, 'field_values');

      $scope.downloadDetail = function(){
        window.open('/data/api/download_csv/?parameters=' + JSON.stringify(detailParameters), '_blank');
      };

      // Need to set this so the download detail and run a campaign links show
      $scope.detailData = true;
    };

    $scope.download = function(){

      var parametersToSend = JSON.parse(JSON.stringify($scope.parameters));

      removeProperties(parametersToSend, '$$hashKey');
      removeProperties(parametersToSend, 'field_values');

      parametersToSend = cleanFilters(parametersToSend);

      window.open('/data/api/download_csv/?parameters=' + JSON.stringify(parametersToSend), '_blank');
    };

    $scope.resetQuery = function(){
      var datasetId = null;
      if ($scope.selectedDataset) {
        datasetId = $scope.selectedDataset.id;
        for (var fieldIndex = 0; fieldIndex < $scope.selectedDataset.fields.length; fieldIndex++) {
          var field = $scope.selectedDataset.fields[fieldIndex];
          field.selected = false;
        }
      }
      $scope.parameters = {
        dataset: datasetId,
        rows: [],
        columns: [],
        filters: [
        ],
        aggregation: {
          type: 'standard', functions: ['count']
        },
        hasValues: false,
        totals: false
      };

      $scope.addFilter();

      $scope.summarizedData = null;

      delete $scope.selectedCell;
      $scope.detailData = null;
      $scope.loader = {
        selectedDataset: {}
      };
      $scope.saver = {};
      $scope.share = {};

      $scope.aggregation = {
        function_name: 'count',
        field_key: null
      };

    };

    $scope.$watch('selectedDataset.id', function() {
      if ($scope.selectedDataset) {
        $scope.loader.selectedDataset.id = $scope.selectedDataset.id;
      }
    });

    $scope.refreshSavedParameterList = function() {
      if ($scope.loader.selectedDataset.id) {
        $http.get('/data/api/v1/saved_parameters/?format=json&dataset__id=' + $scope.loader.selectedDataset.id,
          { tracker: 'global'}
        ).then(
          function (response) {
            $scope.savedParameters = response.data.objects;
          }
        );
      }
    };

    $scope.$watch('loader.selectedDataset.id', function() {
      if ($scope.loader.selectedDataset.id) {
        $scope.refreshSavedParameterList();
      }
    });

    $scope.loadParameters = function(parameters) {
      $scope.resetQuery();
      $scope.selectDataset($scope.loader.selectedDataset.id);
      window.setTimeout(function() {
        $scope.$apply(function() {
          $scope.parameters = parameters;
          $scope.summarize();
        });
      }, 1000);

    };

    function sendParameters(saveType, title, description, email) {
      parametersToSend = JSON.parse(JSON.stringify($scope.parameters));
      removeProperties(parametersToSend, 'field_values');
      $http({
        url: '/data/api/v1/saved_parameters/?format=json',
        data: {
          dataset_id: $scope.selectedDataset.id,
          title: title,
          description: description,
          parameters: $scope.parameters,
          page_source: "EXPLORE_SUMMARY",
          share_parameters_email: email,
          save_type: saveType
        },
        method: 'POST',
      }).success(
        function (response) {
        }
      ).error(
        function (response) {
        }
      );
    }

    $scope.saveParameters = function() {
      sendParameters("USER", $scope.saver.title, $scope.saver.description, null);
    };

    $scope.shareParameters = function() {
      sendParameters("RECV", $scope.share.title, $scope.share.description, $scope.share.email);
    };

    $scope.loading = true;

    $http.get('/data/api/v1/dataset/?format=json',
      { tracker: 'global'}
    ).then(
      function(response) {
        $scope.loading = false;
        $scope.datasets = response.data.objects;

        if ($scope.datasets.length == 1) {
          var singleDatasetID = $scope.datasets[0].id;
          $scope.selectDataset(singleDatasetID);
        }
      }
    );

    $scope.resetQuery();

    var exploreFilterModal = $modal(
      {
        scope: $scope,
        contentTemplate: 'views/explore/filters.html',
        show: false
      }
    );

    $scope.showFilterModal = function() {
      exploreFilterModal.$promise.then(exploreFilterModal.show);
    };

    $scope.aggregation = {
      function_name: 'count',
      field_key: null
    };

    $scope.isCardinalityGreaterThanTwo = function(field) {
      return field.cardinality > 2;
    };

    $scope.aggregationFunctionChanged = function() {
      if ($scope.aggregation.function_name == "count") {
        $scope.aggregation.field_key = null;
        $scope.addSummary(
          getField('id'),
          $scope.aggregation.function_name
        );
      } else if ($scope.aggregation.field_key) {
        $scope.addSummary(
          getField($scope.aggregation.field_key),
          $scope.aggregation.function_name
        );
      }

    };

    $scope.aggregationFieldChanged = function() {
      $scope.addSummary(
        getField($scope.aggregation.field_key),
        $scope.aggregation.function_name
      );
    };

    $scope.getFieldAverage = function(field_key) {
      var field = getField(field_key);
      if (field && field.mean) {
        return field.mean;
      } else {
        return null;
      }
    };

    $scope.getFieldFormatForAverage = function(field_key) {
      var format = getField(field_key).display_format;
      if (format == "0,0" ) {
        format = "0,0.00";
      }
      return format;
    };

    $scope.getFieldName = function(field_key) {
      var field = getField(field_key);
      if (field.name == "id") {
        return "ID";
      } else {
        return field.name.split('_').join(' ').capitalize();
      }

    };

    $scope.static_base = static_base;
  }
]);
