angular.module('vantageApp').controller('controller.shareAudience', [
  '$element',
  'va.config',
  function($element, config) {

    this.facebookAccountId = config.store.facebook_ad_account_id.replace('act_','');

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };
  }
]);