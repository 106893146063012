angular.module('vantageApp').controller('controller.App', [
  'va.config',
  '$rootScope',
  '$interval',
  '$http',
  '$cookies',
  function(config, rootScope, interval, http, cookies) {
    // Open the feedback tab. This could just be an email link, but
    // this way trains users into using the feedback box.
    rootScope.openFeedback = function (event) {
      event.preventDefault();
      if (rootScope.hasFeature('chat')) {
        window.FrontChat('show');
      } else {
        window.open("mailto:support@gotvantage.com");
      }
    };

    rootScope.dismissAlert = function(alertKey) {
      var expireOn = new Date();
      expireOn.setDate(expireOn.getDate() + 30);

      cookies.put('vantage-alert-' + alertKey, true, {expires: expireOn});
    };

    rootScope.isAlertDismissed = function(alertKey) {
      return cookies.get('vantage-alert-' + alertKey);
    };

    // Keyboard shortcuts for admins
    $(document).on('keypress', function(event) {
        // Only afford this if we're not focused in any element,
        // like an input box or whatnot.
        if (event.target !== document.body) {
            return;
        }

        // C-s: opens up the store search
        if (event.ctrlKey && event.keyCode === 19) {
          window.location = '/#/organizations';
        }
    });
  }
]);
