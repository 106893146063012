angular.module('vantageApp').directive('selectedLineItem', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/selected-line-item.html',
      scope: {
        campaign: '=',
        store: '='
      },
      link: function (scope) {
        scope.campaignUrl = function(lineItem) {
          return '/?oid=' + lineItem.assignedCampaigns[0].organization_id + '#/campaigns/edit/' + lineItem.assignedCampaigns[0].campaign_id
        }
        scope.lineItemUrl = function(lineItem) {
          return 'https://app.placements.io/campaigns/' + lineItem.campaignPIOId + '/offline_charges/' + lineItem.lineItemPIOId + '/edit';
        }
      }
    };
  }
]);
