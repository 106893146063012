angular.module('vantageApp').factory('model.CampaignBudget', [
  'lib.Model',
  'va.config',
  'model.StoreDetails',
  function(Model, config, StoreDetails) {
    /**
     * Model for campaign budgets.
     */
    var CampaignBudget = Model({
      init: function() {
        this.$parent.init.apply(this, arguments);
        this.minimum = +Math.max(500, this.totalSpend || 0).toFixed(2)

        if (!this.isValid()) {
          this.old_actual = 0;
          this.actual = this.recommended;
        }
        else {
          this.old_actual = this.actual;
        }

        this.unallocatedAmount = 0;
      },

      fetchUnallocatedAmount: function () {
        return new StoreDetails({'store': config.store}).mediaAgreements()
      },

      initializeUnallocatedAmount: function () {
        var budget = this;
        budget.assignAmount = function (agreements) {
          budget.unallocatedAmount = agreements.unallocate_amount;
        };
        this.fetchUnallocatedAmount().then(this.assignAmount.bind(this));
      },

      initializeUnallocatedAmountHDUS: function (unallocatedAmount) {
        this.unallocatedAmount = unallocatedAmount
      },

      isHomeDepotUS: function () {
        return config.store.name.endsWith("- Home Depot US");
      },

      isNaN: function() {
        return isNaN(this.actual) || this.actual === "";
      },

      isBelowMinimum: function() {
        return this.actual < this.minimum;
      },

      budgetExceedsMKAmount: function() {
        return this.isHomeDepotUS() && ((this.actual-this.old_actual) > this.unallocatedAmount);
      },

      isHigh: function() {
        return (this.actual > (this.recommended * 1.25)) || this.budgetExceedsMKAmount();
      },

      isLow: function() {
        return this.actual < (this.recommended * 0.9);
      },

      isValid: function() {
        // This would be easier in lodash 4 with _.isNil
        return !(_.isNull(this.actual) || _.isUndefined(this.actual));
      },

      isRunnable: function() {
        this.old_actual = 0;
        return this.isValid() && !this.isNaN() && !this.isBelowMinimum() && !this.budgetExceedsMKAmount();
      }
    });

    if (config.store) {  // TODO Bandaid for drilldown_modal
      CampaignBudget.configure({
        url: '/api/' + config.store.id + '/budget',
        updateUrl: '/api/' + config.store.id + '/budget/update',
      });
    }

    return CampaignBudget;
  }
]);
