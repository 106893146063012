angular.module('vantageApp').factory('lib.Tile', [
  'lib.Class',
  'model.Tile',
  'va.config',
  '$rootScope',
  function(Class, TileModel, config, rootScope) {
    /**
     * A base class for Tile controllers.
     *
     * There are several hooks that a sub-class can and should implement
     * if necessary, so it's worth going over the lifecycle of a tile.
     *
     * 1. link - Called when the tile is added to the DOM via its
     *           associated directive.
     *
     * 2. load - After linking, this is called to load the initial
     *           set of data for the tile. This method always returns
     *           a promise.
     *
     *
     * 4. loadData - This is called when the promise from load has been
     *               resolved. It allows the subclass to massage the data
     *               once it's been loaded.
     *
     * 5. refreshData - This is called when any websocket updates come in.
     *                  By default, we simply call loadData, assuming that
     *                  the payload is exactly the same.
     */
    var Tile = Class({
      // A unique name to identify the tile
      name: null,

      // The DOM element this tile is associated with.
      element: null,

      // The last time the data was updated.
      lastUpdate: null,

      // Whether or not the tile is unlinked.
      unlinked: false,

      // A hash of events to subscribe to.
      events: {
        refreshed: function(response) {
          if (this.period !== 'daily') {
            return;
          }

          // Only update the tile if the data is fresher
          // than what we've already got displayed
          var updatedAt = moment(response.meta.updated_at);

          if (updatedAt.isBefore(this.lastUpdate)) {
            return;
          }

          this.refreshData(response);
          this.lastUpdate = updatedAt;
          if (!rootScope.coronaLastUpdate){
            rootScope.coronaLastUpdate = {};
          }

          rootScope.coronaLastUpdate[this.ownerId] = updatedAt;

          this.scope.$apply();
        }
      },

      /**
       * Returns the template to use for the tile.
       *
       * @return string
       */
      getTemplate: function() {
        return 'views/tile/' + this.name + '.html';
      },

      /**
       * Main hook called when the tile is ready to be added to the DOM.
       *
       * This method handles initializing and preparing the initial state
       * of the instance, as well as kicking off the HTTP requests necessary
       * to retrieve the tile's data.
       *
       * It will always return a promise.
       *
       * @return  $q
       */
      link: function(scope, element) {
        var tile = this;

        // Give access to the raw element if necessary.
        this.element = element;
        this.scope = scope;
        this.store = scope.store;
        this.period = scope.period;
        this.config = config;
        this.hasBenchmarks = scope.hasBenchmarks;
        this.benchmarkName = scope.benchmarkName;
        this.ownerId = scope.ownerId;
        this.segmentName = scope.segmentName;

        // This allows scoped jquery queries.
        this.$ = function(selector) {
          return element.find(selector);
        };

        // Give the view access to the whole tile instance
        scope.tile = this;

        return this.load(scope);
      },

      /**
       * This is called when the tile is torn down, giving it
       * a chance to free any references and un-bind events.
       */
      unlink: function() {
        var tile = this;

        // Make sure we're marked as unlinked, so we can
        // disable any other promise handlers.
        this.unlinked = true;
      },

      /**
       * Loads the data for the tile from the Inbound API.
       *
       * @return  $q
       */
      load: function(scope) {
        var tile = this;

        return this.makeRequest().then(function(model) {
          if (tile.unlinked) {
            return;
          }

          tile.loadData(model);

          if (model.meta && model.meta.updated_at) {
            var lastUpdate = moment(model.meta.updated_at);
            tile.lastUpdate = lastUpdate;
            if (!rootScope.coronaLastUpdate) {
              rootScope.coronaLastUpdate = {};
            }
            rootScope.coronaLastUpdate[tile.ownerId] = lastUpdate;
          }

          return model;
        });
      },

      /**
       * Makes the request for data.
       *
       * @return  $q
       */
      makeRequest: function() {
        var params = {
          period: this.period,
        };
        if (this.benchmarkName) {
          params['benchmark_name'] = this.benchmarkName;
        }

        if (this.ownerId) {
          params['segment_owner_id'] = this.ownerId;
        }

        if (this.segmentName) {
          params['segment_name'] = this.segmentName;
        }

        if (config.store.forecast_history_start_date) {
          params['forecast_history_start_date'] = config.store.forecast_history_start_date;
        }

        if (config.store.forecast_days_to_ignore) {
          params['forecast_days_to_ignore'] = config.store.forecast_days_to_ignore;
        }

        if (config.store.force_query_strategy) {
          params['force_query_strategy'] = config.store.force_query_strategy;
        }

        return TileModel.get(this.name, params);
      },

      /**
       * Hook for subclasses to implement so they can
       * massage the data retrieved in "load".
       */
      loadData: function() {
        throw new Error('Not implemented.');
      },

      /**
       * Hook for subclasses to implement so they can
       * massage the data retrieved in the refresh event.
       */
      refreshData: function() {
        return this.loadData.apply(this, arguments);
      },

      /**
       * Helper method for setting the trend of the tile.
       *
       * This automatically sets a CSS class on the DOM element
       * so that it's easy to add trend-specific styles.
       */
      setTrend: function(trend) {
        if (!trend) {
          trend = 'neutral';
        }

        this.element.removeClass(function(index, css) {
          return (css.match (/(^|\s)is-trend-\S+/g) || []).join(' ');
        }).addClass('is-trend-' + trend);
      },
      setBenchmarkTrend: function(trend) {
        if (!trend) {
          trend = 'neutral';
        }

        this.element.removeClass(function(index, css) {
          return (css.match (/(^|\s)is-benchmark-trend-\S+/g) || []).join(' ');
        }).addClass('is-benchmark-trend-' + trend);
      },
      setShareOfBenchmarkTrend: function(trend) {
        if (!trend) {
          trend = 'neutral';
        }

        this.element.removeClass(function(index, css) {
          return (css.match (/(^|\s)is-share-of-benchmark-trend-\S+/g) || []).join(' ');
        }).addClass('is-share-of-benchmark-trend-' + trend);
      },


      /**
       * Returns the start date of the period that the tile is
       * showing as a moment timestamp.
       *
       * @return  moment
       */
      getStartDate: function(fiscal_start_month) {
        var now = moment();

        switch (this.period) {
          case 'daily':
            return now.startOf('day');
          case 'weekly':
            return now.startOf('isoWeek');
          case 'monthly':
            return now.startOf('month');
          case 'quarterly':
            return moment(now.fquarter(fiscal_start_month).start);
          case 'yearly':
            fiscal_date_this_year = moment([now.year(), fiscal_start_month - 1, 1]);

            if (fiscal_date_this_year.valueOf() <= now.valueOf()) {
              return fiscal_date_this_year;
            }

            fiscal_date_last_year = moment([now.year() - 1, fiscal_start_month - 1, 1]);
            return fiscal_date_last_year;
        }
      },

      /**
       * Returns the end date of the period that the tile is
       * showing as a moment timestamp.
       *
       * @return  moment
       */
      getEndDate: function(fiscal_start_month) {
        var now = moment();

        switch (this.period) {
          case 'daily':
            return now.endOf('day');
          case 'weekly':
            return now.endOf('isoWeek');
          case 'monthly':
            return now.endOf('month');
          case 'quarterly':
            return moment(now.fquarter(fiscal_start_month).end);
          case 'yearly':
            fiscal_date_this_year = moment([now.year(), fiscal_start_month - 1, 1]);

            if (fiscal_date_this_year.valueOf() > now.valueOf()) {
              return fiscal_date_this_year;
            }

            fiscal_date_last_year = moment([now.year() + 1, fiscal_start_month - 1, 1]);
            return fiscal_date_last_year;
        }
      }
    });

    return Tile;
  }
]);

