angular.module('vantageApp').factory('tile.StoreOverview', [
  'lib.Tile',
  '$q',
  'va.config',
  function(Tile, Promise, config) {
    return Tile({
      loadData: function(response) {
        this.amount = response.data.amount;
        this.percentChange = Math.abs(response.data.percent_change);
        this.setTrend(response.data.trend);

        if (response.data.benchmark) {
          this.benchmark = Math.abs(response.data.benchmark.value);
          this.setBenchmarkTrend(response.data.benchmark.trend);
        }

        if (response.data.share_of_benchmark) {
          this.shareOfBenchmark = response.data.share_of_benchmark.value;
          this.setShareOfBenchmarkTrend(response.data.share_of_benchmark.trend);
          this.shareOfBenchmarkPercentChange = Math.abs(response.data.share_of_benchmark.percentage_change);
        }
      }
    });
  }
]);
