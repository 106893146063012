angular.module('vantageApp').directive('campaignActions', [
  '$rootScope',
  '$http',
  '$location',
  'lib.Modal',
  'model.Campaign2',
  'va.config',
  function($rootscope, $http, $location, Modal, Campaign, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-actions.html',
      scope: {
        campaign: '=',
        store: '=',
        isMediaPlanner: '='
      },
      link: function(scope) {
        var ctl = this;

        ctl.deleteCampaign = function(campaignId) {
          var resource_uri = Campaign.url + campaignId + "/delete/";
          return $http.delete(resource_uri, {});
        };

        scope.isAdmin = function() {
          return $rootscope.hasFeature('admin');
        };

        $rootscope.isCopying = false;

        scope.isCopyingCampaign = function (){
          return $rootscope.isCopying;
        };

        scope.copyCampaign = function(campaignId, targetOrgId) {
          if (!$rootscope.isCopying) {
            $rootscope.isCopying = true;

            const resource_uri = Campaign.url + campaignId + "/copy/";
            const request_body = {
              target_org_id: targetOrgId
            }

            var org_url = "";
            if (targetOrgId) {
              org_url = "/?oid=" + targetOrgId;
            }

            $http.post(resource_uri, request_body).then(function (response) {
              if (response.data.new_campaign_id) {
                var newCampaignLocation = org_url + "#/campaigns/edit/" + response.data.new_campaign_id;
                window.location.href = newCampaignLocation;
              }
            });
          }
        };

        scope.crossStoreCopyCampaign = function(campaignId) {
          Modal.show({
            templateUrl: 'views/modal/cross-store-copy.html',
            controller: 'controller.crossStoreCopy',
            controllerAs: 'ctl',
            inputs: {
              action: function(targetOrgId) {
                scope.copyCampaign(campaignId, targetOrgId);
              }
            }
          })
        };

        scope.getCopyMessage = function(campaign) {
            return "Copy Campaign";
        };

        scope.getCrossStoreCopyMessage = function() {
          return "Copy campaign to another " + config.storeTerm();
        }

        scope.getDeleteMessage = function(campaign) {
          if (campaign.state === 'draft') {
            return 'Delete Draft';
          } else {
            return "You can only delete Draft Campaigns";
          }
        };

        scope.confirmDelete = function(campaign) {
          Modal.show({
            templateUrl: 'views/modal/delete-campaign-confirmation.html',
            controller: 'controller.deleteCampaignConfirmation',
            controllerAs: 'modal',
            inputs: {
              campaign: campaign,
              action: ctl.deleteCampaign,
            }
          });
        };

        scope.edit = function(campaign) {
          $location.path('/campaigns/edit/' + campaign.id);
        };

        scope.editPlan = function(campaign, store) {
          var url = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/r/' + store.id + '/campaign/plan/' + campaign.id;
          window.location.href = url;
        }

        scope.showCrossStoreCopy = (config.features.numOrgs > 1 || scope.isAdmin()) && !scope.isMediaPlanner;
      }
    };
  }
]);
