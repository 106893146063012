angular.module('vantageApp').directive('adsetAd', [
  'lib.Modal',
  function(Modal) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/adset-ad.html',
      require: ['ngModel', '^adsetEditor'],

      link: function(scope, element, attrs, controllers) {
        var model = controllers[0];
        var adset = controllers[1];

        model.$render = function() {
          scope.ad = this.$viewValue;
        };

        scope.showToolbar = function(ad) {
          return !scope.isDeleting && !ad.isEditable;
        };

        scope.getTooltipCopy = function(ad) {
          if (ad.isPristine && !scope.store.retailerIsHomeDepotUS() && !scope.store.retailerIsHomeDepotCanada()) {
            return "This ad is using default copy to get you started. Edit the ad, and make it your own!";
          } else if (ad.isCopied) {
            return "This ad was copied from another one. You should edit the ad, and use different copy.";
          }
        };

        scope.select = function(event) {
          // Don't de-select when entering text in an input, that's annoying.
          if ($(event.target).is(':input:enabled') && scope.ad.isSelected) {
            return;
          }

          adset.toggleAdSelection(scope.ad);
        };

        // Toolbar button helpers
        scope.copyAd = function(ad) {
          adset.copyAd(ad);
        };

        scope.editAd = function(ad) {
          this.ad = ad;
          adset.selectNone();
          adset.edit([ad]);
        };

        scope.deleteAd = function(ad) {
          ad.isDeleting = true;
        };

        scope.cancelDelete = function(ad) {
          ad.isDeleting = false;
        };

        scope.confirmDelete = function(ad) {
          if (!ad.isDeleting) {
            // Just in case.
            return;
          }

          _.pull(scope.adset.ads, ad);
        };

        scope.markChangesComplete = function(ad) {
          scope.campaign.setReviewStatus([ad], 'REVIEW_NEEDED');
        }

        scope.commentOnAd = function(ad) {
          console.log(ad, scope.store, scope.campaign);
          Modal.show({
            templateUrl: 'views/modal/comment-on-ads-brand.html',
            controller: 'controller.commentOnAds',
            controllerAs: 'modal',
            inputs: {
              campaign: scope.campaign,
              store: scope.store,
              ads: [ad],
              default_status: 'NO_CHANGE',
              action: function() {}
            }
          });
        }
      }
    };
  }
]);
