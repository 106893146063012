angular.module('vantageApp').controller('controller.changeGoogleAudienceConfirmation', [
  '$element',
  'close',
  'campaign',
  'model',
  'audience',
  'model.Audience',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  function($element, close, campaign, model, selectedAudience, Audience, AdvancedEcom) {

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };

    // Proceed with switching the audience
    this.proceed = function() {
      // Delete the Google Display ads from adsets
      if (campaign.adsets.hasOwnProperty('DISPLAY')) {
        campaign.adsets['DISPLAY'].ads =[];
      };

      $element.modal('hide');
      var audience = new Audience(_.clone(selectedAudience));

      model.$setViewValue(audience);
      model.$render();
      AdvancedEcom.pickAudience();

      close();
    };
  }
]);
