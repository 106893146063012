angular.module('vantageApp').directive('campaignEmail', [
  '$http',
  function(http) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-email.html',
      scope: {
        email: '=',
      },
      link: function(scope, element) {
        // Since emails are not currently editable we render a preview
        // that's generated server side. This will need to change.
        http.post('/ecom/api/v1/email/' + scope.email.id + '/preview_email/')
          .then(function(response) {
            var iframe = element.find('iframe')[0].contentWindow.document;
            iframe.open();
            iframe.write(response.data);
            iframe.close();
          }, function(response) {
            scope.isError = true;
          }).finally(function() {
            scope.isRendered = true;
          });
      }
    };
  }
]);
