angular.module('vantageApp').run([
  '$rootScope',
  'va.config',
  function (root, config) {
    root.hasFeature = function (feature) {
      return config.features[feature] === true;
    };

    root.isDemoing = function () {
      return config.isDemoing();
    };

    root.isExternal = function () {
      return config.store && config.store.platform === 'EXTERNAL';
    };

    root.isShopify = function () {
      return config.store && (config.store.platform === 'SHOPIFY' || config.store.platform === 'SHOPIFY_SAT');
    };

    root.isRetailer = function () {
      return config.store && config.store.is_retailer;
    };

    root.isVFA = function () {
      return config.user.isVantageFirst;
    };

    root.isBrandUser = function () {
      return config.user.isBrandUser;
    }

    root.isRetailerUser = function () {
      return config.user.isRetailerUser;
    }

    root.showSpecialTHDDemoTiles = function () {
      return (config.user.id == 1 || config.user.id == 29522) && (config.store.name.indexOf('Home Depot CA') > 0);
    }
  }
]);
