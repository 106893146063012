angular.module('vantageApp').controller('controller.pauseCancelCampaignModal', [
  '$scope',
  'campaign',
  'action',
  '$element',
  'close',
  'model.Organization',
  'model.Campaign2',
  'store',
  'va.config',
  function($scope, campaign, action, element, close, Organization, Campaign, store, config) {
    var actionToBtn = {
      pause: 'btn-secondary',
      cancel: 'btn-danger'
    };

    $scope.action = action;
    $scope.campaign = campaign;
    $scope.warning_url = 'views/includes/' + action + '-campaign.html';
    $scope.buttonClass = actionToBtn[action];
    $scope.reasonCode = null;
    $scope.loading = false;

    $scope.reasonCodes = {
      REVENUE: 'Not enough revenue',
      CHANGES: 'Need to make changes',
      OTHER:  'Other'
    };

    $scope.storeHasBilling = function(){
      return store.charge_for_campaigns && !config.store.is_brand && !config.store.is_retailer;
    };

    $scope.chargeForCampaigns = function(){
      return store.charge_for_campaigns;
    };

    var today = new Date();
    var daysSinceStart = Math.round(((today - new Date(campaign.schedule.startDate))) / (1000 * 60 * 60 * 24));
    $scope.isTooShort = daysSinceStart < 7;
    $scope.hasLowCTR = campaign.insights_report.unique_ctr < 3;

    Organization.current().then(function(organization) {
      var currency =  organization.python_currency;
      if (currency === 'USD' && campaign.insights_report.spend > 10) {
        var campaignReturn = campaign.insights_report.total_conversion_value - campaign.insights_report.spend;
        $scope.campaignROI = campaignReturn / campaign.insights_report.spend;
        $scope.hasHighROI = $scope.campaignROI > 5;
      }
      $scope.hasCampaignWarnings = $scope.isTooShort || $scope.hasLowCTR || $scope.hasHighROI;
    });

    Campaign.all({state: 'running'}).then(function(campaigns) {
      $scope.numberRunning = campaigns.length;
    });
    // Close the modal.
    this.close = function() {
      element.modal('hide');
      close();
    };

    this.setReasonCode = function(key) {
      $scope.reasonCode = key;
    };

    // Proceed with cancelling the Campaign.
    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      var reason = $scope.reason;

      // If they selected one of the presets, include it and append whatever they put in
      if ($scope.reasonCode) {
        reason = $scope.reasonCodes[$scope.reasonCode] + ' - ' + reason;
      }

      if (action === 'pause') {
        campaign.pause(reason);
      } else if (action === 'cancel') {
        campaign.cancel(reason);
      }
    };
  }
]);
