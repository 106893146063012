angular.module('vantageApp').directive('tile', [
  '$injector',
  function (injector) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'views/directives/tile/layout.html',
      require: '^intro',
      scope: {
        period: '=',
        tileName: '=?',
        tileIntroStep: '=?',
        store: '=?',
        config: '=?',
        hasBenchmarks: '=?',
        benchmarkName: '=?',
        ownerId: '=?',
        segmentName:'=?'
      },

      link: function(scope, element, attrs, intro) {
        /**
         * Helper for setting the tile's state, which
         * also handles adding a class to the element
         * based on its state.
         */
        scope.setState = function(state) {
          scope.state = state;
        };

        /**
         * Returns the tile's template, which cycles between
         * various possibilities based on the state of the tile.
         */
        scope.getTemplate = function() {
          return {
            action: ctl.getTemplate(),
            loading: 'views/directives/tile/loading.html',
            dismissed: 'views/directives/tile/dismissed.html',
            error: 'views/directives/tile/error.html',
            loaded: ctl.getTemplate(),
          }[scope.state];
        };

        /**
         * Tiles can have help associated with them.
         */
        scope.help = function() {
          intro.start(scope.tileIntroStep);
        }

        // Make sure to clean up the controller when we've finished
        scope.$on('$destroy', function() {
          ctl.unlink();
        });

        // Now we can kick off loading the controller
        var ctl = new (injector.get(attrs.tileController));

        if (scope.tileName) {
          ctl.name = scope.tileName;
        }

        scope.setState('loading');

        ctl.link(scope, element).then(function(data) {
          if (scope.state === 'loading') {
            scope.setState('loaded');
          }
        }, function() {
          scope.setState('error');
        });
      }
    };
  }
]);
