// This a request interceptor that adds some nice customizations
// on top of the $http gives us, including automatic base urls.
angular.module('vantageApp').factory('config.httpInterceptor', [
  '$q',
  '$cookies',
  'va.config',
  function(promise, cookies, config) {
    var jwt = config.jwt();

    return {
      request: function(config) {
        if (config.url.indexOf('.s3.amazonaws.com') !== -1 ||
            config.url.indexOf('.cloudfront.net') !== -1) {
            return config;
        }

        // Django likes this...
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['X-CSRFToken'] = cookies.get('csrftoken');
        config.headers['Authorization'] = jwt;

        return config;
      },

      responseError: function(error) {
        return promise.reject(error);
      }
    };
  }]

// Configure the httpProvider with our newfound interceptor
).config(['$httpProvider', function (http) {
  http.interceptors.push('config.httpInterceptor');
}]);
