(function(app) {
  app.controller('controller.DashboardMetrics', [
    '$scope',
    '$state',
    'store',
    'metrics',
  function(scope, state, store, metrics) {
    var groups = [
      {
        title: 'Visitors',
        filterByPeriod: false,
        filter: function(metric) {
          return metric.group === 'GOOGLE_ANALYTICS';
        }
      },
      {
        title: 'Customers',
        filterByPeriod: true,
        filter: function(metric) {
          return metric.group === 'CUSTOMER';
        }
      },
      {
        title: 'Orders',
        filterByPeriod: true,
        filter: function(metric) {
          return metric.group === 'ORDERS';
        }
      },
      {
        title: 'Revenue',
        filterByPeriod: true,
        filter: function(metric) {
          return metric.group === 'REVENUE';
        }
      }
    ];

    scope.store = store;

    // Bring the resolved data over to the controller.
    scope.groups = _.mapValues(groups, function(group) {
      return _.assign(group, {
        metrics: _.filter(metrics, group.filter)
      });
    });

    // The available time periods for metrics.
    scope.availablePeriods = {
      "day": true,
      "week": true,
      "month": true,
      "quarter": true,
      "year": true,
      "all_time": true
    };

    scope.filterByPeriod = function(metric) {
      for (var key in scope.availablePeriods) {
        if (metric.metric.key.endsWith(key)) {
          return scope.availablePeriods[key] == true;
        }
      }

      return true;
    };
  }]);
})(window.vantageApp);
