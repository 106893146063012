angular.module('vantageApp').directive('pageVideoDropdown', [
  'model.PageVideo',
  'model.UrlVideo',
  '$sce',
  'lib.Modal',
  function(PageVideo, UrlVideo, $sce, Modal) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/page-video-dropdown.html',
      scope: {
        addVideo: '=',
        card: '='
      },
      link: function(scope) {
        // Initialize picker attributes
        scope.isChoosingVideos = false;
        scope.isLoadingVideos = false;

        scope.pageVideosSelected = true;

        // Initialize paging
        scope.pageNumber = 1;

        scope.urlVideoProperties = {
          'fileUrl' : '',
          'title' : ''
        };
        scope.urlVideoQuery = "";

        scope.secondsToTimestamp = function(seconds) {
          var sec_num = parseInt(seconds, 10);
          var hours   = Math.floor(sec_num / 3600);
          var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
          seconds = sec_num - (hours * 3600) - (minutes * 60);

          if (hours   < 10) {hours   = "0" + hours;}
          if (minutes < 10) {minutes = "0" + minutes;}
          if (seconds < 10) {seconds = "0" + seconds;}

          return hours + ':' + minutes + ':' + seconds;
        };

        scope.closeVideoPicker = function() {
          scope.isChoosingVideos = false;
        };

        scope.hasVideos = function() {
          return !_.isEmpty(scope.videos);
        };

        scope.queryVideos = function(useCache) {
          if (typeof useCache === 'undefined') {
            useCache = true;
          }

          scope.isChoosingVideos = true;

          if (scope.isLoadingVideos) {
            return;
          }

          scope.isLoadingVideos = true;

          PageVideo.all({
            search: scope.videoQuery,
            page: scope.pageNumber,
            useCache: useCache
          }).then(function(response) {
            scope.videos = response.videos;
            scope.pageInfo = response.pageInfo;
            scope.isLoadingVideos = false;
          });
        };

        scope.queryUrlVideos = function() {
          UrlVideo.all().then(
            function (response) {
              scope.urlVideos = response.videos;
            });
        };

        scope.refreshVideos = function() {
          scope.videoQuery = "";
          scope.queryVideos(false);
        };

        scope.resetPage = function() {
          scope.pageNumber = 1;
        };

        scope.searchPlaceholder = function() {
          if (scope.isLoadingVideos) {
            return "Search disabled while loading videos from Facebook";
          } else {
            return "Search your videos...";
          }
        };

        // Pagination
        scope.isFirstPage = function() {
          return scope.pageNumber === 1;
        };

        scope.isLastPage = function() {
          return scope.pageInfo && scope.pageNumber === scope.pageInfo.num_pages;
        };

        scope.nextPage = function() {
          if (!scope.isLastPage()) {
            scope.pageNumber += 1;
            scope.queryVideos();
          }
        };

        scope.previousPage = function() {
          if (!scope.isFirstPage()) {
            scope.pageNumber -= 1;
            scope.queryVideos();
          }
        };

        scope.selectPageVideos = function () {
          scope.pageVideosSelected = true;
        };

        scope.selectUrlVideos = function () {
          scope.pageVideosSelected = false;
        };

        scope.getVideoFileUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        scope.isVideoGif = function (urlVideo) {
          return Boolean(urlVideo.file_url) && !Boolean(urlVideo.facebook_source_url) && urlVideo.file_url.toLowerCase().indexOf('.gif') >= 0;
        };

        scope.newUrlVideo = function() {
          var newUrl = scope.urlVideoProperties.fileUrl;
          var newUrlVideo = scope.urlVideoProperties;

          var urlIsNew = _.all(scope.urlVideos, function (urlVideo) {
            return urlVideo.file_url !== newUrl;
          });

          if (newUrl && urlIsNew) {
            UrlVideo.create(newUrlVideo).then(
              function(urlVideo) {
                scope.addToUrlVideos(urlVideo);

                scope.urlVideoProperties.fileUrl = '';
                scope.urlVideoProperties.title = '';
              },
              function(err) {
                Modal.show({
                  templateUrl: 'views/modal/invalid-video-url.html',
                  controller: 'controller.invalidVideoUrl',
                  controllerAs: 'modal',
                });
              }
            );
          }
        };

        scope.addToUrlVideos = function(urlVideo){
          scope.urlVideos.unshift(urlVideo);
        };

        scope.filterUrlVideoList = function (urlVideo) {
          var filterString = scope.urlVideoQuery;


          if (filterString === undefined || !filterString) {
            return true;
          }

          var filterRe = new RegExp(".*" + filterString.toLowerCase() + ".*");
          var matchedUrl = Boolean(urlVideo.file_url) && filterRe.test(urlVideo.file_url.toLowerCase());
          var matchedTitle = Boolean(urlVideo.title) && filterRe.test(urlVideo.title.toLowerCase());

          return matchedUrl || matchedTitle;
        };
      }
    };

  }
]);
