angular.module('vantageApp').factory('lib.VideoDimensions', [
  'va.config',
  '$http',
  '$timeout',
  function(config, http, timeout) {

    VideoDimensions = function () {
      this.requestCache = {};
      this.dimensionsCache = {};
    };

    VideoDimensions.prototype.getDimensionsByVideoId = function (videoId) {

      if (videoId in this.dimensionsCache) {

        var deferred = $.Deferred();

        //Timeout so the deferred doesnt resolve immediately and allows FB Iframes to render first
        timeout(
          function(){
            deferred.resolve(this.dimensionsCache[videoId]);
          }.bind(this)
        );

        return deferred;
      } else {

        // Use the same request for video_ids that are currently in-flight so we don't double fetch for the same id.
        if (videoId in this.requestCache){
          return this.requestCache[videoId];
        } else {
          var request = http.get('/api/' + config.store.id + '/video_dimensions?video_id=' + videoId).then(
            function (response) {
              this.dimensionsCache[videoId] = response['data'];
              return response['data'];
            }.bind(this)
          );

          this.requestCache[videoId] = request;

          return request;
        }
      }
    };

    return new VideoDimensions()
  }
]);