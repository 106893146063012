angular.module('vantageApp').controller('controller.YoutubeVideoModal', [
  'url',
  'close',
  '$element',
  '$sce',
  function(url, close, element, sce) {
    this.url = sce.trustAsResourceUrl(url);
    this.close = function() {
      element.modal('hide');
      close();
    };
  }
]);
