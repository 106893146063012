angular.module('vantageApp').factory('lib.Modal', [
  'ModalService',
  function(Modal) {
    return {
      show: function(opts) {
        return Modal.showModal(opts).then(function(modal) {
          modal.element.modal({
            backdrop: 'static',
            keyboard: false,
          });

          return modal;
        });
      },

      video: function(opts) {
        return this.show({
          templateUrl: 'views/modal/youtube-video.html',
          controller: 'controller.YoutubeVideoModal',
          controllerAs: 'modal',
          inputs: {
            url: opts.url,
          }
        });
      }
    };
  }
]);
