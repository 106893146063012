angular.module('vantageApp').factory('tile.CustomerBreakdown', [
  '$rootScope',
  'lib.Tile',
  '$q',
  '$timeout',
  function($rootScope, Tile, Promise, timeout) {
    return Tile({
      colors: {
        'abandoned-carts': '#C16C6C',
        'repeat-customers': '#9A8ABD',
        'conversion-rate': '#3DB1C8',
      },

      seriesNames: {
        'abandoned-carts': 'Abandoned Cart Rate',
        'repeat-customers': 'Repeat Customer Rate',
        'conversion-rate': 'Conversion Rate',
      },

      seriesFormats: {
        'abandoned-carts': '0',
        'repeat-customers': '0',
        'conversion-rate': '0.00',
      },

      getTemplate: function() {
        if (this.name === 'abandoned-carts' && !$rootScope.hasFeature(this.name)) {
          return 'views/tile/' + this.name + '/upgrade.html';
        }

        return this.$parent.getTemplate.apply(this);
      },

      load: function() {
        if (this.name === 'abandoned-carts' && !$rootScope.hasFeature(this.name)) {
          var deferred = Promise.defer();
          deferred.resolve();

          return deferred.promise;
        }

        return this.$parent.load.apply(this, arguments);
      },

      loadData: function(response) {
        var data = response.data || {};

        this.scope.amount = data.amount;
        this.scope.revenue = data.revenue;
        this.scope.percent_change = Math.abs(data.percent_change);

        if (response.data.benchmark) {
          this.benchmark = Math.abs(response.data.benchmark.value);
        }

        this.isEmpty = 0 === _.sum(data.series, function(point) {
          return point[1].count;
        });

        this.loadChart(data);
      },

      loadChart: function(data) {
        var tile = this;

        var config = {
          title: {
            text: '',
            style: {
              display: 'none'
            }
          },
          subtitle: {
            text: '',
            style: {
              display: 'none'
            }
          },
          chart: {
            margin: [0, 0, 0, 0],
            marginTop: 15,
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            type: 'areaspline',
            backgroundColor: 'transparent'
          },
          xAxis: {
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            labels: {
              enabled: false
            },
            minorTickLength: 0,
            tickLength: 0,
            minPadding:0,
            maxPadding:0
          },
          yAxis:{
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            labels: {
              enabled: false
            },
            title: {
              text: ''
            },
            minorTickLength: 0,
            tickLength: 0,
            gridLineWidth: 0,
            minPadding:0,
            maxPadding:0,
          },
          plotOptions: {
            areaspline: {
              fillOpacity: 0.5
            },
            series: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            style: {
              zIndex: 9999
            },
            formatter: function() {
              var dateFormat = tile.period === 'daily'
                  ? 'MMMM Do YYYY, h:mma'
                  : 'MMMM Do YYYY';

              return [
                '<b>',
                moment(this.x).format(dateFormat),
                '</b>',
                '<br />',
                this.series.name,
                ': ',
                numbro(this.y).format(tile.seriesFormats[tile.name]) + '%'
              ].join('');
            }
          },
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          colors: [this.colors[this.name]],
          series:[{
            name: this.seriesNames[this.name],
            data: _.map(data.series, function(point) {
              return tile.formatPoint(point[0], point[1].rate);
            }),
          }]
        };

        if (!this.isEmpty) {
          timeout(function() {
            tile.$('.customer-tile-chart').highcharts(config);
          });
        }
      },

      formatPoint: function(x, y) {
        return [moment(x).valueOf(), y !== null ? parseFloat(y) : null];
      }
    });
  }
]);
