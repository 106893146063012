angular.module('vantageApp').controller('controller.Settings', [
  '$scope',
  '$location',
  'store',
  'va.config',
  function(scope, location, store, config) {
    var populateSection = function() {
      var url = location.url();

      if (!url) {
        return;
      }

      scope.section = url.split('/')[2];

    };
    scope.store = store;
    scope.isBrand = config.store.is_brand;
    scope.show_billing = store.charge_for_campaigns && !config.store.is_brand && !config.store.is_retailer;
    scope.storeTerm = config.storeTerm()
    scope.$on('$locationChangeSuccess', populateSection);
    populateSection();
  }
]);
