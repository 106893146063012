angular.module('vantageApp').factory('tile.Team', [
  'lib.Tile',
  'model.Campaign2',
  'va.config',
  function(Tile, Campaign2, config) {

    return Tile({
      name: 'team',

      makeRequest: function() {
        return Campaign2.all();
      },

      totalSpend: function() {
        return this.sumCampaignData(function(campaign) { return campaign.aggregate_report.spend; });
      },

      totalUSDSpend: function () {
        return this.sumCampaignData(function (campaign) {
          return campaign.aggregate_report.spend / campaign.facebook_account_currency_exchange_rate;
        });
      },

      totalRevenue: function() {
        return this.sumCampaignData(function(campaign) { return campaign.aggregate_report.total_conversion_value; });
      },

      totalUSDRevenue: function() {
        return this.sumCampaignData(function (campaign) {
            return campaign.aggregate_report.total_conversion_value / campaign.exchange_rate;
        });
      },

      totalROAS: function() {
        if (this.totalUSDSpend() > 0) {
          return this.totalUSDRevenue() / this.totalUSDSpend();
        }
      },

      sumCampaignData: function(getData) {
        totalValue = 0;
        _.each(this.campaigns, function(campaign) {
          totalValue += parseFloat(getData(campaign) || 0);
        });
        return totalValue;
      },

      loadData: function(response) {
        this.campaigns = response;
        if (this.isCoop()){
          this.roas = this.totalRetailerROAS();
          this.revenue = this.totalRetailerRevenue();
        } else {
          this.roas = this.totalROAS();
          this.revenue = this.totalRevenue();
        }
      },

      isCoop: function() {
        return _.filter(this.campaigns, function(campaign) { return campaign.brand }).length > 0;
      },

      totalRetailerRevenue: function(skipFilters) {
        return this.sumCampaignData(function(campaign) {
          if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
            return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value;
          } else {
            return 0;
          }
        }, skipFilters);
      },

      totalUSDRetailerRevenue: function(skipFilters) {
          return this.sumCampaignData(function (campaign) {
            if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
              return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value / campaign.exchange_rate ;
            } else {
              return 0;
            }
          }, skipFilters);
      },

      totalRetailerROAS: function(skipFilters) {
        if (this.totalUSDSpend(skipFilters) > 0) {
          return this.totalUSDRetailerRevenue(skipFilters) / this.totalUSDSpend(skipFilters);
        }
      }
    });
  }
]);
