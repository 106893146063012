angular.module('vantageApp').controller('controller.StoreSettings', [
  '$scope',
  'model.Store',
  'store',
  '$http',
  'Upload',
  'va.config',
  '$rootScope',
  function(scope, Store, store, $http, Upload, config, $rootScope) {
    /*
      While this is somewhat ugly and seemingly lazy, it's required as a string for
      the options under a select. We don't want to do this elsewhere, as it's otherwise
      unnecessary and may break other logic.
    */
    store.fiscal_year_start_month = String(store.fiscal_year_start_month);

    scope.store = store;
    scope.showSaveCompletedMessage = false;

    scope.storeTerm = config.storeTerm();
    scope.isBrand = config.store.is_brand;

    scope.editEnabled = !config.store.is_brand || config.user.id == store.owner.id || $rootScope.hasFeature('admin');

    scope.save = function() {
      Store.update(store, {
        organization: store.organization,
        owner: store.owner,
        category: store.category,
        fiscal_year_start_month: store.fiscal_year_start_month,
        _from_email: store._from_email,
        address_line: store.address_line,
        city: store.city,
        state: store.state,
        country: store.country,
        postal_code: store.postal_code,
        logo_image_url: store.logo_image_url
      }).then(scope.openSaveCompletedMessage);
    };

    scope.openSaveCompletedMessage = function() {
      return scope.showSaveCompletedMessage = true;
    };

    scope.closeSaveCompletedMessage = function() {
      return scope.showSaveCompletedMessage = false;
    };

    var doUpload = function (s3bucket, filename, awsData, file) {
      Upload.upload({
        url: 'https://' + s3bucket + '.s3.amazonaws.com/',
        method: 'POST',
        data: {
          key: '' + scope.store.id + '/' + filename,
          acl: 'public-read',
          AWSAccessKeyId: awsData.access_key_id,
          policy: awsData.policy,
          signature: awsData.signature,
          "Content-Type": file.type !== '' ? file.type : 'image/jpg',
          filename: filename,
          file: file
        }
      }).then(
        function (response) {
          store.logo_image_url = 'https://' + s3bucket + '.s3.amazonaws.com/' + scope.store.id + '/' + filename
        },
        function (error) {
          console.log('Error uploading image')
        }
      );
    };

    scope.uploadLogo = function (file) {
      $http.get(
        '/api/' + scope.store.id + '/images/policy'
      ).then(function (response) {
        var s3bucket = response.data.bucket;
        doUpload(s3bucket, file.name, response.data, file);
      });
    };
  }
]);
