angular.module('vantageApp').config([
  function() {
    var languages = {
      'ar': 'د.إ.',
      'ar-af': '؋',
      'ar-eg': 'EGP',
      'ar-bh': 'د.ب.',
      'ar-kw': 'ك',
      'ar-lb': 'ل.ل',
      'ar-om':'ر.ع' ,
      'ar-sa': '﷼',
      'ar-tn': 'د.ت.',
      'bg': 'лв',
      'bn-bd': 'Tkk',
      'bs-ba': 'KM',
      'bw': 'Pu',
      'by': 'руб',
      'cn': '￥',
      'co': '$',
      'cz': 'Kč',
      'eg': 'EGP',
      'en-gh': 'GH¢',
      'en-ie': '€',
      'en-jm': 'J$',
      'en-ng': '₦',
      'en-ro': '€',
      'en-sg': '$',
      'en-tr': '€',
      'en-za': 'R',
      'es-bo': '$b',
      'es-cl': '$',
      'es-co': '$',
      'es-cr': '₡',
      'es-mx': '$',
      'es-pe': 'S/.',
      'fi': '€',
      'gt': 'Q',
      'he-il': '₪',
      'hi-in': '₹',
      'hk': 'HK$',
      'hr-hr': 'kn',
      'hy-am': 'AMD',
      'id': 'Rp',
      'is': 'kr',
      'ko-kr': '₩',
      'kw': 'د.ك.',
      'lg-ug': 'UGX',
      'lk': '₨',
      'lv': 'Ls',
      'ma': 'د.م.',
      'mn': '₮',
      'ms-my': 'RM',
      'mt': '€',
      'mu': 'MUR',
      'ne': 'रू',
      'no': 'kr',
      'pe': 'S/',
      'pk': '₨',
      'pt-mz': 'MTn',
      'ro': 'Lei',
      'se': 'kr',
      'sq-al': 'L',
      'sr-rs': 'РСД',
      'sw-ke': 'KSh',
      'th': '฿',
      'tr-tr': '₺',
      'tt': '$',
      'tw': 'NT$',
      've': 'Bs.',
      'vi-vn': '₫',
      'vn': '₫',
    };

    var aliases = {
      'be-nl': 'nl-BE',
      'da-dk': 'da-DK',
      'de': 'de-DE',
      'de-ch': 'de-CH',
      'en': 'en-US',
      'en-gb': 'en-GB',
      'en-ph': 'fil-PH',
      'fi': 'fi-FI',
      'fr': 'fr-FR',
      'gr': 'it-IT',
      'hu': 'hu-HU',
      'it': 'it-IT',
      'lt': 'it-IT',
      'nl-nl': 'nl-NL',
      'pl': 'pl-PL',
      'pt': 'pt-PT',
      'pt-br': 'pt-BR',
      'sk': 'sk-SK',
    };

    function addLanguage(code, symbol) {
      var language = {
        delimiters: {
          thousands: ',',
          decimal: '.'
        },
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
        },
        ordinal: function (number) {
          return number === 1 ? 'er' : 'e';
        },
        currency: {
          symbol: symbol
        }
      };

      window.numbro.language(code, language);
    }

    // Add new, undefined languages
    _.forEach(languages, function(symbol, code) {
      addLanguage(code, symbol);
    });

    // Map old numeraljs locales to their new names
    _.forEach(aliases, function(alias, code) {
      window.numbro.language(code, window.numbro.languageData(alias));
    });

    if (window.config && window.config.org) {
      try {
        window.numbro.language(window.config.org.currency);
      } catch (e) {
        // If we end up with an invalid language in the db,
        // numbro will blow up. Just default to EN in that case.
      }
    }
  }
]);
