angular.module('vantageApp').controller('controller.pristineAdsConfirmationModal', [
  '$scope',
  'hasPristineAds',
  'action',
  '$element',
  'close',
  function($scope, hasPristineAds, action, element, close) {
    // Close the modal.
    this.close = function() {
      element.modal('hide');
      close();
    };

    // Proceed with cancelling the Campaign.
    this.proceed = function() {
      this.close();
      action();
    }
  }
]);
