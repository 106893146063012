angular.module('vantageApp').factory('model.UserCopy', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    var UserCopy = Model({
      body: null,
      source: null,
    });

    var isHomeDepot = config.store.name.includes("Home Depot") || config.store.domain.includes("homedepot")
    var sources = {}
    if (isHomeDepot){
      sources = {
        VANTAGE: 'VANTAGE',
        USER: 'USER',
      }
    }
    else {
      sources = {
        USER: 'USER',
        VANTAGE: 'VANTAGE',
      }
    }

    // TODO Drilldown bandaid?
    UserCopy.configure({
      url: '/api/' + config.store.id + '/user-copy/',

      sources: sources,
    });

    UserCopy.sourceNameMap = {};
    UserCopy.sourceNameMap[UserCopy.sources.USER] = ["Your Copy", "Brand Copy"];
    UserCopy.sourceNameMap[UserCopy.sources.VANTAGE] = ["Example Copy", "Home Depot Copy"];

    UserCopy.sourceIconMap = {};
    UserCopy.sourceIconMap[UserCopy.sources.USER] = "ion-person";
    UserCopy.sourceIconMap[UserCopy.sources.VANTAGE] = "ion-connection-bars";

    return UserCopy;
  }
]);
