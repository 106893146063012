angular.module('vantageApp').factory('model.CampaignSchedule', [
  'lib.Model',
  function(Model) {
    /**
     * Model for campaign schedules.
     */
    var CampaignSchedule = Model({
      startDate: null,
      endDate: null,

      isOngoing: function() {
        return !this.endDate;
      },

      isValid: function() {
          return this.isValidStartDate() && this.isValidEndDate();
      },

      isValidStartDate: function() {
        var startDate = moment(this.startDate);

        if (this.startDate && startDate.isBefore(moment())) {
          return false;
        }
        return true;
      },

      isValidEndDate: function() {
        if(!this.endDate) {
          return false;
        }
        var startDate = moment(this.startDate);
        var endDate = moment(this.endDate);

        if (this.endDate && endDate.isBefore(startDate)) {
          return false;
        }

        if (this.endDate && endDate.isBefore(moment())) {
          return false;
        }

        return true;
      },

      getDuration: function() {
        if (!this.endDate) {
          return 30;
        }
        var startDate = moment(this.startDate);
        var endDate = moment(this.endDate);
        if (!this.startDate) {
          startDate = moment();
        }
        return Math.floor(endDate.diff(startDate, "days", true)) + 1;
      }
    });

    return CampaignSchedule;
  }
]);
