angular.module('vantageApp').factory('model.Campaign2', [
  '$location',
  'va.config',
  'lib.Model',
  'model.CampaignSchedule',
  'model.CampaignBudget',
  'model.FacebookAd',
  'model.StoreDetails',
  'model.Audience',
  '$http',
  function($location, config, Model, CampaignSchedule, CampaignBudget, FacebookAd, StoreDetails, Audience, $http) {
    /**
     * Base model for Facebook Campaigns.
     */

    var ADSET_MAX_SIZE = 50; // Facebook has a limit of 50 ads per adset

    var Campaign = Model({
      name: null,
      budget: null,
      schedule: null,
      audience: null,
      adsets: null,
      isDirty: false,
      catalog: '',
      productGroupId: null,
      selectedLineItemId: null,
      hasDynamicAds: null,

      facebookPagePhoto: null,
      facebookPageName: null,
      instagramPhoto: null,
      instagramName: null,
      taxonomy: null,
      isMarketingPackageCampaign: false,
      isForTesting: false,
      getAggregateReport: function(facebookReport, googleReport, pinterestReport, exchangeRate, facebookAccountCurrencyExchangeRate) {
        if (!facebookReport && googleReport && !pinterestReport) {return googleReport}
        else if (facebookReport && !googleReport && !pinterestReport) {return facebookReport}
        else if (!facebookReport && !googleReport && pinterestReport) {return pinterestReport}
        else if (!facebookReport && !googleReport && !pinterestReport){return {}}

        var aggregateReport = {};
        var keysToSum = [
          'impressions', 'clicks', 'spend',
          'click_conversions', 'click_conversions_value',
          'view_conversions', 'view_conversions_value',
          'total_conversions', 'total_conversion_value',
          'online_total_conversion_value', 'online_click_conversions_value',
          'online_view_conversions_value', 'online_total_conversions',
          'online_click_conversions', 'online_view_conversions',
          'offline_total_conversion_value', 'offline_click_conversions_value',
          'offline_view_conversions_value', 'offline_total_conversions',
          'offline_click_conversions', 'offline_view_conversions',
        ];
        keysToSum.forEach(function(key) {
          aggregateReport[key] =
              (facebookReport && facebookReport[key] || 0)
              + (googleReport && googleReport[key] || 0)
              + (pinterestReport && pinterestReport[key] || 0);
        });

        aggregateReport['ctr'] = (parseFloat(aggregateReport['clicks']) / aggregateReport['impressions'] * 100) || 0;
        aggregateReport['cpa'] = (parseFloat(aggregateReport['spend']) / aggregateReport['total_conversions']) || 0;
        aggregateReport['conversion_rate'] = (parseFloat(aggregateReport['click_conversions']) / aggregateReport['clicks'] * 100) || 0;

        aggregateReport['coop_breakdown'] = {};
        var coopBreakdownLevels = [];
        if (facebookReport && facebookReport['coop_breakdown']) {
          coopBreakdownLevels = coopBreakdownLevels.concat(Object.keys(facebookReport.coop_breakdown));
        }
        if (googleReport && googleReport['coop_breakdown']) {
          coopBreakdownLevels = coopBreakdownLevels.concat(Object.keys(googleReport.coop_breakdown));
        }
        if (pinterestReport && pinterestReport['coop_breakdown']) {
          coopBreakdownLevels = coopBreakdownLevels.concat(Object.keys(pinterestReport.coop_breakdown));
        }
        var coopBreakdownLevelSet = new Set(coopBreakdownLevels);
        coopBreakdownLevelSet.forEach(function(level) {
          aggregateReport['coop_breakdown'][level] = {};
          keysToSum.forEach(function(key) {
            var facebookValue = 0;
            if (facebookReport && facebookReport['coop_breakdown'] && facebookReport['coop_breakdown'][level]) {
              facebookValue = facebookReport['coop_breakdown'][level][key] || 0;
              aggregateReport['coop_breakdown'][level]['key'] = facebookReport['coop_breakdown'][level]['key'];
            }

            var googleValue = 0;
            if (googleReport && googleReport['coop_breakdown'] && googleReport['coop_breakdown'][level]) {
              googleValue = googleReport['coop_breakdown'][level][key] || 0;
              aggregateReport['coop_breakdown'][level]['key'] = googleReport['coop_breakdown'][level]['key'];
            }

            var pinterestValue = 0;
            if (pinterestValue && pinterestReport['coop_breakdown'] && pinterestReport['coop_breakdown'][level]) {
              pinterestValue = pinterestReport['coop_breakdown'][level][key] || 0;
              aggregateReport['coop_breakdown'][level]['key'] = pinterestReport['coop_breakdown'][level]['key'];
            }
            aggregateReport['coop_breakdown'][level][key] = facebookValue + googleValue + pinterestValue;
          });
          var totalUSDRevenue = aggregateReport['coop_breakdown'][level]['total_conversion_value'] / exchangeRate;
          var usdSpend = aggregateReport['spend'] / facebookAccountCurrencyExchangeRate;
          if (usdSpend === 0) {
            aggregateReport['coop_breakdown'][level]['return_on_ad_spend'] = null;
          } else {
            aggregateReport['coop_breakdown'][level]['return_on_ad_spend'] = totalUSDRevenue / usdSpend;
          }
        });

        return aggregateReport
      },

      init: function() {
        this.$parent.init.apply(this, arguments);

        if (!this.schedule) {
          this.schedule = new CampaignSchedule();
        } else {
          this.schedule = new CampaignSchedule(this.schedule);
        }

        if (!this.budget) {
          this.budget = new CampaignBudget();
          // This causes the actual budget to be set when receive an estimate.
          this.budget.overrideActual = true;
        } else {
          this.budget = new CampaignBudget(this.budget);
          // This causes the actual budget to not be set when receive an estimate.
          this.budget.overrideActual = false;
        }

        if (!this.adsets) {
          this.adsets = {};
        } else {
          _.forEach(this.adsets, function(adset, placement) {

            if (adset.ads.length) {
              var ads = adset.ads.map(function(ad) {
                ad.type = placement;
                return new FacebookAd(ad);
              });
              adset.ads = ads;
            }
          });
        }

        this.isFacebookOnly = !this.googleCampaign && !this.pinterestCampaign;
        this.hasReachAndFreq = this.hasAdsets();
        this.isHomeDepotUS = config.store.name.includes('Home Depot US') || config.store.name.includes('homedepot.com');

        var googleReport = null;
        var googleReport30d = null;
        if (this.googleCampaign) {
          googleReport = this.googleCampaign.report;
          googleReport30d = this.googleCampaign.report_30d;
        }

        var pinterestReport = null;
        var pinterestReport30d = null;
        if (this.pinterestCampaign) {
          pinterestReport = this.pinterestCampaign.report;
          pinterestReport30d = this.pinterestCampaign.report_30d;
        }

        this.aggregate_report = this.getAggregateReport(
          this.insights_report,
          googleReport,
          pinterestReport,
          this.exchange_rate,
          this.facebook_account_currency_exchange_rate
        );

        this.aggregate_report_30d = this.getAggregateReport(
          this.insights_report_30d,
          googleReport30d,
          pinterestReport30d,
          this.exchange_rate,
          this.facebook_account_currency_exchange_rate
        );
        if ( this.pinterestCatalog ) {
          this.catalog = this.pinterestCatalog;
        } else {
          this.catalog = this.useSecondaryFacebookCatalog ? 'SECONDARY' : 'PRIMARY';
        }

        if (typeof this.optimizeOptIn === 'undefined') {
          this.optimizeOptIn = true;
        }
      },

      isValid: function() {
        if (!this.isSaveable()) {
          return false;
        }

        if (!this.hasAdsets()) {
          return false;
        }

        if (!this.adsetsValid()) {
          return false;
        }

        if (this.overfullAdsets()) {
          return false;
        }

        if (this.hasGoogleAdsNoGoogleAudience()) {
          return false;
        }

        if (!this.schedule || !this.schedule.isValid()) {
          return false;
        }

        if (!this.budget || !this.budget.isRunnable()) {
          return false;
        }

        if (this.needsProductGroup() && !this.productGroupId) {
          return false;
        }

        return true;
      },

      isSaveable: function() {
        if(this.name === null || this.name === '') {
          return false;
        }
        if (!this.audience) {
          return false;
        }

        if (+this.budget.actual < +this.budget.totalSpend) {
          return false;
        }
        return true;
      },

      isRepublishable: function() {
        // Very similar to isValid, but we don't check for schedule validity.
        if (!this.isSaveable()) {
          return false;
        }

        if (!this.hasAdsets()) {
          return false;
        }

        if (!this.adsetsValid()) {
          return false;
        }

        if (this.overfullAdsets()) {
          return false;
        }

        // We only care about the end date check, since start date could be in the past.
        if (!this.schedule || !this.schedule.isValidEndDate()) {
          return false;
        }

        if (!this.budget || !this.budget.isRunnable()) {
          return false;
        }

        if (this.needsProductGroup() && !this.productGroupId) {
          return false;
        }

        return true;
      },

      needsProductGroup: function() {
        return this.isHomeDepotUS && this.audience && (this.isDPA() || (this.audience.isShopping && this.audience.isShopping()) || this.isDPAWithCustomAudience() || this.hasPinterestShoppingAds());
      },

      getAdset: function(type) {
        return this.adsets[type] || {id: null, ads: []};
      },

      getPreviewPlacement: function(type) {
        if (type === 'DISPLAY AD' && this.googleCampaign && this.googleCampaign.adGroups) {
          return this.googleCampaign.adGroups[0];
        }
        return this.adsets[type] || {id: null, ads: []};
      },

      replaceAdset: function(type, ads) {
        this.adsets[type]['ads'] = ads;
        this.isDirty = true;
      },

      hasAdsets: function() {
        return _.filter(this.adsets, function(adset) {
          return adset.ads.length > 0;
        }).length > 0;
      },

      deleteAllAds: function() {
        delete this.adsets['PINTEREST_SHOPPING'];
        this.pinterestCampaign = null;
        _.forEach(this.adsets, function(adSet) {
          adSet.ads = [];
        });
      },

      hasPinterestShoppingAds: function() {
        return 'PINTEREST_SHOPPING' in this.adsets ||
          (this.pinterestCampaign && this.pinterestCampaign.adGroups && this.pinterestCampaign.adGroups.length > 0);
      },

      hasGoogleAds: function() {
        return 'DISPLAY' in this.adsets ||
          (this.googleCampaign && this.googleCampaign.adGroups && this.googleCampaign.adGroups.length > 0);
      },

      isDPA: function() {
        return (this.audience && this.audience.isDPA && this.audience.isDPA()) || this.isDPAWithCustomAudience();
      },

      isDPAWithCustomAudience: function() {
        return this.hasDynamicAds && this.audience && this.audience.isDPA && !this.audience.isDPA();
      },

      isDynamicContentCampaign: function() {
        return this.hasPinterestShoppingAds() || this.isDPAWithCustomAudience();
      },

      overfullAdset: function(type) {
        return this.getAdset(type).ads.length > ADSET_MAX_SIZE;
      },

      overfullAdsets: function() {
        // One or more adsets are overfull
        return _.filter(this.adsets, function(adset) {
          return adset.ads.length > ADSET_MAX_SIZE;
        }).length > 0;
      },

      pristineAdset: function(type) {
        return _.some(this.getAdset(type).ads, function(ad) {
          return ad.isPristine;
        });
      },

      pristineAdsets: function() {
        return _.some(this.adsets, function(adset) {
          return _.some(adset.ads, function(ad) {
            return ad.isPristine;
          });
        });
      },

      adsetsValid: function() {
        var num_ads = this.totalAds();
        if (num_ads > 0) {
          return this.totalValidAds() >= num_ads;
        }
        return false;
      },

      hasGoogleAdsNoGoogleAudience: function () {
        var audience = new Audience(this.audience);
        return Boolean(this.googleCampaign && !audience.isAvailableGoogle());
      },

      totalInvalidAdsInAdset: function(type) {
        return this.getAdset(type).ads.length - this.totalValidAdsInAdset(type);
      },

      totalValidAdsInAdset: function(type) {
        return _.sum(this.getAdset(type).ads, function(ad) {
          return (ad.isValid() ? 1 : 0);
        });
      },

      totalValidAds: function() {
        return _.sum(this.adsets, function(adset) {
          return _.sum(adset.ads, function(ad) {
            return (ad.isValid() ? 1 : 0);
          });
        });
      },

      totalNeedsReviewInAdset: function(type) {
        return _.sum(this.getPreviewPlacement(type).ads, function(ad) {
          var needs_review = ad.review_status === 'REVIEW_NEEDED' || !ad.review_status;
          return (needs_review ? 1 : 0);
        });
      },

      setAdset: function(type, retailerIsHomeDepotUS) {
        if (!this.adsets[type]) {
          this.adsets[type] = {id: null, ads: []};
          if (type === 'NEWS') {
            this.adsets[type].include_audience_network = !retailerIsHomeDepotUS;
            this.adsets[type].include_marketplace = true;
            this.adsets[type].include_facebook_search = true;
          }
          if (type === 'MOBILE') {
            this.adsets[type].include_audience_network = !retailerIsHomeDepotUS;
            this.adsets[type].include_messenger = true;
            this.adsets[type].include_marketplace = true;
            this.adsets[type].include_stories = false;
            this.adsets[type].include_facebook_search = true;
          }
          if (type === 'INSTAGRAM') {
            this.adsets[type].include_stories = false;
            this.adsets[type].include_facebook_search = true;
            this.adsets[type].include_instagram_explore = true;
          }
        }
        if (retailerIsHomeDepotUS && this.adsets[type].id == null){
          if (type === 'MOBILE' || type === 'NEWS')
            this.adsets[type].include_marketplace = true;
        }
        if (this.adsets[type].id == null) {
          if (type === 'MOBILE' || type === 'NEWS')
            this.adsets[type].include_facebook_search = true;
          if (type === 'INSTAGRAM')
            this.adsets[type].include_instagram_explore = true;
        }
      },

      pushAd: function(ad) {
        var type = ad.type;

        this.setAdset(type, this.isHomeDepotUS);

        this.adsets[type].ads.push(ad);
        this.isDirty = true;
      },

      totalAds: function() {
        return _.sum(this.adsets, function(adset) {
          return adset.ads.length;
        });
      },

      allAds: function() {
        var allAds = [];
        _.forEach(this.adsets, function(adset, placement) {
          _.forEach(adset.ads, function(ad) {
            allAds.push(ad);
          });
        });

        //If adsets has DISPLAY, the Google Ads have already been copied.
        if(!this.adsets.hasOwnProperty('DISPLAY')){
          if (this.googleCampaign){
            _.forEach(this.googleCampaign.adGroups[0].ads, function(ad) {
              ad.type = 'DISPLAY'
              allAds.push(ad);
            });
          }
        }

        if (this.pinterestCampaign){
          _.forEach(this.pinterestCampaign.adGroups, function(adgroup) {
            _.forEach(adgroup.ads, function(ad) {
              ad.type = 'PINTEREST'
              allAds.push(ad);
            });
          });
        }
        return allAds;
      },

      isDraft: function() {
        return this.state === 'draft';
      },

      isPendingRetailerApproval: function() {
        return this.state === 'pending_retailer_approval';
      },

      isApproved: function() {
        var approvedStates = ['draft', 'pending_tracking_setup', 'pending_brand_approval', 'pending_retailer_approval', 'pending_internal', 'pending_external', 'pending_audience', 'on_hold'];
        // indexOf returns the index of the element if it exists, so we have to check -1 explicitly.
        return approvedStates.indexOf(this.state) !== -1;
      },

      isRejected: function() { //this one
        return this.state === 'rejected';
      },

      isRunning: function() { //this one
        return this.state === 'running';
      },

      isRunningOrApproved: function() {
        var approvedStates = ['pending_tracking_setup', 'pending_audience', 'pending_internal', 'pending_external'];
        return this.isRunning() || approvedStates.indexOf(this.state) !== -1;
      },

      isPaused: function() { //this one
        var pausedStates = ['paused', 'system_paused'];
        // indexOf returns the index of the element if it exists, so we have to check -1 explicitly.
        return pausedStates.indexOf(this.state) !== -1;
      },

      isCompleted: function() { //this one
        return this.state === 'completed';
      },

      isCancelled: function() { //this one
        return this.state === 'terminated';
      },

      isEnded: function() {
        return this.isCompleted() || this.isCancelled();
      },

      changeState: function(toState, reason) {
        // Helper method to facilitate changing of state.

        // Set the resource_uri so that the Model.update method knows what to call.
        // It'll be in the form of api/<store_id>/campaigns/<campaign_id>/
        var Campaign = this.$class;
        this.resource_uri = Campaign.url + this.id;

        return Campaign.update(this, {
          toState: toState,
          reason: reason
        });
      },

      pause: function(reason) {
        this.changeState('pause', reason).then(function() {
          window.location.reload();
        });
      },

      brand_approved: function() {
        this.changeState('approved').then(function() {
          window.location.reload();
        });
      },

      resume: function() {
        this.changeState('resume').then(
          // Success
          function() {
            window.location.reload();
          },
          // Failure
          function() {
            alert(
              "We were unable to resume your campaign.\n\n" +
              "This can happen if your budget was reached or there was a problem charging your card.\n\n" +
              "Please increase your budget or contact support for assistance."
            );
            window.location.reload();
          }

        );
      },

      cancel: function(reason) {
        this.changeState('cancel', reason).then(function() {
          window.location.reload();
        });
      },

      cancelByRetailer: function(reason) {
        this.changeState('cancelByRetailer', reason).then(function() {
          window.location.reload();
        });
      },

      returnToDraft: function(reason) {
        this.changeState('draft', reason).then(function() {
          $location.path('/r/' + config.store.id + '/campaigns/');
        });
      },

      setAudience: function(audience) {
        this.audience = audience;
        var storeDetails = new StoreDetails({'audience': audience, 'store': config.store});
        var that = this;

        storeDetails.facebookPages().then(function(details) {
          that.facebookPages = details.pages;
        });
      },

      generateAdOrderNumber: function(type) {
        // Only compute sortOrder if the adset for this type exists, and has a length > 0.
        // Otherwise, this will be the first ad, and sortOrder is 0.
        if (typeof this.adsets[type] !== "undefined" && this.adsets[type].ads.length > 0) {
          // Find the ad with the highest sortOrder in this adset (type).
          var lastAd = _.max(this.adsets[type].ads, function(ad) {
            return ad.sortOrder;
          });

          return lastAd.sortOrder + 1;
        }

        return 0;
      },

      setReviewStatus: function(ads, reviewStatus) {
        var adIds = {
          'facebook': [],
          'google': [],
        };

        _.forEach(ads, function(ad) {
          if (ad.hasOwnProperty('creative')) {
            adIds['facebook'].push(ad.id);
          } else {
            adIds['google'].push(ad.id);
          }
        });

        var data = {
          adIds: adIds,
          'review_status': reviewStatus
        };

        url = '/api/' + config.store.id + '/campaigns/' + this.id + '/set_review_status/';
        $http.post(url, data).then(function(resp) {
          _.forEach(ads, function(ad) {
            ad.review_status = reviewStatus;
          });
        });
      },

      deleteComment: function(comment) {
        var url = '/api/' + config.store.id + '/comments/' + comment.id + '/';
        $http.delete(url, {});
        _.forEach(this.allAds(), function(ad) {
          ad.comments = ad.comments.filter(function(adComment) {
            return adComment.id !== comment.id;
          })
        })
      },

      updateComment: function(comment) {
        var url = '/api/' + config.store.id + '/comments/' + comment.id + '/';
        $http.patch(url, {text: comment.text});

      },

      getLearningStateStatus: function() {
        if (this.adsets) {
          var success = true;
          var lastExitDate = null;
          _.forEach(this.adsets, function(adset) {
            if (adset.id && adset.learning_phase_status !== 'SUCCESS') {
              success = false;
            }
            else if (adset.id && (!lastExitDate || lastExitDate < adset.learning_phase_exited)){
                lastExitDate = adset.learning_phase_exited;
            }
          })
          if (success){
            return (lastExitDate)?'Exited learning phase on ' + (moment(lastExitDate)).format('MMM DD YYYY'):'';
          }
          else {
            return 'Campaign is in the learning phase';
          }
        }
        return '';
      },

      getProductCatalogDisplay: function() {
        if (this.isHomeDepotUS) {
          return this.catalog === 'PRIMARY' ? 'National' : 'Local';
        } else {
          if (this.catalog === 'PRIMARY') {
            return 'English';
          } else if (this.catalog === 'SECONDARY') {
            return 'French';
          } else {
            return 'English and French';
          }
        }
      }
    });

    if (config.store) {  // TODO Bandaid for drilldown_modal
      Campaign.configure({
        url: '/api/' + config.store.id + '/campaigns/',

        CTA_MAPPING: {
          'BOOK_TRAVEL': 'Book Now',
          'DOWNLOAD': 'Download',
          'LEARN_MORE': 'Learn More',
          'OPEN_LINK': 'Open Link',
          'SHOP_NOW': 'Shop Now',
          'LISTEN_MUSIC' : 'Listen Now',
          'WATCH_MORE': 'Watch More',
          'SIGN_UP': 'Sign Up',
        }
      });
    }
    return Campaign;
  }
]);
