angular.module('vantageApp').directive('internalMessages', [
  '$rootScope',
  function(rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/internal-messages.html',
      scope: {
        messages: '='
      },
      link: function(scope) {

        scope.showInternalMessages = function() {
          if (
            rootScope.hasFeature('admin')
            && typeof scope.messages !== 'undefined'
            && scope.messages.length > 0
          ) {
            return true;
          }

          return false;
        };

      }
    };
  }
]);
