angular.module('vantageApp').controller('controller.productOverview', [
  '$scope',
  '$element',
  'close',
  'va.config',
  function($scope, element, close, config) {

    // Close the modal.
    this.close = function() {
      element.modal('hide');
      close();
    };

    $scope.storeTerm = config.storeTerm();

    $scope.carouselSettings = {
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      dots: true,
    };
  }
]);
