angular.module('vantageApp').directive("showPassword", function() {
    return function linkFn(scope, elem, attrs) {
        scope.$watch(attrs.showPassword, function(newValue) {
            if (newValue) {
                elem.prop("type", "text");
            } else {
                elem.prop("type", "password");
            }
        });
    };
});