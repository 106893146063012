angular.module('vantageApp').directive('adsetEditorDelete', [
  'service.GoogleAnalyticsAdvancedEcomEvents',
  function(AdvancedEcom) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/adset-editor-delete.html',
      require: ['ngModel', '^adsetEditor'],
      link: function(scope, element, attrs, requires) {
        var model = requires[0];
        var adset = requires[1];

        // Called when the ng-model is passed to the view.
        model.$render = function() {
          scope.ads = model.$viewValue;
        };

        // Give adset functions to the view, so we can
        // confirm or cancel the delete action.
        scope.adset = adset;

        scope.cancel = function() {
          adset.cancelDelete();
        };

        scope.delete = function() {
          AdvancedEcom.markAdsetEdited(scope.type);
          adset.confirmDelete();
        };
      }
    }
  }
]);
