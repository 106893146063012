angular.module('vantageApp').directive('campaignBudget', [
  '$interval',
  '$timeout',
  'service.BudgetEstimate',
  'va.config',
  function($interval, $timeout, BudgetEstimate, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-budget.html',
      require: 'ngModel',
      scope: {
        store: '=',
      },
      link: function(scope, element, attrs, model) {
        var ctl = this;

        // Prevent entering a non-numeric values in the budget textbox.
        $(".campaign-budget-budget").on("keypress", "#changeBudget", function (evt) {
          if (evt.which < 48 || evt.which > 57)
          {
            evt.preventDefault();
          }
        });

        model.$render = function() {
          scope.campaign = model.$viewValue;
          scope.budget = scope.campaign.budget;
        };

        scope.getBudgetEstimate = function() {
          if (scope.isRetailerHomeDepotUS) {
            return;
          }
          BudgetEstimate.getBudgetEstimate(scope.campaign).then(function(response) {
            BudgetEstimate.updateBudgetEstimate(
              scope,
              parseInt(response.data.budget),
              parseInt(response.data.minimum)
            );
            BudgetEstimate.updateState(scope);
          });
        };

        scope.$watch('campaign.audience', function(audience) {
          if (audience) {
            scope.getBudgetEstimate(); // Query for the new budget estimate
          }
        }, true);

        scope.$watch('campaign.schedule', function(schedule) {
          if (scope.campaign.audience) {
            scope.getBudgetEstimate(); // Query for the new budget estimate
          }
        }, true);

        scope.$watch('campaign.adsets', function(adsets) {
          if (scope.campaign.audience) {
            if (adsets !== {}) {
              scope.getBudgetEstimate(); // Query for the new budget estimate
            }
          }
        }, true);

        scope.$watch('budget.actual', function(budget) {
          BudgetEstimate.updateState(scope);
        });

        scope.stateHelpText = function(state) {
          return {
            NaN: "Please enter a number.",
            minimum: "Below required minimum of " + scope.budget.minimum + ".",
            recommended: 'Sweet spot!',
            high: 'A little high.',
            low: 'Too low.',
          }[state];
        };

        var LEFT_MOUSE = 1;
        var interval;
        var timeout;

        scope.schedule = function(fn, event) {
          // Don't do anything when right-clicking
          if (event && event.which !== LEFT_MOUSE) {
            return;
          }

          if ( typeof fn !== 'function' ) {
            return;
          }

          timeout = $timeout(function() {
            interval = $interval(fn, 100);
          }, 500);
        };

        scope.finish = function(fn) {
          $timeout.cancel(timeout);
          timeout = null;

          if ( typeof fn !== 'function' ) {
            return;
          }

          if (!interval) {
            if (fn) {
              fn();
            }
          } else {
            $interval.cancel(interval);
            interval = null;
          }
        };

        scope.isRetailerHomeDepotUS = config.store.name.endsWith(' - Home Depot US') ||  config.store.name === 'www.homedepot.com';

        scope.getBudgetClass = function () {
          if ( !scope.budget ) {
            return ''
          }
          if ( scope.budget.isNaN() )
            return 'is-NaN';

          if ( scope.isRetailerHomeDepotUS )
            return '';

          if ( scope.budget.isHigh() )
            return 'is-high';

          if ( scope.budget.isLow() )
            return 'is-low';

          return 'is-recommended';
        }

        ctl._get_change_amount = function() {
          return Math.max(
            Math.floor(scope.budget.actual * 0.1 / 10.0) * 10,
            10
          );
        }

        scope.increaseBudget = function() {
          if (!scope.budget.actual) {
            scope.budget.actual = scope.budget.minimum;
            return;
          }

          var increment = ctl._get_change_amount();

          scope.budget.actual = parseFloat(scope.budget.actual) + increment;

          if (scope.budget.actual < scope.budget.minimum) {
            scope.budget.actual = scope.budget.minimum;
            $interval.cancel(interval);
          }
        };

        scope.decreaseBudget = function() {
          if (!scope.budget.actual) {
            scope.budget.actual = scope.budget.minimum;
            return;
          }

          var decrement = ctl._get_change_amount();

          scope.budget.actual = parseFloat(scope.budget.actual) - decrement;

          if (scope.budget.actual < scope.budget.minimum) {
            scope.budget.actual = scope.budget.minimum;
            $interval.cancel(interval);
          }
        };
      }

    };
  }
]);
