angular.module('vantageApp').directive('campaignHeader', [
  '$rootScope',
  '$window',
  '$document',
  '$timeout',
  'va.config',
  function($rootScope, window, document, timeout, config) {
    return {
      templateUrl: 'views/directives/campaign-header.html',
      restrict: 'A',
      scope: {
        campaign: '=',
        store: '=',
        isTourRunning: '=',
        onSave: '&',
        onSaveDraft: '&',
        onSaveDraftGetPackage: '&',
      },
      link: function(scope, element) {
        var $window = $(window);
        var $document = $(document);
        var navigation = $('.navigation');

        // Always add this, just in case the user forgets to add it
        element.addClass('campaign-header');

        // Keep an eye on scroll height. We'll make the header fixed
        // if the user scrolls a bit, so it always stays with them.
        $window.scroll(function() {
          if ($document.scrollTop() >= 110 && !scope.isTourRunning) {
            element.addClass('is-fixed');
          } else {
            element.removeClass('is-fixed');
          }
        });

        scope.isAdmin = function() {
          return $rootScope.hasFeature('admin');
        };

        // Helper for scrolling to a particular element.
        scope.scrollTo = function(selector) {
          var element = $(selector);
          var tile = element.find('.tile');

          if (!element.length) {
            throw new Error('Invalid selector "' + selector + '"');
          }

          $('html, body').animate({
            scrollTop: element.offset().top - 155
          });

          // As a convenience, highlight the tile.
          tile.addClass('is-highlighted');
          timeout(function() {
            tile.removeClass('is-highlighted');
          }, 4000);
        };

        scope.showRunButton = function() {
          return _.every([
            !scope.campaign.state || scope.campaign.state === 'draft' || scope.campaign.state === 'pending_brand_approval',
            scope.store && scope.store.hasAdvertisingPackage()
          ], Boolean);
        };

        scope.showSaveAndGetButton = function() {
          return _.every([
            !scope.campaign.state || scope.campaign.state === 'draft' || scope.campaign.state === 'pending_brand_approval',
            scope.store && !scope.store.hasAdvertisingPackage(),
          ], Boolean);
        };

        scope.showRepublishButton = function() {
          return _.every([
            !scope.campaign.state || (scope.campaign.state !== 'draft' && scope.campaign.state !== 'pending_brand_approval'),
            scope.isAdmin(),
          ], Boolean);
        };

        // Afford changing the audience type at will
        scope.$watch('campaign.audience', function(newValue, oldValue) {
          if (newValue && oldValue && newValue.id === oldValue.id) {
            return;
          }

          if (newValue && scope.nameEditor.name.$pristine) {
            scope.campaign.name = newValue.getName() + ' Campaign';
          }
        });
      }
    };
  }
]);
