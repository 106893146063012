angular.module('vantageApp').factory('model.PageVideo', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    /*
     * Model for Facebook PageVideos
     */
     var PageVideo = Model({
       id: null,
       title: null,
       embed_html: null,
       is_instagram_eligible: null,

       init: function(opts) {
         this.$parent.init.apply(this, arguments);

         if(!this.thumbnail) {
           this.thumbnail = {
             id: null,
             uri: null
           }
         }
       }
     });

     if (config.store) {
       PageVideo.configure({
         url: '/api/' + config.store.id + '/page_videos',

         deserializeAll: function(response) {
           var cls = this;

           var videos = _.map(response.data.objects, function(data) {
             return cls.deserialize(data);
           });
           var pageInfo = response.data.meta;

           return {
             videos: videos,
             pageInfo: pageInfo
           };
         }

       });
     }
     return PageVideo
  }
]);
