angular.module('vantageApp').directive('pinterestAd', [
  '$rootScope',
  'lib.Modal',
  'va.config',
  function(rootScope, Modal, config) {
    return {
      restrict: 'E',
      template: '<ng-include src="template()"></ng-include>',
      scope: {
        ad: '=',
        campaign: '=',
        store: "=",
        isPreview: '=',
      },

      link: function(scope) {
        scope.template = function() {
            return 'views/directives/pinterest-ad/responsive-display-ad.html';
        };

        scope.merchantName = scope.store.coopRetailerName() || scope.store.name;
        scope.logoImageUrl = function() {
          return scope.store.retailer_logo_image_url || scope.store.logo_image_url;
        };

      }
    };
  }
]);