angular.module('vantageApp').directive('budgetBreakdown', [
  '$rootScope',
  '$timeout',
  '$http',
  '$timeout',
  '$interval',
  'va.config',
  function (rootScope, timeout, http, $timeout, $interval, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/budget-breakdown.html',
      scope: {
      },
      link: function (scope) {
        scope.data = null;
        scope.monthsOfData = null;

        scope.hasData = function(){
          return scope.data && scope.data['history'];
        };

        var request = http.get('/api/' + config.store.id + '/budget_recommendation').then(
          function (response) {
            scope.data = response['data'];
            if (!scope.hasData()){
              return;
            }

            var history = response['data']['history'];
            var projection = response['data']['projection'];

            scope.monthsOfData = history['dates'].length;

            var rows = {};
            var funnelStages = ['attract','convert','reengage','overall'];

            var average = function (l) {
              return _.sum(l) / l.length;
            };

            _.forEach(funnelStages, function (funnelStage){
              var row = {
                'currentMonthlySpend': average(history[funnelStage +'Spend']),
                'currentMonthlyRevenue': average(history[funnelStage +'Revenue']),
                'proposedMonthlySpend': projection[funnelStage + 'Spend'][0],
                'proposedMonthlyRevenueMin': projection[funnelStage + 'MinRevenue'][0],
                'proposedMonthlyRevenueMax': projection[funnelStage + 'MaxRevenue'][0]
              };

              rows[funnelStage] = row;
            });

            scope.rows = rows;
            scope.totalMonthlyBudget.value = parseInt(projection['overallSpend'][0]);
            scope.totalMonthlyMinRevenue.value = parseInt(projection['overallMinRevenue'][0]);
            scope.seasonalProjectionTotal = response['data']['seasonalProjectionTotal'];
            if(scope.seasonalProjectionTotal) {
              scope.totalYearlyBudget.value = scope.seasonalProjectionTotal['totalSpend'];
              scope.totalYearlyMinRevenue.value = scope.seasonalProjectionTotal['totalMinRevenue'];
              scope.totalYearlyMaxRevenue.value = scope.seasonalProjectionTotal['totalMaxRevenue'];
            }

            window.setTimeout(function() {
              $(".budget-input-container").on("keypress", "#changeBreakdownBudget", function (evt) {
                if (evt.which < 48 || evt.which > 57) {
                  evt.preventDefault();
                }
              });
            }, 0);
          }
        );

        scope.getFunnelStageLabel = function (funnelStage) {
          var label = null;
          if (funnelStage == 'overall') {
            label = 'Monthly Total';
          }
          else {
            label = funnelStage.capitalize()
          }
          return label;
        };

        scope.totalMonthlyBudget = {'value': 0};
        scope.totalMonthlyMinRevenue = {'value': 0};
        scope.totalYearlyBudget = {'value': 0};
        scope.totalYearlyMinRevenue = {'value': 0};
        scope.totalYearlyMaxRevenue = {'value': 0};

        scope.updateBreakdownWithBudgetMultiplier = function(){
          var multiplier = 0;
          if(scope.totalMonthlyBudget.value){
            multiplier = parseInt(scope.totalMonthlyBudget.value) / scope.data['projection']['overallSpend'][0];
          }

          scope.updateMonthlyBreakdown(multiplier);
          scope.totalMonthlyMinRevenue.value = scope.rows['overall']['proposedMonthlyRevenueMin'];
        };

        scope.updateBreakdownWithMinRevenueMultiplier = function(){
          var multiplier = 0;
          if(scope.totalMonthlyMinRevenue.value){
            multiplier = parseInt(scope.totalMonthlyMinRevenue.value) / scope.data['projection']['overallMinRevenue'][0];
          }

          scope.updateMonthlyBreakdown(multiplier);
          scope.totalMonthlyBudget.value  = scope.rows['overall']['proposedMonthlySpend'];
        };

        scope.updateYearlyTotalsWithBudgetMultiplier = function(){
          var multiplier = 0;
          if(scope.totalYearlyBudget.value){
            multiplier = parseInt(scope.totalYearlyBudget.value) / scope.seasonalProjectionTotal['totalSpend'];
          }
          scope.totalYearlyMinRevenue.value = scope.roundDownToNearestThousand(scope.seasonalProjectionTotal['totalMinRevenue'] * multiplier);
          scope.updateYearlyTotals(multiplier);
        };

        scope.updateYearlyTotalsWithMinRevenueMultiplier = function(){
          var multiplier = 0;
          if(scope.totalYearlyMinRevenue.value){
            multiplier = parseInt(scope.totalYearlyMinRevenue.value) / scope.seasonalProjectionTotal['totalMinRevenue'];
          }
          scope.totalYearlyBudget.value = scope.roundDownToNearestThousand(scope.seasonalProjectionTotal['totalSpend'] * multiplier);
          scope.updateYearlyTotals(multiplier);
        };

        scope.updateMonthlyBreakdown = function (multiplier) {
          var projection = scope.data['projection'];
          var funnelStages = ['attract','convert','reengage','overall'];
          _.forEach(funnelStages, function (funnelStage){
            var row = scope.rows[funnelStage];
            row['proposedMonthlySpend'] = scope.roundDownToNearestHundred(projection[funnelStage + 'Spend'][0] * multiplier);
            row['proposedMonthlyRevenueMin'] = scope.roundDownToNearestHundred(projection[funnelStage + 'MinRevenue'][0] * multiplier);
            row['proposedMonthlyRevenueMax'] = scope.roundDownToNearestHundred(projection[funnelStage + 'MaxRevenue'][0] * multiplier);
          });

          var overallRow = scope.rows['overall'];
          _.forEach(['proposedMonthlySpend', 'proposedMonthlyRevenueMin', 'proposedMonthlyRevenueMax'], function (field) {
            overallRow[field] = scope.rows['attract'][field] + scope.rows['convert'][field] + scope.rows['reengage'][field]
          });
        };

        scope.updateYearlyTotals = function (multiplier) {
          scope.totalYearlyMaxRevenue.value = scope.roundDownToNearestThousand(scope.seasonalProjectionTotal['totalMaxRevenue'] * multiplier);
        };

        scope.roundDownToNearestHundred = function(value){
          return Math.floor(value / 100) * 100;
        };

        scope.roundDownToNearestThousand = function(value){
          return Math.floor(value / 1000) * 1000;
        };

        var LEFT_MOUSE = 1;
        var interval;
        var timeout;
        var increment = 500;
        var yearlyIncrement = 1000;

        scope.increaseBudget = function (){
          scope.totalMonthlyBudget.value = parseInt(scope.totalMonthlyBudget.value) + increment;
          scope.updateBreakdownWithBudgetMultiplier();
        };

        scope.decreaseBudget = function (){
          scope.totalMonthlyBudget.value = Math.max(parseInt(scope.totalMonthlyBudget.value) - increment, 0);
          scope.updateBreakdownWithBudgetMultiplier();
        };

        scope.increaseMinRevenue = function (){
          scope.totalMonthlyMinRevenue.value = parseInt(scope.totalMonthlyMinRevenue.value) + increment;
          scope.updateBreakdownWithMinRevenueMultiplier();
        };

        scope.decreaseMinRevenue = function (){
          scope.totalMonthlyMinRevenue.value = Math.max(parseInt(scope.totalMonthlyMinRevenue.value) - increment, 0);
          scope.updateBreakdownWithMinRevenueMultiplier();
        };

        scope.increaseYearlyBudget = function (){
          scope.totalYearlyBudget.value = parseInt(scope.totalYearlyBudget.value) + yearlyIncrement;
          scope.updateYearlyTotalsWithBudgetMultiplier();
        };

        scope.decreaseYearlyBudget = function (){
          scope.totalYearlyBudget.value = Math.max(parseInt(scope.totalYearlyBudget.value) - yearlyIncrement, 0);
          scope.updateYearlyTotalsWithBudgetMultiplier();
        };

        scope.increaseYearlyMinRevenue = function (){
          scope.totalYearlyMinRevenue.value = parseInt(scope.totalYearlyMinRevenue.value) + yearlyIncrement;
          scope.updateYearlyTotalsWithMinRevenueMultiplier();
        };

        scope.decreaseYearlyMinRevenue = function (){
          scope.totalYearlyMinRevenue.value = Math.max(parseInt(scope.totalYearlyMinRevenue.value) - yearlyIncrement, 0);
          scope.updateYearlyTotalsWithMinRevenueMultiplier();
        };

        scope.schedule = function(fn, event) {
          // Don't do anything when right-clicking
          if (event && event.which !== LEFT_MOUSE) {
            return;
          }

          timeout = $timeout(function() {
            interval = $interval(fn, 100);
          }, 500);
        };

        scope.finish = function(fn) {
          $timeout.cancel(timeout);
          timeout = null;

          if (!interval) {
            if (fn) {
              fn();
            }
          } else {
            $interval.cancel(interval);
            interval = null;
          }
        };

        scope.current_year = moment().format('YYYY');
        scope.currency_symbol = numbro('').format('$')[0];
        scope.revenue_currency = config.store.revenue_currency;
        scope.facebook_account_currency = config.store.facebook_account_currency;
        scope.current_month = moment().add(1,"month").format('MMMM');
      }
    };
  }
]);
