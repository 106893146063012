angular.module('vantageApp').directive('placementMetrics', [
  '$rootScope',
  'va.config',
  function(rootScope, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/placement-metrics.html',
      scope: {
        adsets: '=',
        campaign: '=',
      },

      link: function($scope, element, attrs, model) {
        $scope.retailerIsHomeDepotUS = config.store.name.endsWith("- Home Depot US") || config.store.name === "www.homedepot.com";
        $scope.adminDisplay = !$scope.retailerIsHomeDepotUS || config.features.admin;
        _.forEach($scope.adsets, function(adset, placement) {
          var numRunningAds = _.filter(adset.ads, 'status', 'ACTIVE').length;
          var numAds = adset.ads.length;

          if (numAds) {
            adset.adsRunning = numRunningAds + " of " + numAds;
          } else {
            adset.adsRunning = "0";
          }

        });

        if (!$scope.campaign.isFacebookOnly) {
          var adgroups = $scope.campaign.googleCampaign.adGroups;

          _.forEach(adgroups, function (adgroup) {

            var numRunningAds = _.filter(adgroup.ads, 'status', 'ENABLED').length;
            var numAds = adgroup.ads.length;

            if (numAds) {
              adgroup.adsRunning = numRunningAds + " of " + numAds;
            } else {
              adgroup.adsRunning = "0";
            }

          });

        }

        $scope.revenue_currency = config.store.revenue_currency;
        $scope.facebook_account_currency = config.store.facebook_account_currency;
      }
    };
  }
]);
