angular.module('vantageApp').factory('model.PreviousCampaignCopy', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    // XXX This is a weird model. It doesn't represent a backend model, but a
    // few of many models. Shrug

    var PreviousCampaignCopy = Model({
      body: null,
    });

    PreviousCampaignCopy.configure({
      url: '/api/' + config.store.id + '/previous-campaign-copy/',
    });

    return PreviousCampaignCopy;
  }
]);
