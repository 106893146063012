angular.module('vantageApp').service('service.CopyValidator', function() {
  // These rules are a subset of: http://bit.ly/1RC47Nj
  var disallowedPunctuationRegex = new RegExp("[~_=|<>\\^]|[\[\]]");
  var disallowedBracesRegex = new RegExp("[{}]");
  var startsWithPunctuationRegex = new RegExp("^[!\\\\?.,*:;()/]");
  var doublePunctuationRegex = new RegExp("[!\\\\?.,*:;()/]{2}");
  var doubleSymbolRegex = new RegExp("[!\\\\?.,*:;()/{}<>@#$%^&=]{2}");
  var spacedDoubleSymbolRegex = new RegExp("[!\\\\?.,*:;()/{}<>@#$%^&=] +[!\\\\?.,*:;()/{}<>@#$%^&=]");
  var doubleSpaceRegex = new RegExp(" {2}");
  var emojiRegex = /(?:[\u00A9\u203C\u2049\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g
  var doubleUpperCaseRegex = new RegExp("[A-Z]{2}")

  var badBracesRegex = new RegExp("\\[\{|\{\\[|\\]\}|\}\\]");

  var multipleExclamations = function (copy) {return copy && (copy.match(new RegExp("!", "g")) || []).length > 1;};
  var hasTilde = function (copy) {return copy && copy.indexOf('~') !== -1;};
  var hasDoubleSymbol = function (copy) {return doubleSymbolRegex.test(copy)};
  var hasSpacedDoubleSymbol = function (copy) {return spacedDoubleSymbolRegex.test(copy)};
  var hasDoubleSpace = function (copy) {return doubleSpaceRegex.test(copy)};
  var hasEmoji = function (copy) {return emojiRegex.test(copy)};

  var rules = [
    disallowedPunctuationRegex,
    disallowedBracesRegex,
    startsWithPunctuationRegex,
    doublePunctuationRegex,
  ];

  var dpaRules = [
    disallowedPunctuationRegex,
    startsWithPunctuationRegex,
    doublePunctuationRegex,
    badBracesRegex,
  ];

  var googleRules = [
    multipleExclamations,
    hasTilde,
    hasDoubleSymbol,
    hasSpacedDoubleSymbol,
    hasDoubleSpace,
    hasEmoji
  ];

  this.hasDoubleUpperCase = function (copy) {return doubleUpperCaseRegex.test(copy)};

  this.isValidGoogleCommonRules = function(copy){
    var test_result = googleRules.every(function (rule) {
      return !rule(copy);
    });
    return test_result;
  };

  this.isValidGoogleHeadline = function(copy) {
    return !copy || copy.indexOf('!') === -1;
  };

  var testRules = function(rules, copy) {
    test_results = rules.every(function(rule) {
      return !rule.test(copy);
    });
    return test_results;
  };

  this.isValid = function(copy) {
    return testRules(rules, copy);
  };

  this.isDPAValid = function(copy) {
    return testRules(dpaRules, copy);
  };
});
