angular.module('vantageApp').factory('model.StripeCustomer', [
  'lib.Model',
  'va.config',
  '$http',
  function(Model, config, $http) {
    var StripeCustomer = Model({
      getCard: function(cardId) {
        return _.find(this.cards, function (card) {
          return card.id == cardId;
        });
      },

      deleteCard: function(cardId, callback) {
        var stripeCustomer = this;
        return $http.post('/api/' + config.store.id + '/stripe/delete-card/', {
          "card_id": cardId
        }).then(function(response) {
          _.remove(stripeCustomer.cards, function (card) {
            return card.id == cardId;
          });

          if (callback) {
            callback();
          }

        });
      },

      addCard: function(token, callback) {
        var stripeCustomer = this;
        if (config.store.stripe_customer_id) {
          $http.post(
            "/api/" + config.store.id + "/stripe/add-card/",
            {
              stripe_credit_card_token: token
            }
          ).then(function(response){
            stripeCustomer.cards.push(response.data);

            if (callback) {
              callback();
            }
          });
        } else {
          $http.post(
            "/api/" + config.store.id + "/stripe/",
            {
              stripe_credit_card_token: token
            }
          ).then(function(response){
            stripeCustomer.cards.push(response.data);

            if (callback) {
              callback();
            }
          });
        }

      },

      setDefaultCard: function(cardId) {
        var stripeCustomer = this;
        return $http.post('/api/' + config.store.id + '/stripe/set-default-card/', {
          "card_id": cardId
        }).then(function(response) {
          _.forEach(stripeCustomer.cards, function(card) {
            card.isDefault = false;
          });
          var card = stripeCustomer.getCard(cardId);
          card.isDefault = true;
        });
      }
    });

    return StripeCustomer.configure({
      url: '/api/' + config.store.id + '/stripe/',

      current: function() {
        if (config.store.id) {
          return this.get(config.store.id);
        }
      }
    });
  }
]);
