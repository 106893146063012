angular.module('vantageApp').controller('controller.DashboardProfiles', [
  '$scope',
  '$state',
  'model.Store',
  'store',
  'profiles',
  function(scope, state, Store, store, profiles) {
    scope.profiles = profiles;

    // We only show this if they've authorized but have to choose
    // a profile, a case which occurs if the google account
    // has many GA profiles that it's connected to.
    scope.needsGAProfile = function() {
      return store.has_authorized_google && !store.ga_profile_id;
    };

    scope.chooseProfile = function(profile, event) {
      Store.update(store, {
        ga_profile_id: profile.id
      }).then(function() {
        state.go(state.current, {}, { reload: true });
        analytics.track('google-analytics-installed');
      });

      event.preventDefault();
    };
  }
]);
