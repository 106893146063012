angular.module('vantageApp').directive('intro', function() {
  return {
    restrict: 'A',
    scope: {
      introOpts: '=',
      onExit: '=',
    },

    controller: ['$scope', '$element', function(scope, element) {
      var self = this;

      this.opts = angular.extend({
        steps: [],
        nextLabel: 'Next',
        prevLabel: 'Back',
        doneLabel: 'x',
        skipLabel: 'x',
      }, scope.introOpts);

      this.addStep = function(index, step) {
        self.opts.steps[index] = step;
      };

      this.start = function(step) {
        var intro = introJs(),
            opts = _.clone(this.opts);

        // Don't request the HTML until tour time, so we
        // let bindings render themselves.
        opts.steps = _.map(opts.steps, function(step) {
          return angular.extend({}, step, {
            intro: step.intro.html(),
          });
        });

        intro.setOptions(opts);
        intro.goToStep(step || 1);
        if (_.isFunction(scope.onExit)) {
            intro.onexit(scope.onExit);
            intro.oncomplete(scope.onExit);
        }
        intro.start();

      };
    }]
  };

}).directive('introStep', ['$timeout', function(timeout) {
  return {
    restrict: 'E',
    transclude: true,
    require: '^intro',
    template: '<div ng-transclude></div>',
    scope: {
      step: '@',
      element: '@',
      position: '@'
    },

    link: function(scope, element, attrs, intro, transclude) {
      intro.addStep(scope.step - 1, {
        position: scope.position,
        element: scope.element,
        intro: element,
      });

      element.hide();
    }
  };

}]).directive('introBegin', function() {
  return {
    restrict: 'A',
    template: '',
    require: '^intro',
    scope: {},

    link: function(scope, element, attrs, intro) {
      element.on('click', function(event) {
        intro.start(parseInt(attrs.introBegin));
        event.preventDefault();
      });

      element.css('cursor', 'pointer');
    }
  };
});
