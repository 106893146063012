angular.module('vantageApp').controller('controller.AccountSettings', [
  'model.User',
  'user',
  function(User, user) {
    var ctl = this;

    this.user = user;

    function alert(type, message) {
      ctl.alert = {
        type: type,
        message: message,
      };
    };

    /**
     * Handler for saving the user details.
     */
    this.saveUser = function() {
      User.update(user, {
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone
      }).then(function() {
        alert('success', 'Personal details updated');
      });
    };

    /**
     * Handler for updating the user's password.
     */
    this.savePassword = function(passwords) {
      User.update(user, {
        old_password: passwords.old_password || '',
        password1: passwords.password1 || '',
        password2: passwords.password2 || '',
      }).then(function() {
        alert('success', 'Password updated');
      }, function(response) {
        console.log(response);
        alert('danger', response.data.error);
      });
    };
  }
]);
