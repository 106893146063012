angular.module('vantageApp').service('service.AprimoSelector', [
    'va.config',
    '$window',
    '$http',
    function (config, $window, $http) {

        const service = this;

        service.openAprimo = function (callback, cancel, errorHandler, single) {

            const handleMessageEvent = function (event) {

                if ((event.origin) !== config.aprimoSelectorHost) {
                    return;
                }

                if (event.data.result === 'cancel') {
                    $window.removeEventListener("message", handleMessageEvent);
                    cancel();
                }
                else if (event.data.result === 'accept') {

                    $window.removeEventListener("message", handleMessageEvent);

                    const ids = event.data.selection.map(function (selection) {
                        return selection.id
                    });

                    $http({
                        url: '/api/' + config.store.id + '/aprimo',
                        data: {
                            selected_ids: ids
                        },
                        method: 'POST',
                    }).success(function(data) {
                        callback(data);
                    }).error(function(message) {
                        errorHandler(message, true);
                    });
                }
            }

            var url = config.aprimoDamUI;
            const selectorOptions = {
              limitingSearchExpression: 'File.Version.FileType.Kind = JPG OR ' +
                'File.Version.FileType.Kind = PNG OR ' +
                'File.Version.FileType.Kind = JPEG OR ' +
                'File.Version.FileType.Kind = TIFF'
            };

            if (single){
                selectorOptions.select = 'single';
            }
            const encodedOptions = window.btoa(JSON.stringify(selectorOptions));
            url += "#options=" + encodedOptions;
            $window.open(url, "_blank");
            $window.addEventListener("message", handleMessageEvent, false);

        }
    }
])
