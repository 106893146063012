angular.module('vantageApp').config([
  '$stateProvider',
  '$urlRouterProvider',
  'StripeCheckoutProvider',
  function(router, url, StripeCheckoutProvider) {
    // The default URL when going to /
    url.otherwise(function(injector) {
      var pathname = window.location.pathname;
      var fullUrl = window.location.protocol + '//' + window.location.host;
      var config = injector.get('va.config');

      if (config.user && !config.org) {
        window.location.assign(fullUrl + '/r/organizations')
      }

      if (config.store && !config.store.import_complete) {
        return '/setup';
      }

      // Cannot use hasFeature() here since we don't have the root scope
      if (!pathname || pathname === '/' && config.org) {
        if (config.features['ecommerce_dashboard'] === true && config.store && config.store.platform !=='EXTERNAL') {
          if (config.features['react_analytics'] === true) {
            window.location.assign(fullUrl + '/r/' + config.store.id + '/realtime')
          } else {
            return '/realtime/daily';
          }
        } else if (config.store && config.features['corona_dashboard'] === true) {
          if (config.store.name.endsWith(' - Home Depot US') || config.store.name === 'www.homedepot.com') {
            window.location.assign(fullUrl + '/r/' + config.store.id + '/campaigns')
          } else {
            if (config.features['react_analytics'] === true) {
              window.location.assign(fullUrl + '/r/' + config.store.id + '/realtime')
            } else {
              return '/realtime/monthly';
            }
          }
        } else if (config.store && config.features['advertising'] === true) {
          window.location.assign(fullUrl + '/r/' + config.store.id + '/campaigns')
        } else {
          return '/dashboard';
        }
      }
    });

    /**
     * The list of routes for the application, powered by ui-router.
     *
     * @see  https://github.com/angular-ui/ui-router
     */
    var routes = {
      'app': {
        abstract: true,
        views: {
          '': {
            template: '<ui-view />',
            controller: 'controller.App as app',
          }
        },
        resolve: {
          store: ['model.Store', function(Store) {
            return Store.current();
          }],
          org: ['model.Organization', function(Organization) {
            return Organization.current();
          }]
        }
      },

      // TODO remove when the vantage dash is retired
      'dashboard': {
        abstract: true,
        parent: 'app',
        templateUrl: 'views/dashboard/index.html',
        resolve: {
          metrics: ['model.DatasetMetric', function(DatasetMetric) {
            return DatasetMetric.all();
          }]
        },
        requiredFeature: 'ecommerce_dashboard',
        requireStoreFinalized: true,
      },

      // TODO remove when the vantage dash is retired
      'dashboard.home': {
        url: '/dashboard',
        views: {
          "": {
            templateUrl: 'views/dashboard/metrics.html',
            controller: 'controller.DashboardMetrics',
          }
        },
        resolve: {
          recommendations: ['model.Recommendation', function(Recommendation) {
            return Recommendation.all();
          }]
        }
      },

      'campaign-create': {
        parent: 'app',
        url: '/campaigns/create?audience',
        templateUrl: 'views/campaigns/create.html',
        controller: 'controller.CampaignCreate as ctl',
        resolve: {
          stripe: StripeCheckoutProvider.load,
          stripeCustomer: ['model.StripeCustomer', function(StripeCustomer) {
            return StripeCustomer.current();
          }],
          audiences: ['model.Audience', function(Audience) {
            return Audience.all();
          }],
          campaign: [function() { return null; }]
        },
        requiredFeature: 'advertising',
        requireStoreFinalized: true,
      },

      'campaign-edit': {
        parent: 'app',
        url: '/campaigns/edit/:id?wgc',
        templateUrl: 'views/campaigns/create.html',
        controller: 'controller.CampaignCreate as ctl',
        resolve: {
          stripe: StripeCheckoutProvider.load,
          stripeCustomer: ['model.StripeCustomer', function(StripeCustomer) {
            return StripeCustomer.current();
          }],
          audiences: ['model.Audience', function(Audience) {
            return Audience.all();
          }],
          campaign: ['model.Campaign2', '$stateParams', function(Campaign, params) {
            return Campaign.get(params.id);
          }]
        },
        requiredFeature: 'advertising',
        requireStoreFinalized: true,
      },

      'campaign-details': {
        parent: 'app',
        url: '/campaigns/details/:id',
        templateUrl: 'views/campaigns/details.html',
        controller: 'controller.CampaignDetails as ctl',
        resolve: {
          campaign: ['model.Campaign2', '$stateParams', function(Campaign, params) {
            return Campaign.get(params.id);
          }],
          charges: ['model.CampaignCharges', '$stateParams', function(CampaignCharges, params) {
            return CampaignCharges.get(params.id);
          }]
        },
        requiredFeature: 'advertising',
        requireStoreFinalized: true,
      },

      'campaigns': {
        parent: 'app',
        url: '/campaigns',
        views: {
          "": {
            templateUrl: 'views/campaigns/list.html',
            controller: 'controller.CampaignList as ctl',
          },
          "recommendations@campaigns": {
            templateUrl: 'views/dashboard/recommendations.html',
            controller: 'controller.DashboardRecommendations',
          }
        },
        resolve: {
          campaigns: ['model.Campaign2', function(Campaign2) {
            return Campaign2.all();
          }],
          audiences: ['model.Audience', function(Audience) {
            return Audience.all();
          }],
          recommendations: ['model.Recommendation', function(Recommendation) {
            return Recommendation.all();
          }]
        },
        requiredFeature: 'advertising',
        requireStoreFinalized: true,
      },

      'explore': {
        parent: 'app',
        url: '/explore',
        templateUrl: 'views/explore/index.html',
        controller: 'controller.Explore',
        requiredFeature: 'explore',
        requireStoreFinalized: true,
      },

      'settings': {
        abstract: true,
        parent: 'app',
        url: '/settings',
        templateUrl: 'views/settings/index.html',
        controller: 'controller.Settings',
        requireStoreFinalized: true,
      },

      'settings.store': {
        url: '/store',
        templateUrl: 'views/settings/store.html',
        controller: 'controller.StoreSettings',
        requireStoreFinalized: true,
      },

      'add-store': {
        parent: 'app',
        url: '/addStore',
        templateUrl: 'views/organizations/addStore.html',
        controller: 'controller.AddStore as ctl',
        resolve: {
          orgs: ['model.Organization', function(Organization) {
            return Organization.all();
          }]
        },
        requireStoreFinalized: false,
      },

      'connect-store': {
        parent: 'app',
        url: '/connectStore/:platform/:shopDomain',
        templateUrl: 'views/organizations/connectStore.html',
        controller: 'controller.ConnectStore as ctl',
        requireStoreFinalized: false,
      },

      'setup': {
        parent: 'app',
        url: '/setup',
        templateUrl: 'views/setup/index.html',
        controller: 'controller.Setup as setup',
        requireStoreFinalized: false,
      },

      'style-guide': {
        parent: 'app',
        url: '/docs/style-guide',
        templateUrl: 'views/docs/style-guide.html',
      },

      'dashboard-v2': {
        abstract: true,
        url: '/realtime',
        parent: 'app',
        views: {
          "": {
            templateUrl: 'views/dashboard-v2/index.html',
          },

          "recommendations@dashboard-v2": {
            templateUrl: 'views/dashboard/recommendations.html',
            controller: 'controller.DashboardRecommendations',
          }
        },

        resolve: {
          metrics: ['model.DatasetMetric', function(DatasetMetric) {
            return DatasetMetric.all();
          }],
          recommendations: ['model.Recommendation', function(Recommendation) {
            return Recommendation.all();
          }],
          campaigns: ['model.Campaign2', function(Campaign2) {
            return Campaign2.all();
          }],
          audiences: ['model.Audience', function(Audience) {
            return Audience.all();
          }],
        },
        requiredFeature: 'ecommerce_dashboard',
        requireStoreFinalized: true,
      },

      'dashboard-v2.realtime': {
        url: '/:period',
        views: {
          "realtime@dashboard-v2": {
            templateUrl: 'views/dashboard-v2/realtime.html',
            controller: 'controller.DashboardV2Realtime',
          },

          "metrics@dashboard-v2": {
            templateUrl: 'views/dashboard-v2/metrics.html',
            controller: 'controller.DashboardV2Metrics',
          },
        },
        requiredFeature: 'ecommerce_dashboard',
        requireStoreFinalized: true,
      }
    };

    _.forEach(routes, function(route, name) {
      router.state(name, route);
    });
  }
]).run([
  '$rootScope',
  '$state',
  'va.config',
  '$location',
  function(rootScope, state, config, location) {
    rootScope.$on('$stateChangeStart', function() {
      // Show a loading indicator, which we'll remove whenever we're done.
      rootScope.isLoading = true;
    });

    rootScope.$on('$stateChangeSuccess', function() {
      // We've successfully transitioned, so let's remove the loading indicator
      rootScope.isLoading = false;

      // Scroll to the top so the new route isn't loaded in the middle of the page.
      window.scrollTo(0, 0);
    });

    rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

      if (toState.requireStoreFinalized && config.store && !config.store.import_complete) {
        event.preventDefault();
        rootScope.isLoading = false;
        state.go('setup');
      } else if (toState.requiredFeature) {
          var requiredFeature = toState.requiredFeature;
          if (toState.requiredFeature === 'ecommerce_dashboard' && config.store.platform === 'EXTERNAL') {
            requiredFeature = 'corona_dashboard';
          }
          var hasRequiredFeature = rootScope.hasFeature(requiredFeature);
          if (!hasRequiredFeature) {
            event.preventDefault();
            rootScope.isLoading = false;
            if (toState.requiredFeature === 'advertising') {
              return state.go('dashboard-v2.realtime', { period: 'daily' });
            } else if (toState.requiredFeature === 'ecommerce_dashboard') {
              return state.go('campaigns');
            }
          }
      }
    });
  }
]);
