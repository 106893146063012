angular.module('vantageApp').controller('controller.CampaignCreate', [
  '$scope',
  '$stateParams',
  '$filter',
  '$http',
  '$location',
  'va.config',
  'lib.Modal',
  'model.Campaign2',
  'model.Audience',
  'model.Store',
  'model.FacebookAd',
  'model.GoogleAd',
  'model.StoreDetails',
  'StripeCheckout',
  'audiences',
  'campaign',
  'stripeCustomer',
  'service.FacebookPixelEvents',
  'service.GoogleAnalyticsEvents',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  'service.BrandMeasurement',
  function(
    $scope,
    $params,
    $filter,
    $http,
    $location, // providers
    config,
    Modal,
    Campaign,
    Audience,
    Store,
    FacebookAd,
    GoogleAd,
    StoreDetails,
    StripeCheckout, // models
    audiences,
    campaign,
    stripeCustomer, // objects
    vfbq,
    vga,
    AdvancedEcom,
    BrandMeasurement // services
  ) {
    var ctl = this;

    if (!campaign) {
      campaign = new Campaign(); // Create a new campaign if we don't get an existing campaign
      campaign.optimizeOptIn = true;
    }

    this.campaign = campaign;
    this.audiences = audiences;
    this.stripeCustomer = stripeCustomer;
    this.config = config
    this.currentProductCatalog = '';
    this.currentProductGroupName = '';
    this.showSaveErrorMessage = false;
    this.showSocialWarning = false;
    this.retailerIsHomeDepotCanada = false;
    this.optionalAttributes = ['Brief ID', 'Pod'];
    this.incorrectTaxonomyValue = {};
    if (this.campaign.googleCampaign) {
      this.googleShoppingType = this.campaign.googleCampaign.channelType;
      this.googleBidStrategy = this.campaign.googleCampaign.bidStrategy;
    } else {
      this.googleShoppingType = 'SHOPPING';
      this.googleBidStrategy = 'TARGET_CPA';
    }

    this.showManagedServiceAlert = false;
    if (
      campaign.brand &&
      campaign.brand.managed_service &&
      (config.store.name.includes('homedepot.com') || config.store.name.includes('homedepot.ca'))
    ) {
      this.showManagedServiceAlert = true;
    }

    if (config.store.name.endsWith('- Home Depot US')) {
      if (this.campaign.lineItem) {
        var availableBudget = Number(this.campaign.lineItem.unallocatedBudget);
        if (!this.campaign.lineItem.isFake) {
          availableBudget += this.campaign.budget.actual;
        }
        this.campaign.budget.initializeUnallocatedAmountHDUS(availableBudget);
      }
    } else {
      this.campaign.budget.initializeUnallocatedAmount();
    }

    ctl.isTourRunning = false;
    ctl.isAdmin = $scope.hasFeature('admin');

    this.startTour = function() {
      ctl.isTourRunning = true;
    };
    this.endTour = function() {
      ctl.isTourRunning = false;
      // This is necessary to reset the adset builder after the tour closes
      $scope.$apply();
    };

    if (ctl.campaign.isMarketingPackageCampaign) {
      ctl.bidStrategyName = ctl.campaign.bidStrategy;
      if (ctl.campaign.bidStrategy === 'CPC') {
        ctl.bidStrategyName = 'Cost Per Click (CPC)';
      } else if (ctl.campaign.bidStrategy === 'CPM') {
        ctl.bidStrategyName = 'Cost Per Thousand Impressions (CPM)';
      }
    }

    this.getMeasurementType = function() {
      var i = 0;
      while (i < ctl.metadata.length && ctl.metadata[i].key !== 'measurement_type') {
        i++;
      }
      if (i < ctl.metadata.length) {
        var type = ctl.metadata[i].value.replaceAll(' ', '_').toLowerCase();
        var typeName = {
          department: 'MD',
          enterprise: 'ME',
          manufacturing_brand: 'MB',
          secondary_brand: 'SB',
          parent_brand: 'PB',
          brand_id: 'BI',
          pvendor: 'PV'
        };
        if (type in typeName) {
          ctl.metadata[i].value = typeName[type];
        } else {
          ctl.metadata[i].value = 'NA';
        }
      }
    };

    this.getBidStrategy = function() {
      if (ctl.campaign.bidStrategy === 'CPC') {
        return 'Cost Per Click (CPC)';
      } else if (ctl.campaign.bidStrategy === 'CPM') {
        return 'Cost Per Thousand Impressions (CPM)';
      } else {
        return ctl.campaign.bidStrategy;
      }
    };

    this.attributeIsOptional = function(attributeName) {
      var i = 0;
      while (i < ctl.optionalAttributes.length && ctl.optionalAttributes[i] !== attributeName) {
        i++;
      }
      return i < ctl.optionalAttributes.length;
    };

    this.allowEdit = function() {
      var isDraft = campaign.state === 'draft';
      var isBrandEdit = campaign.state === 'pending_brand_approval';
      var showEditToAdmins = ctl.isAdmin && !campaign.isCompleted() && !campaign.isCancelled();
      var isHomeDepot = config.store.name.includes("Home Depot") || config.store.domain.includes("homedepot")
      var showEditToRetailer =
        config.user.isRetailerUser && isHomeDepot && (campaign.isDraft() || campaign.isPendingRetailerApproval());
      return isDraft || showEditToAdmins || showEditToRetailer || isBrandEdit;
    };

    this.showDpaPlacement = function(type) {
      var isStories = type == 'STORIES';
      var isChannelAvailable =
        (type !== 'DISPLAY' && ctl.campaign.audience.isAvailableFacebook()) ||
        (type == 'DISPLAY' && ctl.campaign.audience.isAvailableGoogle());
      return !isStories && isChannelAvailable;
    };

    this.showAdsetBuilder = function() {
      return (
        (ctl.campaign.audience && ctl.campaign.audience.isDIY && ctl.campaign.audience.isDIY()) ||
        ctl.campaign.isDPAWithCustomAudience()
      );
    };

    this.showTaxonomy = function() {
      if (ctl.taxonomy && !ctl.campaign.isPosting) {
        return ctl.taxonomy.facebook_campaign || ctl.taxonomy.google_campaign || ctl.taxonomy.pinterest_campaign;
      }
    };

    this.checkMetadata = function() {
      ctl.incorrectTaxonomyValue = {};
      _.forEach(ctl.metadata, function(attribute) {
        if (!ctl.attributeIsOptional(attribute.name)) {
          if (!attribute.value || attribute.value === 'NA') {
            ctl.incorrectTaxonomyValue[attribute.name] = ' is required';
          }
        }
        if (attribute.value.match(/^\W+$/)) {
          ctl.incorrectTaxonomyValue[attribute.name] = ' contains invalid characters';
        }
      });
    };

    this.saveMetadata = function() {
      $http
        .post('/api/' + ctl.getStoreId() + '/campaigns/' + ctl.campaign.id + '/metadata', { metadata: ctl.metadata })
        .then(function(response) {
          ctl.metadata = response.data.metadata;
          ctl.taxonomy = response.data.taxonomy;
          ctl.metadata = ctl.metadata.filter(function(data) {
            return data.key !== 'is_marketing_package_campaign' && data.key !== 'is_for_testing';
          });
        });
    };

    // Explicitly fetch the store from the back end.
    Store.current().then(function(store) {
      ctl.store = store;
      ctl.retailerIsHomeDepotCanada = ctl.store.retailerIsHomeDepotCanada();
      ctl.retailerIsHomeDepotUS = ctl.store.retailerIsHomeDepotUS();
      this.defaultProductGroupName = 'All ' + ctl.store.coopBrandName() + ' Products';
      if (ctl.retailerIsHomeDepotUS && ctl.campaign.id) {
        var metadata_api_url = '/api/' + ctl.getStoreId() + '/campaigns/' + ctl.campaign.id + '/metadata';
        $http.get(metadata_api_url).then(function(response) {
          ctl.metadata = response.data.metadata;
          ctl.metadata = ctl.metadata.filter(function(data) {
            return data.key !== 'is_marketing_package_campaign' && data.key !== 'is_for_testing';
          });
          if (ctl.campaign.isMarketingPackageCampaign) {
            ctl.showMediaAgreements = false;
            ctl.optionalAttributes = [];
            ctl.store.isMarketingPackageCampaign = ctl.campaign.isMarketingPackageCampaign;
          }
          ctl.taxonomy = response.data.taxonomy;
          if (ctl.retailerIsHomeDepotUS && ctl.campaign.lineItem) {
            for (var i = 0; i < ctl.metadata.length; i++) {
              if (
                ctl.metadata[i].key === 'measurement_type' &&
                (!ctl.metadata[i].value || ctl.metadata[i].value === 'NA')
              ) {
                ctl.metadata[i].value = ctl.campaign.lineItem.measurementType || 'NA';
                ctl.getMeasurementType();
                continue;
              }
              if (
                ctl.metadata[i].key === 'measurement_brand' &&
                (!ctl.metadata[i].value || ctl.metadata[i].value === 'NA')
              ) {
                ctl.metadata[i].value = ctl.campaign.lineItem.measurementBrand || 'NA';
              }
            }
          }
        });
      }
      ctl.getProductGroups();
      BrandMeasurement.initialize().then(function() {
        ctl.secondaryBrands = BrandMeasurement.secondaryBrands;
        ctl.departmentCodes = BrandMeasurement.departmentCodes;
        ctl.productClass = BrandMeasurement.productClass;
        ctl.parentBrands = BrandMeasurement.getParentBrands(ctl.store.coopBrandName());
        ctl.brandIds = BrandMeasurement.getBrandIds(ctl.store.brand_key);
        ctl.eventType = BrandMeasurement.eventType;
        ctl.podCodes = BrandMeasurement.podCodes;
        ctl.objectiveType = BrandMeasurement.objectiveType;
      });
      ctl.showMediaAgreements = ctl.retailerIsHomeDepotCanada || ctl.retailerIsHomeDepotUS;
      // Check if social warnings need to be shown
      if (!ctl.store.primary_facebook_page) {
        ctl.showSocialWarning = true;
        ctl.store.agency_access = false;
      } else {
        Store.checkAgencyAccess(ctl.store).then(function(response) {
          var agency_access = response.data.agency_access;
          ctl.store.agency_access = agency_access;

          if (!agency_access) {
            ctl.showSocialWarning = true;
          }
        });

        if (!ctl.store.hasInstagramAccountId()) {
          Store.checkInstagramAccess(ctl.store).then(function(response) {
            var instagram_access = response.data.instagram_access;

            if (!instagram_access) {
              ctl.showSocialWarning = true;
            }
          });
        } // end `if`
      }

      // Called when ready to save and run the campaign.
      ctl.save = function() {
        if (
          ctl.store.charge_for_campaigns &&
          (ctl.store.stripe_customer_id === null ||
            ctl.store.stripe_customer_id === '' ||
            (ctl.stripeCustomer && ctl.stripeCustomer.cards && ctl.stripeCustomer.cards.length === 0))
        ) {
          var handler = StripeCheckout.configure();
          var budget = ctl.campaign.budget.actual;
          var options = {
            description: 'Campaign Budget',
            panelLabel: 'Authorize'
          };

          options.panelLabel +=
            ' ' +
            $filter('money')(budget, {
              language: 'en-US',
              format: '$0,0'
            });

          if (ctl.campaign.schedule.isOngoing()) {
            options.panelLabel += '/mo';
          }

          handler.open(options).then(function(response) {
            $http
              .post('/api/' + ctl.store.id + '/stripe/', {
                stripe_credit_card_token: response[0]
              })
              .then(function(response) {
                createCampaign();
              });
          });
        } else {
          createCampaign();
        }
      };

      ctl.spendAmount = function() {
        if (ctl.campaign.budget.actual) {
          var feeRatio = 1 + ctl.store.default_campaign_fee_percentage / 100;
          return ctl.campaign.budget.actual / feeRatio;
        }
      };

      if ($params.wgc) {
        $scope.showGoogleCopiedMessage = true;
      }

      // Setup the models using the data from router
      if (ctl.campaign.audience) {
        // Audience already attached to model
        ctl.campaign.setAudience(new Audience(ctl.campaign.audience));
      } else if ($params.audience) {
        // Audience pre-selected from url

        // We have to inject the store into the audience params, so that it knows
        // where to pull social media info from.
        $params.audience.store = ctl.store;

        if (!config.isDemoing()) {
          _.forEach(ctl.audiences, function(audience) {
            if (audience.type === $params.audience) {
              ctl.campaign.setAudience(
                new Audience({
                  id: audience.id,
                  type: audience.type
                })
              );
            }
          });
        } else {
          ctl.campaign.setAudience(
            new Audience({
              type: $params.audience
            })
          );
        }
      }

      // fire pickAudience after all methods of selecting an audience have been checked
      if (ctl.campaign.audience) {
        AdvancedEcom.pickAudience();
      }

      if (ctl.campaign.adsets) {
        // If the campaign isn't newly created it will have adsets have ads as
        // objects rather than 'models'. This will change them to models.
        var isDpa = ctl.campaign.audience && ctl.campaign.isDPA && ctl.campaign.isDPA();

        _.forEach(ctl.campaign.adsets, function(adset, placement) {
          if (placement !== 'DISPLAY') {
            ctl.campaign.adsets[placement].ads = _.map(adset.ads, function(ad) {
              return new FacebookAd({
                id: ad.id,
                type: placement,
                creative: ad.creative,
                isDpa: isDpa,
                isVideoAd: ad.isVideoAd,
                isPristine: ad.isPristine,
                isCopied: ad.isCopied,
                sortOrder: ad.sortOrder,
                comments: ad.comments,
                review_status: ad.review_status,
                hasClaim: ad.hasClaim
              });
            });
          }
        });
      }
    });

    ctl.campaign.adsets['DISPLAY'] = { ads: [] };
    if (ctl.campaign.googleCampaign && ctl.campaign.googleCampaign.adGroups.length) {
      var ads = ctl.campaign.googleCampaign.adGroups[0].ads;

      var isDisplay = ctl.campaign.googleCampaign['channelType'] == 'DISPLAY';

      if (isDisplay && ads) {
        ctl.campaign.adsets['DISPLAY'].ads = _.map(ads, function(ad) {
          return new GoogleAd({
            id: ad.id,
            type: 'DISPLAY',
            report: ad.report,
            status: ad.status,
            marketingImage: ad.marketingImage,
            finalUrl: ad.finalUrl,
            shortHeadline: ad.shortHeadline,
            longHeadline: ad.longHeadline,
            description: ad.description,
            businessName: ad.businessName,
            return_on_ad_spend: ad.return_on_ad_spend,
            callToAction: ad.callToAction,
            isDpa: ad.isDpa,
            isPristine: ad.isPristine,
            isCopied: ad.isCopied,
            sortOrder: ad.sortOrder,
            comments: ad.comments,
            review_status: ad.review_status,
            hasClaim: ad.hasClaim
          });
        });
      }
    }

    this.getStoreId = function() {
      if (ctl.store.is_retailer) {
        return ctl.campaign.store.id;
      }

      return ctl.store.id;
    };

    this.getProductGroups = function() {
      this.productGroupLink = this.getProductGroupLink();
      $http.get('/api/' + ctl.getStoreId() + '/product_groups').then(function(response) {
        ctl.currentProductCatalog = ctl.campaign.catalog;
        ctl.productGroups = response.data['productGroups'];
        if (ctl.campaign.productGroupId || ctl.campaign.productGroupId == 0) {
          var i = 0;
          while (i < ctl.productGroups.length && ctl.productGroups[i].id != ctl.campaign.productGroupId) {
            i++;
          }
          if (i < ctl.productGroups.length) {
            ctl.campaign.productGroupId = ctl.productGroups[i].id;
            ctl.currentProductGroupName = ctl.productGroups[i].name;
            if (ctl.productGroups[i].pinterestSource) {
              ctl.pinterestPrimaryProductsCount = ctl.productGroups[i].pinterestSource.productCount;
              ctl.pinterestSecondaryProductsCount = ctl.productGroups[i].pinterestSource.secondaryCatalogProductCount;
            }

            if (ctl.productGroups[i].facebookSource) {
              if (ctl.currentProductCatalog === 'PRIMARY') {
                ctl.facebookProductsCount = ctl.productGroups[i].facebookSource.productCount;
              } else {
                ctl.facebookProductsCount = ctl.productGroups[i].facebookSource.secondaryCatalogProductCount;
              }
            }
          }
        }
      });
    };

    this.showProductGroups = function() {
      if (!ctl.store) {
        return;
      }
      this.defaultProductGroupName = 'All ' + ctl.store.coopBrandName() + ' Products';
      return (
        (ctl.retailerIsHomeDepotUS || ctl.retailerIsHomeDepotCanada) &&
        ctl.campaign.audience &&
        (ctl.campaign.isDPA() ||
          (ctl.campaign.audience.isShopping && ctl.campaign.audience.isShopping()) ||
          ctl.campaign.isDPAWithCustomAudience() ||
          ctl.campaign.hasPinterestShoppingAds())
      );
    };

    this.showProductFeedSelector = function() {
      return (
        (ctl.retailerIsHomeDepotUS && ctl.campaign.audience && ctl.campaign.audience.isAvailableFacebook()) ||
        (ctl.retailerIsHomeDepotCanada && ctl.campaign.audience)
      );
    };

    this.showGoogleShoppingType = function() {
      return ctl.retailerIsHomeDepotUS && ctl.campaign.audience && ctl.campaign.audience.isGoogleShopping();
    };

    if (this.campaign.id && !this.allowEdit()) {
      // This is the wrong page, redirect to the campaign details page
      $location.url('/campaigns/details/' + campaign.id);
    }

    if (this.campaign.schedule.startDate) {
      this.campaign.schedule.startDate = moment(this.campaign.schedule.startDate);

      // If the campaign is still in draft, ensure the startDate isn't in the past.
      if (this.campaign.state === 'draft' && this.campaign.schedule.startDate.isBefore(moment())) {
        this.campaign.schedule.startDate = null;
      }
    }

    if (this.campaign.schedule.endDate) {
      this.campaign.schedule.endDate = moment(this.campaign.schedule.endDate);
    }

    // Prevent users from navigating away when there are unsaved changes
    var navigateAwayMessage = 'You have unsaved changes. Click \'OK\' to abandon your progress.';
    $scope.$on('$locationChangeStart', function(event) {
      if (!ctl.campaign.isDirty) {
        return;
      }
      var answer = confirm(navigateAwayMessage);
      if (!answer) {
        event.preventDefault();
      }
    });

    // Report step 1 of the funnel.
    AdvancedEcom.visitCampaignCreate();

    // Example placements that we show for the demo. These do not work.
    this.demoPlacements = {
      facebook: [
        'Canvas',
        'Lead Ad',
        'Link',
        'Multi-product',
        'Text',
        'Offer',
        'Page Like',
        'Mobile App',
        'Desktop App',
        'Event'
      ],
      twitter: [
        'Promoted Tweet',
        'Promoted Account',
        'Website Card',
        'Lead Generation Card',
        'Image App Card',
        'Basic App Card'
      ]
    };

    // Container for each of the different adsets, images, copysets, and more.
    // Eventually these will get turned into ads that we publish.
    this.adsets = {
      NEWS: {
        name: 'Newsfeed',
        active: true
      },
      RIGHT: {
        name: 'Right-hand column', // TODO Is this used anywhere?
        active: false
      },
      MOBILE: {
        name: 'Mobile',
        active: false
      },
      INSTAGRAM: {
        name: 'Instagram',
        active: false
      },
      STORIES: {
        name: 'Stories',
        active: false
      },
      DISPLAY: {
        name: 'Display',
        active: false
      }
    };

    if (
      this.campaign.isMarketingPackageCampaign ||
      (ctl.campaign.isHomeDepotUS && ctl.campaign.audience.funnel_stage === 'ATTRACT')
    ) {
      delete this.adsets['RIGHT'];
    }

    // Afford changing tabs for placement.
    this.showAdset = function(keyToShow, event) {
      _.forEach(ctl.adsets, function(adset, key) {
        adset.active = false;

        if (key === keyToShow) {
          adset.active = true;
        }
      });

      if (event) {
        event.preventDefault();
      }
    };

    function fireFacebookEvent(campaignResponse) {
      var campaign_type = campaignResponse.audience.type.replace('Audience', 'Campaign');
      var campaign_ids = [campaignResponse.id];

      vfbq.purchase(campaignResponse.budget.actual, campaign_ids, campaign_type, campaignResponse.name);
    }

    function fireGoogleAnalyticsEvent(campaignResponse) {
      var campaign_type = campaignResponse.audience.type.replace('Audience', 'Campaign');
      var campaign_value = campaignResponse.budget.actual;
      AdvancedEcom.runCampaign(campaign_value, campaign_type);
      vga.purchase(campaign_value, campaign_type, 'Campaign');
    }

    function postErrorCallback(campaignResponse) {
      $scope.showSaveErrorMessage = true;
      ctl.campaign.isPosting = false;
    }

    function postCampaign(asDraft, onSaveCallback) {
      ctl.campaign.isDirty = false;
      ctl.campaign.isPosting = true;
      ctl.campaign.saveAsDraft = asDraft;
      // Send a message to adset-editor-copy directive
      $scope.$broadcast('campaignSave');

      if (ctl.campaign.adsets['DISPLAY']) {
        var ads = ctl.campaign.adsets['DISPLAY'].ads;

        if (ads && ads.length > 0) {
          if (!ctl.campaign.googleCampaign) {
            ctl.campaign.googleCampaign = { adGroups: [{ ads: null }] };
          }
          ctl.campaign.googleCampaign.adGroups[0].ads = ads;
          ctl.campaign.googleCampaign['type'] = 'DISPLAY';
        } else {
          ctl.campaign.googleCampaign = null;
        }

        delete ctl.campaign.adsets['DISPLAY'];
      }

      if (
        ctl.campaign.isMarketingPackageCampaign ||
        (ctl.campaign.isHomeDepotUS && ctl.campaign.audience.funnel_stage === 'ATTRACT')
      ) {
        delete ctl.campaign.adsets['RIGHT'];
      }

      if (ctl.campaign.adsets['SHOPPING']) {
        var ads = ctl.campaign.adsets['SHOPPING'].ads;

        if (ads && ads.length > 0) {
          ctl.campaign.googleCampaign = {
            type: ctl.googleShoppingType,
            adGroups: [{ ads: [{}] }]
          };
        }

        delete ctl.campaign.adsets['SHOPPING'];
      }

      if (ctl.campaign.adsets['PINTEREST_SHOPPING']) {
        var ads = ctl.campaign.adsets['PINTEREST_SHOPPING'].ads;

        if (ads && ads.length > 0) {
          ctl.campaign.pinterestCampaign = {
            type: 'PINTEREST_SHOPPING',
            adGroups: [{ ads: [{}] }]
          };
        }

        delete ctl.campaign.adsets['PINTEREST_SHOPPING'];
      }

      if (ctl.retailerIsHomeDepotUS && ctl.campaign.id) {
        ctl.saveMetadata();
      }

      if (!ctl.showProductGroups()) {
        ctl.campaign.productGroupId = null;
      }

      if (ctl.campaign.brand) {
        Campaign.url = '/api/' + ctl.campaign.brand.store.id + '/campaigns/';
      }
      Campaign.create(ctl.campaign).then(onSaveCallback, postErrorCallback);
    }

    function createCampaign() {
      postCampaign(false, function(campaignResponse) {
        fireFacebookEvent(campaignResponse);
        fireGoogleAnalyticsEvent(campaignResponse);
        $location.url('/campaigns/details/' + campaignResponse.id);
      });
    }

    function saveDraft(goToBilling) {
      if (!ctl.campaign.budget) {
        ctl.campaign.budget = { actual: 0 };
      } else if (!ctl.campaign.budget.isValid() || ctl.campaign.budget.isNaN()) {
        ctl.campaign.budget.actual = 0;
      }
      AdvancedEcom.saveDraft();

      postCampaign(true, function(campaignResponse) {
        var editLocation = '/campaigns/edit/' + campaignResponse.id;

        if (goToBilling) {
          $location.url('/r/' + ctl.campaign.store.id + '/settings/billing');
        } else if ($location.path() === editLocation) {
          window.location.reload();
        } else {
          $location.url(editLocation);
        }
      });
    }

    this.saveDraftAndRefresh = function() {
      saveDraft(false);
    };

    this.saveDraftAndGoToBilling = function() {
      saveDraft(true);
    };

    this.checkPristineAds = function() {
      if (ctl.retailerIsHomeDepotUS || ctl.retailerIsHomeDepotCanada) {
        ctl.save();
        return;
      }

      var hasPristineAds = ctl.campaign.pristineAdsets();

      if (hasPristineAds) {
        Modal.show({
          templateUrl: 'views/modal/pristine-ads-confirmation.html',
          controller: 'controller.pristineAdsConfirmationModal',
          controllerAs: 'modal',
          inputs: {
            hasPristineAds: hasPristineAds,
            action: ctl.save
          }
        });
      } else {
        ctl.save();
      }
    };

    this.feeAmount = function() {
      if (ctl.campaign.budget.actual) {
        return ctl.campaign.budget.actual - ctl.spendAmount();
      }
    };

    this.chargeForCampaigns = function() {
      return Boolean(this.store && this.store.charge_for_campaigns);
    };

    this.chooseProductGroup = function() {
      var currentPgIndex = 0;
      while (
        currentPgIndex < ctl.productGroups.length &&
        ctl.productGroups[currentPgIndex].name !== ctl.currentProductGroupName
        ) {
        currentPgIndex++;
      }
      if (currentPgIndex < ctl.productGroups.length) {
        ctl.campaign.productGroupId = ctl.productGroups[currentPgIndex].id;
        this.setMatchedProductsCount(currentPgIndex);
        this.productGroupLink = this.getProductGroupLink();
      }
    };

    this.chooseProductCatalog = function() {
      ctl.campaign.catalog = ctl.currentProductCatalog;

      var currentPgIndex = 0;
      while (
        currentPgIndex < ctl.productGroups.length &&
        ctl.productGroups[currentPgIndex].name !== ctl.currentProductGroupName
        ) {
        currentPgIndex++;
      }
      if (currentPgIndex < ctl.productGroups.length) {
        this.setMatchedProductsCount(currentPgIndex);
      }
    };

    this.setMatchedProductsCount = function(index) {
      if (ctl.productGroups[index].pinterestSource) {
        ctl.pinterestPrimaryProductsCount = ctl.productGroups[index].pinterestSource.productCount;
        ctl.pinterestSecondaryProductsCount = ctl.productGroups[index].pinterestSource.secondaryCatalogProductCount;
      }

      if (ctl.productGroups[index].facebookSource) {
        if (ctl.currentProductCatalog === 'PRIMARY') {
          ctl.facebookProductsCount = ctl.productGroups[index].facebookSource.productCount;
        } else {
          ctl.facebookProductsCount = ctl.productGroups[index].facebookSource.secondaryCatalogProductCount;
        }
      }
    };

    this.getProductGroupLink = function() {
      var productGroupLink = '/r/' + ctl.getStoreId() + '/campaign/product-group';
      if (ctl.campaign.productGroupId) {
        productGroupLink = productGroupLink + '/' + ctl.campaign.productGroupId;
      }

      if (ctl.campaign.id) {
        productGroupLink = productGroupLink + '?campaignId=' + ctl.campaign.id;
      }
      return productGroupLink;
    };

    this.storeTerm = config.storeTerm();

    this.showReactCampaignBuilder = function() {
      var ALLOW_LIST = [
        'austin_slaton@homedepot.com',
        'ian_renwick@homedepot.com',
        'tim_melbourne@homedepot.com',
        'dev+thdusretailer@gotvantage.com',
        'joseph_minkowitz@homedepot.com',
        'thamara_ataide_carvalho@homedepot.com',
        'sarah_n_lupien@homedepot.com',
        'steven_d_geshrick@homedepot.com',
        'stacey_mackenzie@homedepot.com',
        'customerservice@kentinv.com',
        'ada@brewp.com',
        'EmigJ@yorkwall.com',
        'ToutsisJ@yorkwall.com',
        'dipin_puri@whirlpool.com',
        'mpilz@aosom.ca',
        'skolpin@aosom.ca',
        'fanny.theoret@artika.com',
        'katrina@vanbelle.com',
        'rebecca@vanbelle.com',
        'lauren@ringofire.com',
        'reese@ringofire.com',
        'will@ringofire.com',
        'meredith.cottle@affinitytool.com',
        'rocky.reynolds@affinitytool.com',
        'kat.buzelatto@ca.bosch.com',
        'stephanie.clarke@bshg.com',
        'brightening_house@163.com',
        'hd_ca@boyelliving.com',
        'jspearin@mascocanada.com',
        'kbagga@mascocanada.com',
        'vye@mascocanada.com',
        'CalleO@venmar.ca',
        'AnnisK@venmar.ca',
        'DeslandesJ@venmar.ca',
        'DoucetM@venmar.ca',
        'ForcierP@venmar.ca',
        'tLamb@omcbbq.com',
        'tmealing@omcbbq.com',
        'cwitzel@omcbbq.com',
        'corinna.cho@brondell.com',
        'geoffrey.dunlop@brondell.com',
        'jacqueline.staples@brondell.com',
        'jolie.nguyen@brondell.com',
        'melissa.glancey@brondell.com',
        'Emma.Henry@GlenDimplexAmericas.com',
        'stephanie.wilson@glendimplexamericas.com',
        'Halima.Bouya@glendimplexamericas.com',
        'saleshd3@casainc.com',
        'mcasselman@usg.com',
        'ydevost@usg.com',
        'mymorris@usg.com',
        'brittany.smith@chamberlain.com',
        'dwain.osborn@chamberlain.com',
        'jing.chen@chamberlain.com',
        'maureen.shea@chamberlain.com',
        'sruminski@chamberlain.com',
        'Taylor.Calcagno@chamberlain.com',
        'JDeForge@clopay.com',
        'abagail.carte@cfpwood.com',
        'kcrisp@cfpwood.com',
        'tvogelsinger@cfpwood.com',
        'JOSHUA_FERNANDES@homedepot.com',
        'gabriel@cookina.cn',
        'yaniv.silelnikov@cwilighting.com',
        'jnavarro@danby.com',
        'SHousworth@danby.com',
        'dpodema@dap.com',
        'roliveira@dap.com',
        'BBaral@mascocanada.com',
        'agoertz@mascocanada.com',
        'sgotety@mascocanada.com',
        'debagitiwaikar@gmail.com',
        'Jeff.Syer@sbdinc.com',
        'Kelly.Su@sbdinc.com',
        'Mitch.Brown@sbdinc.com',
        'Samira.Suleiman@glendimplexamericas.com',
        'csimard@multyhome.com',
        'cblondi@scj.com',
        'ARangel2@scj.com',
        'DGirald@scj.com',
        'beth.bowen@dreamline.com',
        'genevieve.bolduc@maax.com',
        'michael.rowan@dreamline.com',
        'ashley.brown@maax.com',
        'camila.arbouin@ca.bosch.com',
        'ekjot.chadha@ca.bosch.com',
        'Danielle.Denstedt@agtproductsinc.com',
        'Jeff.Leung@agtproductsinc.com',
        'Mathew.Sargeant@agtproductsinc.com',
        'Osaid.Mukhtar@agtproductsinc.com',
        'Paul.Trzcinko@agtproductsinc.com',
        'Phil.Emery@agtproductsinc.com',
        'gtaylor@shurtape.com',
        'heverson@shurtape.com',
        'jwojtowicz@shurtape.com',
        'kosinski@shurtape.com',
        'srobins@shurtape.com',
        'petitcartaya.a@duracell.com',
        'spiro.n@duracell.com',
        'carolane.primeau@eglo.com',
        'kim.quelch@eglo.com',
        'meaghan.sansom@electrolux.com',
        'melissa.paul@electrolux.com',
        'thais.santos@electrolux.com',
        'ian.madeiros@energizer.com',
        'Frank.Kocis@rustoleum.ca',
        'jeremy.dawson@rustoleum.ca',
        'Melissa.Cruickshank@rustoleum.ca',
        'c.couture@monoserra.com',
        'rjohnston@monoserra.com',
        'David.Cadieux@fein.ca',
        'Rod.Markewich@fein.ca',
        'angel.perez@fiskars.com',
        'dean.albonese@fiskars.com',
        'Gail.Brissette@fiskars.com',
        'jviolacywinski@gmail.com',
        'caquino@swiftdrtv.com',
        'cduque@swiftdrtv.com',
        'howieflexfence@gmail.com',
        'sales@flexfence.com',
        'bruna.varella@ca.galanz.com',
        'robert.mansell@ca.galanz.com',
        'Kyle_Humrichouser@geniecompany.com',
        'abeaudoin@creativeguild.ca',
        'amy@creativeguild.ca',
        'cburr@creativeguild.ca',
        'michael@creativeguild.ca',
        'rabram@creativeguild.ca',
        'katherinewolf@google.com',
        'tonyruggiero@gorillaglue.com',
        'fbrault@gsctechnology.com',
        'glenn.macgregor@affiliates.hamiltonbeach.com',
        'colinb@accenthome.com',
        'marie-claude.lavigueur@carrier.com',
        'nisita.obulareddy@kohler.com',
        'Russell.Rueden@kohler.com',
        'SAMUEL.JENKINS@kohler.com',
        'stephanie.shoup@kohler.com',
        'ZAYN.ALDABAGH@kohler.com',
        'brady@krosswood.com',
        'jcastelot@daltonenterprises.com',
        'allenalison@themarsagency.com',
        'daryananik@themarsagency.com',
        'Filmers@themarsagency.com',
        'upadhyaya@themarsagency.com',
        'Danica.brett@lge.com',
        'Emmy.tu@lge.com',
        'foram.patel@lge.com',
        'shannon.newell@lge.com',
        'dfawcett@powerdekorna.com',
        'bbowen@americanbathgroup.com',
        'mrowan@americanbathgroup.com',
        'kcentofranchi@masonite.com',
        'kcrego@masonite.com',
        'moon.g@loxcreenflooring.com',
        'stephanie.a@loxcreenflooring.com',
        'jonathan@pctvinyl.com',
        'AGregus3@scj.com',
        'MPatien@scj.com',
        'tonia.coletta@metrie.com',
        'natalie.chan@midea.com',
        'ricardo.carmo@midea.com',
        'claire.zhang@midea.com',
        'stephen.clairmont@midea.com',
        'christopher.oudie@ttigroupna.com',
        'rachelle.jantzon@ttigroupna.com',
        'roy.herron@ttigroupna.com',
        'Vanessa.Aquino@ttigroupna.com',
        'chesha.mudgil@scotts.com',
        'joe.puntillo@scotts.com',
        'Anastasia.Ribeiro@rustoleum.ca',
        'ea@tileshoppes.com',
        'Suleyman@marmecanada.com',
        'vm@tileshoppes.com',
        'andrew.marubashi@moen.com',
        'Bernardo.Guzman@moen.com',
        'maureen.shea@moen.com',
        'Saurabh.Utneja@moen.com',
        'abdel.albarq@msisurfaces.com',
        'gavin.contreras@msisurfaces.com',
        'AMarubashi@napoleon.com',
        'CEverest@napoleon.com',
        'DAucoin@napoleon.com',
        'jspray@napoleon.com',
        'vaibhav@bumoutdoor.ca',
        'brianne.dilorenzo@cccga.com',
        'jack.henson@cccga.com',
        'samantha.campbell@cccga.com',
        'mdesalliers@gsctechnology.com',
        'cara.rahaman@owenscorning.com',
        'alessia.dalessandro@peakproducts.com',
        'honey.tabal@peakproducts.com',
        'jonathan.sparks@peakproducts.com',
        'mike.stuart@peakproducts.com',
        'd.wray@comcast.net',
        'zdavidson@pearlmantels.com',
        'jesse.calay@spectrumbrands.com',
        'sluo@polaronsolar.com',
        'rwreynolds@prattindustries.com',
        'Ashley.Foley@newellco.com',
        'Emily.Stratford@newellco.com',
        'Megan.Artero@newellco.com',
        'Vidya.Jagannathan@newellco.com',
        'rblake@mpglobalproducts.com',
        'Asif.Husain@renin.com',
        'Brent.Fuller@Emerson.com',
        'heather.schmerber@emerson.com',
        'jacalyn.high@emerson.com',
        'lauren.brace@emerson.com',
        'lisa.wagner@emerson.com',
        'michelle.nutting@emerson.com',
        'h.geister@hdhudson.com',
        'k.mcnett@hdhudson.com',
        'dina.biniaris@ttigroupna.com',
        'Eva.Phillippopoulos@ttigroupna.com',
        'Klevis.mazreku@ttigroupna.com',
        'stephanie.rebelo@ttigroupna.com',
        'n.sridhar@samsung.com',
        'Ameen.hassan@allegion.com',
        'Paul.Loughrey@allegion.com',
        'peter.na@allegion.com',
        'fara.abrar@se.com',
        'melanie.roblesaragon@se.com',
        'ehsan@mysmartcocoon.com',
        'francis.malboeuf@agtproductsinc.com',
        'mwoolf@sonopan.com',
        'ecastonguay@southshorefurniture.com',
        'mpbeaulieu@southshorefurniture.com',
        'smaltais@southshorefurniture.com',
        'Olga.Galushka@southwire.com',
        'tanvi@squareup.com',
        'Matthew.Markowiak@sbdinc.com',
        'jjacobson@Traeger.com',
        'ljanssen@traeger.com',
        'rcleghorn@traeger.com',
        'rnoonan@traeger.com',
        'media@hooplah.com',
        'matthewvandort@uniqueappliances.com',
        'nick@uniqueappliances.com',
        'NikoletteKapetanios@uniqueappliances.com',
        'DSaita@vigoindustries.com',
        'jbelenitsky@vigoindustries.com',
        'SPokala@vigoindustries.com',
        'vstetska@vigoindustries.com',
        'amehna@spypoint.com',
        'anamboodiri@vosker.com',
        'dbourassa@spypoint.com',
        'sgill@vosker.com',
        'splante82@gmail.com',
        'kspence@wd40.com',
        'syoon@wd40.com',
        'Brittany.Raposo@spectrumbrands.com',
        'kenana.alyakobi@spectrumbrands.com',
        'steve.kolobaric@spectrumbrands.com',
        'aleksandra_stratton@whirlpool.com',
        'sharangha_vaseekaran@whirlpool.com',
        'AshleySpar@woosterbrush.com',
        'niharika_linga@homedepot.com',
        'roda_medhat@homedepot.com',
        'stacey_mackenzie@homedepot.com',
        'tania_vera@homedepot.com',
        'julia_chriss@homedepot.com',
        'astrid_monge@homedepot.com',
        'mahima_nigam@homedepot.com',
        'francesca_audino1@homedepot.com',
        'cihat_gider@homedepot.com',
        'mohamed_aamir_khan@homedepot.com',
        'erin_richmond@homedepot.com',
        'vidya_a_argonda1@homedepot.com',
        'marie_v_marraccini@homedepot.com',
        'rajendra_v_sidhaye@homedepot.com',
        'miqueline_silveira@homedepot.com',
        'connor_gaskell@homedepot.com',
        'christopher_easby@homedepot.com',
        'cody_speares@homedepot.com',
        'sean_abdul@homedepot.com',
        'zain_husain@homedepot.com',
        'bianca_colaco@homedepot.com',
        'zoe_morawetz@homedepot.com',
        'debagiti_bhattacharya@homedepot.com',
        'arthur_marcoviciu@homedepot.com',
        'andrea_s_king@homedepot.com',
        'david_lam@homedepot.com',
        'jereme_magsaysay1@homedepot.com',
        'jon_hartmann@homedepot.com',
        'mya_dean@homedepot.com',
        'nicholas_martin@homedepot.com',
        'alberto_fernandes@homedepot.com',
        'jordan_d_worsham@homedepot.com',
        'arun_cumaraswamy@homedepot.com',
        'jonathan_j_bliangas@homedepot.com',
        'karen_tang@homedepot.com',
        'joshua_fernandes@homedepot.com',
        'khushboo_punjabi@homedepot.com',
        'deirdre_mcging@homedepot.com',
        'catherine_belvedere@homedepot.com',
        'gavindeep_pandher@homedepot.com',
        'dhruv_kapoor@homedepot.com',
        'brynel_r_coutinho@homedepot.com',
        'matthew_w_spruiell@homedepot.com',
        'ohm_patel@homedepot.com',
        'javier_vassell1@homedepot.com',
        'rachael_astier@homedepot.com',
        'calvin_su@homedepot.com',
        'taylor_barnes@homedepot.com',
        'zachary_chocek@homedepot.com',
        'amanda_berglove@homedepot.com',
        'mackensie_kayler@homedepot.com',
        'matthew_j_takacs@homedepot.com',
        'bailey_edwards@homedepot.com',
      ];
      return this.isAdmin || ALLOW_LIST.indexOf(this.config.user.email.toLowerCase()) >= 0;
    };

    this.getReactCampaignBuilder = function() {
      const fullUrl = window.location.protocol + '//' + window.location.host;
      var brandId = config.store.id
      if (ctl.campaign.brand) brandId = ctl.campaign.brand.store.id
      var builderPath = '/r/' + brandId + '/campaign/builder/';
      if (ctl.campaign.id) {
        builderPath += ctl.campaign.id;
      }

      window.location.assign(fullUrl + builderPath);
    };
  }
]);
