angular.module('vantageApp').service('service.FacebookPixelEvents', function() {
  var service = this;

  service.purchase = function(value, content_ids, content_category, content_name, currency, order_id) {
    if (!value) {
      value = 0;
    }

    if (!content_category) {
      content_category = '';
    }

    if (!content_name) {
      content_name = '';
    }

    if (!currency) {
      currency = 'USD';
    }

    if (!order_id) {
      order_id = '';
    }

    if (typeof window.fbq !== 'undefined') {
      fbq('track', 'Purchase', {
        value: value,
        content_ids: content_ids,
        content_type: 'product',
        content_category: content_category,
        content_name: content_name,
        currency: currency,
        order_id: order_id,
      });
    }
  }
});
