angular.module('vantageApp').factory('model.Organization', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    var Organization = Model({
      currency: function() {
        return this.python_currency;
      },
      locale: function() {
        return this.python_locale;
      },
      displayName: function() {
        return this.name.replace(/- Home Depot /gi, '- ');
      }
    });

    return Organization.configure({
      url: '/core/api/v1/organization/',

      current: function() {
        if (config.org) {
          return this.get(config.org.id);
        }
      },

      search: function(query) {
        return this.all({
          name__icontains: query
        });
      }
    });
  }
]);
