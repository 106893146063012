angular.module('vantageApp').controller('controller.crossStoreCopy', [
  '$scope',
  '$http',
  '$element',
  'close',
  'action',
  'model.Organization',
  'va.config',
  function($scope, $http, element, close, action, Organization, config) {
    var ctl = this;

    $scope.loading = false;

    $scope.targetOrgId = null;
    $scope.storeTerm = config.storeTerm();

    $scope.$watch('ctl.query', _.debounce(function(query) {
      // This happens on page load so we load all orgs.
      if (typeof query === 'undefined') {
        Organization.all().then(function(allOrgs) {
          ctl.populate(allOrgs);
        })
      }

      Organization.search(query).then(function(newOrgs) {
        ctl.populate(newOrgs);
      });
    }, 200));

    $scope.canProceed = function() {
      return $scope.targetOrgId !== null;
    };

    ctl.close = function() {
      element.modal('hide');
      close();
    };

    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }
      ctl.close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      action($scope.targetOrgId);

      this.close();
    };

    // Helper for populating the list of organizations.
    this.populate = function(list) {
      ctl.list = list;
      ctl.index = null;
    };

    // Handler for selecting an organization.
    this.select = function(org) {
      $scope.targetOrgId = org.id;
    };
  }
]);
