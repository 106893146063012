angular.module('vantageApp').directive('bigCheckbox', [
  '$rootScope',
  function(rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/big-checkbox.html',
      scope: {
        model: '=',
        name: '=',
        message: '='
      }
    };
  }
]);
