angular.module('vantageApp').directive('campaignTopAds', [
  'service.DPASampleProducts',
  'model.FacebookAd',
  'model.Audience',
  'va.config',
  'lib.BrandNameExtractor',
  function(DPASampleProducts, FacebookAd, Audience, config, BrandNameExtractor) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-top-ads.html',
      scope: {
        campaign: '=',
        store: '=',
      },
      link: function(scope, element, attrs, model) {

        scope.campaign.audience = new Audience(scope.campaign.audience);
        var currentAudience = scope.campaign.audience;
        var isDpa = currentAudience.isDPA();
        var currentAdIndex = 0;
        var adsWithReport = _.filter(scope.campaign.allAds(), function(ad) {
          return Object.keys(ad.report).length > 0;
        });

        var ctr_field = scope.campaign.isFacebookOnly ? 'unique_ctr' : 'ctr';
        var report_ctr_field = 'report.' + ctr_field;
        if (currentAudience && currentAudience.funnel_stage && currentAudience.funnel_stage === 'ATTRACT') {
          scope.topAds = _.sortByOrder(adsWithReport,
              [report_ctr_field, "report.total_conversion_value", "report.clicks", "report.reach"],
              ["desc", "desc", "desc", "desc"]
          ).slice(0, 5);
        } else {
          scope.topAds = _.sortByOrder(adsWithReport,
              ["report.total_conversion_value", report_ctr_field, "report.clicks", "report.reach"],
              ["desc", "desc", "desc", "desc"]
          ).slice(0, 5);
        }

        var brandName = BrandNameExtractor.getBrandName(scope.campaign);
        var dpaProductFilter = null;

        if (brandName) {
          dpaProductFilter = {
            brand_name: brandName
          }
        }
        DPASampleProducts.populateSampleProducts(scope.topAds, dpaProductFilter);

        scope.selectAd = function(index) {
          currentAdIndex = index;
          scope.selectedAd = scope.topAds[index];
          scope.selectedAd.isDpa = isDpa;
        };

        scope.selectAd(currentAdIndex); // Show the best ad first.

        scope.nextAd = function() {
          currentAdIndex = (currentAdIndex + 1) % scope.topAds.length;
          scope.selectAd(currentAdIndex);
        };

        scope.prevAd = function() {
          currentAdIndex = (currentAdIndex - 1) % scope.topAds.length;
          if (currentAdIndex < 0) {
            currentAdIndex = scope.topAds.length - 1;
          }
          scope.selectAd(currentAdIndex);
        };

        scope.adIsSelected = function(ad) {
          return ad.id == scope.selectedAd.id;
        };

        scope.revenue_currency = config.store.revenue_currency;
      },
    };
  }
]);
