angular.module('vantageApp').factory('tile.OrderTimeline', [
  '$rootScope',
  'lib.Tile',
  '$q',
  '$timeout',
  '$filter',
  'va.config',
  function($rootScope, Tile, Promise, timeout, filter, config) {

    return Tile({
      name: 'order-timeline',

      loadData: function(response) {
        var tile = this;

        // The breakdowns and forecasting are only available to
        // the paid tiers, so the UI must reflect that.
        this.hasBreakdowns = $rootScope.hasFeature('revenue-breakdowns');
        this.hasForecast = $rootScope.hasFeature('forecasts');

        // Map the incoming data to the initial format we expect.
        this.initCharts(response.data);
        this.initSummaries(response.data);

        // Kick off the initial chart.
        this.changeBreakdown('order_count');
      },

      // Handles partial updates of the revenue data.
      refreshData: function(response) {
        var tile = this;
        var chartIdentifier = '.revenue-timeline-chart' + (tile.ownerId || "");

        _.forEach(response.data, function(breakdown, name) {
          var updatedPoint = tile.formatPoint.apply(tile, breakdown.current_period[0]),
              chart = this.$(chartIdentifier).highcharts();

          // We store the new values for all of the breakdowns, but only
          // dynamically add the point when the point is for the active chart.
          if (tile.charts[name]) {
            tile.charts[name].current_period.push(updatedPoint);

            // Remove all of the previous markers so the newest one
            // has the maker and none of the other ones.
            _.each(chart.series[0].data, function(point) {
              if (point.marker) {
                point.marker.enabled = false;
              }
            });

            if (name === tile.activeBreakdown) {
              chart.series[0].addPoint({
                x: updatedPoint[0],
                y: updatedPoint[1],
                marker: { enabled: true },
              });
            }

            var lastUpdateText;
            if (tile.storeIsExternal()) {
              lastUpdateText = 'Last data refresh';
            }
            else {
              lastUpdateText = 'Right now';
            }

            chart.series[2].setData([{
              x: updatedPoint[0],
              y: updatedPoint[1],
              title: lastUpdateText,
            }]);

            // We also get back a brand new forecast, so update that.
            tile.charts[name].forecast = _.map(breakdown.forecast, function(point) {
              return tile.formatPoint(point[0], point[1]);
            });

            if (tile.hasForecast && name === tile.activeBreakdown) {
              chart.series[3].setData(tile.charts[name].forecast);
            }

            // The endpoint only gives us back updated forecast and current
            // period data, so we have to make sure to reflect those new values.
            tile.summaries[name].current_period = breakdown.summary.current_period;
            tile.summaries[name].forecast = breakdown.summary.forecast;
          }
        });

        this.hasLoadedForecast = true;
        this.redrawSummary(this.activeBreakdown);
      },

      initSummaries: function(breakdowns) {
        var tile = this;

        // Similarly, map all of the summary data so we can display it.
        this.summaries = _.mapValues(breakdowns, function(breakdown) {
          return breakdown.summary;
        });

        this.hasLoadedForecast = true;
      },

      initCharts: function(breakdowns) {
        var tile = this;

        // Map all of the chart data into a sensible format
        // that we can easily turn into a highcharts chart.
        this.charts = _.mapValues(breakdowns, function(breakdown) {
          return _.chain(breakdown).pick([
            'current_period',
            'previous_period',
            'forecast'
          ]).mapValues(function(series, name) {
            var series = _.map(series, function(point) {
              if (name === 'previous_period') {
                return tile.formatPreviousPoint(point[0], point[1]);
              }

              return tile.formatPoint(point[0], point[1]);
            });

            if (name === 'current_period') {
              var lastPoint = series[series.length - 1];

              series[series.length - 1] = {
                x: lastPoint[0],
                y: lastPoint[1],
                marker: { enabled: true },
              };
            }

            // We have to add a starting point to both graphs so
            // they start at 0 on the first of the period.
            if (_.includes(['current_period', 'previous_period'], name)) {
              fiscal_start_month = config.store.fiscal_year_start_month || 1;
              series.unshift([tile.getStartDate(fiscal_start_month).valueOf(), 0]);
              series.push([tile.getEndDate(fiscal_start_month).valueOf(), null]);
            }

            return series;
          }).value();
        });
      },

      changeBreakdown: function(name) {
        this.redrawSummary(name);
        this.redrawChart(name);
        this.activeBreakdown = name;
      },

      redrawSummary: function(name) {
        if (!this.summaries[name] ) {
          throw new Error('Invalid summary name "' + name + '".');
        }

        var summary = this.summaries[name];
        var maxAmount = Math.max(summary.current_period, summary.forecast, summary.previous_period);

        this.summary = _.mapValues(summary, function(amount) {
          return {
            amount: amount,
            percent: amount ? parseInt(amount / maxAmount * 100) : 0,
          };
        });
      },

      redrawChart: function(name) {
        var tile = this;
        this.breakdownAvailable = true;

        if (!this.charts[name] ) {
          throw new Error('Invalid chart name "' + name + '".');
        }

        // Only the total revenue chart is available to free customers
        if (!this.hasBreakdowns && name !== 'total') {
          this.breakdownAvailable = false;
          return;
        } else {
          this.breakdownAvailable = true;
        }

        var chart = this.charts[name];

        var lastUpdateDate;
        var lastUpdateText;
        if (this.storeIsExternal()) {
          var coronaUpdatedAt = null;
          if ($rootScope.coronaLastUpdate) {
            coronaUpdatedAt = $rootScope.coronaLastUpdate[tile.ownerId];
          }

          lastUpdateDate = moment(coronaUpdatedAt).valueOf();
          lastUpdateText = 'Last data refresh';
        }
        else {
          var lastBucketDate = _.find(chart.current_period, function(point){return !_.isArray(point);})['x'];
          lastUpdateDate = moment(lastBucketDate).valueOf();
          lastUpdateText = 'Right now';
        }

        // We need this for the legend
        this.chart = {
          chart: {
            spacingLeft: 0,
            spacingBottom: 0,
            spacingRight: 0,
          },
          title: {
            text: ''
          },
          xAxis: {
            type: 'datetime',
            labels: {
              formatter: function() {
                var time = moment(this.value);

                return {
                  'daily': time.format('h:mma'),
                  'weekly': time.format('MMM Do'),
                  'monthly': time.format('MMM Do'),
                  'quarterly': time.format('MMM Do'),
                  'yearly': time.format('MMM Do'),
                }[tile.period];
              }
            }
          },
          yAxis: {
            title: {
              text: null
            },
            labels: {
              formatter: function() {
                return numbro(this.value).format('0,0');
              }
            },
            gridLineColor: '#edf1f5',
            tickAmount: 5,
            min: 0,
          },
          plotOptions: {
            series: {
              allowPointSelect: true,
              marker: {
                enabled: false,
              }
            }
          },
          tooltip: {
            shared: true,
            formatter: function() {
              var dateFormat = null;
              if (tile.period === 'daily') {
                dateFormat = 'MMMM Do YYYY, h:mma';
              } else {
                dateFormat = 'MMMM Do YYYY';
              }

              var s = '<b>' + moment(this.x).format(dateFormat) + '</b>';

              $.each(this.points, function () {
                  s += '<br/>' + this.series.name + ': ' + numbro(this.y).format('0,0') + ' Orders';
              });

              return s;
            }
          },
          legend: {
              enabled: false
          },
          credits: {
              enabled: false
          },
          series: [{
            id: 'current',
            name: filter('periodName')('current', this.period).capitalize(),
            type: 'spline',
            zIndex: 2,
            lineWidth: 3,
            color: '#3EB1C8',
            marker: {
                fillColor: '#3EB1C8',
                lineWidth: 0,
                lineColor: '#3EB1C8',
            },
            data: chart.current_period,
          },
          {
            name: filter('periodName')('previous', this.period).capitalize(),
            type: 'spline',
            zIndex: 1,
            lineWidth: 3,
            color: '#c5ced6',
            marker: {
              fillColor: '#c5ced6',
              lineWidth: 0,
              lineColor: '#c5ced6',
            },
            data: chart.previous_period,
          }, {
            type: 'flags',
            data: [{
              x: lastUpdateDate,
              title: lastUpdateText,
            }],
            onSeries: 'current',
            shape: 'squarepin',
            color: '#3EB1C8',
            lineWidth: 2,
            zIndex: 3,
            style: {
              color: '#3EB1C8',
              textAlign: 'center',
            }
          }]
        };

        if (this.hasForecast) {
          this.chart.series.push({
            name: 'Forecast',
            type: 'spline',
            zIndex: 2,
            dashStyle: 'shortdot',
            color: '#3EB1C8',
            marker: {
              fillColor: '#3EB1C8',
              lineWidth: 0,
              lineColor: '#3EB1C8',
            },
            data: chart.forecast,
          });
        }

        var chartIdentifier = '.revenue-timeline-chart' + (tile.ownerId || "");
        timeout(function() {
          $(chartIdentifier).highcharts(tile.chart);
        }, 0);
      },

      formatPreviousPoint: function(time, value) {
        var point = this.formatPoint(time, value);
        var time = moment(point[0]);

        // We have to get series data on the same X axis so that
        // the current and previous periods appear in the same position.
        switch (this.period) {
          case 'daily':
            time.add(1, 'day');
            break;
          case 'weekly':
            time.add(7, 'days');
            break;
          case 'monthly':
            time.add(1, 'month');
            break;
          case 'quarterly':
            time.add(3, 'month');
            break;
        case 'yearly':
            time.add(1, 'year');
            break;
        }

        return [time.valueOf(), point[1]];
      },

      formatPoint: function(time, value) {
        var time = moment(time);

        return [
          time.valueOf(),
          value !== null ? parseFloat(value) : null
        ];
      },
      storeIsExternal: function () {
        return $rootScope.isExternal()
      },
    });
  }
]);
