angular.module('vantageApp').controller('controller.CampaignDetails', [
  '$window',
  '$scope',
  '$http',
  '$location',
  'va.config',
  'campaign',
  'charges',
  'lib.Modal',
  'model.Store',
  'model.Audience',
  'store',
  function($window, $scope, $http, $location, config, campaign, charges, Modal, Store, Audience, store) {
    var ctl = this;
    var fullUrl = window.location.protocol + '//' + window.location.host;


    this.campaign = campaign;
    this.charges = charges;
    this.internalMessages = null;
    this.store = store;
    this.isResuming = false;
    this.isAdmin = $scope.hasFeature('admin');

    this.facebook_account_currency = config.store.facebook_account_currency;
    this.approved_steps = [];
    this.retailerIsHomeDepotCanada = store.retailerIsHomeDepotCanada();
    this.retailerIsHomeDepotUS = store.retailerIsHomeDepotUS() || store.name.includes("homedepot.com");
    this.showMediaAgreements = this.retailerIsHomeDepotCanada || this.retailerIsHomeDepotUS;
    this.isRetailerUser = config.user.isRetailerUser;
    this.isGoogleShoppingAudience = campaign.audience.type == 'ShoppingAudience';
    this.isEditingName = false;
    this.originalCampaignName = this.campaign.name;
    this.learningPhaseMessage = campaign.getLearningStateStatus();
    this.productCatalogDisplay = campaign.getProductCatalogDisplay();

    if (this.campaign.state === 'draft') {
      // This is the wrong page, redirect to the campaign edit page
      $location.url("/campaigns/edit/" + campaign.id);
    }
    this.showManagedServiceAlert = false;
    if (campaign.brand && campaign.brand.managed_service && (store.name.includes("homedepot.com") || store.name.includes("homedepot.ca"))) {
      this.showManagedServiceAlert = true;
    }

    if (campaign.audience) {
      campaign.setAudience(new Audience(campaign.audience));
    }

    if (this.campaign.schedule.startDate) {
      this.campaign.schedule.startDate = moment(this.campaign.schedule.startDate);
    }

    if (this.campaign.schedule.endDate) {
      this.campaign.schedule.endDate = moment(this.campaign.schedule.endDate);
    }

    if (this.retailerIsHomeDepotUS) {
      this.fullCampaignName = this.campaign.taxonomy_name || this.campaign.name;
    }

    if (ctl.campaign.isMarketingPackageCampaign){
      ctl.bidStrategyName = ctl.campaign.bidStrategy
      if (ctl.campaign.bidStrategy === 'CPC'){
          ctl.bidStrategyName = 'Cost Per Click (CPC)';
      }
      else if (ctl.campaign.bidStrategy === 'CPM'){
          ctl.bidStrategyName = 'Cost Per Thousand Impressions (CPM)';
      }
    }

    this.getStore = function() {
      if (ctl.campaign.brand && ctl.campaign.brand.store) {
        return ctl.campaign.brand.store;
      } else {
        return ctl.store;
      }
    }

    // Helper function to determine visibility of Edit/Pause/Cancel buttons.
    this.showControlButtons = function() {
      return (campaign.isRunning() || campaign.isPaused());
    };

    this.showChangeBudget = function() {
      if (!ctl.campaign.isEnded()) {
        return true;
      }

      return ctl.isAdmin;
    };

    this.showChangeSchedule = function() {
      return !(ctl.store.charge_for_campaigns && ctl.campaign.isEnded());
    };

    this.showBrandApprove = function() {
      return ctl.store.isCoopBrand() && (ctl.campaign.state === 'pending_brand_approval');
    };

    this.showCancel = function() {
      if (ctl.campaign.isEnded()) {
        return false;
      } else if (ctl.campaign.isConcierge && ctl.store.isCoopBrand()){
        return ctl.isAdmin;
      } else if (ctl.showRetailerButtons() || ctl.isRetailerUser) {
          return ctl.isAdmin;
      } else {
          return true;
      }
    }

    this.showRetailerButtons = function() {
      if (ctl.campaign.brand || ctl.retailerIsHomeDepotUS) {
        return true;
      }
    };

    this.showEdit = function() {
      var showEditToAdmins = ctl.isAdmin && !campaign.isCompleted() && !campaign.isCancelled() && ctl.store.hasAdvertisingPackage();
      var isHomeDepotCA = config.store.name.endsWith("- Home Depot CA") || config.store.name === "www.homedepot.ca";
      var isHomeDepotUS = config.store.name.endsWith("- Home Depot US") || config.store.name === "www.homedepot.com";
      var showEditToRetailer = (ctl.isRetailerUser && isHomeDepotCA || isHomeDepotUS) && (campaign.isDraft() || campaign.isPendingRetailerApproval());

      if (campaign.state === 'pending_brand_approval')
            return true;
      if (campaign.isConcierge && campaign.state !== 'pending_brand_approval' && ctl.store.isCoopBrand())
            return showEditToAdmins;

      return showEditToAdmins || showEditToRetailer;
    };

    this.showPauseResume = function() {
      return ctl.showControlButtons() && ctl.store.hasAdvertisingPackage();
    };

    // Opens a modal to allow the user to change their campaign's budget.
    this.changeBudget = function() {
      Modal.show({
        templateUrl: 'views/modal/change-budget.html',
        controller: 'controller.changeBudget',
        controllerAs: 'modal',
        inputs: {
          campaign: campaign,
          store: store
        }
      });
    };

    this.changeSchedule = function() {
      Modal.show({
        templateUrl: 'views/modal/change-schedule.html',
        controller: 'controller.changeSchedule',
        controllerAs: 'modal',
        inputs: {
          campaign: campaign,
          store: store,
          action: this.submitChangeSchedule
        }
      });
    };

    this.requestChanges = function() {
      Modal.show({
        templateUrl: 'views/modal/request-changes.html',
        controller: 'controller.requestChanges',
        controllerAs: 'modal',
        inputs: {
          campaign: campaign,
          store: store
        }
      });
    };

    this.cancelWithMessage = function() {
      Modal.show({
        templateUrl: 'views/modal/cancel-with-message.html',
        controller: 'controller.cancelWithMessage',
        controllerAs: 'modal',
        inputs: {
          campaign: campaign,
          store: store
        }
      });
    };

    // Opens a confirmation modal for the user, where they can confirm their decision and leave a comment.
    this.changeStateConfirmation = function(action) {
      Modal.show({
        templateUrl: 'views/modal/pause-cancel-campaign.html',
        controller: 'controller.pauseCancelCampaignModal',
        controllerAs: 'modal',
        inputs: {
          campaign: campaign,
          action: action,
          store: store,
        }
      });
    };

    this.editCampaign = function($event) {
      if (ctl.showEdit()) {
        $location.path('/campaigns/edit/' + ctl.campaign.id);
      }
    };

    this.showReactCampaignDetails = function() {
      if (this.isAdmin) {
         window.location.assign(fullUrl + '/r/' + config.store.id + '/campaign/details/' + ctl.campaign.id)
      }
    }

    this.toggleEditName = function($event) {
      this.isEditingName = !this.isEditingName;
      this.campaign.name = this.originalCampaignName;
    }

    this.submitEditName = function($event) {
      url = '/api/' + config.store.id + '/campaigns/' + ctl.campaign.id + '/';
      $http.patch(url, {'name': ctl.campaign.name}).then(function(resp) {
        ctl.alert = 'Campaign name changed successfully';
        $window.setTimeout(function() {
          $scope.$apply(function() {
            ctl.alert = null;
        });
        }, 3000);
      });
      ctl.isEditingName = false;
      ctl.originalCampaignName = ctl.campaign.name;
    }

    this.submitChangeSchedule = function($event) {
      url = '/api/' + config.store.id + '/campaigns/' + ctl.campaign.id + '/';
      data = {
        'startDate': ctl.campaign.schedule.startDate,
        'endDate': ctl.campaign.schedule.endDate
      };
      $http.patch(url, data).then(function(resp) {
        ctl.alert = 'Campaign schedule changed successfully';
        $window.setTimeout(function() {
          $scope.$apply(function() {
            ctl.alert = null;
        });
        if (ctl.campaign.state == 'completed' || ctl.campaign.state == 'terminated'){
          location.reload();
        }
        }, 3000);
      });
    }

    this.pauseCampaign = function() {
      this.changeStateConfirmation('pause');
    };

    this.approveCampaign = function() {
      campaign.brand_approved();
    };

    this.cancelCampaign = function() {
      this.changeStateConfirmation('cancel');
    };

    this.resumeCampaign = function() {
      if (ctl.isResuming) {
        return;
      }

      ctl.isResuming = true;
      ctl.campaign.resume();
    };

    this.getInternalMessages = function() {
      if (!this.internalMessages) {
        var messages = [];

        if (!this.campaign.optimizeOptIn) {
          messages.push('The customer has explicitly asked us not to optimize this campaign.');
        }

        this.internalMessages = messages;
      }

      return this.internalMessages;
    };

    this.showChargeSection = function() {
      // no charges in retailer/vendor views
      if (!this.store.charge_for_campaigns) {
          return false;
      }

      return true;
    };

    this.showCharges = function() {
      return this.charges.charges.length > 0;
    };

    this.showCampaignStats = function() {
      return campaign.state !== 'pending_retailer_approval';
    }

    this.showTopAds = function() {
      return !this.campaign.isApproved() && !this.campaign.audience.isShopping(); // Despite what the name implies this isn't "rejected"
    };

    this.showPlacementMetrics = function() {
      return campaign.state !== 'pending_retailer_approval';
    }

    this.approveStep = function(step_name) {
      is_approving = true;
      url = '/api/' + config.store.id + '/campaigns/' + ctl.campaign.id + '/' + step_name + '/approve';
      $http.post(url).then(function(resp) {
        ctl.is_approving = false;
        ctl.approved_steps.push(step_name);
        if (resp.data.new_state === 'pending_internal' || resp.data.new_state === 'pending_audience') {
          location.reload();
        }
      });
    }

  }
]);
