angular.module('vantageApp').controller('controller.AgreementSettings', [
  '$scope',
  'va.config',
  'store',
  'model.StoreDetails',
  function(
      $scope, config, store, StoreDetails
  ) {
    $scope.agreements = [];
    $scope.store = store;

    $scope.formatDate = function(date){
      return moment(date).format('MMMM Do YYYY');
    };
    var storeDetails = new StoreDetails({'store': store});

    storeDetails.mediaAgreements().then(function(details){
      $scope.agreements = details.agreements;
      }
    );
  }
]);
