angular.module('vantageApp').directive('campaignCopyDragBar', [
  '$document',
  '$window',
  'va.config',
  'service.CampaignCopy',
  'model.UserCopy',
  'model.PreviousCampaignCopy',
  function(document, window, config, CampaignCopy, UserCopy, PreviousCampaignCopy) {

    // These are shared amongst all instances of the campaignCopyDragBar
    var dragBarProperties = {
      copyList: [],
      dragBarExpanded: true,
      copyFilterSource: UserCopy.sources.VANTAGE, // The selected item in the legend
      copyFilterString: null,
      newCopy: null,
      supplementalCopyRequested: false,
      currentAudienceType: null,
    };

    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-copy-drag-bar.html',

      link: function(scope, element, attrs, model) {
        scope.dragBarProperties = dragBarProperties;
        scope.isDemoing = config.isDemoing();
        scope.isHomeDepot = scope.store.retailerIsHomeDepotCanada() || scope.store.retailerIsHomeDepotUS() || scope.store.domain.includes("homedepot");
        scope.copySources = UserCopy.sources; // Make constants available in view
        if (scope.isDemoing){
          delete scope.copySources.USER;
        }
        var $window = $(window);
        var $document = $(document);

        var DRAG_BAR_HEIGHT = 700;
        var MAGIC_OFFSET = 120;
        var SCROLL_OFFSET = 180;
        var campaign = scope.$eval(attrs.campaign);
        var isDPA = campaign.audience && campaign.isDPA && campaign.isDPA();

        // Each of these functions return a list of strings usable as ad copy
        var copyGettingFunctions = [
          CampaignCopy.getTitles,
          CampaignCopy.getRHSTitles,
          CampaignCopy.getBodies,
          CampaignCopy.getDPADescriptionTemplates,
          CampaignCopy.getDPANameTemplates,
          CampaignCopy.getDPAMessageTemplates
        ];

        $window.scroll(function() {
          var container = element.find(".drag-bar-container:visible");
          var $adsetAdsContainer = $(".adset-ads:visible");

          // Guard the scrolling just in case the container isn't visible yet
          if ($adsetAdsContainer[0]) {
            var minMargin = $adsetAdsContainer.offset().top;
            var maxMargin = $adsetAdsContainer.height();

            if ($adsetAdsContainer.height() < DRAG_BAR_HEIGHT) {
              container.css('margin-top', '0px');
            } else if ($document.scrollTop() >= (maxMargin + SCROLL_OFFSET)) {
              container.css('margin-top', (maxMargin - DRAG_BAR_HEIGHT) + 'px');
            } else if ($document.scrollTop() >= (minMargin - MAGIC_OFFSET)) {
              var margin = $document.scrollTop() - minMargin;
              margin = margin + MAGIC_OFFSET;
              container.css('margin-top', margin + 'px');
            } else {
              container.css('margin-top', '0px');
            }
          }
        });

        var clearCopySet = function() {
          dragBarProperties.copyList = [];
        };

        scope.addToCopySet = function(body, source) {
          // Don't bother if body isn't set
          if (!body) {
            return;
          }

          // Don't add any copy that has DPA elements, in non-DPA campaigns.
          if (
            !isDPA &&
            (
              body.indexOf('{{') >= 0 ||
              body.indexOf('}}') >= 0
            )
          ) {
            return;
          }

          var copy = new UserCopy({
            body: body,
            source: source
          });

          // Check to see if the copy is already in the Drag Bar. If so, do nothing.
          var existing = _.find(dragBarProperties.copyList, function(copy) {
            return copy.body == body;
          });
          if (!existing) {
            dragBarProperties.copyList.unshift(copy);
          }
        };

        var fetchCopy = function() {
          clearCopySet();

          // Fetch Vantage copy.
          _.forEach(copyGettingFunctions, function(copyListFunc) {
            _.forEach(copyListFunc(), function(title) {
              scope.addToCopySet(title, UserCopy.sources.VANTAGE);
            });
          });

          if (!scope.isDemoing) {
            UserCopy.all().then(function (allUserCopy) {
              _.forEach(allUserCopy, function (userCopy) {
                scope.addToCopySet(userCopy.body, UserCopy.sources.USER);
              });

              if (scope.isHomeDepot || !allUserCopy.length) {
                scope.dragBarProperties.copyFilterSource = UserCopy.sources.VANTAGE;
              }
              else {
                scope.dragBarProperties.copyFilterSource = UserCopy.sources.USER;
              }
            });
          }
        };

        // Only do this in one arbitrary instance so that we only make the requests once, or if the audience changes.
          var shouldFetchCopy = campaign.audience && campaign.audience.type !== dragBarProperties.currentAudienceType;
          if (!dragBarProperties.supplementalCopyRequested || shouldFetchCopy) {
          // Fetch the store's user, and previous campaign copy.
          fetchCopy();

          dragBarProperties.supplementalCopyRequested = true;
          if (campaign.audience) {
            dragBarProperties.currentAudienceType = campaign.audience.type;
          }
        }

        // Continuously fetch copy from ads in this campaign.
        var allAds = campaign.allAds();
        _.forEach(allAds, function(ad) {

          if (ad.type !== 'DISPLAY') {
            scope.addToCopySet(ad.creative.body, UserCopy.sources.USER);
            scope.addToCopySet(ad.creative.title, UserCopy.sources.USER);
            scope.addToCopySet(ad.creative.linkDescription, UserCopy.sources.USER);
          } else {
            scope.addToCopySet(ad.shortHeadline, UserCopy.sources.USER);
            scope.addToCopySet(ad.longHeadline, UserCopy.sources.USER);
            scope.addToCopySet(ad.description, UserCopy.sources.USER);
            scope.addToCopySet(ad.businessName, UserCopy.sources.USER);
          };
        });

        scope.toggleDragBar = function() {
          dragBarProperties.dragBarExpanded = !dragBarProperties.dragBarExpanded;
        };

        scope.filterCopyList = function(copy) {
          var filterString = dragBarProperties.copyFilterString;

          if (dragBarProperties.copyFilterSource !== "ALL") {
            if (copy.source != dragBarProperties.copyFilterSource) {
              return false; // Reject the other sources.
            }
          }

          if (filterString === undefined || !filterString){
            return true;
          }

          var filterRe = new RegExp(".*" + filterString.toLowerCase() + ".*");

          return filterRe.test(copy.body.toLowerCase());
        };

        scope.newCopy = function() {
          if (dragBarProperties.newCopy) {
            var newCopy = {body: dragBarProperties.newCopy, source: 'USER'};
            UserCopy.create(newCopy);

            scope.addToCopySet(newCopy.body, newCopy.source);
          }

          dragBarProperties.newCopy = null;
          $(".copy-list").animate({ scrollTop: 0 }, "fast");
        };

        // This sorts all copy alphabetically.
        scope.copyOrder = function(item) {
          return item.body;
        };

        scope.getCopySourceIcon = function(source) {
          return UserCopy.sourceIconMap[source];
        };

        var userIndex = (scope.store.isCoopBrand()) ? 1 : 0;
        var vantageIndex = (scope.isHomeDepot) ? 1 : 0;
        scope.getCopySourceName = function(source) {
          var index = (source.endsWith("USER")) ? userIndex : vantageIndex;
          return UserCopy.sourceNameMap[source][index];
        };

        scope.setCopyFilterSource = function(value) {
          dragBarProperties.copyFilterSource = value;
        };
      }
    };
  }
]);
