angular.module('vantageApp').controller('controller.ConnectStore', [
  '$scope',
  '$stateParams',
  '$location',
  'va.config',
  '$http',
  function($scope, $stateParams, $location, config, $http) {
    var ctl = this;

    this.shopDomain = "";
    ctl.platform = $stateParams.platform;
    this.shopifyAuthenticateUrl = function(shopDomain) {
      return '/shopify/authenticate/?shop=' + shopDomain;
    };

    this.bigcommerceAuthenticateUrl = function(shopDomain) {
      return '/bigcommerce/authenticate/?shop=' + shopDomain;
    };

    this.externalRegisterUrl = function(shopDomain) {
      return '/account/createExternal';
    };

    ctl.platformIsShopify = function() {
      return ctl.platform === "shopify";
    };

    ctl.platformIsBigcommerce = function() {
      return ctl.platform === "bigcommerce";
    };

    ctl.platformIsExternal = function() {
      return ctl.platform === "external";
    };

    ctl.platformIsMagento = function() {
      return ctl.platform === "magento";
    };

    ctl.connectStore = function() {
      var authenticateUrl = null;

      if (this.platformIsShopify()) {
        authenticateUrl = this.shopifyAuthenticateUrl(ctl.shopDomain);
      } else if (this.platformIsBigcommerce()) {
        authenticateUrl = this.bigcommerceAuthenticateUrl(ctl.shopDomain);
      } else if (this.platformIsExternal()) {
        authenticateUrl = this.externalRegisterUrl(ctl.shopDomain);
        params = {'domain': ctl.shopDomain};
        $http.post(authenticateUrl, params).then(
          function(response){
            window.location = response.data.redirect_url
          },
          function (response) {
            if (response.data.error === "NO_DOMAIN") {
              ctl.error = "Domain is required";
            } else if (response.data.error === "DOMAIN_IN_USE") {
              ctl.error = "Domain is already in use"
            }
          }
        );
        return;
      } else if (this.platformIsMagento() && ctl.selectedCount() === 0) {
        ctl.working = true;
        var params = {'domain': ctl.shopDomain};
        $http.get('/ecom/magento/fetch_stores', {params: params}).then(
          function(response) {
            ctl.errorCode = null;
            _.forEach(response.data.stores, function(magento_store) {
              magento_store.installed = _.contains(ctl.magentoInstalledStores, magento_store.store_key);
              ctl.magentoStores.push(magento_store);
            });
            ctl.working = false;
          },
          function(response) {
            // Something bad happened.
            // response.data contains an error code if we know what the issue might have been.
            ctl.errorCode = response.data;
            ctl.working = false;
          }
        );
      } else if (this.platformIsMagento() && ctl.selectedCount() > 0) {
        ctl.working = true;
        ctl.processedCount = 0;
        ctl.storeErrorCode = null;
        _.forEach(ctl.selectedStores(), function(store) {
          // If any store fails, stop processing since the rest are unlikely to succeed
          if (!ctl.storeErrorCode) {
            $http.post('/ecom/magento/register_from_vantage/  ', {
              name: store.name,
              domain: store.domain,
              store_id: store.id,
              site_id: store.site_id
            }).then(
              function(response) {
                store.registered = true;
                ctl.processedCount += 1;
                if (ctl.processedCount === ctl.selectedCount()) {
                  ctl.working = false;
                  // take them to the setup flow for the last registered store
                  window.location = '/?oid=' + response.data.organization_id + '#/setup';
                }
              }, function(response) {
                ctl.storeErrorCode = response.data;
                ctl.working = false;
              }
            );
          }
        });
      }

      if (authenticateUrl) {
        window.location = authenticateUrl;
      }

    };

    if ($stateParams.shopDomain !== '') {
        ctl.shopDomain = $stateParams.shopDomain;
        ctl.connectStore();
    }

    ctl.selectedStores = function() {
      return _.filter(ctl.magentoStores, function(store) {
        return store.selected;
      });
    };

    ctl.selectedCount = function() {
      return ctl.selectedStores().length;
    };

    if (ctl.platformIsMagento()) {
      ctl.magentoStores = [];
      ctl.secret = "Loading...";
      ctl.username = config.user.email;
      $http.get('/ecom/magento/secret/').then(function(response) {
        ctl.secret = response.data.secret;
        ctl.magentoInstalledStores = _.pluck(response.data.installations, 'store_key');
      });
    }

    ctl.disableConnect = function() {
      // Always disable if there is a request in progress
      if (ctl.working) {
        return true;
      }

      if (ctl.platformIsMagento()) {
        // Disable the button until they specify a domain
        // Or if they have specified a domain until they select at least one store to connect
        return !ctl.shopDomain || (ctl.shopDomain && ctl.magentoStores.length > 0 && ctl.selectedCount() === 0);
      } else {
        return false;
      }
    };

    ctl.connectButtonLabel = function() {
      if (ctl.working) {
        return "Working...";
      }
      if (ctl.platformIsMagento() && ctl.magentoStores.length === 0) {
        return "Next Step";
      }
      return "Connect";
    };

    ctl.startOver = function() {
      ctl.magentoStores = [];
      ctl.shopDomain = null;
    };
  }
]);
