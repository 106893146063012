angular.module('vantageApp').directive('adsetEditor', [
  '$rootScope',
  'service.CampaignCopy',
  'service.DPASampleProducts',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  'model.FacebookAd',
  'model.GoogleAd',
  'model.Product',
  'lib.BrandNameExtractor',
  function($rootScope, CampaignCopy, DPASampleProducts, AdvancedEcom, FacebookAd, GoogleAd, Product, BrandNameExtractor) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/adset-editor.html',
      require: 'ngModel',

      scope: {
        type: '=',
        store: '=',
      },

      link: function(scope, element, attrs, model) {
        // Initialize some variables
        scope.campaign = null;
        scope.adset = null;

        // The editor can be in various states which control the
        // user's interaction with it. Keep track of all of these.
        scope.isExpanded = true;
        scope.isEditing = false;
        scope.isDeleting = false;

        // Default sorting options, so ads appear in a repeatable manner.
        scope.ordering = [
          'sortOrder',

          // From now on, sortOrder will be the main method of sorting.
          // However for older ads that don't have the value, we use the old ordering.
          'creative.imageUrl',
          'creative.title',
          'creative.body',
          'creative.objectUrl',
          'creative.callToAction',
        ];

        // Called when the model is ready to work with.
        model.$render = function() {
          scope.campaign = model.$viewValue;
          scope.adset = scope.campaign.getAdset(scope.type);
          // If just opening the builder for the first time create an ad for each placement.
          if (scope.campaign.audience && scope.campaign.isDPA && scope.campaign.isDPA()) {
            AdvancedEcom.pickImages();
            AdvancedEcom.buildAds();
            if (scope.campaign.totalAds() === 0 && scope.type !== 'STORIES') {
              scope.newCarouselAd();
            } else {
              var brandName = BrandNameExtractor.getBrandName(scope.campaign);
              var dpaProductFilter = null;

              if (brandName) {
                dpaProductFilter = {
                  brand_name: brandName
                }
              }

              DPASampleProducts.populateSampleProducts(scope.adset.ads, dpaProductFilter);
              scope.render();
            }
          }
        };

        scope.$watch(function() {
          return scope.campaign;
        }, function() {
          scope.render();
        });

        // Kicks off the re-rendering process.
        scope.render = function() {
          scope.adset = scope.campaign.getAdset(scope.type);
          if(scope.type !=='DISPLAY'){
            updateCopySuggestions();
          }
        };

        // Helper to update the list of autocomplete suggestions.
        function updateCopySuggestions() {
          scope.copySuggestions = CampaignCopy.suggest(scope.type, scope.adset);
        }

        scope.adsetValid = function() {
          return scope.campaign.totalInvalidAdsInAdset(scope.type);
        };

        scope.adsetOverfull = function() {
          return scope.campaign.overfullAdset(scope.type);
        };

        scope.adsetContainsPristineAds = function() {
          if ( scope.store.retailerIsHomeDepotUS() || scope.store.retailerIsHomeDepotCanada() )
            return false;

          return scope.campaign.pristineAdset(scope.type);
        };

        scope.hasGoogleAdsNoGoogleAudience = function () {
          return scope.campaign.hasGoogleAdsNoGoogleAudience();
        };

        // Commits the adset to the campaign.
        scope.commitAdset = function() {
          scope.campaign.replaceAdset(scope.type, scope.adset);
          scope.commit();
        };

        scope.commit = function() {
          model.$setViewValue(scope.campaign);
        };

        scope.showEdit = function () {
          return !(scope.type == 'DISPLAY' && scope.campaign.audience && scope.campaign.isDPA && scope.campaign.isDPA());
        };

        scope.showDelete = function () {
          return !(scope.type == 'DISPLAY' && scope.campaign.audience && scope.campaign.isDPA && scope.campaign.isDPA());
        };

        scope.showCarousel = function () {
          return !(scope.type == 'RIGHT' || scope.type == 'DISPLAY') && scope.selectedAds().length == 1 && scope.selectedAds()[0].creative.creativeType != 'CAROUSEL';
        };
      },

      controller: ['$scope', function(scope) {
        var ctl = this;

        // Delete section
        scope.delete = function(ads) {
          if (scope.isEditing || scope.isDeleting) {
            return;
          }

          var selections = _.map(getSelections(ads), function(ad) {
            ad.isSelected = true;
          });

          scope.isDeleting = getSelections(ads);
        };

        ctl.cancelDelete = function() {
          scope.isDeleting = null;
          ctl.selectNone();
        };

        ctl.confirmDelete = function() {
          scope.adset = _.reject(scope.adset.ads, 'isSelected');
          scope.isDeleting = null;
          ctl.selectNone();
          scope.commitAdset();
          scope.render();
        };

        ctl.carousel = function (){
          var carouselAd = scope.selectedAds()[0];
          scope.edit(carouselAd, true);
        }
        scope.carousel = this.carousel;
        scope.previousType = null;


        // Edit section
        ctl.edit = function(ads, carousel) {
          if (scope.isEditing || scope.isDeleting) {
            return;
          }
          scope.isEditing = getSelections(ads);
          if (carousel){
            var carouselAd = scope.isEditing[0];
            scope.previousType = carouselAd.creative.creativeType;
            var emptyVideo = {
                'videoId': '',
                'videoFileUrl': '',
                'videoFacebookSourceUrl': '',
                };
            var video = {};

            _.forEach(Object.keys(emptyVideo),  function(item){
                video[item] = carouselAd.creative[item];
            });
            var cards = [{'imageUrl': carouselAd.creative.imageUrl,
                          'video': video,
                          'link': carouselAd.creative.objectUrl
                         },
                         {'imageUrl': '',
                          'video': emptyVideo,
                          'link': carouselAd.creative.objectUrl
                         }];
            carouselAd.creative.creativeType = 'CAROUSEL';
            carouselAd.creative.carouselCards = cards;
          }

        };

        scope.edit = this.edit;

        ctl.confirmEdit = function(updates) {
          scope.adset = _.map(scope.adset.ads, function(ad) {
            if (ad.isSelected) {
              if (scope.previousType){
                scope.previousType = null;
                updates['creative']['creativeType'] = ad.creative.creativeType;
              }
              _.merge(ad, updates);
              ad.isPristine = false;
              ad.isCopied = false;
              if (ad.review_status === 'APPROVED') {
                scope.campaign.setReviewStatus([ad], 'REVIEW_NEEDED');
              }
            }

            return ad;
          });

          scope.isEditing = null;
          scope.commitAdset();
          scope.render();
          ctl.selectNone();
        };

        ctl.cancelEdit = function() {
          if (scope.previousType){
            var carouselAd = scope.isEditing[0];
            delete carouselAd.creative.carouselCards;
            carouselAd.creative.creativeType = scope.previousType;
            scope.previousType = null;
          }
          scope.isEditing = null;
          ctl.selectNone();
        };

        scope.newCarouselAd = function() {
          var copyIndex = _.random(lower=0, upper=3); // Very few titles to choose from.
          if ( scope.campaign.lineItem && scope.campaign.lineItem.creativeHeadline ) {
            var titles = CampaignCopy.getTitles();
            for ( var i = 0; i < titles.length; i++ ) {
              if ( titles[i].toLowerCase().indexOf(scope.campaign.lineItem.creativeHeadline.toLowerCase()) >= 0 ) {
                copyIndex = i;
                break;
              }
            }
          }

          var brandName = BrandNameExtractor.getBrandName(scope.campaign);

          var params = {
            is_available: true,
            search: scope.productQuery,
          };

          if (brandName) {
            params['brand_name'] = brandName;
          }

          Product.all(params).then(function(products) {
            if(scope.type !== 'DISPLAY') {
              if (!scope.campaign.audience.isAvailableFacebook()) {
                return;
              }

              var ad = new FacebookAd({
                'type': scope.type,
                'isDpa': true,
                'isPristine': true,
                'isCopied': false,
                'sortOrder': scope.campaign.generateAdOrderNumber(scope.type),
              });

              if (scope.type === 'RIGHT') {
                ad.creative.title = CampaignCopy.getRHSTitle(copyIndex);
              } else {
                ad.creative.title = CampaignCopy.getDpaTitle(copyIndex);
              }

              ad.creative.body = CampaignCopy.getDpaBody(copyIndex);
              ad.creative.linkDescription = CampaignCopy.getDpaDescription(copyIndex);
              ad.creative.callToAction = 'SHOP_NOW';

              ad.creative.objectUrl = scope.store.retailer_domain || scope.store.preferred_domain || scope.store.domain;

              var facebook_page = scope.store.primary_facebook_page || scope.store.retailer_facebook_page;
              if (facebook_page && facebook_page.facebook_id) {
                ad.creative.pageId = facebook_page.facebook_id;
              }
            } else {
              if (!scope.campaign.audience || !scope.campaign.audience.isAvailableGoogle()) {
                return;
              }

              var shortHeadline = 'Shop ' + scope.store.name;
              var longHeadline = 'Shop ' + scope.store.name;
              var description = 'Shop ' + scope.store.name;
              var businessName = scope.store.name;
              if (scope.store.isCoopBrand()) {
                shortHeadline = 'Shop ' + scope.store.coopBrandName();
                longHeadline = 'Shop ' + scope.store.coopBrandName() + '. Available at ' + scope.store.coopRetailerDisplayName() + '.';
                description = 'Shop ' + scope.store.coopBrandName() + '. Available at ' + scope.store.coopRetailerDisplayName() + '.';
                businessName = scope.store.coopRetailerDisplayName();
              }
              var ad = new GoogleAd({
                'type': 'DISPLAY',
                'marketingImage': scope.store.logo_image_url || scope.store.retailer_logo_image_url,
                'finalUrl': scope.store.retailer_domain || scope.store.domain,
                'shortHeadline': shortHeadline,
                'longHeadline': longHeadline,
                'description': description,
                'businessName': businessName,
                'callToAction': 'Shop Now',
                'isPristine': false,
                'isCopied': false,
                'sortOrder': 1,
                'isDpa': true,
              });
            }

            DPASampleProducts.setSampleProducts(products, ad, 2);

            // Push the ad to the campaign and render
            scope.campaign.pushAd(ad);
            scope.render();
            ctl.selectNone();
          });
        };

        // Helper section
        ctl.toggleAdSelection = function(ad) {
          /**
           * Toggles the selection state of an individual ad.
           *
           * This is called by child <adset-ad>'s which need to let the
           * editor know when they've been selected by the user.
           */
          if (scope.isEditing || scope.isDeleting) {
            return;
          }
          ad.isSelected = !ad.isSelected;
        };

        ctl.copyAd = function(originalAd) {
          var newAd;
          if(originalAd.type == 'DISPLAY') {
            newAd = new GoogleAd({
              'type': 'DISPLAY',
              'marketingImage': originalAd.marketingImage,
              'finalUrl': originalAd.finalUrl,
              'shortHeadline': originalAd.shortHeadline,
              'longHeadline': originalAd.longHeadline,
              'description': originalAd.description,
              'businessName': originalAd.businessName,
              'return_on_ad_spend': originalAd.return_on_ad_spend,
              'callToAction' : originalAd.callToAction,
              'isPristine': false,
              'isCopied': true,
              'sortOrder': scope.campaign.generateAdOrderNumber(originalAd.type),
            });
          }
          else {
            newAd = new FacebookAd({
              'type': originalAd.type,
              'isDpa': originalAd.isDpa,
              'isVideoAd': originalAd.isVideoAd,
              'isPristine': false,
              'isCopied': true,
              'sortOrder': scope.campaign.generateAdOrderNumber(originalAd.type),
            });

            newAd.creative = {
              creativeType: originalAd.creative.creativeType,
              title: originalAd.creative.title,
              body: originalAd.creative.body,
              imageUrl: originalAd.creative.imageUrl,
              resizedImageUrl: originalAd.creative.resizedImageUrl,
              videoId: originalAd.creative.videoId,
              videoFileUrl: originalAd.creative.videoFileUrl,
              videoFacebookSourceUrl: originalAd.creative.videoFacebookSourceUrl,
              objectUrl: originalAd.creative.objectUrl,
              callToAction: originalAd.creative.callToAction,
              linkDescription: originalAd.creative.linkDescription,
              pageId: originalAd.creative.pageId,
              carouselOptimized: originalAd.creative.carouselOptimized,
              carouselEndCard: originalAd.creative.carouselEndCard,
            };

            if (originalAd.creative.carouselCards){
              newAd.creative.carouselCards = []
              _.forEach(originalAd.creative.carouselCards, function(card){
                var video = {
                  'videoId': '',
                  'videoFileUrl': '',
                  'videoFacebookSourceUrl': '',
                };
                _.forEach(Object.keys(video),  function(item){
                  video[item] = card.video[item];
                });
                var newCard = {
                  'title': card.title,
                  'description': card.description,
                  'link': card.link,
                  'imageUrl': card.imageUrl,
                  'video': video,
                }
                newAd.creative.carouselCards.push(newCard);
              });
            }

            if (newAd.isDpa) {
              newAd.sampleProducts = originalAd.sampleProducts;
            }
          }
          scope.campaign.pushAd(newAd);
          scope.render();
          ctl.selectNone();
        };

        // Helper for selecting none of the ads in a set
        ctl.selectNone = function() {
          scope.adsets = _.map(scope.adset.ads, function(ad) {
            return _.assign(ad, {isSelected: false});
          });
        };

        scope.toggleExpandedView = function() {
          scope.isExpanded = !scope.isExpanded;
        };

        scope.selectedAds = function() {
          return _.filter(scope.adset.ads, 'isSelected');
        };

        // Returns a list of selected items to work with.
        function getSelections(defaults) {
          var selected = _.filter(scope.adset.ads, 'isSelected');

          if (selected.length) {
            return selected;
          }

          return _.map(defaults, function(ad) {
            return _.assign(ad, { isSelected: true, isDeleting: false });
          });
        }

        scope.isAdmin = function() {
          return $rootScope.hasFeature('admin');
        };

      }]
    };
  }
]);
