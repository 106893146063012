angular.module('vantageApp').controller('controller.changeSchedule', [
  '$scope',
  '$http',
  'campaign',
  '$element',
  'close',
  'store',
  'action',
  'model.CampaignSchedule',
  function($scope, $http, campaign, element, close, store, action, CampaignSchedule) {
    var ctl = this;

    $scope.campaign = campaign;
    $scope.budget = $scope.campaign.budget;
    ctl.originalSchedule =  new CampaignSchedule(campaign.schedule);

    $scope.loading = false;
    $scope.error = false;
    $scope.store = store;

    $scope.canProceed = function() {
      return true;
    };

    ctl.close = function() {
      element.modal('hide');
      close();
    };

    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }
      $scope.campaign.schedule = ctl.originalSchedule;
      ctl.close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      action();

      $scope.loading = true;

      this.close();
    };


  }
]);
