angular.module('vantageApp').service('service.BudgetEstimate', [
  '$http',
  'model.CampaignBudget',
  'model.FacebookAd',
  'va.config',
  function($http, CampaignBudget, FacebookAd, config) {
    var service = this;

    service.getMinimumBudget = function(totalSpend, defaultMinimum) {
      return +Math.max(defaultMinimum, totalSpend || 0).toFixed(2)
    }

    service.getBudgetEstimate = function(campaign) {
      var params = {
        audience_id: campaign.audience.id,
        audience_type : campaign.audience.type,
        campaign_length : campaign.schedule.getDuration()
      };

      // There are a few cases where we don't need to make a call to the estimator
      const minimum = this.getMinimumBudget(campaign.budget.totalSpend, 500)

      var data = null

      if (campaign.audience.type === 'GenericProspecting') {
        data = { budget: 500.0,  minimum: this.getMinimumBudget(campaign.budget.totalSpend, 500) };
      } else if (campaign.audience.type === 'GenericRetargeting') {
        data = { budget: 500.0,  minimum: minimum };
      } else if (campaign.audience.type === 'ShoppingAudience') {
        data = {budget: 1000.0,  minimum: minimum };
      } else if (config.isDemoing()) {
        // We don't need a real number in a demo
        data = { budget: 2500.0, minimum: 2000.00 };
      }

      if (data) {
        return Promise.resolve({data: data});
      }

      if (campaign.getAdset(FacebookAd.RIGHT_SIDE).ads.length > 0) {
        params["rhs_adset_enabled"] = "true";
      }
      if (campaign.getAdset(FacebookAd.NEWSFEED).ads.length > 0) {
        params["newsfeed_adset_enabled"] = "true";
      }
      if (campaign.getAdset(FacebookAd.MOBILE).ads.length > 0) {
        params["mobile_adset_enabled"] = "true";
      }
      if (campaign.getAdset(FacebookAd.INSTAGRAM).ads.length > 0) {
        params["instagram_adset_enabled"] = "true";
      }
      if (campaign.getAdset("DISPLAY").ads.length > 0 || campaign.getAdset("SHOPPING").ads.length > 0) {
        params["google_enabled"] = "true";
      }
      if (!campaign.hasAdsets()) {
        // If an image has not been selected we should price with all
        // adsets so that we don't show them a low price and make it jump.
        params["rhs_adset_enabled"] = "true";
        params["newsfeed_adset_enabled"] = "true";
        params["mobile_adset_enabled"] = "true";
        params["instagram_adset_enabled"] = "true";
        if (campaign.audience && typeof campaign.audience.isAvailableGoogle == 'function' && campaign.audience.isAvailableGoogle()) {
          params["google_enabled"] = "true";
        }
      }

      return $http.get(CampaignBudget.url, {
        params: params
      });
    };

    service.updateBudgetEstimate = function(scope, newEstimate, newMinimum) {
      scope.budget.estimate = newEstimate;
      scope.budget.recommended = newEstimate;
      scope.budget.minimum = this.getMinimumBudget(scope.budget.totalSpend, newMinimum);

      if (scope.budget.overrideActual) {
        scope.budget.actual = newEstimate;
      }
    };

    service.updateState = function(scope) {
      if (scope.budget.isNaN()) {
        scope.state = 'NaN';
      } else if (scope.budget.isBelowMinimum()) {
        scope.state = 'minimum';
      } else if (scope.budget.isLow()) {
        scope.state = 'low';
      } else if (scope.budget.isHigh()) {
        scope.state = 'high';
      } else {
        scope.state = 'recommended';
      }
    };

  }
]);
