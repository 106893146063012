angular.module('vantageApp').service('service.FacebookPage', [
  '$window',
  'model.StoreDetails',
  function($window, StoreDetails) {
    service = this;

    URL_PREFIX = "https://";
    FACEBOOK_BASE_URL = "www.facebook.com/";
    ADMIN_PAGE_SUFFIX = "/settings/?tab=admin_roles";
    SETTINGS_PAGE_SUFFIX = "/settings/?tab=settings";
    INSTAGRAM_SETTINGS_PAGE_SUFFIX = "/settings/?tab=instagram_ads";

    service.getURL = function(store) {
      var storeDetails = new StoreDetails({'store': store});

      return storeDetails.primaryFacebookPage().then(function(details) {
        if(details.page_url){
          return details.page_url
        }

        var pageName = details.name;

        if (pageName && store.primary_facebook_page && store.primary_facebook_page.facebook_id) {
          pageName = pageName.replace(/ /g, "");
          return [URL_PREFIX, FACEBOOK_BASE_URL, pageName, "-", store.primary_facebook_page.facebook_id].join('');
        }

        return pageName
      });
    };

    service.getApproveURL = function(facebookPageUrl) {
      if (facebookPageUrl) {
        var businessPageUrl = facebookPageUrl.replace('www.', 'business.');
        return businessPageUrl + ADMIN_PAGE_SUFFIX;
      }
    };

    service.getSettingsURL = function(facebookPageUrl) {
      var businessPageUrl = facebookPageUrl.replace('www.', 'business.');
      return businessPageUrl + SETTINGS_PAGE_SUFFIX;
    };

    service.getInstagramSettingsURL = function(facebookPageUrl) {
      var businessPageUrl = facebookPageUrl.replace('www.', 'business.');
      return businessPageUrl + INSTAGRAM_SETTINGS_PAGE_SUFFIX;
    };

    service.sanitizeURL = function(facebookPageUrl) {
      if (facebookPageUrl.indexOf("http") === -1) {
        facebookPageUrl = URL_PREFIX + facebookPageUrl;
      }

      if(facebookPageUrl.substr(-1) === '/') {
        facebookPageUrl = facebookPageUrl.substr(0, facebookPageUrl.length - 1);
      }

      return facebookPageUrl;
    };

    service.openAdmin = function(facebookPageUrl) {
      $window.open(service.getApproveURL(facebookPageUrl));
    };

  }
]);
