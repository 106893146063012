angular.module('vantageApp').controller('controller.CampaignList', [
  '$scope',
  '$rootScope',
  '$location',
  'va.config',
  'campaigns',
  'audiences',
  'store',
  function($scope, $rootScope, $location, config, campaigns, audiences, store) {
    var ctl = this;
    this.campaigns = campaigns;
    this.audiences = audiences;
    this.store = store;
    this.searchText = null;
    this.revenue_currency = config.store.revenue_currency;
    this.facebook_account_currency = config.store.facebook_account_currency;
    this.has_budget_recommendation = config.store.has_budget_recommendation;
    this.retailerIsHomeDepotCanada = store.retailerIsHomeDepotCanada();
    this.retailerIsHomeDepotUS = store.retailerIsHomeDepotUS() || store.name.includes("homedepot.com");
    this.showMediaAgreements = this.retailerIsHomeDepotCanada;
    this.showBudgetRecommendation = this.has_budget_recommendation && !this.retailerIsHomeDepotUS;
    this.showSalesFunnel = !$rootScope.isRetailer() && !this.retailerIsHomeDepotUS && !this.has_budget_recommendation;
    this.showCampaignExport = !$rootScope.isRetailer() && !this.retailerIsHomeDepotUS;

    $scope.isDemoing = config.isDemoing;
    // Returns true if the store has any available audiences
    // audience.isAvailable() will return true always when in demo mode
    this.hasAvailableAudiences = function() {
      if (config.isDemoing()) {
        return true;
      }
      return _.some(
        this.audiences,
        function(audience) {
          return audience.isAvailable();
        }
      );
    };

    const runningStates = ['running', 'pending_tracking_setup', 'pending_brand_approval', 'pending_retailer_approval', 'pending_internal', 'pending_external', 'pending_audience', 'rejected'];
    const endedStates = ['completed', 'terminated'];
    const pausedStates = ['paused', 'system_paused'];
    const needsRetailerApprovalStates = ['pending_retailer_approval', ];

    this.setFilter = function(filter) {
      this.activeFilter = filter;

      if (filter === 'all') {
        ctl.filterStates = [];
      } else if (filter === 'ended') {
        ctl.filterStates = endedStates
      } else if (filter === 'running') {
        ctl.filterStates = runningStates;
      } else if (filter === 'paused') {
        ctl.filterStates = pausedStates;
      } else if (filter === 'needs_retailer_approval') {
        ctl.filterStates = needsRetailerApprovalStates;
      } else {
        ctl.filterStates = [filter];
      }
    };

    $scope.getCampaignFilter = function (brandName) {
      var filter = function (campaign) {
        var brandNameFilter = true;
        if (brandName && campaign.brand && campaign.brand.name && brandName !== campaign.brand.name){
          brandNameFilter = false;
        }

        var stateFilter = !ctl.filterStates || ctl.filterStates.length === 0 || _.contains(ctl.filterStates, campaign.state);
        var searchFilter = !ctl.searchText || ctl.searchText.length < 3 ||
          campaign.name.toLowerCase().indexOf(ctl.searchText.toLowerCase()) > -1 ||
          (campaign.brand && campaign.brand.name.toLowerCase().indexOf(ctl.searchText.toLowerCase()) > -1);
        return stateFilter && searchFilter && brandNameFilter;
      };
      return filter;
    };

    var runningCampaigns = this.campaigns.filter(function(campaign) {
        return _.contains(runningStates, campaign.state);
    });

    if (runningCampaigns.length > 0 && !this.retailerIsHomeDepotUS) {
        this.setFilter('running');
    } else {
        this.setFilter('all');
    }

    $scope.getBrands = function () {
      var brands = {};

      _.each(ctl.campaigns, function (campaign) {
        if (campaign.brand && campaign.brand.name && $scope.getCampaignFilter()(campaign)) {
          brands[campaign.brand.name] = null;
        }
      });

      return Object.keys(brands);
    };

    $scope.parseBrandName = function (brandName) {
      var lastDashIndex = brandName.lastIndexOf(" - ");
      if(lastDashIndex > -1) {
        return brandName.substring(0, lastDashIndex);
      }

      return brandName;
    };



    $scope.isAdmin = function() {
      return $scope.hasFeature('admin');
    };

    function sumCampaignData(getData, skipFilters, brandName) {
      if (_.isUndefined(skipFilters)) {
          skipFilters = false;
      }

      if (_.isUndefined(brandName)) {
          brandName = null;
      }


      totalValue = 0;
      _.each(ctl.campaigns, function(campaign) {
        // Only include the value if skipFilters is true,
        // or skipFilters is false and the campaign meets the filter criteria.
        if (skipFilters || $scope.getCampaignFilter(brandName)(campaign)) {
          totalValue += parseFloat(getData(campaign) || 0);
        }
      });
      return totalValue;
    }

    this.totalBudget = function(skipFilters) {
      return sumCampaignData(function(campaign) { return campaign.budget.actual; }, skipFilters);
    };

    this.totalSpend = function(skipFilters) {
      return sumCampaignData(function(campaign) { return campaign.aggregate_report.spend; }, skipFilters);
    };

    this.totalUSDSpend = function (skipFilters) {
      return sumCampaignData(function (campaign) {
        return campaign.aggregate_report.spend / campaign.facebook_account_currency_exchange_rate;
      }, skipFilters);
    };

    this.totalRevenue = function(skipFilters) {
      return sumCampaignData(function(campaign) { return campaign.aggregate_report.total_conversion_value; }, skipFilters);
    };

    this.totalUSDRevenue = function(skipFilters) {
        return sumCampaignData(function (campaign) {
            return campaign.aggregate_report.total_conversion_value / campaign.exchange_rate;
        }, skipFilters);
    };

    this.totalROAS = function(skipFilters) {
      if (ctl.totalUSDSpend(skipFilters) > 0) {
        return ctl.totalUSDRevenue(skipFilters) / ctl.totalUSDSpend(skipFilters);
      }
    };

    this.totalBrandBudget = function(brandName) {
      return sumCampaignData(function(campaign) { return campaign.budget.actual; }, false, brandName);
    };

    this.totalBrandSpend = function(brandName) {
      return sumCampaignData(function(campaign) { return campaign.aggregate_report.spend; }, false, brandName);
    };

    this.totalBrandUSDSpend = function(brandName) {
      return sumCampaignData(function(campaign) {
        return campaign.aggregate_report.spend / campaign.facebook_account_currency_exchange_rate;
        }, false, brandName);
    };

    this.totalBrandRevenueForBrand = function(brandName) {
      return sumCampaignData(function (campaign) {
        if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.brand) {
          return campaign.aggregate_report.coop_breakdown.brand.total_conversion_value;
        } else {
          return 0;
        }
      }, false, brandName);
    };

    this.totalBrandUSDRevenueForBrand = function(brandName) {
      return sumCampaignData(function (campaign) {
        if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.brand) {
          return campaign.aggregate_report.coop_breakdown.brand.total_conversion_value / campaign.exchange_rate;
        } else {
          return 0;
        }
      }, false, brandName);
    };

    this.totalBrandROASForBrand = function(brandName) {
      if (ctl.totalBrandUSDSpend(brandName) > 0) {
        return ctl.totalBrandUSDRevenueForBrand(brandName) / ctl.totalBrandUSDSpend(brandName);
      }
    };

    this.totalRetailerRevenueForBrand = function(brandName) {
      return sumCampaignData(function(campaign) {
        if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
          return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value;
        } else {
          return 0;
        }
      }, false, brandName);
    };

    this.totalUSDRetailerRevenueForBrand = function(brandName) {
        return sumCampaignData(function (campaign) {
          if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
            return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value / campaign.exchange_rate;
          } else {
            return 0;
          }
        }, false, brandName);
    };

    this.totalRetailerROASForBrand = function(brandName) {
      if (ctl.totalBrandUSDSpend(brandName) > 0) {
        return ctl.totalUSDRetailerRevenueForBrand(brandName) / ctl.totalBrandUSDSpend(brandName);
      }
    };

    this.allBrandCampaignsOngoing = function(brandName) {
      allOngoing = true;
      _.each(ctl.campaigns, function(campaign) {
        if ($scope.getCampaignFilter(brandName)(campaign)) {
          allOngoing = allOngoing && campaign.schedule.isOngoing();
        }
      });
      return allOngoing;
    };

    this.isCoop = function() {
      return _.filter(ctl.campaigns, function(campaign) { return campaign.brand }).length > 0;
    };

    this.totalBrandUSDRevenue = function() {
      return sumCampaignData(function (campaign) {
        if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.brand) {
          return campaign.aggregate_report.coop_breakdown.brand.total_conversion_value / campaign.exchange_rate;
        } else {
          return 0;
        }
      }, false);
    };

    this.totalBrandROAS = function() {
      if (ctl.totalUSDSpend() > 0) {
        return ctl.totalBrandUSDRevenue() / ctl.totalUSDSpend();
      }
    };

    this.totalRetailerRevenue = function(skipFilters) {
      return sumCampaignData(function(campaign) {
        if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
          return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value;
        } else {
          return 0;
        }
      }, skipFilters);
    };

    this.totalUSDRetailerRevenue = function(skipFilters) {
        return sumCampaignData(function (campaign) {
          if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
            return campaign.aggregate_report.coop_breakdown.retailer.total_conversion_value / campaign.exchange_rate ;
          } else {
            return 0;
          }
        }, skipFilters);
    };

    this.totalRetailerROAS = function(skipFilters) {
      if (ctl.totalUSDSpend(skipFilters) > 0) {
        return ctl.totalUSDRetailerRevenue(skipFilters) / ctl.totalUSDSpend(skipFilters);
      }
    };

    this.allCampaignsOngoing = function() {
      allOngoing = true;
      _.each(ctl.campaigns, function(campaign) {
        if ($scope.getCampaignFilter()(campaign)) {
          allOngoing = allOngoing && campaign.schedule.isOngoing();
        }
      });
      return allOngoing;
    };

    this.allCampaignsFacebook = function() {
      var facebookOnly = true;

      _.each(ctl.campaigns, function(campaign) {
        if (!campaign.isFacebookOnly){
          facebookOnly = false;
        }
      });

      return facebookOnly
    }
  }
]);
