angular.module('vantageApp').factory('model.DigestRecipient', [
  'lib.Model',
  function(Model) {
    return Model({
      // Nothing to do here for now
    }).configure({
      url: '/ecom/api/v1/digest_recipient/',
    });
  }
]);
