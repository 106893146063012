angular.module('vantageApp').directive('mediaAgreementsSummary', [
  'model.StoreDetails',
  function(
    StoreDetails
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/media-agreements-summary.html',
      scope: {
        store: '=',
      },
      link: function (scope) {
        scope.totalAgreementAmount = 0;
        scope.totalUnallocatedAmount = 0;

        var storeDetails = new StoreDetails({'store': scope.store});
        storeDetails.mediaAgreements().then(
          function (details) {
            scope.totalAgreementAmount = details.total_allocation;
            scope.totalUnallocatedAmount = details.unallocate_amount;
          }
        );
      }
    };
  }
]);
