angular.module('vantageApp').controller('controller.addPackageModal', [
  '$scope',
  '$element',
  'close',
  'action',
  function($scope, $element, close, action) {

    $scope.loading = false;

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      action();
    };

  }
]);
