angular.module('vantageApp').directive('metric', [
  'model.DatasetMetric',
  'lib.Modal',
  '$rootScope',
  function(DatasetMetric, Modal, rootScope) {
    return {
      restrict: 'E',
      template: '<ng-include src="template"></ng-include>',
      scope: {
        metric: '=?',
        store: '=?',
        onboarding: '@?',
      },

      link: function(scope) {
        // Right now we can either show the metric or
        // a placeholder for onboarding scenarios
        if (scope.onboarding) {
          scope.template = 'views/directives/metric/onboarding.html';
        } else {
          scope.template = 'views/directives/metric/default.html';
        }
      },

      controller: ['$scope', function(scope) {
        var metric = scope.metric,
            store = scope.store;

        // No need to calculate anything for onboarding metrics
        if (scope.onboarding) {
          return;
        }

        // Clean up a few weird names to make things easier to understand.
        scope.model = metric;
        scope.name = metric.metric.name;
        scope.value = metric.current_value;
        scope.type = metric.metric_type;
        scope.status = metric.change_indicator;
        scope.trend = metric.change_direction;
        scope.ratio = [
          metric.ratio_numerator,
          metric.ratio_denominator,
        ];

        // Statuses that will help us determine what to display
        scope.hasDrilldown = !!metric.resource_uri;
        scope.isRatio = metric.isRatio();
        scope.isPremium = metric.isPremium();
        scope.isProduct = metric.isProduct();

        var setLastUpdate = function () {
          if (scope.store.isExternal()) {
              if(rootScope.coronaLastUpdate) {
                scope.lastUpdate = rootScope.coronaLastUpdate[null];
              }
              else {
                scope.lastUpdate = null;
              }
            } else {
              scope.lastUpdate = metric.modified_on;
            }
        };
        setLastUpdate();

        scope.$watch(
          function(){return rootScope.coronaLastUpdate},
          function (newValue, oldValue) {
            setLastUpdate();
          });

        scope.drilldown = function(event) {
          if (event) {
            event.stopPropagation();
            event.preventDefault();
          }

          if (!scope.hasDrilldown) {
            return;
          }

          Modal.show({
            templateUrl: 'views/directives/metric/drilldown.html',
            controller: 'controller.MetricDrilldown',
            controllerAs: 'drilldown',
            inputs: {
              metric: metric,
              store: store
            }
          });
        };
      }]
    };
  }
]).controller('controller.MetricDrilldown', [
  'metric',
  'store',
  '$element',
  'close',
  function(metric, store, element, close) {
    this.metric = metric;

    // Show a pretty title for the drilldown
    this.title = metric.metric.description || metric.metric.name;
    this.value = metric.current_value;

    // This is the URL we'll use to show a drilldown for the metric.
    this.url = '/ecom/drilldowns/' + metric.metric.key;

    // Afford closing the modal
    this.close = function() {
      element.modal('hide');
      close();
    };
  }
]);
