angular.module('vantageApp').factory('model.FacebookAd', [
  '$sce',
  'lib.Model',
  'service.CopyValidator',
  'service.UrlValidator',
  'va.config',
  function($sce, Model, copyValidator, urlValidator, config) {

    var FacebookAd = Model({
      id: null,
      type: null,
      creative: null,
      insights_report: null,
      isDpa: false,
      isVideoAd: false,

      // Frontend-only states that the ad can find itself in.
      isSelected: false,
      isEditable: false,

      // Persisted frontend states and values.
      isPristine: false,
      isCopied: false,

      sortOrder: 0,

      isValid: function() {
        // If edits are needed, nothing else matters, the ad is invalid
        if (this.review_status === 'EDITS_NEEDED') {
          return false;
        }

        if (this.creative.creativeType == 'CAROUSEL'){
          //Most of the ad has to be created manually, assume for now carousel ads are valid
          return true;
        }

        if (this.type !== 'RIGHT' && !this.creative.body) {
          return false;
        }

        if (!this.isDpa) {
          if (this.creative.title && !copyValidator.isValid(this.creative.title)) {
            return false;
          }
          if (this.type !== 'RIGHT' && !copyValidator.isValid(this.creative.body)) {
            return false;
          }
        } else {
          if (this.creative.title && !copyValidator.isDPAValid(this.creative.title)) {
            return false;
          }
          if (this.type !== 'RIGHT' && !copyValidator.isDPAValid(this.creative.body)) {
            return false;
          }
        }
        if (this.creative.objectUrl) {
          if (!urlValidator.isUrlParseable(this.creative.objectUrl)) {
            return false;
          }
          if (!urlValidator.isUrlCoopDomain(this.creative.objectUrl)) {
            return false;
          }
        }

        return true;
      },

      init: function(opts) {
        this.$parent.init.apply(this, [opts]);

        if (!_.contains(FacebookAd.ALLOWED_TYPES, this.type)) {
          throw new Error("Invalid ad type '" + this.type + "'");
        }

        if (!this.creative) {
          this.creative = {
            pageId: null,
            title: null,
            body: null,
            imageUrl: null,
            videoId: null,
            videoFileUrl: null,
            videoFacebookSourceUrl: null,
            objectUrl: null,
            callToAction: null,
            linkDescription: null,
          };
        }

        //Duck to be consistent with 'report' on Google.
        this.report = this.insights_report
      },

      replaceToken: function(inputValue, token, value) {
        newValue = inputValue;
        newValue = newValue.replace('{{ ' + token + ' }}', value);
        newValue = newValue.replace('{{' + token + '}}', value);
        newValue = newValue.replace('{{' + token + ' }}', value);
        newValue = newValue.replace('{{ ' + token + '}}', value);
        return newValue;
      },

      replaceTokens: function (inputValue, index) {
        if (typeof this.sampleProducts === "undefined") {
          return inputValue;
        }
        outputValue = this.replaceToken(inputValue, 'product.name', this.sampleProducts[index].name);
        if (this.sampleProducts[index].price) {
          try {
            numbro.language(window.config.org.currency);
          } catch (e) {
            numbro.language('en'); // Default to USD if the org doesn't have a currency.
          }
          samplePrice = numbro(this.sampleProducts[index].price).format('$0.00');
          outputValue = this.replaceToken(outputValue, 'product.price', samplePrice);
        }
        return outputValue;
      },

      sampleTitle: function(index) {
        if (this.creative.title) {
          var title = this.creative.title;
          return this.replaceTokens(title, index);
        }
      },

      sampleMessage: function(index) {
        if (this.creative.body) {
          var message = this.creative.body;
          return this.replaceTokens(message, index);
        }
      },

      sampleDescription: function(index) {
        if (this.creative.linkDescription) {
          var description = this.creative.linkDescription;
          return this.replaceTokens(description, index);
        }
      },

      videoUrl: function(width, height) {
        if (this.creative.videoId) {
          var sourceUrl =  "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fabc%2Fvideos%2F" + this.creative.videoId;

          if(width){
            sourceUrl = sourceUrl + "&width=" + String(width);
          }
          if(height){
            sourceUrl = sourceUrl + "&height=" + String(height);
          }

          return $sce.trustAsResourceUrl(sourceUrl);
        } else {
          return;
        }
      },

      isCarouselCardVideo: function(card) {
        return Boolean(card.video.videoId || card.video.videoFileUrl);
      },

      carouselVideoUrl: function(videoId, width, height) {
        if (videoId) {
          var sourceUrl =  "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fabc%2Fvideos%2F" + videoId;

          if(width){
            sourceUrl = sourceUrl + "&width=" + String(width);
          }
          if(height){
            sourceUrl = sourceUrl + "&height=" + String(height);
          }

          return $sce.trustAsResourceUrl(sourceUrl);
        } else {
          return;
        }
      },

      carouselVideoFileUrl: function(card) {
        var url = card.video.videoFacebookSourceUrl || card.video.videoFileUrl;
        return $sce.trustAsResourceUrl(url);
      },

      isCarouselVideoGif: function(card) {
        return Boolean(card.video.videoFileUrl) && !Boolean(card.video.videoFacebookSourceUrl) && card.video.videoFileUrl.toLowerCase().indexOf('.gif') >= 0;
      },

      hasVideoFileUrl: function(){
        return Boolean(this.getVideoFileUrl());
      },

      isVideoGif: function(){
        var url = this.creative.videoFacebookSourceUrl || this.creative.videoFileUrl;
        return url.toLowerCase().indexOf('.gif') >= 0;
      },

      getVideoFileUrl: function(){
        var url = this.creative.videoFacebookSourceUrl || this.creative.videoFileUrl;
        if (url){
          return $sce.trustAsResourceUrl(url);
        }
        else {
          return;
        }
      },

      isCarouselVideo : function() {
        return this.creative.creativeType == 'CAROUSEL' && (this.creative.carouselCards[0].video.videoId || this.creative.carouselCards[0].video.videoFileUrl);
      },

      isActive: function() {
        return this.status === 'ACTIVE';
      },
    });

    FacebookAd.configure({
      RIGHT_SIDE: 'RIGHT',
      NEWSFEED: 'NEWS',
      MOBILE: 'MOBILE',
      INSTAGRAM: 'INSTAGRAM',
      STORIES: 'STORIES',
    });

    FacebookAd.DEFAULT_CTA_MAPPING = {
      'NO_BUTTON': 'No Button',
      'APPLY_NOW': 'Apply Now',
      'BOOK_TRAVEL': 'Book Now',
      'CONTACT_US': 'Contact Us',
      'DOWNLOAD': 'Download',
      'LEARN_MORE': 'Learn More',
      'SHOP_NOW': 'Shop Now',
      'SIGN_UP': 'Sign Up',
      'WATCH_MORE': 'Watch More',
      'GET_SHOWTIMES': 'Get Showtimes',
      'GET_QUOTE': 'Get Quotes',
      'LISTEN_NOW': 'Listen Now',
      'PLAY_GAME': 'Play Game',
      'SUBSCRIBE': 'Subscribe',
      'DONATE_NOW': 'Donate Now',
      'GET_OFFER': 'Get Offer',
    };

    FacebookAd.DPA_CTA_MAPPING = {
      'NO_BUTTON': 'No Button',
      'BOOK_TRAVEL': 'Book Now',
      'DOWNLOAD': 'Download',
      'LEARN_MORE': 'Learn More',
      'OPEN_LINK': 'Open Link',
      'SHOP_NOW': 'Shop Now',
      'SIGN_UP': 'Sign Up',
      'GET_SHOWTIMES': 'Get Showtimes',
      'DONATE_NOW': 'Donate Now',
      'LISTEN_NOW': 'Listen Now',
      'SUBSCRIBE': 'Subscribe',

    };

    FacebookAd.HDUS_CTA_MAPPING = {
      'NO_BUTTON': 'No Button',
      'BOOK_TRAVEL': 'Book Now',
      'DOWNLOAD': 'Download Now',
      'LEARN_MORE': 'Learn More',
      'SHOP_NOW': 'Shop Now',
      'SIGN_UP': 'Sign Up',

    };

    // For name lookup purposes, we create a third object that is the joining of the first two.
    FacebookAd.FULL_CTA_MAPPING = $.extend(
      {},
      FacebookAd.DEFAULT_CTA_MAPPING,
      FacebookAd.DPA_CTA_MAPPING,
        FacebookAd.HDUS_CTA_MAPPING
    );

    FacebookAd.ALLOWED_TYPES = [
      FacebookAd.RIGHT_SIDE,
      FacebookAd.NEWSFEED,
      FacebookAd.MOBILE,
      FacebookAd.INSTAGRAM,
      FacebookAd.STORIES,
    ];

    return FacebookAd;
  }
]);
