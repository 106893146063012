
angular.module('vantageApp').directive('campaignData', [
  'va.config',
  function(config) {
    return {
      restrict: 'A',
      templateUrl: 'views/directives/campaign-data.html',
      scope: {
        campaign: '=',
        store: '=',
        isAdmin: '=',
        allCampaignsFacebook: '=',
        isCoop: '=',
        isDraftState: '=',
        isMediaPlanner: '='
      },
      link: function(scope) {
        scope.isDemoing = function() {
          return config.isDemoing();
        };

        scope.getDemoCampaignName = function (campaign) {
          var month = moment(campaign.schedule.startDate).format('MMMM');

          return month + ' Campaign';
        };

        scope.getCampaignName = function (campaign) {
          if ( scope.isMediaPlanner ) {
            return campaign.taxonomy_name || campaign.name;
          }

          return campaign.name;
        }
      },
    };
  }
]);
