angular.module('vantageApp').run([
  '$rootScope',
  'model.Campaign2',
  'model.Audience',
  'model.FacebookAd',
  function($rootScope, Campaign, Audience, FacebookAd) {
    $rootScope.Campaign = Campaign;
    $rootScope.Audience = Audience;
    $rootScope.FacebookAd = FacebookAd;
  }
]);
