angular.module('vantageApp').controller('controller.buildCampaignFromTemplate', [
  '$scope',
  '$element',
  'Upload',
  'store',
  'campaign',
  'close',
  function($scope, $element, Upload, store, campaign, close) {

    $scope.file = null;
    $scope.showUploadErrorMessage = false;
    $scope.isUploading = false;

    this.close = function() {
      $element.modal('hide');
      close();
    };

    this.proceed = function() {
      $element.modal('hide');
      close();
    };

    this.isFileSelected = function() {
      return Boolean($scope.file);
    };

    this.cleanCampaignAttributes = function(campaign) {
      if(campaign.adsets['DISPLAY']) {
        delete campaign.adsets['DISPLAY'];
      }
    };

    this.uploadTemplate = function () {
      this.cleanCampaignAttributes(campaign);

      $scope.isUploading = true;

      Upload.upload({
        url: '/api/' + store.id + '/campaigns/upload',
        data: {
          'creative_template': $scope.file,
          'campaign_attributes': JSON.stringify(campaign)
        },
      }).then(
        function (response) {
          $element.modal('hide');
          close();

          if (campaign['id']) {
            window.location.reload()
          }
          else {
            window.location.href = "/#/campaigns/edit/" + response.data.campaign_id
          }
        },
        function (err) {
          $scope.showUploadErrorMessage = true
        }
      )
    };

    this.selectTemplate = function (file) {
      if (!file) {
        return;
      }

      $scope.file = file

    };

    this.campaignHasExistingAds = function () {
      return campaign.totalAds() > 0;
    }
  }
]);