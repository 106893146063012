angular.module('vantageApp').directive('facebookAd', [
  'model.FacebookAd',
  'lib.VideoDimensions',
  'lib.Modal',
  '$timeout',
  'va.config',
  function(FacebookAd, VideoDimensions, Modal, timeout, config) {
    return {
      restrict: 'E',
      template: '<ng-include src="template()"></ng-include>',
      scope: {
        ad: '=',
        campaign: '=',
        isPreview: '=',
        rank: '=',
        numads: '=',
      },

      link: function(scope, element, attrs) {
        scope.isBrand = config.store.is_brand;
        scope.isRetailer = config.store.is_retailer;
        scope.retailerIsHomeDepotCanada = config.store.name.endsWith("- Home Depot CA") || config.store.name === "www.homedepot.ca";
        scope.retailerIsHomeDepotUS = config.store.name.endsWith("- Home Depot US") || config.store.name === "www.homedepot.com";
        scope.adminDisplay = !scope.retailerIsHomeDepotUS || config.features.admin;
        scope.defaultLogo = 'frontend/images/campaign/default_page.png';
        scope.start = 0;
        scope.end = 1;

        scope.nextCard = function() {
          scope.start++;
          scope.end = scope.start + 1;
          var len = scope.ad.creative.carouselCards.length;
          if ( scope.start > len - 1 ) {
            scope.start = len - 1;
            scope.end = len;
          }
        }

        scope.prevCard = function() {
          scope.start--;
          scope.end = scope.start + 1;
          if ( scope.start < 0 ) {
            scope.start = 0;
            scope.end = 1;
          }
        }

        scope.cardsContainClaim = function() {
          if (scope.ad.creative.creativeType == 'CAROUSEL') {
            return _.some(scope.ad.creative.carouselCards, {'hasClaim': true})
          }
          return false;
        };

        scope.template = function() {
          var map = {};

          if (scope.ad.isDpa) {
            map[FacebookAd.RIGHT_SIDE] = 'views/directives/facebook-ad/right-side-dpa.html';
            map[FacebookAd.NEWSFEED] = 'views/directives/facebook-ad/newsfeed-dpa.html';
            map[FacebookAd.MOBILE] = 'views/directives/facebook-ad/mobile-dpa.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/facebook-ad/instagram-dpa.html';
            map[FacebookAd.STORIES] = 'views/directives/facebook-ad/stories.html'
            return map[scope.ad.type];
          }
          else if (scope.ad.creative.creativeType == 'CAROUSEL'){
            map[FacebookAd.NEWSFEED] = 'views/directives/facebook-ad/newsfeed-carousel.html';
            map[FacebookAd.MOBILE] = 'views/directives/facebook-ad/mobile-carousel.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/facebook-ad/instagram-carousel.html';
            map[FacebookAd.STORIES] = 'views/directives/facebook-ad/stories-carousel.html'
            return map[scope.ad.type];
          }
          else if (scope.ad.creative.creativeType == 'COLLECTION'){
            map[FacebookAd.MOBILE] = 'views/directives/facebook-ad/mobile-collection.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/facebook-ad/instagram-collection.html';
            return map[scope.ad.type];
          }
          else {
            map[FacebookAd.RIGHT_SIDE] = 'views/directives/facebook-ad/right-side.html';
            map[FacebookAd.NEWSFEED] = 'views/directives/facebook-ad/newsfeed.html';
            map[FacebookAd.MOBILE] = 'views/directives/facebook-ad/mobile.html';
            map[FacebookAd.STORIES] = 'views/directives/facebook-ad/stories.html';
            map[FacebookAd.INSTAGRAM] = 'views/directives/facebook-ad/instagram.html';
            return map[scope.ad.type];
          }
        };

        if (scope.ad.type == FacebookAd.STORIES && ((scope.ad.isVideoAd && !scope.ad.hasVideoFileUrl()) || scope.ad.isCarouselVideo())) {
          var videoId = null;
          if(scope.ad.isCarouselVideo() && !scope.ad.creative.carouselCards[0].videoFileUrl){
            videoId = scope.ad.creative.carouselCards[0].videoId ;
          } else {
            videoId = scope.ad.creative.videoId;
          }

          VideoDimensions.getDimensionsByVideoId(videoId).then(function (dimensions) {
            if (dimensions) {
              var video_iframe = $(element).find('iframe').first();

              original_width = dimensions['width'];
              original_height = dimensions['height'];
              new_height = 320 * (original_height / original_width);

              video_iframe.height(new_height);
            }
          });
        }

        if (scope.ad.type == FacebookAd.STORIES && ((scope.ad.isVideoAd && scope.ad.hasVideoFileUrl()) || scope.ad.isCarouselVideo())) {
          timeout(function () {
            var videoTag = $(element).find('video').first();

            videoTag.on('loadedmetadata', function (e) {
              var videoSourceWidth = videoTag[0].videoWidth;
              var videoSourceHeight = videoTag[0].videoHeight;
              var newHeight = 320 * (videoSourceHeight / videoSourceWidth);

              videoTag.height(newHeight);
            });
          }, 0);
        }

        scope.storiesImageAspectRatio = function(){
          var image = $(element).find('.stories-image').first().find('img').get();
          if (image.length > 0) {
            return image[0].width / image[0].height;
          }
          else{
            return null;
          }
        };

        scope.storyImageUsesAllSpace = function() {
          return scope.storiesImageAspectRatio() && scope.storiesImageAspectRatio() < 0.6;
        };

        scope.storiesVideoAspectRatio = function(){
          var iframe = $(element).find('iframe').first();
          var video = $(element).find('video').first();
          var videoTag = null;

          if(iframe[0]){
            videoTag = iframe;
          }
          else if(video[0]){
            videoTag = video;
          }

          // Set a default ratio if a video element hasnt been loaded yet
          if(!videoTag){
            return 0.1;
          }

          var width = videoTag.width();
          var height = videoTag.height();

          if (width && height) {
            return width / height;
          }
          else {
            return null;
          }
        };

        scope.storyVideoUsesAllSpace = function() {
          return scope.storiesVideoAspectRatio() && scope.storiesVideoAspectRatio() < 0.6;
        };

        scope.mediaUsesAllSpace = function () {
          if (scope.ad.isVideoAd || scope.ad.isCarouselVideo()) {
            return scope.storyVideoUsesAllSpace();
          } else {
            return scope.storyImageUsesAllSpace();
          }
        };


        scope.ctaName = function(type) {
          return FacebookAd.FULL_CTA_MAPPING[type];
        };

        scope.imageUrl = function(url) {
          // Ensure we show all images over SSL when we're SSL
          if (location.protocol === 'https:') {
            url = url.replace('http://', 'https://');
          }

          return url;
        };

        scope.adUrl = function(url, caption) {
          // The caption overrides the display of the url if present
          if (caption) {
            url = caption;
          }

          if (!url || _.contains(['http://', 'https://'], url)) {
            return '...';
          }

          // Don't let strings end in a trailing dot.
          url = url.replace(/\.+$/, '');

          // If there isn't a protocol then the code below
          // will interpret the url as a path, even though
          // we have url's come in like "www.foo.bar.com".
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
          }

          var location = document.createElement('a');
          location.href = url;

          return location.hostname.replace('www.', '');
        };

        scope.copyDrag = function(event, copy) {
          var field = event.currentTarget.getAttribute("data-creative-field");
          var maxSize = event.currentTarget.getAttribute("data-creative-field-max-length");
          if (copy.source === "VANTAGE" && copy.body.includes(": ")){
            copy.body = copy.body.split(":")[1].trim();
          }
          if (maxSize) {
            copy.body = copy.body.substring(0, maxSize);
          }
          scope.ad.creative[field] = copy.body;
          scope.ad.isPristine = false;
          scope.ad.isCopied = false;
        };

        var fetchFacebookPageForID = function(facebook_id) {
          return _.reduce(_.filter(
            scope.campaign.facebookPages, function(item) {
              return (item.facebook_id === facebook_id);
            }));
        };

        scope.getFacebookPageDetails = function(facebook_id) {
          if (config.isDemoing()) {
            return;
          }
          var page = fetchFacebookPageForID(facebook_id);

          if (page) {
            return page.facebook;
          }
        };

        var details = scope.getFacebookPageDetails(scope.ad.creative.pageId);

        if ( details ) {
          scope.facebookLogo = details.url;
        }

        scope.getInstagramDetails = function(facebook_id) {
          if (config.isDemoing()) {
            return;
          }

          var page = fetchFacebookPageForID(facebook_id);

          if (page) {
            return page.instagram;
          }
        };


        scope.addHttp = function (url) {
          if ( !url || !url.length )
            return '...';

          if (url.toLowerCase().startsWith('http')) {
            return url
          } else {
            return 'http://' + url;
          }
        };

        scope.getUrl = function (index) {
          if (scope.ad['creative']['carouselCards'] && index !== undefined && index < scope.ad['creative']['carouselCards'].length) {
            return scope.addHttp(scope.ad['creative']['carouselCards'][index]['link']);
          }
          else {
            return scope.addHttp(scope.ad['creative']['objectUrl']);
          }
        };

        scope.openLink = function () {
          window.open(scope.getUrl(), '_blank');
        };

        scope.editComment = function(comment) {
          Modal.show({
            templateUrl: 'views/modal/edit-comment.html',
            controller: 'controller.editComment',
            controllerAs: 'modal',
            inputs: {
              comment: comment,
              action: function() {
                scope.campaign.updateComment(comment);
              }
            }
          });
        };

        scope.deleteComment = function(comment) {
          Modal.show({
            templateUrl: 'views/modal/confirm-delete-comment.html',
            controller: 'controller.confirmDeleteComment',
            controllerAs: 'modal',
            inputs: {
              action: function() {
                scope.campaign.deleteComment(comment);
              }
            }
          });
        };

        scope.canEditComment = function(comment) {
          return comment.user_id == config.user.id;
        };

        scope.canDeleteComment = function(comment) {
          return comment.user_id == config.user.id;
        };
      }
    };
  }
]);
