angular.module('vantageApp').service('service.GoogleAnalyticsEvents', function() {
  var service = this;

  service.purchase = function(value, content_category, eventCategory) {
    if (!value) {
      value = 0;
    }

    if (!content_category) {
      content_category = '';
    }

    if (typeof window.ga !== 'undefined') {
      ga('send', {
        hitType: 'event',
        eventCategory: eventCategory,  // or put content_category here
        eventAction: 'Purchase',
        eventLabel: content_category,  // and campaign.name here?
        eventValue: value
      });
    }
  };
});
