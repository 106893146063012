angular.module('vantageApp').factory('model.User', [
  'lib.Model',
  'va.config',
  function(Model, config) {
    var User = Model({
      // Nothing for now
    });

    return User.configure({
      url: '/core/api/v1/user/',

      current: function() {
        if (config.user) {
          return this.get(config.user.id);
        }
      }
    });
  }
]);
