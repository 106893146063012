angular.module('vantageApp').directive('campaignStackBar', [
  '$rootScope',
  'va.config',
  function (rootScope, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-stack-bar.html',
      scope: {
        store: '=',
        audiences: '=',
        campaigns: '=',
      },
      link: function (scope) {

        var verboseNames = {
          "LookalikeAudience": "Lookalike",
          "DynamicProductAudience": "Dynamic",
          "WebsiteVisitorsAudience": "Visitors",
          "TopCustomerAudience": "Top Customer",
          "AbandonerAudience": "Abandoner",
          "RepeaterAudience": "Ready Customer",
          "GenericProspecting": "Prospecting",
          "GenericRetargeting": "Retargeting",
        };

        // These audiences are in priority order, broken down by funnel stage
        var audiencesTypesByFunnelStage = {
          "ATTRACT": [
            "LookalikeAudience",
            "GenericProspecting",
          ],
          "CONVERT": [
            "DynamicProductAudience",
            "WebsiteVisitorsAudience",
            "AbandonerAudience",
            "GenericRetargeting",
          ],
          "REENGAGE": [
            "RepeaterAudience",
            "TopCustomerAudience",
          ]
        };

        var availableAudiences = _.filter(scope.audiences, {'status': 'AVAILABLE'});

        function numberAudiencesAvailable(funnelStage) {
          return _.filter(availableAudiences, {funnel_stage: funnelStage}).length;
        }

        // Get list of unique audience types from any approved/running campaigns
        var runningOrApprovedCampaigns = _.filter(
          scope.campaigns, function (campaign) {
            return campaign.isRunningOrApproved();
          }
        );
        var runningCampaigns = _.filter(
          scope.campaigns, function (campaign) {
            return campaign.isRunning();
          }
        );
        var runningAudiences = _.uniq(_.map(runningOrApprovedCampaigns,'audience'));

        function numberRunningAudiences(funnelStage) {
          return _.filter(runningAudiences, {funnel_stage: funnelStage}).length;
        }

        var isRunningProspecting = numberRunningAudiences(['LookalikeAudience']) > 0;
        var isProspectingAvailable = numberAudiencesAvailable(['LookalikeAudience']) > 0;

        function getStatus(requireProspecting, numberRunning, numberAvailable) {
          var status = null;
          var reason = null;

          var isAvailable = numberAvailable > 0;
          var isRunning = numberRunning > 0;

          if (isAvailable && !isRunning) {
            status = 'bad';
            reason = 'not_running_anything'
          } else if (isRunning && requireProspecting && isProspectingAvailable && !isRunningProspecting) {
            status = 'warning';
            reason = 'not_running_prospecting';
          } else if (isRunning && numberAvailable <= numberRunning) {
            status = 'good';
          } else if (isRunning) {
            status = 'warning';
            reason = 'not_running_all';
          } else if (!isAvailable) {
            status = 'unavailable';
            reason = 'nothing_available';
          }
          return { key: status, reason: reason };
        }

        function getNextBestAudience(requireProspecting, numberRunning, numberAvailable, funnelStage) {
          var nextBestAudience = null;
          var nextBestAudienceName = null;

          if (numberRunning > 0 && requireProspecting && isProspectingAvailable && !isRunningProspecting) {
            nextBestAudience = 'LookalikeAudience';
            nextBestAudienceName = 'Lookalike';
          } else if (numberRunning < numberAvailable) {
            var stageAudienceTypes = audiencesTypesByFunnelStage[funnelStage];
            var availableAudienceTypes = _.uniq(_.pluck(availableAudiences, 'type'));
            var runningAudienceTypes = _.uniq(_.pluck(runningAudiences, 'type'));
            nextBestAudience = _.difference(_.intersection(stageAudienceTypes, availableAudienceTypes), runningAudienceTypes)[0];
            nextBestAudienceName = verboseNames[nextBestAudience];
          }
          return { type: nextBestAudience, name: nextBestAudienceName };
        }

        function getMetricValue(funnelStage, property) {
          var metric = 0;

          _.forEach(runningCampaigns, function (campaign) {

            if (campaign.audience.funnel_stage === funnelStage) {
              var isRetailer = campaign.hasOwnProperty('brand') && campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer;
              if (isRetailer && property === 'total_conversion_value') {
                if (campaign.aggregate_report.coop_breakdown && campaign.aggregate_report.coop_breakdown.retailer) {
                  metric += campaign.aggregate_report.coop_breakdown.retailer[property];
                }
              } else {
                if (campaign.aggregate_report[property]) {
                  metric += campaign.aggregate_report[property];
                }
              }
            }
          });
          return metric;
        }

        function getNumberCampaignsRunning(funnelStage) {
           return _.filter(runningCampaigns, function (campaign) {
            return campaign.audience.funnel_stage === funnelStage;
          }).length;
        }

        function getMetric(name, funnelStage, numberAvailable) {
          var metric = 0;
          var label = "";

          var numberRunningCampaigns = getNumberCampaignsRunning(funnelStage);
          if (numberRunningCampaigns === 0 && numberAvailable === 0) {
            metric = null;
            label = "Not available yet";
          } else if (name === 'Attract') {
            label = "Clicks";
            metric = getMetricValue(funnelStage, 'clicks');
          } else {
            label = " Revenue";
            if (numberRunningCampaigns > 0) {
              metric = getMetricValue(funnelStage, 'total_conversion_value');
            }
          }
          return {value: metric, label: label};
        }

        function buildSection(name, funnelStage, requireProspecting) {
          var numberAvailable = numberAudiencesAvailable(funnelStage);
          var numberRunning = numberRunningAudiences(funnelStage);

          var nextBestAudience = getNextBestAudience(requireProspecting, numberRunning, numberAvailable, funnelStage);
          var status = getStatus(requireProspecting, numberRunning, numberAvailable);

          var metric = getMetric(name, funnelStage, numberAvailable);

          var funnelName = name;
          if (name.endsWith('e')) {
            funnelName += 'd';
          } else {
            funnelName += 'ed';
          }

          return {
            name: name,
            funnelName: funnelName,
            numberAvailable: numberAvailable,
            numberRunning: numberRunning,
            status: status,
            nextBestAudience: nextBestAudience,
            metric: metric,
          }
        }

        function buildAttractStep() {
          return buildSection('Attract', 'ATTRACT', false);
        }
        function buildConvertStep() {
          return buildSection('Convert', 'CONVERT', true);
        }
        function buildReengageStep() {
          return buildSection('Re-Engage', 'REENGAGE', true);
        }

        if (!scope.store.steps) {
          scope.store.steps = [
            buildAttractStep(),
            buildConvertStep(),
            buildReengageStep()
          ];
        }

        scope.openFeedback = rootScope.openFeedback;
        scope.storeTerm = config.storeTerm();
      }
    };
  }
]);
