angular.module('vantageApp').directive('pioLineItemSelector', [
  'model.StoreDetails',
  function(
    StoreDetails
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/pio-line-item-selector.html',
      scope: {
        store: '=',
        campaign: '=',
      },
      link: function (scope) {

        var storeDetails = new StoreDetails({'store': scope.store});
        storeDetails.pioLineItems().then(
          function (details) {
            scope.pio_line_items = details.objects;
          }
        );

        scope.campaignUrl = function(lineItem) {
          return '/?oid=' + lineItem.assigned_campaigns[0].organization_id + '#/campaigns/edit/' + lineItem.assigned_campaigns[0].campaign_id
        }

        scope.selectLineItem = function(lineItem) {
          scope.campaign.selectedLineItemId = lineItem.id;
          scope.campaign.name = lineItem.campaign_name;
          if (moment(lineItem.line_item_start_date).isBefore(moment())) {
            scope.campaign.schedule.startDate = null;
          } else {
            scope.campaign.schedule.startDate = moment(lineItem.line_item_start_date);
          }
          scope.campaign.schedule.endDate = moment(lineItem.line_item_end_date);
          scope.campaign.budget.actual = Math.round(lineItem.cogs_working_media_budget);
        }
      }
    };
  }
]);
