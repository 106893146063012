angular.module('vantageApp').controller('controller.DashboardV2Realtime', [
  'lib.Modal',
  '$scope',
  'store',
  '$stateParams',
  '$cookies',
  'va.config',
  function(Modal, scope, store, params, cookies, config) {
    scope.store = store;
    scope.period = params.period;
    scope.config = config;
    scope.overviewSelected = true;
    scope.storeTerm = config.storeTerm();

    scope.retailerIsHomeDepotUS = store.retailerIsHomeDepotUS();

    scope.scrollToSegment = function (segmentName) {
      $("#segment-anchor-" + segmentName)[0].scrollIntoView({
        behavior: "smooth"
      });
    };

    scope.segmentIsOffline = function (segmentName){
      return segmentName.toLowerCase().indexOf('offline') >= 0
    };

    scope.removeSpaces = function (str) {
      return str.replace(/\s/g, '')
    };

    scope.storeHasSegments = function() {
      return config.store.segments.length > 0
    };

    scope.storeHasOfflineSegments = function () {
      for (var i = 0; i < config.store.segments.length; i++) {
        if (scope.segmentIsOffline(config.store.segments[i].name)) {
          return true
        }
      }

      return false
    };

    scope.showConversionRateTile = function() {
      var isAdmin = scope.hasFeature('admin');

      var isFreshDirectBrand = config.store.name.indexOf("Fresh Direct") > -1;
      var isFreshDirect = config.store.name.indexOf("freshdirect") > -1;
      var isLongosBrand = config.store.name.indexOf("Longos's") > -1;
      var isLongos = config.store.name.indexOf("longos") > -1;
      var storeIsExcluded = _.some([isFreshDirectBrand, isFreshDirect, isLongosBrand, isLongos]);

      return store.showTrafficData() && (isAdmin || !storeIsExcluded);
    };

    // Starts the video to explain how the dashboard works.
    scope.beginVideo = function() {
      Modal.video({
        url: 'https://www.youtube.com/embed/GYxaLbn1ohE',
      });
    };

    function trackOverview() {
      var expireOn = new Date();
      expireOn.setDate(expireOn.getDate() + 1);
      cookies.put('vantage-product-overview', true, {expires: expireOn});
    }

    scope.startOverview = function() {
      trackOverview();
      Modal.show({
        templateUrl: 'views/modal/product-overview.html',
        controller: 'controller.productOverview',
        controllerAs: 'modal',
        inputs: {
        }
      });
    };

    scope.hasSeenOverview = cookies.get('vantage-product-overview');

    if (!scope.hasSeenOverview && store && moment(store.installed_at).isAfter(moment().subtract(5, 'days'))) {
      scope.startOverview();
    }
  }
]);
