angular.module('vantageApp').controller('controller.TrackingSettings', [
  '$scope',
  '$http',
  'store',
  function(scope, $http, store) {
    var ctl = this;

    scope.store = store;

    $http({
      url:"/account/trackingCode",
      method:"GET",
    }).then(function(response) {
      scope.trackingUrl = response.data;
    });

  }
]);
