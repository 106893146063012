angular.module('vantageApp').filter('campaignStateDetails', function() {
  return function(campaign) {

    var details = {
      'pending_internal': ' by Vantage',
      'pending_retailer_approval': ' by Retailer',
      'pending_brand_approval': ' by Brand',
      'pending_tracking_setup': ' by Vantage',
      'pending_external': ' by Ad Platforms',
    }

    var detail = '';
    if ((campaign.brand || campaign.isHomeDepotUS) && details[campaign.state]) {
      detail = details[campaign.state];
      if (campaign.state === 'pending_retailer_approval' && campaign.approval_steps && campaign.approval_steps.length > 0) {
        var step_names = []
        detail += " (";
        _.forEach(campaign.approval_steps, function(step){
          step_names.push(step.step_name.replace(' Approval', ''));
        });
        detail += step_names.join(', ');
        detail += ")";
      }
    }
    if (campaign.is_sent_back){
        detail += " Edits Needed";
    }
    if (campaign.ending_soon){
        detail += " Ending Soon";
    }
    if (campaign.state == 'draft' && campaign.isHomeDepotUS){
      if (!campaign.selectedLineItemId){
        detail += " MK Needed";
      }
      else if (!campaign.adsCount){
        detail += " Ads Needed";
      }
    }

    return detail;
  };
});
