angular.module('vantageApp').controller('controller.changeBudget', [
  '$scope',
  '$http',
  'campaign',
  'model.CampaignBudget',
  'service.BudgetEstimate',
  '$element',
  'close',
  'store',
  function($scope, $http, campaign, CampaignBudget, BudgetEstimate, element, close, store) {
    var ctl = this;

    campaign.budget.old_actual = campaign.budget.actual;

    $scope.campaign = campaign;
    $scope.budget = $scope.campaign.budget;
    $scope.isHomeDepotUS = store.retailerIsHomeDepotUS();

    $scope.loading = false;
    $scope.error = false;
    $scope.store = store;

    if ($scope.isHomeDepotUS) {
      var availableBudget = Number($scope.campaign.lineItem.unallocatedBudget);
      if ($scope.campaign.lineItem.isFake) {
        availableBudget -= $scope.campaign.budget.actual;
      }
      $scope.campaign.budget.initializeUnallocatedAmountHDUS(availableBudget);
    }
    else {
      $scope.campaign.budget.initializeUnallocatedAmount();
    }

    $scope.canProceed = function() {
      return (campaign.budget.actual >= campaign.budget.minimum) && !$scope.budget.budgetExceedsMKAmount();
    };

    BudgetEstimate.getBudgetEstimate($scope.campaign).then(function(response) {
      BudgetEstimate.updateBudgetEstimate(
        $scope,
        parseInt(response.data.budget),
        parseInt(response.data.minimum)
      );
      BudgetEstimate.updateState($scope);

      $scope.budgetInfo = [
        {name: "Current Budget", amount: campaign.budget.actual},
        {name: "Minimum Budget", amount: campaign.budget.minimum},
      ];

      if ($scope.isHomeDepotUS){
        $scope.budgetInfo.push({name: "Unallocated MK Amount", amount: campaign.budget.unallocatedAmount});
      }
      else{
        $scope.budgetInfo.push({name: "Recommended Budget", amount: campaign.budget.recommended});
      }

    });

    ctl.changeBudget = function() {
      if (campaign.budget.actual === campaign.budget.old_actual) {
        return Promise.resolve({data: {success: true, error: false}});
      } else if (campaign.budget.actual < campaign.budget.minimum) {
        return Promise.resolve({data: {success: false, error: false}});
      }

      var params = {
        campaign_id: campaign.id,
        old_budget: campaign.budget.old_actual,
        new_budget: campaign.budget.actual
      };

      return $http.post(CampaignBudget.updateUrl, params);
    };

    // Close the modal.
    ctl.close = function() {
      element.modal('hide');
      close();
    };

    // Cancel changing the budget.
    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }

      campaign.budget.actual = campaign.budget.old_actual;

      ctl.close();
    };

    // Proceed with changing the Budget.
    ctl.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;

      this.changeBudget().then(function(resp) {
        if (resp.data.success) {
          ctl.close();

        } else if (resp.data.error) {
          $scope.loading = false;
          $scope.error = true;
        } else {
          $scope.loading = false;
          $scope.error = false;
        }
      });

    };

  }
]);
