angular.module('vantageApp').controller('controller.pinterestShoppingContentChangeConfirmation', [
  '$element',
  'close',
  'campaign',
  'model',
  'model.PinterestAd',
  function($element, close, campaign, model, PinterestAd) {

    // Close the modal.
    this.close = function() {
      $element.modal('hide');
      close();
    };

    // Proceed with switching the content type
    this.proceed = function() {
      campaign.deleteAllAds();
      newAd = new PinterestAd({
        'type': 'PINTEREST_SHOPPING',
      });
      campaign.pushAd(newAd);

      model.$setViewValue(campaign);
      model.$render();

      $element.modal('hide');
      close();
    };
  }
]);
