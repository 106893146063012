angular.module('vantageApp').controller('controller.SocialMediaSettings', [
  '$scope',
  'model.Store',
  'model.DigestRecipient',
  'store',
  'service.FacebookPage',
  function(scope, Store, DigestRecipient, store, facebookPage) {
    var ctl = this;

    scope.facebook_page_url = "";
    scope.loading = false;

    scope.noAgencyAccess = false;
    scope.noInstagramAccount = false;
    scope.pageBacked = false;

    scope.facebookApproveURL = facebookPage.getApproveURL;
    scope.facebookSettingsURL = facebookPage.getSettingsURL;
    scope.instagramSettingsURL = facebookPage.getInstagramSettingsURL;

    scope.facebookPageRequestResult = null;

    scope.store = store;
    scope.retailerIsHomeDepotCanada = store.retailerIsHomeDepotCanada();

    scope.popover = {
      'title': 'Using Facebook Page for Instagram',
      'content': "Your Facebook Page's name and image will be used on your Instagram " +
                 "ad so that people can easily identify your business.<br /><br />" +

                 "You won't be able to respond to comments on your ad.<br /><br />" +

                 "Your Instagram handle will be your Facebook Page's name. It'll be " +
                 "grayed out and won't be clickable."
    };

    scope.requestFacebookPage = function() {
      if (scope.loading) {
        return;
      }

      scope.loading = true;
      scope.facebook_page_url = facebookPage.sanitizeURL(scope.facebook_page_url);

      Store.requestFacebookPage(store, scope.facebook_page_url).then(
        function(ok_response) {
          scope.facebookPageRequestResult = 'success';
          facebookPage.openAdmin(scope.facebook_page_url);
          scope.loading = false;
        },
        function(err_response) {
          scope.facebookPageRequestResult = 'error';
          scope.page_request_response = err_response;
          scope.loading = false;
        }
      );
    };

    scope.checkAgencyAccess = function(autoCheck) {
      if (scope.loading) {
        return;
      }

      scope.noAgencyAccess = false;
      scope.loading = true;

      Store.checkAgencyAccess(store).then(
        function(response) {
          var agency_access = response.data.agency_access;
          store.agency_access = agency_access;
          scope.loading = false;

          if (agency_access) {
            scope.checkInstagramAccess(false, true);
          } else if (!autoCheck) {
            scope.noAgencyAccess = true;
          }
        }
      );
    };

    scope.checkInstagramAccess = function(pageBackedAccount, forceFetch) {
      if (scope.loading) {
        return;
      }

      scope.loading = true;
      scope.noInstagramAccount = false;

      if (pageBackedAccount) {
        scope.pageBacked = true;

        Store.checkPageBackedInstagramAccess(store, forceFetch).then(
          function(response) {
            var instagram_access = response.data.page_backed_instagram_access;
            scope.loading = false;

            // Not the actual ID, but it doesn't matter. We just want the field to be truthy.
            if (store.primary_facebook_page) {
              store.primary_facebook_page.instagram_account_id = "1";
            }
          }
        );

      } else {
        Store.checkInstagramAccess(store, forceFetch).then(
          function(response) {
            var instagram_access = response.data.instagram_access;
            scope.noInstagramAccount = !instagram_access;
            scope.loading = false;

            if (instagram_access && store.primary_facebook_page) {
              // Not the actual ID, but it doesn't matter. We just want the field to be truthy.
              store.primary_facebook_page.instagram_account_id = "1";
            }
          }
        );

      }
    };

    scope.showForm = function() {
      var checks = [
        !scope.showAgency(),
        !scope.showInstagram(),
        !scope.showPageBackedInstagram(),
        !scope.showSuccess(),
      ];

      return checks.every(function(element) {
        return element;
      });
    };

    scope.showAgency = function() {
      var checks = [
        scope.facebookPageRequestResult === 'success',
        !scope.showInstagram(),
        !scope.showPageBackedInstagram(),
        !scope.showSuccess(),
      ];

      return checks.every(function(element) {
        return element;
      });
    };

    scope.showInstagram = function() {
      var checks = [
        store.agency_access,
        !scope.showPageBackedInstagram(),
        !scope.showSuccess(),
      ];

      return checks.every(function(element) {
        return element;
      });
    };

    scope.showPageBackedInstagram = function() {
      var checks = [
        scope.pageBacked,
        !scope.showSuccess(),
      ];

      return checks.every(function(element) {
        return element;
      });
    };

    scope.showSuccess = function() {
      var checks = [
        store.primary_facebook_page && store.primary_facebook_page.facebook_id,
        store.agency_access,
        store.primary_facebook_page && store.primary_facebook_page.instagram_account_id,
      ];

      return checks.every(function(element) {
        return element;
      });
    };

    scope.reset = function() {
      store.primary_facebook_page = null;
      store.agency_access = false;

      scope.facebook_page_url = "";
      scope.loading = false;

      scope.noAgencyAccess = false;
      scope.noInstagramAccount = false;
      scope.pageBacked = false;

      scope.facebookApproveURL = facebookPage.getApproveURL;
      scope.facebookSettingsURL = facebookPage.getSettingsURL;
      scope.instagramSettingsURL = facebookPage.getInstagramSettingsURL;

      scope.facebookPageRequestResult = null;

      scope.store = store;

      ctl.fetchPageUrl();
    };

    ctl.fetchPageUrl = function() {
      facebookPage.getURL(store).then(function(pageURL) {
        if (pageURL) {
          scope.facebook_page_url = pageURL;
        }
      });
    };
    ctl.fetchPageUrl();

    if (store.primary_facebook_page) {
      scope.checkAgencyAccess(autoCheck=true);
    } else {
      store.agency_access = false;
    }

  }
]);
