angular.module('vantageApp').factory('tile.Funnel', [
  'lib.Tile',
  'model.Campaign2',
  function(Tile, Campaign2) {

    return Tile({
      name: 'funnel',

      makeRequest: function() {
        // This call to .all() is unnecessary, the data for the funnel tile is pulled elsewhere.
        // However to use the Tile classes, makeRequest MUST return a promise that resolves with data
        // TODO: Refactor the tile service to handle makeRequest returning None so we can have tiles without data
        return Campaign2.all({
          state: 'running'
        });
      },

      loadData: function(response) {

      }
    });
  }
]);
