angular.module('vantageApp').factory('model.DatasetMetric', [
  'lib.Model',
  '$http',
  function(Model, http) {
    var DatasetMetric = Model({
      isPremium: function() {
        var metric = this.metric;

        if (!metric) {
          return false;
        }

        return metric.is_premium;
      },

      isRatio: function() {
        return this.metric_type === 'RATIO';
      },

      isProduct: function() {
        return this.metric.key.startsWith('top_');
      }
    });

    return DatasetMetric.configure({
      url: '/data/api/v1/dataset_metric/',

      /**
       * Abstraction for finding a metric by key.
       */
      getByKey: function(id, params) {
        return this.all(params).then(function(metrics) {
          return _.find(metrics, function(metric) {
            return metric.metric.key === id;
          });
        });
      },
    });
  }
]);
