angular.module('vantageApp').directive('campaignPreview', [
  '$rootScope',
  'service.DPASampleProducts',
  'model.FacebookAd',
  'lib.BrandNameExtractor',
  'lib.Modal',
  'va.config',
  function(rootScope, DPASampleProducts, FacebookAd, BrandNameExtractor, Modal, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/campaign-preview.html',

      scope: {
        adsets: '=',
        campaign: '=',
        store: '=',
      },

      link: function(scope, element, attrs, model) {
        scope.isExpanded = false;
        scope.isPreview = true;
        scope.activeTab = {};
        var firstAdset = true; // This is used to set the first tab to active

        var googleCampaign = scope.campaign.googleCampaign;
        var googleAdGroups = googleCampaign ? googleCampaign.adGroups : null;
        if(googleAdGroups) {
          scope.googleAdGroups = {'DISPLAY AD': googleAdGroups[0]};
        }

        var pinterestCampaign = scope.campaign.pinterestCampaign;
        var pinterestAdGroups = pinterestCampaign ? pinterestCampaign.adGroups : null;
        if (pinterestAdGroups) {
          scope.pinterestAdGroups = {'PINTEREST SHOPPING': pinterestAdGroups[0]}
        }

        scope.selectedAds = [];

        process_placements = function (baseAdGroupings) {

          _.forEach(baseAdGroupings, function (adset, placement) {
            if (placement != 'PINTEREST SHOPPING' && !adset.ads.length) {
              return;
            }

            scope.activeTab[placement] = false;
            if (firstAdset) {
              scope.activeTab[placement] = true;
              firstAdset = false;
            }

          // Add some attributes onto the ad objects so we can sort by them.
          _.forEach(adset.ads, function(ad) {
            ad.sort_return_on_ad_spend = ad.return_on_ad_spend || 0;

            var report ={}
            if(ad.insights_report) {
              report = ad.insights_report
            }
            else if(ad.report) {
              report = ad.report
            }

            ad.sort_total_conversion_value = report.total_conversion_value || 0;
            ad.sort_ctr = report.ctr || 0;
          });

            if (scope.campaign.audience.funnel_stage === 'ATTRACT') {
              adset.sortedAds = _.sortByOrder(
                adset.ads,
                ['status', 'sort_ctr', 'sort_total_conversion_value'],
                ['asc', 'desc', 'desc']
              );
            } else {
              adset.sortedAds = _.sortByOrder(
                adset.ads,
                ['status', 'sort_return_on_ad_spend', 'sort_total_conversion_value'],
                ['asc', 'desc', 'desc']
              );
            }

          });
        }
        process_placements(scope.adsets);
        process_placements(scope.googleAdGroups);
        process_placements(scope.pinterestAdGroups);

        var brandName = BrandNameExtractor.getBrandName(scope.campaign);
        var dpaProductFilter = null;

        if (brandName) {
          dpaProductFilter = {
            brand_name: brandName
          }
        }

        if ((scope.campaign.audience && scope.campaign.isDPA && scope.campaign.isDPA())
              || scope.campaign.isDPAWithCustomAudience()) {
          DPASampleProducts.getSampleProducts(dpaProductFilter).then(function(products) {
            _.forEach(scope.adsets, function(adset) {
              _.forEach(adset.ads, function(ad) {
                ad.isDpa = true;
                DPASampleProducts.setSampleProducts(products, ad, 2);
              });
            });

            _.forEach(scope.googleAdGroups, function(adgroup) {
              _.forEach(adgroup.ads, function(ad) {
                DPASampleProducts.setSampleProducts(products, ad, 2);
              });
            });
          });
        }

        DPASampleProducts.getSampleProducts(dpaProductFilter).then(function (products) {
          _.forEach(scope.adsets, function (adset) {
            _.forEach(adset.ads, function (ad) {
              if (ad.creative.creativeType == 'COLLECTION') {
                DPASampleProducts.setSampleProducts(products, ad, 4);
              }
            });
          });
        });

        scope.toggleAdset = function(type) {
          _.forEach(scope.activeTab, function(active, placement) { scope.activeTab[placement] = false; });
          scope.activeTab[type]= true;
        };

        scope.toggleExpandedView = function() {
          scope.isExpanded = !scope.isExpanded;
        };

        scope.allowCommenting = function() {
          var isPendingRetailerApproval = scope.campaign.state === 'pending_retailer_approval';
          var isRetailerUser = config.user.isRetailerUser;
          var isAdmin = rootScope.hasFeature('admin');
          return isPendingRetailerApproval && (isRetailerUser || isAdmin);
        };

        scope.select = function(ad, event) {
          // Only allow ad selection if campaign is pending retailer approval
          // Also abort if the user clicked on a link or a button
          if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON' && scope.allowCommenting()) {
            ad.isSelected = !ad.isSelected;
            if (ad.isSelected) {
              scope.selectedAds.push(ad);
            } else {
              scope.selectedAds = scope.selectedAds.filter(function(value, index, arr) {
                return value.id !== ad.id;
              });
            }
          }
        };

        scope.clearSelection = function() {
          _.forEach(scope.selectedAds, function(ad) {
            ad.isSelected = false;
          });
          scope.selectedAds = [];
        };

        scope.commentOnAds = function() {
          Modal.show({
            templateUrl: 'views/modal/comment-on-ads.html',
            controller: 'controller.commentOnAds',
            controllerAs: 'modal',
            inputs: {
              campaign: scope.campaign,
              store: scope.store,
              ads: scope.selectedAds,
              default_status: 'EDITS_NEEDED',
              action: scope.clearSelection,
            }
          });
        };

        scope.approveAds = function() {
          scope.campaign.setReviewStatus(scope.selectedAds, 'APPROVED');
          scope.clearSelection();
        };
      }
    };
  }
]);
