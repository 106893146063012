angular.module('vantageApp').directive('systemMessages', [
  '$http',
  '$window',
  function (http, window) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/system-messages.html',
      scope: {},
      link: function (scope) {
        const storageKey = 'systemMessages';
        var itemKey = window.localStorage.getItem(storageKey);
        var dismissed;
        try {
          dismissed = JSON.parse(itemKey);
        } catch (error) {
          dismissed = [];
        }
        var now = new Date();
        _.forEach(dismissed, function (item, index) {
          if (new Date(item.expires) < now) {
            dismissed.splice(index, 1);
          }
        });

        window.localStorage.setItem(storageKey, JSON.stringify(dismissed));
        var url = '/core/messages/'
        if (config.store){
          url += config.store.id + "/"
        }
        http.get(url).then(function (response) {
          scope.messages = response.data.filter(function (item) {
            return !_.some(dismissed, function (dismissedItem) {
              return dismissedItem.id === item.id;
            });
          });
        });

        scope.dismissItem = function (item) {
          _.forEach(scope.messages, function (message, index) {
            if (message.id === item.id) {
              scope.messages.splice(index, 1);
            }
          });

          dismissed.push(item);
          window.localStorage.setItem(storageKey, JSON.stringify(dismissed));
        };
      }
    };
  }
]);
