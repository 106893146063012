angular.module('vantageApp').factory('model.OrganizationInvite', [
  'lib.Model',
  '$http',
  function(Model, $http) {
    var OrganizationInvite = Model({
    });

    return OrganizationInvite.configure({
      PENDING: "pending",
      REVOKED: 'revoked',
      COMPLETED: 'completed',

      url: '/core/organization-invite/',

      all: function() {
        return this.get(config.org.id);
      },

      getPending: function() {
        return this.get(config.org.id, {state: 'pending'});
      },

      // Shadow the `create` and 'destroy' methods to add the orgId
      create: function(orgId, attrs) {
        var cls = this;

        return $http.post(this.url + orgId + "/", attrs).then(function(response) {
          return cls.deserializeCreate(attrs, response);
        });
      },

      destroy: function(orgId, attrs) {  // Shadow the `destroy` method to add the orgId
        return $http({
          url: this.url + orgId + "/",
          method: "DELETE",
          data: attrs,
          headers: {
            "Content-Type": "application/json;"
          },
        });
      },

    });
  }
]);
