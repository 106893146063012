angular.module('vantageApp').filter('money', [
  function() {
    return function(value, opts) {
      var currentLanguage = numbro.language(),
          format,
          result;

      if (opts && opts.language) {
        numbro.language(opts.language);
      }

      if (opts && opts.format) {
        format = opts.format;
      } else {
        if (value > 9999) {
          format = '$0,0.[0]a';
        } else {
          format = '$0,0.00';
        }
      }

      result = numbro(value).format(format);
      numbro.language(currentLanguage);

      return result;
    };
  }
]);
