angular.module('vantageApp').factory('tile.TotalUsers', [
  'lib.Tile',
  '$q',
  'lib.Modal',
  'model.GAProfile',
  function(Tile, Promise, Modal, GAProfile) {
    return Tile({
      loadData: function(response) {
        this.amount = response.data.amount;
        this.percentChange = Math.abs(response.data.percent_change);
        this.setTrend(response.data.trend);

        if (response.data.benchmark) {
          this.benchmark = Math.abs(response.data.benchmark.value);
          this.setBenchmarkTrend(response.data.benchmark.trend);
        }

        if (response.data.share_of_benchmark) {
          this.shareOfBenchmark = response.data.share_of_benchmark.value;
          this.setShareOfBenchmarkTrend(response.data.share_of_benchmark.trend);
          this.shareOfBenchmarkPercentChange = Math.abs(response.data.share_of_benchmark.percentage_change);
        }
        this.message = response.meta.message;
      },

      load: function(scope) {
        this.isExternal = this.store.store_type === 'EXTERNAL';
        this.hasAuthorizedGoogle = this.store.has_authorized_google;
        this.uses_corona_traffic_data = this.config.store.uses_corona_traffic_data;
        this.hasChosenProfile = !!this.store.ga_profile_id;
        this.chooseProfile = function() {
          Modal.show({
            templateUrl: 'views/modal/ga-profile.html',
            controller: 'controller.GAProfileModal',
            inputs: {
              store: scope.store
            }
          });
        };

        var deferred = Promise.defer();

        if (!this.store.showTrafficData()) {
          scope.setState('action');
          deferred.resolve({});
          return deferred.promise;
        } else {
          return this.$parent.load.apply(this);
        }
      },

      getTemplate: function() {
        if (!this.store.showTrafficData()) {
          return 'views/tile/ga.html';
        }

        return this.$parent.getTemplate.apply(this);
      },
    });
  }
]);
