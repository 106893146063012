angular.module('vantageApp').filter('periodName', [
  function() {
    return function(type, period) {
      switch(type) {
        case 'current':
          return {
            'daily': 'today',
            'weekly': 'this week',
            'monthly': 'this month',
            'quarterly': 'this quarter',
            'yearly': 'this year',
          }[period]

        case 'previous':
          return {
            'daily': 'yesterday',
            'weekly': 'last week',
            'monthly': 'last month',
            'quarterly': 'last quarter',
            'yearly': 'last year',
          }[period]
      };
    };
  }
]);
