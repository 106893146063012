angular.module('vantageApp').controller('controller.GAProfileModal', [
  '$scope',
  '$state',
  'model.Store',
  'model.GAProfile',
  'store',
  '$element',
  'close',
  '$http',
  function(scope, state, Store, GAProfile, store, element, close, http) {
    scope.isLoading = true;

    // We load the profiles here so the modal feels snappier
    GAProfile.all().then(function(profiles) {
      scope.profiles = profiles;
      scope.isLoading = false;
    });

    // We only show this if they've authorized but have to choose
    // a profile, a case which occurs if the google account
    // has many GA profiles that it's connected to.
    scope.needsGAProfile = function() {
      return store.has_authorized_google && !store.ga_profile_id;
    };

    scope.chooseProfile = function(profile, event) {
      Store.update(store, {
        ga_profile_id: profile.id
      }).then(function() {
        state.go(state.current, {}, { reload: true });
        analytics.track('google-analytics-installed');
      });

      event.preventDefault();
      scope.close();
    };

    scope.disconnectGoogleAccount = function(event) {
      http.post('/ga/disconnect/').then(function() {
        state.go(state.current, {}, { reload: true });
        analytics.track('google-analytics-uninstalled');
      });

      event.preventDefault();
      scope.close();
    },

    scope.close = function($event) {
      element.modal('hide');
      close();

      if (event) {
        $event.preventDefault();
      }
    };
  }
]);
