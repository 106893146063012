angular.module('vantageApp').controller('controller.commentOnAds', [
  '$scope',
  '$http',
  'campaign',
  '$element',
  'close',
  'store',
  'ads',
  'default_status',
  'action',
  function($scope, $http, campaign, element, close, store, ads, default_status, action) {
    var ctl = this;

    $scope.campaign = campaign;
    $scope.ads = ads;
    $scope.comment = '';
    $scope.review_status = default_status;

    $scope.loading = false;
    $scope.error = false;
    $scope.store = store;

    $scope.canProceed = function() {
      return $scope.comment.length > 0;
    };

    ctl.close = function() {
      element.modal('hide');
      close();
    };

    ctl.cancel = function() {
      if ($scope.loading) {
        return;
      }
      ctl.close();
    };

    this.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      var comment = $scope.comment;
      var review_status = $scope.review_status;

      var adIds = {
        'facebook': [],
        'google': [],
      };

      _.forEach(ads, function(ad) {
        if (ad.hasOwnProperty('creative')) {
          adIds['facebook'].push(ad.id);
        } else {
          adIds['google'].push(ad.id);
        }
      });

      var data = {
        adIds: adIds,
        text: comment,
        review_status: review_status
      };

      url = '/api/' + store.id + '/campaigns/' + campaign.id + '/comment_on_ads/';
      $http.post(url, data).then(function(resp) {
        _.forEach(ads, function(ad) {
          ad.comments.splice(0, 0, resp.data.comment);
          ad.isSelected = false;
          if (review_status !== 'NO_CHANGE') {
            ad.review_status = review_status;
          }

        });
        action();
      }, function(resp) {
        console.log("Comments failed to add");
      });

      this.close();
    };
  }
]);
