angular.module('vantageApp').controller('controller.AgencyRequest', [
  '$scope',
  '$timeout',
  'model.Store',
  'store',
  function(scope, timeout, Store, store) {
    scope.store = store;
    scope.facebookAdAccountRequestComplete = false;
    scope.requestFacebookAdAccount = function() {
      Store.requestFacebookAdAccount(scope.store, scope.store.facebook_ad_account_id)
        .then(function() {
          scope.facebookAdAccountRequestComplete = true;
          scope.hasError = false;

          timeout(function () {
            scope.facebookAdAccountRequestComplete = false;
          }, 5000);
        }, function() {
          scope.hasError = true;
        });
    };
  }
]);
