angular.module('vantageApp').controller('PasswordController', [
  '$scope',
  function(scope) {
    var checkLength = function(password) {
      return password && password.length >= 8;
    };

    var containsUppercase = function(password) {
      var re = /[A-Z]/g;
      return password && re.test(password);
    };

    var containsLowercase = function(password) {
      var re = /[a-z]/g;
      return password && re.test(password);
    };

    var containsNumber = function(password) {
      var re = /[0-9]/g;
      return password && re.test(password);
    };

    var containsSpecial = function(password) {
      var re = /[()\[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/g;
      return password && re.test(password);
    };

    scope.password = {'password1': null, 'showPassword': false};
    scope.checks = [
      {'name': '8 Characters', 'function': checkLength},
      {'name': '1 Uppercase Letter', 'function': containsUppercase},
      {'name': '1 Lowercase Letter', 'function': containsLowercase},
      {'name': '1 Number', 'function': containsNumber},
      {'name': '1 Symbol ()[]{}|\\`~!@#$%^&*_-+=;:\'",<>./?', 'function': containsSpecial},
    ];

    scope.togglePassword = function() {
      scope.password.showPassword = !scope.password.showPassword;
    }

    scope.passwordsMatch = function() {
      return scope.password.password1 === scope.password.password2;
    }

    scope.allChecksComplete = function() {
      var allChecks = true;
      _.forEach(scope.checks, function(check) {
        allChecks = allChecks && check.function(scope.password.password1);
      });
      return allChecks;
    }
  }
]);
