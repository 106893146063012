(function(app) {
  app.filter('formatMetric', function () {
    return function(value, metric){
      // TOP metrics have an invalid display_format, since they
      // usually don't contain numbers at all. Do not format these.
      if (!metric || metric.metric_type === 'TOP') {
        return value;
      }

      if (value == null || value == "nan" || value === "") {
        return '-';
      }

      var sourceUnits = metric.source_unit,
        outputUnits = metric.output_unit,
        numberFormat;

      // TODO: Let's move all of this logic to the frontend
      switch (metric.display_format) {
        case '$0,0.00':
          numberFormat = value >= 10000 ? '$0,0[.]0a' : '$0,0';
          break;

        case '0,0':
        case '0.0':
          numberFormat = value >= 10000 ? '0,0[.]0a' : '0,0';
          break;

        default:
          numberFormat = metric.display_format;
          break;
      }

      var units = '';

      if (sourceUnits == "DAY" && outputUnits == "MONTH") {
        value = parseFloat(value);

        if (value > 365) {
          value = value / 365.25;
          units = " year";
        } else if (value > 45) {
          value = value / 30.4375;
          units = " month";
        } else {
          units = " day";
        }

        if (Math.round(value) > 1 || Math.floor(value) == 0) {
          units += 's';
        }
      }

      if (metric.metric_type == "RATIO") {
        value = parseFloat(value);
      }

      try {
        numbro.language(metric.locale);
      } catch(err) {
        numbro.language('en');
      }

      return numbro(value).format(numberFormat) + units;
    }
  });
})(window.vantageApp);
