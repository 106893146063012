angular.module('vantageApp').service('service.S3ImageUploader', [
        'va.config',
        '$http',
        'Upload',
        function (config, $http, Upload) {
            var service = this;
            service.uploadImages = function (images, callback, error) {
                const doUpload = function (s3bucket, filename, awsData, file, callback, error) {
                    Upload.upload({
                        url: 'https://' + s3bucket + '.s3.amazonaws.com/',
                        method: 'POST',
                        data: {
                            key: '' + config.store.id + '/' + filename,
                            acl: 'public-read',
                            AWSAccessKeyId: awsData.access_key_id,
                            policy: awsData.policy,
                            signature: awsData.signature,
                            "Content-Type": file.type !== '' ? file.type : 'image/jpg',
                            filename: filename,
                            file: file
                        }
                    }).then(function (uploadResponse) {
                        callback(filename, 'https://' + s3bucket + '.s3.amazonaws.com/' + config.store.id + '/' + filename, {
                            facebook: 'ready',
                            instagram: 'ready',
                            google: 'ready'
                        }, file);

                    }, function (uploadResponse) {
                        // Oh no, an error - We should report it to sentry and the user.
                        error(filename);
                    });
                }

                const getRandomSuffix = function () {
                    // This is not guaranteed to be unique but has a <1% chance of generating a dupe
                    // and is preferred to a longer string that would guarantee uniqueness
                    return 'xxxx'.replace(/[x]/g, function () {
                        var v = Math.random() * 16 | 0;
                        return v.toString(16);
                    });
                }

                $http.get(
                    '/api/' + config.store.id + '/images/policy'
                ).then(function (response) {
                    var s3bucket = response.data.imagePolicy.bucket;
                    if (!Array.isArray(images)){
                    images = [images];
                    }

                    _.forEach(images, function (file, index) {
                        var uploadPath = 'https://' + s3bucket + '.s3.amazonaws.com/' + config.store.id + '/' + file.name;
                        $http.head(uploadPath + "?cb=" + new Date().getTime()).then(function (headResponse) {
                            // Image exists
                            var etag = headResponse.headers('ETag').replace(/['"]+/g, '');
                            var reader = new FileReader();

                            reader.onload = function (event) {
                                var md = forge.md.md5.create();
                                md.update(event.target.result);
                                var md5 = md.digest().toHex();
                                if (md5 === etag) {
                                    // Image is already uploaded with the same name and md5, so we don't need to upload it again
                                    callback(file.name, uploadPath, {
                                        facebook: 'ready',
                                        instagram: 'ready',
                                        google: 'ready'
                                    }, file);
                                } else {
                                    // Image is already uploaded but has a different md5, we need to rename and upload it
                                    var fileExt = file.name.split('.').pop();
                                    var suffix = getRandomSuffix();
                                    var newFileName = file.name.replace("." + fileExt, "_" + suffix + "." + fileExt);
                                    file.name = newFileName;
                                    callback(newFileName, newFileName, {
                                        facebook: 'uploading',
                                        instagram: 'uploading'
                                    });
                                    doUpload(s3bucket, newFileName, response.data.imagePolicy, file, callback, error);
                                }
                            };

                            reader.readAsBinaryString(file);
                        }, function (headResponse) {
                            // Image does not exist, upload as usual
                            callback(file.name, file.name, {facebook: 'uploading', instagram: 'uploading'});
                            doUpload(s3bucket, file.name, response.data.imagePolicy, file, callback, error);
                        });
                    });
                });
            }

            return service;
        }
    ]
);