angular.module('vantageApp').controller('controller.TeamSettings', [
  '$scope',
  'user',
  'store',
  'organization',
  'model.Organization',
  'model.OrganizationInvite',
  'va.config',
  function(
      $scope,  // Angular stuff
      user, store, organization,  // model instances
      Organization, OrganizationInvite, // model objects
      config
  ) {
    var ctl = this;
    ctl.user = user;
    ctl.store = store;
    ctl.organization = organization;

    ctl.userInviteEmail = null;
    ctl.errorMessage = null;

    ctl.isBrand = config.store.is_brand;

    // Borrowed from http://stackoverflow.com/a/46181/1745922
    var validateEmail = function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    var refreshOrgUsers = function() {
      Organization.current().then(function(result) {
        ctl.organization = result;
      });
    };

    var refreshPendingInvites = function() {
      OrganizationInvite.getPending().then(function(result) {
        ctl.invites = result.invites;
      });
    };
    refreshPendingInvites();

    ctl.users = function() {
      // Return all users, and ensure that Owner is first in the list.
      return _.sortBy(ctl.organization.users, function(user) {
        return ctl.userIsOwner(user.id) ? 0 : 1;
      });
    };

    ctl.userIsOwner = function(userId) {
      return userId === ctl.store.owner.id;
    };

    ctl.canAdminister = function() {
      return $scope.hasFeature('admin') || ctl.userIsOwner(ctl.user.id);
    };

    ctl.inviteUserToStore = function() {
      ctl.errorMessage = null;
      ctl.successMessage = null;

      if (!ctl.userInviteEmail) {
        ctl.errorMessage = "An email address is required.";
        return;
      }

      if (!validateEmail(ctl.userInviteEmail)) {
        ctl.errorMessage = "Please enter a valid email address.";
        return;
      }

      OrganizationInvite.create(ctl.organization.id, {
        "email": ctl.userInviteEmail,

      }).then(function(result) {
        if (result.invite.state === OrganizationInvite.PENDING) {
          ctl.invites.push(result.invite);
          ctl.successMessage = result.invite.email + " was invited to your team.";
        } else if (result.invite.state === OrganizationInvite.COMPLETED) {
          refreshOrgUsers();
          ctl.successMessage = result.invite.email + " was added to your team.";
        }

        // Reset input box.
        ctl.userInviteEmail = null;

      }, function(error) {
        if (error.status == 400) {
          ctl.errorMessage = error.data;
        } else {
          ctl.errorMessage = "An unknown error occurred. Sorry about that, we're looking into it.";
        }
      });
    };

    ctl.removeUser = function(userId) {
      OrganizationInvite.destroy(ctl.organization.id, {
        "userId": userId
      }).then(function(result) {
        refreshOrgUsers();
      });
    };

    ctl.deleteOrgInvite = function(orgInviteId) {
      OrganizationInvite.destroy(ctl.organization.id, {
        "inviteId": orgInviteId
      }).then(function(result) {
        refreshPendingInvites();
      });
    };

  }
]);
