angular.module('vantageApp').directive('autocomplete', [
    '$http',
  function(http) {
    return {
      restrict: 'E',
      require: 'ngModel',
      templateUrl: 'views/directives/autocomplete.html',
      scope: {
        getOptions: '&options'
      },
      link: function(scope, element, attrs, model) {
        // Store this list so we can filter down the entire
        // list of options and still go back to the full list.
        var options = [];
        scope.has_claim = false;
        scope.isBrand = config.store.is_brand;
        scope.isRetailer = config.store.is_retailer;
        scope.retailerIsHomeDepotCanada = config.store.name.endsWith("- Home Depot CA") || config.store.name === "www.homedepot.ca";

        _.forEach(scope.getOptions(), function(value, key) {
          if (value) {
            options.push({
              value: value,
              active: false,
            });
          }
        });

        scope.attrs = attrs;
        scope.options = options;
        scope.activeOption = null;

        model.$render = function() {
          scope.model = model.$viewValue;
          if ( scope.retailerIsHomeDepotCanada ) {
           scope.hasClaim();
          }
        };

        scope.select = function(option) {
          scope.model = option.value;
          option.active = false;
          scope.options = options;
          scope.activeOption = null;
          scope.update();
          scope.hideOptions();

          // Mark the field dirty for upstream forms. This won't
          // happen by default for autocompletion, so make sure it does.
          scope.markFormAsDirty();
        };

        scope.markFormAsDirty = function () {
          if (attrs.name && scope.form[attrs.name]) {
            scope.form[attrs.name].$setDirty();
          }
        }

        scope.update = function(event) {
          model.$setViewValue(scope.model);
          if ( event ) {
            scope.updateOptions();
          }
        };

        scope.updateOptions = function () {
          scope.refreshOptions();
          scope.showOptions();
          if ( scope.retailerIsHomeDepotCanada ) {
            scope.hasClaim();
          }
        }

        scope.updateFromPaste = function(event) {
          var clipboardData = event.clipboardData || window.clipboardData;
          model.$setViewValue(clipboardData.getData('text'))
          scope.updateOptions();
          scope.markFormAsDirty()
        }

        scope.hasClaim = function() {
          if((attrs.name == 'creative.objectUrl') || (attrs.name == 'finalUrl')) {
            return
          }
          var url = '/api/hasClaim/';
          http.post(url, {"value": scope.model}).then(function(response) {
            scope.has_claim = response.data.has_claim;
          })
        };

        scope.blur = function(event) {
          setTimeout(function() {
            scope.hideOptions();
            scope.$apply();
          });
        };

        scope.handleKeypress = function(event) {
          var keyUp = 38;
          var keyDown = 40;
          var keyEnter = 13;

          if (!_.contains([keyUp, keyDown, keyEnter], event.keyCode)) {
            return true;
          }

          // Typing a newline, so make sure that works if nothing is selected
          if (event.keyCode === keyEnter && scope.activeOption === null) {
            return true;
          }

          if (event.keyCode === keyEnter) {
            scope.selectActiveOption();
          }

          if (event.keyCode === keyUp) {
            scope.activeOption -= 1;

            if (scope.activeOption < 0) {
              scope.activeOption = scope.options.length - 1;
            }

            scope.updateActiveOption();
          }

          if (event.keyCode === keyDown) {
            if (scope.activeOption === null) {
              scope.activeOption = 0;
            } else {
              scope.activeOption += 1;
            }

            if (scope.activeOption >= scope.options.length) {
              scope.activeOption = 0;
            }

            scope.updateActiveOption();
          }

          event.preventDefault();
        };

        scope.refreshOptions = function() {
          if (!scope.model) {
            scope.options = options;
            return;
          }

          var pattern = scope.model.split("").reduce(function(a, b){
            return a + ".*" + _.escapeRegExp(b);
          });

          var regexp = new RegExp(pattern, 'i');

          scope.options = _.filter(options, function(option) {
            return regexp.test(option.value);
          });
        };

        scope.selectActiveOption = function() {
          var option = _.find(scope.options, { active: true });
          scope.select(option);
        };

        scope.updateActiveOption = function(index) {
          scope.options = _.map(scope.options, function(option) {
            return _.assign(option, { active: false });
          });

          scope.options[scope.activeOption].active = true;
        };

        scope.showOptions = function() {
          if (scope.options.length > 0) {
            scope.isEditing = true;
          }
        };

        scope.hideOptions = function() {
          scope.isEditing = false;
        };
      }
    }
  }
]);
