angular.module('vantageApp').filter('campaignState', function() {
  return function(campaign) {

    if (campaign.state === 'running' && moment(campaign.schedule.startDate).isAfter(moment().add(5, 'minutes'))) {
      return 'Scheduled';
    }

    return {
      'draft': 'Draft',
      'discarded': 'Discarded',
      'recommended': 'Recommended',
      'retired': 'Retired',
      'pending_internal': 'Awaiting Verification',
      'pending_audience': 'Awaiting Audience',
      'pending_brand_approval': 'Awaiting Verification',
      'pending_retailer_approval': 'Awaiting Verification',
      'pending_tracking_setup': 'Awaiting Verification',
      'approved': 'Awaiting Verification',
      'pending_external': 'Awaiting Verification',
      'on_hold': 'On Hold',
      'rejected': 'Rejected',
      'permanently_rejected': 'Rejected',
      'running': 'Running',
      'paused': 'Paused',
      'system_paused': 'Paused',
      'completed': 'Finished',
      'terminated': 'Terminated',
    }[campaign.state];
  };
});
