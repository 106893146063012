angular.module('vantageApp').controller('controller.deleteCampaignConfirmation', [
  '$scope',
  '$state',
  'campaign',
  'action',
  '$element',
  'close',
  function($scope, $state, campaign, action, element, close) {
    var ctl = this;

    $scope.campaign = campaign;
    $scope.loading = false;
    $scope.error = false;

    // Helper to format dates.
    ctl.formatDate = function(dateStr) {
      if (!dateStr) {
        return
      }

      var date = moment(dateStr);
      var now = moment();

      if (date.year() === now.year()) {
        return date.format('MMM Do');
      }

      return date.format('MMM Do, YYYY');
    };

    $scope.campaignInfo = [
      {name: "Name", value: campaign.name},
      {name: "Start Date", value: ctl.formatDate(campaign.schedule.startDate) || "ASAP"},
      {name: "End Date", value: ctl.formatDate(campaign.schedule.endDate) || "Ongoing"},
      {name: "Budget", value: campaign.budget.actual},
    ]

    // Close the modal.
    ctl.close = function() {
      if ($scope.loading) {
        return;
      }

      element.modal('hide');
      close();
    };

    ctl.deleteSuccessCallback = function(response) {
      $scope.loading = false;

      ctl.close();
      $state.go($state.current, {}, {reload: true});
    };

    ctl.deleteFailCallback = function(response) {
      $scope.error = true;
      $scope.loading = false;
    };

    // Proceed with changing the Budget.
    ctl.proceed = function() {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      $scope.error = false;

      action(campaign.id).then(ctl.deleteSuccessCallback, ctl.deleteFailCallback);
    };

  }
]);
