angular.module('vantageApp').config(function() {
  Highcharts.setOptions({
    global: {
      useUTC: false
    },
    chart: {
      style: {
        fontFamily: 'Lato',
        fontWeight: 400,
      }
    },
    xAxis: {
      labels: {
        style: {
          color: '#97a3ae',
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: '#97a3ae',
        }
      }
    },
  });
});
