angular.module('vantageApp').factory('tile.CampaignProgress', [
  'lib.Tile',
  'model.Campaign2',
  '$timeout',
  function(Tile, Campaign2, timeout) {
    return Tile({
      name: 'campaign-progress',

      makeRequest: function() {
        return Campaign2.all({
          state: 'running',
        });
      },

      loadData: function(response) {
        var tile = this;

        this.campaigns = response;

        timeout(function() {
          var carouselWidth = tile.$('.carousel').outerWidth();
          var sliderWidth = carouselWidth * response.length;
          var itemWidth = 100 / response.length;
          var index = 0;

          tile.$('.carousel-slider').css('width', sliderWidth + 'px');
          tile.$('.carousel-item').css('width', itemWidth + '%');

          tile.next = function() {
            index = index + 1;

            if (index > response.length - 1) {
              index = 0;
            }

            var offsetWidth = index * carouselWidth;

            tile.$('.carousel-slider').css('left', (-offsetWidth) + 'px');
          };

          tile.prev = function() {
            index = index - 1;

            if (index < 0) {
              index = response.length - 1;
            }

            var offsetWidth = index * carouselWidth;

            tile.$('.carousel-slider').css('left', (-offsetWidth) + 'px');
          };
        });
      }
    });
  }
]);
