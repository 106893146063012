angular.module('vantageApp').service('service.UrlValidator', [
    'va.config',
 function(config) {
      this.isUrlParseable = function(url) {
          // Check for white space
          var white_space = new RegExp(/\s/, "g")
          if(white_space.test(url)){
              return false;
          }
          // Check the validity
          var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
           '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
           '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
           '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
           '(\\?[;&a-z\\d%_.~+=-]*)?'); // query string
          return !!pattern.test(url);
      };

      this.isUrlCoopDomain = function (url) {
        var retailer_domain = getRetailerDomain()
        const regex = new RegExp(retailer_domain, 'g');
        return !!regex.test(url);
      };

      var getRetailerDomain = function() {
          if (config.store.name.endsWith("- Home Depot CA")) {
              return "homedepot.ca"
          }
          if (config.store.name.endsWith("- Home Depot US")) {
              return "homedepot.com"
          }
          if (config.store.name.endsWith("- Ren's Pets")) {
              return "renspets.com"
          }
      }

    }
]);