angular.module('vantageApp').directive('audienceSelector', [
  '$state',
  '$http',
  'Upload',
  'va.config',
  'model.Audience',
  'lib.Modal',
  'service.GoogleAnalyticsAdvancedEcomEvents',
  '$rootScope',
  function (
    $state,
    $http,
    Upload,
    config,
    Audience,
    Modal,
    AdvancedEcom,
    $rootScope
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/audience-selector.html',
      require: 'ngModel',
      scope: {
        store: '=',
        audiences: '=',
        campaign: '=',
      },

      link: function (scope, element, attrs, model) {
        var ctl = this;

        scope.jqCarousel = $('slick');
        scope.Audience = Audience;
        scope.slickInit = false;
        scope.audienceFields = {}; // Used for parameters on audiences
        scope.hasFacebookAccount = Boolean(config.store.facebook_ad_account_id);
        scope.isDemoing = config.isDemoing();
        scope.showAudienceSize = config.co_brand !== 'hdca';
        scope.backendAudiences = scope.audiences;
        scope.frontendAudiences = _.filter(
          _.map(
            scope.isDemoing
              ? Audience.DEFAULT_AUDIENCES.concat(Audience.DEMO_AUDIENCES)
              : Audience.DEFAULT_AUDIENCES,
            function (type) {
              var audience = Audience.create(type);
              if (config.store.is_brand) {
                switch (type) {
                  case Audience.WEBSITE_VISITORS:
                    audience.desc =
                      "Target anybody who's visited your product pages, regardless of where they bounced.";
                    break;
                  case Audience.RETAILER_PAGE_LIKES:
                    audience.showToBrands = true;
                    break;
                  case Audience.GENERIC_RETARGETING:
                  case Audience.GENERIC_PROSPECTING:
                    audience.showToBrands = false;
                    break;
                }
              }

              return audience;
            }
          ),
          function (audience) {
            switch (audience.type) {
              case Audience.BRAND_AFFINITY_RMP:
              case Audience.BRAND_AFFINITY:
              case Audience.BRAND_ACQUISITION:
                return config.store.name.endsWith('Home Depot US');
              default:
                return true;
            }
          }
        );

        ctl.geoAudiences = _.sortBy(
          _.filter(scope.backendAudiences, {
            type: 'GeoTargetingAudience',
            status: 'AVAILABLE',
          }),
          function (audience) {
            return audience.distance;
          }
        );

        ctl.backendAudiences = _.reduce(
          Audience.BACKEND_AUDIENCES,
          function (dict, type) {
            if (scope.isDemoing && type !== Audience.DYNAMIC_PROSPECTING) return dict;

            if (
              type === Audience.DEPARTMENT &&
              config.store.name.endsWith('Home Depot US')
            ) {
              return dict;
            }
            var audiences = _.filter(scope.backendAudiences, {
              type: type,
              status: 'AVAILABLE',
            });
            if (audiences.length > 0) {
              dict[type] = audiences;
              var audience = Audience.create(
                type,
                type !== Audience.DYNAMIC_PROSPECTING
              );
              if (config.store.is_brand && type === Audience.DEPARTMENT) {
                audience.showToBrands = true;
              }

              scope.frontendAudiences.push(audience);
            }

            return dict;
          },
          {}
        );

        scope.carouselNextSlide = function () {
          scope.jqCarousel.slick('slickNext');
        };
        scope.carouselPrevSlide = function () {
          scope.jqCarousel.slick('slickPrev');
        };
        scope.carouselGetTotalSlides = function () {
          var audiences = _.filter(
            scope.audiences,
            scope.filterRetailerAudiences
          );
          return audiences.length - 1;
        };
        scope.carouselGetSlideNumber = function () {
          try {
            // This isn't done initializing when the first digest cycle runs.
            return scope.jqCarousel.slick('slickCurrentSlide');
          } catch (ex) {
            _.noop(); // Putting the return here didn't work
          }
          return 0;
        };
        scope.carouselShowNextButton = function () {
          return (
            scope.carouselGetSlideNumber() <= scope.carouselGetTotalSlides() - 4
          );
        };
        scope.carouselShowPrevButton = function () {
          return scope.carouselGetSlideNumber() > 0;
        };

        var channelAtLeastOneAvailable = function (field) {
          var lookalikeAudience =
            ctl.backendAudiences[Audience.LOOKALIKE] || [];
          var areSomeAvailable = lookalikeAudience.some(function (audience) {
            return audience[field] === 'AVAILABLE';
          });
          return areSomeAvailable ? 'AVAILABLE' : 'UNAVAILABLE';
        };

        scope.audiences = _.map(
          scope.frontendAudiences,
          function (frontendAudience) {
            var backendAudience =
              _.find(scope.audiences, {
                type: frontendAudience.type,
                status: 'AVAILABLE',
              }) || {};

            frontendAudience.id = backendAudience.id;
            frontendAudience.status = backendAudience.status;
            frontendAudience.facebookChannelStatus =
              backendAudience.facebookChannelStatus;
            frontendAudience.googleChannelStatus =
              backendAudience.googleChannelStatus;
            frontendAudience.pinterestChannelStatus =
              backendAudience.pinterestChannelStatus;
            frontendAudience.size = backendAudience.size;
            frontendAudience.googleAudienceSize = backendAudience.googleAudienceSize;

            //Set the channel audience to available if at least one lookalike is available for that channel.
            if (frontendAudience.type === Audience.LOOKALIKE) {
              frontendAudience.facebookChannelStatus =
                channelAtLeastOneAvailable('facebookChannelStatus');
              frontendAudience.googleChannelStatus = channelAtLeastOneAvailable(
                'googleChannelStatus'
              );
            }

            return frontendAudience;
          }
        );

        scope.audiences = _.sortBy(scope.audiences, function (audience) {
          if (!audience.isAvailable()) return 2;

          switch (audience.type) {
            case Audience.CSV:
            case Audience.GEO_TARGETING:
            case Audience.LOYALTY:
              return 1;
            default:
              return 0;
          }
        });

        model.$render = function () {
          scope.audience = model.$viewValue;
        };

        scope.carouselSettings = {
          infinite: false,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          event: {
            init: function (event, slick) {
              // From Slick docks: Fires after first initialization.
              // Once this happens, we simply set the opacity of the surrounding div to 1.
              scope.slickInit = true;
              scope.scrollToActiveAudience();
            },
          },
        };

        // This function uploads a file for the csv audience
        scope.uploadAudience = function (file) {
          /* file may be empty, ngif-select calls this function when the file is cleared or selected */
          if (!file) {
            return;
          }
          Upload.upload({
            url: '/api/' + scope.store.id + '/audience/upload',
            data: { 'uploaded-audience.csv': file },
          }).then(
            function (resp) {
              var csvAudience = _.find(scope.audiences, function (audience) {
                return audience.isType(Audience.CSV);
              });
              csvAudience.errorCode = null;
              csvAudience.id = resp.data.objects[0].id;
              csvAudience.status = resp.data.objects[0].status;
            },
            function (resp) {
              var csvAudience = _.find(scope.audiences, function (audience) {
                return audience.isType(Audience.CSV);
              });
              csvAudience.errorCode = resp.data.errors[0];
            }
          );
        };

        scope.setAddressOnGeoAudience = function () {
          // Prevent the request if the field is blank
          var address = scope.audienceFields.address;
          if (address === undefined || address === '') {
            scope.geoLoadingState = false;
            scope.geoErrorState = true;
            return;
          }

          scope.geoLoadingState = true;
          scope.geoErrorState = false;

          $http({
            url: '/api/' + scope.store.id + '/audience/geotarget',
            data: {
              address: address,
            },
            method: 'POST',
          })
            .success(function (resp) {
              // Insert new audiences into the list.
              _.map(resp.objects, function (newAudience) {
                ctl.geoAudiences.push(newAudience);
              });
              // Show the audience as available.
              var geoAudience = _.find(scope.audiences, {
                type: Audience.GEO_TARGETING,
              });
              geoAudience.id = 'patched';

              scope.geoLoadingState = false;
            })
            .error(function (error_resp) {
              console.log('There was an error with the address');
              scope.geoLoadingState = false;
              scope.geoErrorState = true;
            });
        };

        scope.resetGeoAudience = function () {
          $http({
            url: '/api/' + scope.store.id + '/audience/geotarget',
            method: 'DELETE',
          })
            .success(function (resp) {
              var geoAudience = _.find(scope.audiences, {
                type: Audience.GEO_TARGETING,
              });
              geoAudience.id = null;

              // Remove the addresses from the list in the tile.
              ctl.geoAudiences = [];

              // Unset the selected audience when resetting the geo audiences.
              // It's possible that it's not the geo audience that is selected in
              // which case this could be annoying
              model.$setViewValue(null);
              model.$render();
            })
            .error(function (error_resp) {
              console.log('There was an error with removing the address');
            });
        };

        var confirmationBaseCondition = function (newAudience) {
          return (
            scope.audience &&
            scope.audience.type !== newAudience.type &&
            scope.campaign.totalAds() > 0
          );
        };

        scope.needsDpaSwitchConfirmation = function (newAudience) {
          return (
            confirmationBaseCondition(newAudience) &&
            newAudience.isDPA() !== scope.audience.isDPA()
          );
        };

        scope.needsShoppingSwitchConfirmation = function (newAudience) {
          return (
            confirmationBaseCondition(newAudience) &&
            newAudience.isGoogleShopping() !== scope.audience.isGoogleShopping()
          );
        };

        scope.needsPinterestShoppingSwitchConfirmation = function (newAudience) {
          return (
            confirmationBaseCondition(newAudience) &&
              newAudience.isPinterestShopping() !== scope.audience.isPinterestShopping()
          );
        }

        scope.needsGoogleSwitchConfirmation = function (newAudience) {
          //If they switch from a Google to non-Google audience, need confirmation.
          return (
            confirmationBaseCondition(newAudience) &&
            !newAudience.isAvailableGoogle() &&
            scope.audience.isAvailableGoogle()
          );
        };

        scope.changeFromGoogleConfirmation = function (audience) {
          Modal.show({
            templateUrl: 'views/modal/change-audience-google-confirmation.html',
            controller: 'controller.changeGoogleAudienceConfirmation',
            controllerAs: 'modal',
            inputs: {
              campaign: scope.campaign,
              model: model,
              audience: audience,
            },
          });
        };

        // Opens a confirmation modal for the user
        scope.changeAudienceConfirmation = function (audience) {
          Modal.show({
            templateUrl: 'views/modal/change-audience-confirmation.html',
            controller: 'controller.changeAudienceConfirmation',
            controllerAs: 'modal',
            inputs: {
              campaign: scope.campaign,
              model: model,
              audience: audience,
            },
          });
        };

        scope.scrollToNextStep = function () {
          $('#builder-anchor-step2')[0].scrollIntoView({
            behavior: 'smooth',
          });
        };

        scope.setAudience = function (selectedAudience) {
          if (
            scope.needsDpaSwitchConfirmation(selectedAudience) ||
            scope.needsShoppingSwitchConfirmation(selectedAudience) ||
            scope.needsPinterestShoppingSwitchConfirmation(selectedAudience)
          ) {
            scope.changeAudienceConfirmation(selectedAudience);
          } else if (scope.needsGoogleSwitchConfirmation(selectedAudience)) {
            scope.changeFromGoogleConfirmation(selectedAudience);
          } else {
            scope.campaign.setAudience(new Audience(_.clone(selectedAudience)));
            AdvancedEcom.pickAudience();
          }

          scope.unflipAll();
          scope.scrollToNextStep();
        };

        scope.isActive = function (type) {
          return (
            scope.audience &&
            scope.audience.isType &&
            scope.audience.isType(type)
          );
        };

        scope.filterRetailerAudiences = function (audience) {
          if (
            audience.hasOwnProperty('showToBrands') &&
            audience.showToBrands !== config.store.is_brand
          ) {
            return false;
          }

          if (config.store.is_brand && audience.type) {
            switch (audience.type) {
              case Audience.EXPLORE:
              case Audience.CSV:
                return false;
              case Audience.PAGE_LIKES:
                return !config.store.name.endsWith('Home Depot US');
              default:
                return audience.isAvailable();
            }
          }

          return true;
        };

        // This is only used by demo code.
        scope.goToExplore = function () {
          $state.go('explore');
        };

        scope.unflipAll = function () {
          $('.flip-container.flipped').removeClass('flipped');
        };

        scope.flipThis = function ($event) {
          scope.unflipAll();

          var $button = $($event.currentTarget);
          $button.parents('.flip-container').toggleClass('flipped');
        };

        scope.getAudiences = function (audienceType) {
          return ctl.backendAudiences[audienceType];
        };

        scope.scrollToActiveAudience = function () {
          window.setTimeout(function () {
            var audiences = _.filter(
              scope.audiences,
              scope.excludeDemoAudiences
            );
            audiences = _.filter(audiences, scope.filterRetailerAudiences);
            var selectedAudienceIndex = _.findIndex(
              audiences,
              function (audience) {
                return scope.isActive(audience.type);
              }
            );
            if (selectedAudienceIndex > 0) {
              scope.jqCarousel.slick('slickGoTo', selectedAudienceIndex, true);
              // Need to make sure the left/right scroll buttons are reset so trigger a digest cycle
              scope.$apply();
            }
          }, 1000);
        };

        scope.openFeedback = $rootScope.openFeedback;

        scope.showShareAudienceDialog = function () {
          Modal.show({
            templateUrl: 'views/modal/share-audience.html',
            controller: 'controller.shareAudience',
            controllerAs: 'modal',
          });
        };
      },
    };
  },
]);
