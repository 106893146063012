angular.module('vantageApp').directive('socialStatus', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/social-status.html',
      scope: {
        store: '='
      },
      link: function(scope) {
        scope.facebookPageIdTooltip = "In order for our ads to show your Facebook Page as " +
                                      "the source, we need to know what your Facebook Page is.";

        scope.agencyTooltip = "In order for us to publish ads on behalf of your Facebook Page, " +
                              "Facebook requires us to have Agency access.";

        scope.instagramIdTooltip = "Connect your Instagram Account and Facebook Page, so that " +
                                   "we can build powerful Instagram ads together.";

        scope.retailerIsHomeDepotCanada = scope.store.retailerIsHomeDepotCanada();
      }
    };
  }
]);
