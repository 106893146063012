angular.module('vantageApp').directive('budgetRecommendation', [
  '$rootScope',
  '$timeout',
  '$http',
  'va.config',
  function (rootScope, timeout, http, config) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/budget-recommendation.html',
      scope: {
        store: '=',
      },
      link: function (scope) {
        this.data = null;
        scope.monthsOfData = null;

        scope.isDemoing = function() {
          return config.isDemoing();
        };

        scope.hasData = function(){
          return this.data && this.data['history'];
        }.bind(this);

        var request = http.get('/api/' + config.store.id + '/budget_recommendation').then(
          function (response) {
            this.data = response['data'];
            if(scope.hasData()) {
              renderChart(this.data);
            }
          }
        );

        var createLabelsFromDates = function(dates){
          return _.map(dates, function(date) {
            return moment.utc(date).format('MMM YYYY');
          });
        };

        var renderChart = function (data) {
          var attractColor = '#78be43';
          var convertColor = '#f96302';
          var reengageColor = '#e81e25';

          var revenueColor = '#3eb1c8';

          var historyDateLabels = createLabelsFromDates(data['history']['dates']);

          var projectionDateLabels = null;

          if(data['projection']['dates'].length == 1){
            projectionDateLabels = ['Recommendation']
          }
          else if (data['projection']['dates'].length > 1){
            projectionDateLabels = createLabelsFromDates(data['projection']['dates']);
          }

          scope.monthsOfData = data['history']['dates'].length;

          var categories = historyDateLabels.concat(projectionDateLabels);

          var attractSpendData = data['history']['attractSpend'].concat(data['projection']['attractSpend']);
          var convertSpendData = data['history']['convertSpend'].concat(data['projection']['convertSpend']);
          var reengageSpendData = data['history']['reengageSpend'].concat(data['projection']['reengageSpend']);

          var overallRevenueData = _.clone(data['history']['overallRevenue']);
          overallRevenueData[overallRevenueData.length - 1] = {y: overallRevenueData[overallRevenueData.length - 1], marker: {enabled: true}};

          var projectedData = [];
          if(scope.monthsOfData){
            projectedData.push(data['history']['overallRevenue'][data['history']['overallRevenue'].length - 1])
          }
          projectedData = projectedData.concat(data['projection']['overallRevenue']);
          projectedData[projectedData.length-1] = {y: projectedData[projectedData.length-1], marker: {enabled: true}};

          var allSpendData = attractSpendData.concat(convertSpendData).concat(reengageSpendData).sort(function(a,b) { return a - b; } );
          var topSpend = allSpendData[parseInt(allSpendData.length * 0.75)];
          var maxSpend = _.max(allSpendData);
          var adSpendAxisMax =  _.max([maxSpend, topSpend*4]);

          var maxRevenue = _.max(data['history']['overallRevenue'].concat(data['projection']['overallRevenue']));

          this.chart = {
            chart: {
              spacingLeft: 0,
              spacingBottom: 0,
              spacingRight: 0,
            },
            title: {
              text: ''
            },
            xAxis: {
              categories: categories
            },
            yAxis: [{
              id: 'a',
              title: {
                text: 'Ad Spend'
              }, labels: {
                formatter: function () {
                  return numbro(this.value).format('$0,0.[00]a');
                }
              },
              gridLineColor: '#edf1f5',
              tickAmount: 5,
              min: 0,
              max: adSpendAxisMax
            },
              {
                id: 'b',
                title: {
                  text: 'Sales'
                },
                labels: {
                  formatter: function () {
                    return numbro(this.value).format('$0,0.[00]a');
                  }
                },
                gridLineColor: '#edf1f5',
                min: 0,
                max: maxRevenue,
                opposite: true,
                tickAmount: 5,
                left: 62
              }
            ],
            plotOptions: {
              series: {
                allowPointSelect: true,
                marker: {
                  enabled: false,
                },
                events: {
                  legendItemClick: function () {
                    return false;
                  }
                }
              }
            },
            tooltip: {
              valuePrefix: '$',
              shared: true,
              formatter: function (tooltip) {
                if (this.y === undefined) {
                  return false;
                }

                //Prevent projected sales from appearing in the tooltip
                //for the last point in the historical revenue series.
                if (this.x === historyDateLabels[historyDateLabels.length - 1]) {
                  this.points = this.points.filter(
                    function (point) {
                      return point['series']['name'] !== "Projected Campaign Sales";
                    }
                  )
                }

                return tooltip.defaultFormatter.call(this, tooltip);
              }
            },
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'top',
              float: true,
            },
            credits: {
              enabled: false
            },
            series: [
              {
                type: 'column',
                name: 'Attract Ad Spend',
                data: attractSpendData,
                color: attractColor,
              }, {
                type: 'column',
                name: 'Convert Ad Spend',
                data: convertSpendData,
                color: convertColor
              }, {
                type: 'column',
                name: 'Re-engage Ad Spend',
                data: reengageSpendData,
                color: reengageColor
              },
              {
                id: 'projected',
                name: 'Projected Campaign Sales',
                type: 'line',
                zIndex: 2,
                dashStyle: 'shortdot',
                color: revenueColor,
                marker: {
                  fillColor: revenueColor,
                  lineWidth: 0,
                  lineColor: revenueColor,
                  symbol: 'circle'
                },
                data: projectedData,
                pointStart: (scope.monthsOfData ? overallRevenueData.length - 1 : 0),
                yAxis: 'b',
                showInLegend: !Boolean(scope.monthsOfData)
              },
              {
                type: 'flags',
                data: [{
                  x: categories.length - 1,
                  title: 'Using recommended budget',
                }],
                onSeries: 'projected',
                shape: 'squarepin',
                color: revenueColor,
                lineWidth: 2,
                zIndex: 3,
                style: {
                  color: revenueColor,
                  textAlign: 'center'
                },
                showInLegend: false
              }
            ]
          };

          if(scope.monthsOfData){
            var history_series = [
              {
                id: 'current',
                name: 'Campaign Sales',
                type: 'line',
                zIndex: 2,
                lineWidth: 3,
                color: revenueColor,
                marker: {
                  fillColor: revenueColor,
                  lineWidth: 0,
                  lineColor: revenueColor,
                },
                data: overallRevenueData,
                yAxis: 'b'
              },
              {
                type: 'flags',
                data: [{
                  x: overallRevenueData.length - 1,
                  title: 'This month',
                }],
                onSeries: 'current',
                shape: 'squarepin',
                color: revenueColor,
                lineWidth: 2,
                zIndex: 3,
                style: {
                  color: revenueColor,
                  textAlign: 'center',
                },
                showInLegend: false
              },
            ];
            this.chart['series'] = this.chart['series'].concat(history_series);
          }

          var chartIdentifier = '.budget-recommendation-chart';
          timeout(function () {
            $(chartIdentifier).highcharts(this.chart);
          }, 0);

        }
      }
    };
  }
]);
